// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom class names prefixed with 'subscription-panel' */
.subscription-panel-layout {
  display: flex;
  padding: 20px;
  min-height: 100vh;
}

.subscription-panel-sidebar {
  width: 250px; /* Adjust the width of your sidebar */
}

.subscription-panel-content {
  flex-grow: 1; /* This allows the content area to take up available space */
  margin-left: 20px; /* Space between sidebar and content */
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .subscription-panel-layout {
      flex-direction: column; /* Stack sidebar and content */
  }

  .subscription-panel-sidebar {
      width: 100%; /* Full width on small screens */
  }

  .subscription-panel-content {
      margin-left: 0; /* Remove margin for small screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/SubscriptiopnPanel/SubscriptionPanelPageLayout.css"],"names":[],"mappings":"AAAA,0DAA0D;AAC1D;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY,EAAE,qCAAqC;AACrD;;AAEA;EACE,YAAY,EAAE,4DAA4D;EAC1E,iBAAiB,EAAE,sCAAsC;EACzD,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA,0CAA0C;AAC1C;EACE;MACI,sBAAsB,EAAE,8BAA8B;EAC1D;;EAEA;MACI,WAAW,EAAE,gCAAgC;EACjD;;EAEA;MACI,cAAc,EAAE,oCAAoC;EACxD;AACF","sourcesContent":["/* Custom class names prefixed with 'subscription-panel' */\n.subscription-panel-layout {\n  display: flex;\n  padding: 20px;\n  min-height: 100vh;\n}\n\n.subscription-panel-sidebar {\n  width: 250px; /* Adjust the width of your sidebar */\n}\n\n.subscription-panel-content {\n  flex-grow: 1; /* This allows the content area to take up available space */\n  margin-left: 20px; /* Space between sidebar and content */\n  padding: 20px;\n  background-color: #fff;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n\n/* Responsive design for smaller screens */\n@media (max-width: 768px) {\n  .subscription-panel-layout {\n      flex-direction: column; /* Stack sidebar and content */\n  }\n\n  .subscription-panel-sidebar {\n      width: 100%; /* Full width on small screens */\n  }\n\n  .subscription-panel-content {\n      margin-left: 0; /* Remove margin for small screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
