import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../Footer';
import './PageLayout.css'; // Optional: add common styles here

//TODO FIX DESIGN, design for bulkorder1 is good

const PageLayout = ({ children, pageType }) => {
  const isBulkOrder = pageType === 'bulkorder1';
  return (
    <>
      <Navbar />
      <div className="page-layout" style={{ width: isBulkOrder ? '100%' : '' }}>
        <Sidebar />
        <div className="" style={{ width: isBulkOrder ? '100%' : '' }}> {/* Add a wrapper class for styling */}
          <div className="" style={{ width: isBulkOrder ? '100%' : '' }}> {/*classname was content*/}
            {children} {/* Content of each page will be passed here */}
          </div>
        </div>
      </div>
      <Footer/> {/* Include the Footer component here */}
    </>
  );
};

export default PageLayout;