import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faUser,
  faLink,
  faDollarSign,
  faSearch,
  faShoppingCart,
  faLightbulb,
  faChartLine,
  faFileInvoice,
  faWallet,
  faFilter,
  faGlobe,
  faCog
} from '@fortawesome/free-solid-svg-icons';
import PageLayout from './components/PageLayout';

const HowToUseItAdvertiser = () => {
  return (
    <PageLayout>
      <div className="px-3">
        <h1><FontAwesomeIcon icon={faBook} className="mr-2" /> AdverLink Advertiser Guide: How to Get Started</h1>
        <p>Welcome to AdverLink! This comprehensive guide will walk you through the process of setting up your advertiser account and placing your first links. Follow these steps to maximize your link-building campaign effectiveness.</p>

        <h2>Get Started in 4 Easy Steps</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-4 p-4 border rounded-lg">
            <h3><FontAwesomeIcon icon={faUser} className="mr-2" /> Step 1: Complete Your Profile</h3>
            <p>Set up your account to start placing links:</p>
            <ul>
              <li><strong>Access Account Settings:</strong> Navigate to <a href="/MyAccount">My Account</a></li>
              <li><strong>Fill Required Information:</strong>
                <ul>
                  <li>Personal Details: Name, address, contact information</li>
                  <li>Company Information (if applicable): Company name, VAT number, registration details</li>
                  <li>Billing Information: Payment method and billing address</li>
                </ul>
              </li>
              <li><strong>Save Your Profile:</strong> Ensure all information is accurate before saving</li>
            </ul>
            <p className="tip"><FontAwesomeIcon icon={faLightbulb} className="mr-2" /> <strong>Tip:</strong> Complete all profile fields to unlock full platform functionality.</p>
          </div>

          <div className="mb-4 p-4 border rounded-lg">
            <h3><FontAwesomeIcon icon={faWallet} className="mr-2" /> Step 2: Fund Your Account</h3>
            <p>Add funds to start placing orders:</p>
            <ul>
              <li><strong>Access Wallet:</strong> Go to <a href="/Wallet">Wallet</a> section</li>
              <li><strong>Add Funds:</strong>
                <ul>
                  <li>Choose your preferred payment method</li>
                  <li>Select or enter the amount to deposit</li>
                  <li>Complete the payment process</li>
                </ul>
              </li>
              <li><strong>View Balance:</strong> Monitor your available funds in real-time</li>
            </ul>
            <p className="tip"><FontAwesomeIcon icon={faLightbulb} className="mr-2" /> <strong>Tip:</strong> Keep your wallet funded to ensure uninterrupted link placements.</p>
          </div>

          <div className="mb-4 p-4 border rounded-lg">
            <h3><FontAwesomeIcon icon={faSearch} className="mr-2" /> Step 3: Find Suitable Websites</h3>
            <p>Search and filter websites for your link placement:</p>
            <ul>
              <li><strong>Use Search Filters:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faGlobe} /> Domain Authority</li>
                  <li><FontAwesomeIcon icon={faFilter} /> Website Categories</li>
                  <li><FontAwesomeIcon icon={faDollarSign} /> Price Range</li>
                  <li>Language and Geographic Location</li>
                </ul>
              </li>
              <li><strong>Review Website Metrics:</strong>
                <ul>
                  <li>Traffic statistics</li>
                  <li>Content quality</li>
                  <li>Link placement options</li>
                </ul>
              </li>
            </ul>
            <p className="tip"><FontAwesomeIcon icon={faLightbulb} className="mr-2" /> <strong>Tip:</strong> Save your favorite websites for quick access in future campaigns.</p>
          </div>

          <div className="mb-4 p-4 border rounded-lg">
            <h3><FontAwesomeIcon icon={faLink} className="mr-2" /> Step 4: Place Your Links</h3>
            <p>Order and manage your link placements:</p>
            <ul>
              <li><strong>Single Link Orders:</strong>
                <ul>
                  <li>Select target website</li>
                  <li>Enter your keyword and URL</li>
                  <li>Choose link type (Dofollow, Nofollow, etc.)</li>
                  <li>Set exclusivity options</li>
                </ul>
              </li>
              <li><strong>Bulk Orders:</strong>
                <ul>
                  <li>Upload CSV file with multiple links</li>
                  <li>Review and confirm placements</li>
                  <li>Track bulk order progress</li>
                </ul>
              </li>
            </ul>
            <p className="tip"><FontAwesomeIcon icon={faLightbulb} className="mr-2" /> <strong>Tip:</strong> Use bulk orders for efficient management of multiple link placements.</p>
          </div>
        </div>

        <div className="mt-6">
          <h2><FontAwesomeIcon icon={faChartLine} className="mr-2" /> Monitoring and Optimization</h2>
          <div className="p-4 border rounded-lg">
            <ul>
              <li><strong>Track Performance:</strong>
                <ul>
                  <li>Monitor link status and visibility</li>
                  <li>Review traffic and engagement metrics</li>
                  <li>Generate performance reports</li>
                </ul>
              </li>
              <li><strong>Manage Orders:</strong>
                <ul>
                  <li>View order history</li>
                  <li>Check link placement status</li>
                  <li>Handle renewals and cancellations</li>
                </ul>
              </li>
              <li><strong>Billing and Invoices:</strong>
                <ul>
                  <li>Access payment history</li>
                  <li>Download invoices</li>
                  <li>Manage subscription settings</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* YouTube Video Embed */}
        {/* <div className="my-6">
          <h2>Watch Our Video Tutorial</h2>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/VIDEO_ID" // Replace VIDEO_ID with actual YouTube video ID
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div> */}

        {/* <div className="mt-6">
          <h2><FontAwesomeIcon icon={faCog} className="mr-2" /> Additional Resources</h2>
          <ul>
            <li>FAQ section for common questions</li>
            <li>Best practices for link building</li>
            <li>Support contact information</li>
          </ul>
        </div> */}
      </div>
    </PageLayout>
  );
};

export default HowToUseItAdvertiser; 