import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { BASE_API_URL } from '../src/config/constants';
import targetUrlImg from './assets/images/targeturl.png';
import './AdminLinksReports.css';
import { Alert } from 'react-bootstrap';

const AdminLinksReports = () => {
  const [linksReports, setLinksReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [error, setError] = useState(''); // State to manage error messages
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLinksReports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BASE_API_URL}api/linksreports/get-links-for-admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLinksReports(response.data);
      } catch (error) {
        console.error('Error fetching links reports:', error);
        setError('Failed to fetch links reports.');
      } finally {
        setIsLoading(false); // Loading is done after data is fetched (or failed)
      }
    };

    fetchLinksReports();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-fluid px-5">
        <div className="row">
          <Sidebar className="col-md-3" />
          <div className="col-md-10">
              {/* Alert for error messages */}
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}
            <div className="content">
              <h2 className="my-4">Admin Links Reports</h2>
              {isLoading ? (
                <p className="p-911">Loading...</p> // Show loading during fetch
              ) : (
                <>
                  {linksReports.length === 0 ? (
                    <p>No links reports available.</p> // No data message
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th>Keyword</th>
                            <th>URL</th>
                            <th>Title</th>
                            <th>Exclusivity</th>
                            <th>Link Type</th>
                            <th>Publisher Website</th>
                            <th>Created</th>
                            <th>Domain Authority</th>
                            <th>Monthly Value</th>
                            <th>Occurrences</th>
                            <th>Target URL</th>
                            <th>Active Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {linksReports.map((link, index) => (
                            <tr key={index}>
                              <td>{link.keyword}</td>
                              <td>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                  {link.url}
                                </a>
                              </td>
                              <td>{link.title}</td>
                              <td>{link.exclusivity ? 'Yes' : 'No'}</td>
                              <td>{link.typeOfLink == 'NormalFollow' ? 'Follow' : link.typeOfLink}</td>
                              <td>{link.publisherWebsite}</td>
                              <td>{link.dateCreated}</td>
                              <td>{link.domainAuthority}</td>
                              <td>${link.monthlyValue}</td>
                              <td>{link.occurences}</td>
                              <td>
                                {link.targetUrl && (
                                  <a href={link.targetUrl} target="_blank" rel="noopener noreferrer">
                                    <img src={targetUrlImg} alt="Target url" style={{ width: '16px', height: '16px' }} title={link.targetUrl} />
                                  </a>
                                )}
                              </td>
                              <td>{link.isActive ? 'Active' : 'Inactive'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLinksReports;
