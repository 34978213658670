import React, { useEffect, useState } from "react";

const Theme = ({ data, updateData, errors, setErrors }) => {
  const [formData, setFormData] = useState({
    description: data.description ? data.description : "",
    tags: data.tags ? data.tags : "",
    acceptedCategories: data.acceptedCategories?.length
      ? data.acceptedCategories
      : [],
    blockedWebsites: data.blockedWebsites ? data.blockedWebsites : "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (checked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          acceptedCategories: [...prevFormData.acceptedCategories, value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          acceptedCategories: prevFormData.acceptedCategories.filter(
            (category) => category !== value
          ),
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      themeData: {
        ...prevErrors.themeData,
        [name]: ''
      }
    }));
  };

  useEffect(() => {
    updateData(formData); // Trigger update in parent component
  }, [formData]);

  return (
    <div style={{ width: "130%" }}>
      <div className="form-group">
        <label>Short Description *</label>
        <textarea
          id="description"
          style={{ resize: "none" }}
          className={`form-control ${errors && errors.description ? 'is-invalid' : ''}`}
          placeholder="Enter the description of your website (maximum 600 characters)"
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          rows={4}
        />
        {errors && errors.description && <div className="text-danger">{errors.description}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="tags">Tags *</label>
        <input
          id="tags"
          placeholder="Enter your tags"
          type="text"
          name="tags"
          value={formData.tags}
          onChange={handleChange}
          required
          className={`form-control ${errors && errors.tags ? 'is-invalid' : ''}`}
        />
        {errors && errors.language && <div className="text-danger">{errors.language}</div>}
        <p style={{ fontSize: ".8em" }} className="text-end">
          MAXIMUM 10 NUMBERS OF TAGS (0/10)
        </p>
      </div>
      <div className="form-group">
        <p>I accept keywords and links from business in:</p>
        <div
          style={{ color: "#8f8f8f", gap: "1em" }}
          className="d-sm-flex mb-3"
        >
          <span>
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Gambling"
              checked={formData.acceptedCategories.includes("Gambling")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Gambling',
                  checked: !formData.acceptedCategories.includes('Gambling')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Gambling
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="CBD"
              checked={formData.acceptedCategories.includes("CBD")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'CBD',
                  checked: !formData.acceptedCategories.includes('CBD')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              CBD
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Cryptocurrencies"
              checked={formData.acceptedCategories.includes("Cryptocurrencies")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Cryptocurrencies',
                  checked: !formData.acceptedCategories.includes('Cryptocurrencies')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Cryptocurrencies
            </span>
            <br />
          </span>
          <span>
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Erotica"
              checked={formData.acceptedCategories.includes("Erotica")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Erotica',
                  checked: !formData.acceptedCategories.includes('Erotica')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Erotica
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Dietary supplements and pharmacy"
              checked={formData.acceptedCategories.includes(
                "Dietary supplements and pharmacy"
              )}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Dietary supplements and pharmacy',
                  checked: !formData.acceptedCategories.includes('Dietary supplements and pharmacy')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Dietary supplements and pharmacy
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Loans"
              checked={formData.acceptedCategories.includes("Loans")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Loans',
                  checked: !formData.acceptedCategories.includes('Loans')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Loans
            </span>
            <br />
          </span>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="blockedWebsites">List of blocked websites</label>
        <textarea
          id="blockedWebsites"
          style={{ resize: "none" }}
          className="form-control"
          placeholder="Enter the list comma separated"
          type="text"
          name="blockedWebsites"
          value={formData.blockedWebsites}
          onChange={handleChange}
          required
          rows={4}
        />
      </div>
    </div>
  );
};

export default Theme;
