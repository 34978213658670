import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../../../config/constants';
import { Download } from 'lucide-react';
import toast from 'react-hot-toast';
import AdminSpinner from '../../AdminSpinner';

const PublisherInvoicesManager = () => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloadingId, setDownloadingId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        try {
            const response = await axios.get(
                `${BASE_API_URL}api/publisherinvoiceupload/get-all-invoices`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setInvoices(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching invoices:', error);
            toast.error('Failed to fetch invoices', {
                duration: 4000,
                position: 'top-right',
                style: {
                    background: '#EF4444',
                    color: 'white',
                },
                icon: '❌'
            });
            setLoading(false);
        }
    };

    const handleDownload = async (invoiceId) => {
        let loadingToast;
        try {
            setDownloadingId(invoiceId);
            loadingToast = toast.loading('Downloading invoice...', {
                position: 'top-right'
            });

            const response = await axios.get(
                `${BASE_API_URL}api/publisherinvoiceupload/download/${invoiceId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob'
                }
            );

            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice-${invoiceId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            toast.dismiss(loadingToast);
            setDownloadingId(null);
            toast.success('Invoice downloaded successfully!', {
                duration: 4000,
                position: 'top-right',
                style: {
                    background: '#10B981',
                    color: 'white',
                },
                icon: '📥'
            });
        } catch (error) {
            console.error('Error downloading invoice:', error);
            toast.dismiss(loadingToast);
            setDownloadingId(null);
            toast.error('Failed to download invoice. Please try again.', {
                duration: 5000,
                position: 'top-right',
                style: {
                    background: '#EF4444',
                    color: 'white',
                },
                icon: '❌'
            });
        }
    };

    const filteredInvoices = invoices.filter(invoice => 
        invoice.user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.fileName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="publisher-invoices-container">
            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by publisher email or filename..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            {loading ? (
                <div className="text-center p-4">
                    <AdminSpinner />
                </div>
            ) : (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Publisher</th>
                            <th>File Name</th>
                            <th>Upload Date</th>
                            <th>Period</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredInvoices.map((invoice) => (
                            <tr key={invoice.id}>
                                <td>{invoice.user.email}</td>
                                <td>{invoice.fileName}</td>
                                <td>{new Date(invoice.uploadDate).toLocaleDateString()}</td>
                                <td>
                                    {new Date(invoice.dateStart).toLocaleDateString()} - 
                                    {new Date(invoice.dateEnd).toLocaleDateString()}
                                </td>
                                <td>
                                    <button
                                        className="btn btn-sm btn-outline-primary"
                                        onClick={() => handleDownload(invoice.id)}
                                        title="Download Invoice"
                                        disabled={downloadingId === invoice.id}
                                    >
                                        {downloadingId === invoice.id ? (
                                            <AdminSpinner size="sm" />
                                        ) : (
                                            <Download size={16} />
                                        )}
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {filteredInvoices.length === 0 && (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No invoices found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default PublisherInvoicesManager; 