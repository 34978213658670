import React, { useEffect, useState } from "react";
import { BASE_API_URL } from '../src/config/constants';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const Theme = ({ data, updateData, errors, setErrors, domain }) => {
  // Custom tooltip style with larger text
  const tooltipStyle = {
    tooltip: {
      fontSize: '14px',
      padding: '10px 12px',
      maxWidth: '300px',
      lineHeight: '1.4',
      zIndex: 9999
    }
  };

  // Function to determine tooltip placement based on screen width
  const getTooltipPlacement = () => {
    return window.innerWidth <= 768 ? "bottom" : "right";
  };

  const [formData, setFormData] = useState({
    description: data.description ? data.description : "",
    tags: data.tags ? data.tags : "",
    acceptedCategories: data.acceptedCategories?.length
      ? data.acceptedCategories
      : [],
    blockedWebsites: data.blockedWebsites ? data.blockedWebsites : "",
    uniqueUsers: data.uniqueUsers ? data.uniqueUsers : "",
  });

  const [isLoading, setIsLoading] = useState({
    description: false,
    tags: false
  });

  const handleGenerateDescription = async () => {
    if (!domain) {
      setErrors(prev => ({
        ...prev,
        themeData: {
          ...prev.themeData,
          description: 'Please enter a domain in Basic Data first.'
        }
      }));
      return;
    }

    try {
      setIsLoading(prev => ({ ...prev, description: true }));
      const response = await fetch(`${BASE_API_URL}api/ChatGPT/generate-description-website`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          domain: domain
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate description');
      }

      const result = await response.text();
      setFormData(prev => ({ ...prev, description: result }));
    } catch (error) {
      console.error('Error generating description:', error);
      setErrors(prev => ({
        ...prev,
        themeData: {
          ...prev.themeData,
          description: 'Failed to generate description. Please try again.'
        }
      }));
    } finally {
      setIsLoading(prev => ({ ...prev, description: false }));
    }
  };

  const handleGenerateTags = async () => {
    if (!domain) {
      setErrors(prev => ({
        ...prev,
        themeData: {
          ...prev.themeData,
          tags: 'Please enter a domain in Basic Data first.'
        }
      }));
      return;
    }

    try {
      setIsLoading(prev => ({ ...prev, tags: true }));
      const response = await fetch(`${BASE_API_URL}api/ChatGPT/generate-tags-website`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          domain: domain
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate tags');
      }

      const result = await response.text();
      setFormData(prev => ({ ...prev, tags: result }));
    } catch (error) {
      console.error('Error generating tags:', error);
      setErrors(prev => ({
        ...prev,
        themeData: {
          ...prev.themeData,
          tags: 'Failed to generate tags. Please try again.'
        }
      }));
    } finally {
      setIsLoading(prev => ({ ...prev, tags: false }));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (checked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          acceptedCategories: [...prevFormData.acceptedCategories, value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          acceptedCategories: prevFormData.acceptedCategories.filter(
            (category) => category !== value
          ),
        }));
      }
    } else if (name === "uniqueUsers") {
      // Handle unique users input with comma formatting
      const numericValueWithCommas = value.replace(/[^0-9,]/g, '');
      setFormData((prevFormData) => ({
        ...prevFormData,
        uniqueUsers: numericValueWithCommas,
      }));
      
      // Clear any errors
      setErrors(prevErrors => ({
        ...prevErrors,
        themeData: {
          ...prevErrors.themeData,
          uniqueUsers: '',
        }
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      themeData: {
        ...prevErrors.themeData,
        [name]: ''
      }
    }));
  };

  useEffect(() => {
    updateData(formData);
  }, [formData]);

  return (
    <div style={{ width: "130%" }}>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <div className="d-flex align-items-start">
            <label style={{ whiteSpace: 'nowrap', marginRight: '8px', marginTop: '4px' }}>Short Description *</label>
            <button 
              type="button" 
              className="btn btn-outline-primary btn-sm"
              onClick={handleGenerateDescription}
              disabled={isLoading.description}
              style={{ marginTop: '0' }}
            >
              {isLoading.description ? 'Generating...' : 'Generate Description'}
            </button>
          </div>
          <Tooltip 
            title="Provide a brief overview of your website to help advertisers understand your content. Use the 'Generate' button for an AI-generated suggestion, review it, and post." 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <textarea
          id="description"
          style={{ resize: "none", maxWidth: '90%' }}
          className={`form-control ${errors && errors.description ? 'is-invalid' : ''}`}
          placeholder="Enter the description of your website (maximum 600 characters)"
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          rows={4}
        />
        {errors && errors.description && <div className="text-danger">{errors.description}</div>}
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <div className="d-flex align-items-start">
            <label htmlFor="tags" style={{ whiteSpace: 'nowrap', marginRight: '8px', marginTop: '4px' }}>Tags *</label>
            <button 
              type="button" 
              className="btn btn-outline-primary btn-sm"
              onClick={handleGenerateTags}
              disabled={isLoading.tags}
              style={{ marginTop: '0' }}
            >
              {isLoading.tags ? 'Generating...' : 'Generate Tags'}
            </button>
          </div>
          <Tooltip 
            title="Enter relevant keywords for your website (e.g., food, recipes). Separate tags with commas." 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <input
          id="tags"
          placeholder="Enter your tags"
          type="text"
          name="tags"
          value={formData.tags}
          onChange={handleChange}
          required
          style={{ maxWidth: '90%' }}
          className={`form-control ${errors && errors.language ? 'is-invalid' : ''}`}
        />
        {errors && errors.language && <div className="text-danger">{errors.language}</div>}
        <p style={{ fontSize: ".8em" }} className="text-end">
          MAXIMUM 10 NUMBERS OF TAGS (0/10)
        </p>
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <p>I accept keywords and links from business in:</p>
          <Tooltip 
            title="Select industries you allow links from—this can increase the value of your links. Pricing details will appear in each order." 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <div
          style={{ color: "#8f8f8f", gap: "1em", maxWidth: '90%' }}
          className="d-sm-flex mb-3"
        >
          <span>
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Gambling"
              checked={formData.acceptedCategories.includes("Gambling")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Gambling',
                  checked: !formData.acceptedCategories.includes('Gambling')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Gambling
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="CBD"
              checked={formData.acceptedCategories.includes("CBD")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'CBD',
                  checked: !formData.acceptedCategories.includes('CBD')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              CBD
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Cryptocurrencies"
              checked={formData.acceptedCategories.includes("Cryptocurrencies")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Cryptocurrencies',
                  checked: !formData.acceptedCategories.includes('Cryptocurrencies')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Cryptocurrencies
            </span>
            <br />
          </span>
          <span>
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Erotica"
              checked={formData.acceptedCategories.includes("Erotica")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Erotica',
                  checked: !formData.acceptedCategories.includes('Erotica')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Erotica
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Dietary supplements and pharmacy"
              checked={formData.acceptedCategories.includes(
                "Dietary supplements and pharmacy"
              )}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Dietary supplements and pharmacy',
                  checked: !formData.acceptedCategories.includes('Dietary supplements and pharmacy')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Dietary supplements and pharmacy
            </span>
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Loans"
              checked={formData.acceptedCategories.includes("Loans")}
              onChange={handleChange}
            />
            <span 
              onClick={() => handleChange({ 
                target: { 
                  type: 'checkbox',
                  name: 'acceptedCategories',
                  value: 'Loans',
                  checked: !formData.acceptedCategories.includes('Loans')
                }
              })}
              style={{ cursor: 'pointer' }}
            >
              Loans
            </span>
            <br />
          </span>
        </div>
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <label htmlFor="blockedWebsites">List of blocked websites</label>
          <Tooltip 
            title="Specify domains you do not accept for link insertions (e.g., competitors or unwanted sites). Type each domain on a new line and press Enter." 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <textarea
          id="blockedWebsites"
          style={{ resize: "none", maxWidth: '90%' }}
          className="form-control"
          placeholder="Enter the list comma separated"
          type="text"
          name="blockedWebsites"
          value={formData.blockedWebsites}
          onChange={handleChange}
          rows={4}
        />
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <label htmlFor="uniqueUsers">Number of unique users per month</label>
          <Tooltip 
            title="This number is important because advertisers usually decide to insert a link based on both domain authority and visitor traffic. If you do not provide this number, Adverlink will estimate it based on its data and website crawls." 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <input
          id="uniqueUsers"
          placeholder="Number of visitors"
          type="text"
          name="uniqueUsers"
          value={formData.uniqueUsers}
          onChange={handleChange}
          onBlur={() => {
            if (formData.uniqueUsers) {
              const cleanedValue = formData.uniqueUsers.replace(/,/g, '');
              const numericValue = parseInt(cleanedValue, 10);
              if (!isNaN(numericValue)) {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  uniqueUsers: numericValue.toString(),
                }));
              }
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === '-' || e.key === '+') {
              e.preventDefault();
            }
            const value = e.target.value;
            const lastChar = value[value.length - 1];
            if (lastChar === ',' && (value[value.length - 2] === ',' || value[0] === ',')) {
              e.preventDefault();
            }
          }}
          style={{ maxWidth: '90%' }}
          className={`form-control ${errors && errors.themeData?.uniqueUsers ? 'is-invalid' : ''}`}
        />
        {errors && errors.themeData?.uniqueUsers && <div className="text-danger">{errors.themeData.uniqueUsers}</div>}
      </div>
    </div>
  );
};

export default Theme;
