import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import logoImage from './assets/images/Link-Building-Software.png';
import googleLogo from './assets/images/google-logo.png';
import { useAuth } from './authService';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { jwtDecode } from 'jwt-decode';

const ChooseUserType = () => {
  
  const token = localStorage.getItem('token');
  const { setUser, setUserType, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userType: '',
  });

  const [messages, setMessages] = useState({
    error: '',
    success: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleLogout = () => {
    // Instead of navigating, we'll reload the page to reset all states
    window.location.href = '/login';
    setIsLoggedIn(false);
    localStorage.removeItem('token');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.userType === '') {
      setMessages({
        error: 'You need to select user type!',
        success: '',
      });
      return;
    }

    try {
      setIsLoading(true);
      const userType = formData.userType;
      const email = jwtDecode(token).sub;

      const updateUserTypeResponse = await axios.put(
        `${BASE_API_URL}/api/User/updateUserType`, 
        {
          email: email,
          userType: userType
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      if (updateUserTypeResponse.status !== 200) {
        throw new Error('Error updating user type');
      }

      // Get the new token from the response
      const newToken = updateUserTypeResponse.data.token;
      
      // Store the new token
      localStorage.setItem('token', newToken);

      // Decode the new token and update auth context
      const decodedToken = jwtDecode(newToken);
      setUser(decodedToken);
      setUserType(userType);
      setIsLoggedIn(true);

      // Navigate to the appropriate page
      window.location.href = `/${userType}`;
        
    } catch (error) {
      console.error(error);
      setMessages({
        error: 'Error updating user type. Please try again.',
        success: '',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-container">
      <div className="left-side ">
        <div className="messages">
          {messages.error && <div className="error-message">{messages.error}</div>}
          {messages.success && <div className="success-message">{messages.success}</div>}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group tooltip-container">
            <div className='d-flex justify-content-between pe-2'>
              <label className='w-50' htmlFor="userType">User type:</label>
              <p class="font-italic">𝒊</p>
            </div>
            <select
              style={{ maxWidth: '100%', marginTop: '5px' }}
              id="userType"
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              // required
            >
              <option value="" disabled>Select user type</option>
              <option value="advertiser">Advertiser - Link buyer</option>
              <option value="publisher">Publisher - Link seller</option>
            </select>

            <span className="tooltiptext">Choose Advertiser if you are looking to buy links, choose Publisher if you are looking to sell links</span>
          </div>
          <button 
            type="submit" 
            className="btn btn-primary" 
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Confirming...
              </>
            ) : (
              'Confirm'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChooseUserType;
