import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { BASE_API_URL } from './config/constants';
import { jwtDecode } from 'jwt-decode';

const stripePromise = loadStripe('pk_live_51N3JFnL4S5a1WxAjiPBiwb4ShFOftVd2QS4TPJH9dSqSCLylagT0XFSYbPKCppctTwT4WeXwf2wed4Ya05mVYchL00sGrXx9vV');

const token = localStorage.getItem('token');

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const Checkout = ({ amount, products }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');

  useEffect(() => {
    const createSubscription = async () => {
      try {

        const response = await fetch(`${BASE_API_URL}api/checkout/create-subscription`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            email: getUserName(token),
            amount: amount.toString(),
            products: products
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
        setSubscriptionId(data.subscriptionId);
      } catch (error) {
        console.error('Error creating subscription:', error);
      }
    };

    createSubscription();
  }, []);

  const handlePaymentMethodId = async (id) => {
    setPaymentMethodId(id); // Set the paymentMethodId

    try {
      const response = await fetch(`${BASE_API_URL}api/checkout/update-payment-method`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          email: getUserName(token),
          paymentMethodId: id
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok for payment method update');
      }
    } catch (error) {
      console.error('Error updating payment method:', error);
    }
  };

  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
        clientSecret={clientSecret} 
        subscriptionId={subscriptionId} 
        amount={amount}
        products={products}
        onPaymentMethodId={handlePaymentMethodId} // Pass callback function
      />
    </Elements>
  );
};

export default Checkout;
