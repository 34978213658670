import React from 'react';
import '../SubscriptionPanel.css';

const ProductRemovalModal = ({ isOpen, onClose, onConfirm, productName }) => {
    if (!isOpen) return null;

    return (
        <div className="subscriptionpanel-overlay" onClick={onClose}>
            <div className="subscriptionpanel-content" onClick={(e) => e.stopPropagation()}>
                <div className="subscriptionpanel-header">
                    <h2>Remove Keyword</h2>
                </div>
                <div className="subscriptionpanel-body">
                    <p>Are you sure you want to remove {productName}?</p>
                </div>
                <div className="subscriptionpanel-buttons">
                    <button className="subscriptionpanel-confirm-button" onClick={onConfirm}>Yes</button>
                    <button className="subscriptionpanel-cancel-button" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};

export default ProductRemovalModal;