import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import { jwtDecode } from 'jwt-decode';
import './ChatsManager.css';
import { BASE_API_URL } from '../../../config/constants';

const ChatsManager = () => {
  const [activeChats, setActiveChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [message, setMessage] = useState('');
  const [connection, setConnection] = useState(null);
  const [chatMessages, setChatMessages] = useState({});
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll la schimbarea chat-ului
  useEffect(() => {
    scrollToBottom();
  }, [selectedChat]);

  // Scroll la primirea unui mesaj nou
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  // Funcție pentru a obține email-ul din token
  const getUserEmailFromToken = (userId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return null;

      const decodedToken = jwtDecode(token);
      // Verificăm dacă token-ul aparține utilizatorului specific
      if (decodedToken.nameid === userId) {
        return decodedToken.email || decodedToken.sub;
      }
      return null;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${BASE_API_URL}chatHub`, {
        accessTokenFactory: () => localStorage.getItem('token'),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect([0, 2000, 5000, 10000, 30000, 60000])
      .build();

    // Add connection state change handler
    newConnection.onreconnecting((error) => {
      console.log('Reconnecting due to error:', error);
    });

    newConnection.onreconnected((connectionId) => {
      console.log('Reconnected successfully with ID:', connectionId);
    });

    newConnection.onclose((error) => {
      console.log('Connection closed:', error);
      // Attempt to reconnect after a delay
      setTimeout(() => {
        console.log('Attempting to reconnect...');
        newConnection.start()
          .then(() => {
            console.log('Reconnected successfully');
            return newConnection.invoke('ConnectAsAdmin');
          })
          .then(() => {
            console.log('Successfully re-registered as admin');
          })
          .catch(err => {
            console.error('Failed to reconnect:', err);
          });
      }, 5000);
    });

    newConnection.on('ClientConnected', (userId, userEmail) => {
      console.log('Client connected:', { userId, userEmail });
      
      // Încercăm să obținem email-ul din token
      const emailFromToken = getUserEmailFromToken(userId);
      const finalEmail = emailFromToken || userEmail;
      
      if (!finalEmail) {
        console.warn('No email found for user:', userId);
      }
      
      setActiveChats(prev => {
        if (!prev.find(chat => chat.userId === userId)) {
          const newChat = { 
            userId, 
            userEmail: finalEmail || `Utilizator ${userId}`,
            lastMessage: 'A început conversația',
            timestamp: new Date(),
            unreadCount: 0
          };
          console.log('Adding new chat:', newChat);
          return [...prev, newChat];
        }
        return prev;
      });
    });

    newConnection.on('ClientDisconnected', (userId) => {
      console.log('Client disconnected:', userId);
      setActiveChats(prev => {
        const filtered = prev.filter(chat => chat.userId !== userId);
        console.log('Remaining chats:', filtered);
        return filtered;
      });
    });

    newConnection.on('ReceiveMessage', (message, userId, userEmail) => {
      console.log('Received message:', { message, userId, userEmail });
      
      // Încercăm să obținem email-ul din token
      const emailFromToken = getUserEmailFromToken(userId);
      const finalEmail = emailFromToken || userEmail;

      // Adaugă mesajul în istoricul de mesaje pentru chat-ul specific
      setChatMessages(prev => {
        const chatId = userId;
        const newMessage = {
          id: Date.now(),
          text: message,
          sender: 'user', // Acest mesaj este de la utilizator
          senderName: finalEmail || `Utilizator ${userId}`,
          timestamp: new Date().toISOString()
        };
        
        console.log('Adding received message:', newMessage);
        return {
          ...prev,
          [chatId]: [...(prev[chatId] || []), newMessage]
        };
      });

      // Actualizează ultimul mesaj în lista de chat-uri
      setActiveChats(prev => {
        const chatIndex = prev.findIndex(chat => chat.userId === userId);
        if (chatIndex !== -1) {
          const updatedChats = [...prev];
          updatedChats[chatIndex] = {
            ...updatedChats[chatIndex],
            lastMessage: message,
            timestamp: new Date(),
            unreadCount: selectedChat?.userId === userId ? 0 : updatedChats[chatIndex].unreadCount + 1
          };
          console.log('Updated chat:', updatedChats[chatIndex]);
          return updatedChats;
        }
        return prev;
      });
    });

    newConnection.start()
      .then(() => {
        console.log('Connected to SignalR hub as admin');
        return newConnection.invoke('ConnectAsAdmin');
      })
      .then(() => {
        console.log('Successfully registered as admin');
      })
      .catch(err => {
        console.error('Error in SignalR connection:', err);
      });

    setConnection(newConnection);

    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat) {
      // Reset unread count when selecting a chat
      setActiveChats(prev => prev.map(chat => 
        chat.userId === selectedChat.userId 
          ? { ...chat, unreadCount: 0 }
          : chat
      ));
    }
  }, [selectedChat]);

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedChat || !connection) return;

    try {
      console.log('Sending message:', { message, userId: selectedChat.userId });
      await connection.invoke('SendMessageToClient', message, selectedChat.userId);
      
      // Adaugă mesajul în istoricul local pentru chat-ul selectat
      setChatMessages(prev => {
        const chatId = selectedChat.userId;
        const newMessage = {
          id: Date.now(),
          text: message,
          sender: 'admin', // Acest mesaj este de la admin
          senderName: 'Admin',
          timestamp: new Date().toISOString()
        };
        
        console.log('Adding sent message:', newMessage);
        return {
          ...prev,
          [chatId]: [...(prev[chatId] || []), newMessage]
        };
      });

      // Actualizează ultimul mesaj în lista de chat-uri
      setActiveChats(prev => prev.map(chat => 
        chat.userId === selectedChat.userId
          ? { ...chat, lastMessage: message, timestamp: new Date() }
          : chat
      ));

      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  // Get messages for the selected chat
  const currentMessages = selectedChat ? chatMessages[selectedChat.userId] || [] : [];

  const fetchChats = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/chatbot/chats`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch chats');
      const data = await response.json();
      setActiveChats(data);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const fetchChatHistory = async (userId) => {
    try {
      const response = await fetch(`${BASE_API_URL}api/chatbot/chats/${userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch chat history');
      const data = await response.json();
      setChatMessages(data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  return (
    <div className="chats-manager">
      <div className="chats-list">
        <h3>Chat-uri Active ({activeChats.length})</h3>
        {activeChats.length === 0 ? (
          <div className="no-active-chats">
            Nu există chat-uri active momentan
          </div>
        ) : (
          activeChats.map(chat => (
            <div
              key={chat.userId}
              className={`chat-item ${selectedChat?.userId === chat.userId ? 'selected' : ''}`}
              onClick={() => setSelectedChat(chat)}
            >
              <div className="chat-info">
                <div className="chat-email">
                  {chat.userEmail}
                  {!chat.userEmail && <span className="no-email">(Email indisponibil)</span>}
                </div>
                <div className="chat-last-message">{chat.lastMessage}</div>
                <div className="chat-timestamp">
                  {new Date(chat.timestamp).toLocaleTimeString()}
                </div>
              </div>
              {chat.unreadCount > 0 && (
                <div className="unread-count">{chat.unreadCount}</div>
              )}
            </div>
          ))
        )}
      </div>

      <div className="chat-window">
        {selectedChat ? (
          <>
            <div className="chat-header">
              <h4>
                {selectedChat.userEmail}
                {!selectedChat.userEmail && <span className="no-email">(Email indisponibil)</span>}
              </h4>
            </div>
            <div className="chat-messages">
              {currentMessages.map(message => {
                const isAdminMessage = message.sender === 'admin';
                return (
                  <div
                    key={message.id}
                    className={`message ${isAdminMessage ? 'admin-message' : 'user-message'}`}
                    style={{
                      alignSelf: isAdminMessage ? 'flex-end' : 'flex-start'
                    }}
                  >
                    <div className="message-sender">
                      {isAdminMessage ? 'Admin' : message.senderName}
                    </div>
                    <div className="message-content">{message.text}</div>
                    <div className="message-timestamp">
                      {new Date(message.timestamp).toLocaleTimeString()}
                    </div>
                  </div>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-input">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Scrie un mesaj..."
              />
              <button onClick={handleSendMessage}>Trimite</button>
            </div>
          </>
        ) : (
          <div className="no-chat-selected">
            Selectați un chat pentru a începe conversația
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatsManager; 