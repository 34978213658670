// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Remove old tooltip styles and keep only the necessary styles */
.tooltip-container {
  position: relative;
  width: 100%;
}

/* Ensure form controls have proper spacing */
.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-control, input[type="text"], input[type="number"] {
  width: 90% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
}

/* Domain input container responsive styles */
.domain-input-container {
  flex-wrap: nowrap !important;
}

/* Add responsive styles */
@media screen and (max-width: 768px) {
  .domain-input-container {
    flex-wrap: wrap !important;
  }
  
  .domain-input-container > div {
    min-width: 100% !important;
    margin-bottom: 10px;
  }
  
  .verify-domain-btn {
    margin-left: 0 !important;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  /* Responsive styles */
} `, "",{"version":3,"sources":["webpack://./src/styles/basicdata.css"],"names":[],"mappings":"AAAA,iEAAiE;AACjE;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA,6CAA6C;AAC7C;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA,6CAA6C;AAC7C;EACE,4BAA4B;AAC9B;;AAEA,0BAA0B;AAC1B;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;IAC1B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;AACF;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["/* Remove old tooltip styles and keep only the necessary styles */\n.tooltip-container {\n  position: relative;\n  width: 100%;\n}\n\n/* Ensure form controls have proper spacing */\n.form-group {\n  margin-bottom: 20px;\n  position: relative;\n}\n\n.form-control, input[type=\"text\"], input[type=\"number\"] {\n  width: 90% !important;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  padding: 8px 12px;\n}\n\n/* Domain input container responsive styles */\n.domain-input-container {\n  flex-wrap: nowrap !important;\n}\n\n/* Add responsive styles */\n@media screen and (max-width: 768px) {\n  .domain-input-container {\n    flex-wrap: wrap !important;\n  }\n  \n  .domain-input-container > div {\n    min-width: 100% !important;\n    margin-bottom: 10px;\n  }\n  \n  .verify-domain-btn {\n    margin-left: 0 !important;\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 480px) {\n  /* Responsive styles */\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
