import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import toast from 'react-hot-toast';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import { jwtDecode } from 'jwt-decode';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner'; // Import your custom spinner
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};
const token = localStorage.getItem('token');

const Invoices = () => {
  const [error, setError] = useState(''); // State to manage error messages
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    id: '',
    series: '',
    number: '',
    date: '',
    currency: ''
  });

  const navigate = useNavigate();

  // Fetch invoices data
  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_API_URL}api/billing/get-all-invoices-by-email`, {
        params: { email: getUserName(token) }
      });

      if (response.status === 200) {
        setInvoices(response.data);
        setFilteredInvoices(response.data);
      } else {
        setError('Failed to fetch invoices'); // Update error state
      }
    } catch (error) {
      console.error('Error fetching invoices:', error);
      setError('Error fetching invoices'); // Update error state
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Update filters and filter invoices
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    filterInvoices({ ...filters, [name]: value });
  };

  // Function to filter invoices based on filter values
  const filterInvoices = (filters) => {
    const filtered = invoices.filter((invoice) => {
      return (
        invoice.facturi_key.toLowerCase().includes(filters.id.toLowerCase()) &&
        invoice.facturi_serie.toLowerCase().includes(filters.series.toLowerCase()) &&
        invoice.facturi_serie_numar.toLowerCase().includes(filters.number.toLowerCase()) &&
        invoice.facturi_data.toLowerCase().includes(filters.date.toLowerCase()) &&
        invoice.facturi_moneda.toLowerCase().includes(filters.currency.toLowerCase())
      );
    });
    setFilteredInvoices(filtered);
  };

  // Function to handle PDF download
  const getInvoicePdf = async (invoice) => {
    try {
      setLoading(true); // Show spinner when starting PDF download
      const requestUrl = `${BASE_API_URL}api/Billing/get-invoice-pdf?` +
        `Met=FacturiHtml&` +
        `Act=Facturi&` +
        `Id_fact=${invoice.facturi_key}&` +
        `Serie=${invoice.facturi_serie}&` +
        `Nr=${invoice.facturi_serie_numar}&` +
        `Select_language=1&` +
        `Select_currency=${invoice.facturi_moneda}&` +
        `Select_format=1`;

      const response = await fetch(requestUrl, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      // Generate the file name using series, number, and date
      const fileName = `Adverlink-Invoice-${invoice.facturi_serie}-${invoice.facturi_serie_numar}-${invoice.facturi_data}.pdf`;
      link.download = fileName;

      link.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error("Error fetching invoice PDF:", error);
    } finally {
      setLoading(false); // Hide spinner after download
    }
  };

  return (
    <>
      <PageLayout>
        <div className="container px-5">
                {/* Alert for error messages */}
          <div>
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}
            <h2>Invoices</h2>
            {loading ? (
              <MySpinner /> // Show spinner while loading invoices
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ padding: '15px', fontSize: '16px' }}>
                      ID
                      <input
                        type="text"
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Filter by ID"
                      />
                    </th>
                    <th style={{ padding: '15px', fontSize: '16px' }}>
                      Series
                      <input
                        type="text"
                        name="series"
                        value={filters.series}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Filter by Series"
                      />
                    </th>
                    <th style={{ padding: '3px', fontSize: '16px' }}>
                      Number
                      <input
                        type="text"
                        name="number"
                        value={filters.number}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Filter by Number"
                      />
                    </th>
                    <th style={{ padding: '15px', fontSize: '16px' }}>
                      Date
                      <input
                        type="text"
                        name="date"
                        value={filters.date}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Filter by Date"
                      />
                    </th>
                    <th style={{ padding: '0.5px', fontSize: '16px' }}>
                      Currency
                      <input
                        type="text"
                        name="currency"
                        value={filters.currency}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Filter by Currency"
                      />
                    </th>
                    <th style={{ padding: '15px', fontSize: '16px' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredInvoices.map((invoice) => (
                    <tr key={invoice.facturi_key}>
                      <td style={{ padding: '15px', fontSize: '16px' }}>{invoice.facturi_key}</td>
                      <td style={{ padding: '15px', fontSize: '16px' }}>{invoice.facturi_serie}</td>
                      <td style={{ padding: '15px', fontSize: '16px' }}>{invoice.facturi_serie_numar}</td>
                      <td style={{ padding: '15px', fontSize: '16px' }}>{invoice.facturi_data}</td>
                      <td style={{ padding: '15px', fontSize: '16px' }}>{invoice.facturi_moneda}</td>
                      <td style={{ padding: '15px', fontSize: '16px' }}>
                        {loading ? ( // Show loading state for individual PDF download
                          <span>Loading...</span>
                        ) : (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              getInvoicePdf(invoice);
                            }}
                          >
                            Download PDF
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default Invoices;
