import React from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../config/constants';
import { useNavigate } from 'react-router-dom';

const NotificationDropdown = ({ notifications, setNotifications }) => {
  const navigate = useNavigate();

  const deleteNotification = async (notificationId) => {
    try {
      await axios.delete(`${BASE_API_URL}api/notification/${notificationId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== notificationId)
      );
      console.log(`Deleted notification with ID: ${notificationId}`);
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const handleNotificationClick = async (notification) => {
    if (notification.link && notification.link.trim()) {
      // Navigate to the link first
      navigate(notification.link);
      
      // Then delete the notification
      await deleteNotification(notification.id);
    }
  };

  return (
    <div className="notification-dropdown" style={dropdownStyle}>
      {notifications.length === 0 ? (
        <div>No notifications</div>
      ) : (
        <ul style={listStyle}>
          {notifications.map((notification) => (
            <li key={notification.id} style={listItemStyle}>
              {/* Check if the link exists and is not empty */}
              {notification.link ? (
                <span
                  style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }}
                  onClick={() => handleNotificationClick(notification)}
                >
                  {notification.message}
                </span>
              ) : (
                <span>{notification.message}</span> // Render message without link if link is missing
              )}
              <button
                style={deleteButtonStyle}
                onClick={() => deleteNotification(notification.id)}
              >
                X
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// Styling for the dropdown
const dropdownStyle = {
  position: 'absolute',
  top: '40px',
  right: '10px',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  borderRadius: '8px',
  padding: '15px',
  width: '250px',
  zIndex: 1000,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const listStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
};

const listItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  borderBottom: '1px solid #eaeaea',
};

const deleteButtonStyle = {
  background: 'transparent',
  border: 'none',
  color: '#ff4d4d',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'bold',
};

export default NotificationDropdown;
