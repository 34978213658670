import React, { useState, useEffect } from 'react';
import './Wallet.css';
import PageLayout from './components/PageLayout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_API_URL } from './config/constants';
import MySpinner from './components/MySpinner'; // Import your custom spinner
import congratulationsImg from "./assets/images/congratulations.png";
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap

const WalletPage = () => {
  const [code, setAmount] = useState('');
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [notification, setNotification] = useState('');
  const [totalBalance, setTotalBalance] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const [isRedeemLoading, setIsRedeemLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState(''); //bootstrap alert

  useEffect(() => {
    const savedPaymentStatus = JSON.parse(localStorage.getItem('isPaymentSuccessful')) || false;
    setPaymentSuccessful(savedPaymentStatus);
  }, []);

  useEffect(() => {
    localStorage.setItem('isPaymentSuccessful', JSON.stringify(isPaymentSuccessful));
  }, [isPaymentSuccessful]);

  const fetchTotalBalance = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${BASE_API_URL}api/CodeAmount/get-total-amount`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      if (response.status === 200) {
        setTotalBalance(response.data.totalAmount);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred while fetching total balance. Please try again.');
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  const fetchRedeemedCodes = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${BASE_API_URL}api/CodeAmount/transactions`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      if (response.status === 200) {
        setTransactions(response.data.redeemedCodes || []);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred while fetching redeemed codes. Please try again.');
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchTotalBalance();
    fetchRedeemedCodes();
  }, []);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (code.trim() === '') {
      setError('Please enter a code');
      return;
    }

    try {
      setIsRedeemLoading(true); // Start loading
      const response = await axios.get(`${BASE_API_URL}api/CodeAmount/validate-code`, {
        params: { code },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      if (response.status === 200) {
        fetchRedeemedCodes();
        setPaymentSuccessful(true);
        setNotification('The funds have been successfully added!');
        fetchTotalBalance();
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setIsRedeemLoading(false);
    }
  };

  const handleAddMoreFunds = () => {
    setAmount('');
    setPaymentSuccessful(false);
    setNotification('');
  };

  const handleStartPublishing = () => {
    navigate('/advertiser-step1');
  };

  const handleDismissNotification = () => {
    setNotification('');
  };

  const handleCheckWallet = () => {
    setPaymentSuccessful(true);
  };

  const renderPaymentSuccessContent = () => {
    const sortedRedeemedCodes = [...transactions].sort((a, b) => b.UserCodeId - a.UserCodeId);

    return (
      <div className="congratulations-container">
        {notification && (
          <div className="notification">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
              <h1 style={{ fontSize: '2rem', color: '#333', marginLeft: '50px' }}>Congratulations!</h1>
              <img src={congratulationsImg} alt="Congratulations" style={{ width: '100px', marginLeft: '20px' }} />
            </div>
            <hr style={{ marginBottom: '20px' }} />
            <div style={{ textAlign: 'center', padding: '10px 0' }}>{notification}</div>
            <button onClick={handleDismissNotification} className="dismiss-button" style={{ marginTop: '10px' }}>X</button>
          </div>
        )}

        <div className="wallet-details">
          <table>
            <thead>
              <tr>
                <th colSpan="3" style={{ backgroundColor: '#68b684', color: 'white', border: 'none', textAlign: 'left' }}>
                  AdverLink Wallet
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {sortedRedeemedCodes.map((transaction) => (
                <tr key={transaction.userCodeId}>
                  <td>{transaction.userCodeId}</td>
                  <td>{transaction.code}</td>
                  <td style={{ color: 'green' }}>{transaction.amount}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2"><strong>Total Balance:</strong></td>
                <td style={{ color: 'green' }}>
                  <strong>{(totalBalance?.toFixed(2) ?? '0.00')} USD</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="buttons-container">
          <button className="start-publishing-button" onClick={handleStartPublishing}>Start publishing links</button>
          <button className="add-more-funds-button" onClick={handleAddMoreFunds}>Add more funds</button>
        </div>
      </div>
    );
  };

  const renderFormContent = () => (
    <div className="wallet-container">
      
      {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}
      <h1 style={{ marginLeft: 0 }}>Add funds to your wallet</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '10px', color: '#666' }}>
        Add funds to your wallet to enjoy exclusive bonuses and access our premium services. Enter your desired amount and instantly see the bonus you'll receive!
      </p>

      <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
        <label htmlFor="amount" style={{ display: 'block', fontSize: '1rem', marginBottom: '10px', color: '#333' }}>
          Enter your promotional code to redeem your reward ($):
        </label>
        <input
          type="text"
          id="amount"
          value={code}
          onChange={handleAmountChange}
          placeholder="Enter your code"
          style={{ width: '100%', padding: '10px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px' }}
        />
        <div className="form-buttons">
        {isRedeemLoading ?
                    <div className="text-center"  style={{ marginLeft: '52px', marginRight: '52px', marginTop: '5px'}}> {/* Increased margin here */}
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div> 
          :
          <button type="submit" className="pay-now-button">Redeem code</button>
        }

          <button type="button" className="pay-now-button" onClick={handleCheckWallet}>Check your wallet</button>
        </div>
      </form>
    </div>
  );

  return (
    <PageLayout>
      {loading ? 
          <div className="text-center" style={{ marginLeft: '70px', marginTop: '50px'}}> {/* Increased margin here */}
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> 
      
      : isPaymentSuccessful ? renderPaymentSuccessContent() : renderFormContent()}
    </PageLayout>
  );
};

export default WalletPage;
