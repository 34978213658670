import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../Footer';
import './PageLayout.css'; // Optional: add common styles here

const PageLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="page-layout">
        <Sidebar />
        <div className=""> {/* Add a wrapper class for styling */}
          <div className=""> {/*classname was content*/}
            {children} {/* Content of each page will be passed here */}
          </div>
        </div>
      </div>
      <Footer/> {/* Include the Footer component here */}
    </>
  );
};

export default PageLayout;