import React from 'react';
import './SubscriptionPanelModal.css'; // Optional: Add some styles for the modal

const SubscriptionPanelModal = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    return (
        <div className="subscriptionpanel-overlay" onClick={onClose}>
            <div className="subscriptionpanel-content" onClick={(e) => e.stopPropagation()}>
                <div className="subscriptionpanel-header">
                    <h2>Confirmation</h2>
                </div>
                <div className="subscriptionpanel-body">
                    <p>{message}</p>
                </div>
                <div className="subscriptionpanel-buttons">
                    <button className="subscriptionpanel-confirm-button" onClick={onConfirm}>Yes</button>
                    <button className="subscriptionpanel-cancel-button" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};


export default SubscriptionPanelModal;
