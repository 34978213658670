import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { useNavigate } from 'react-router-dom';
import Checkout from './Checkout';
import PageLayout from './components/PageLayout';
import './YourOrders.css';

const YourOrders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/Order/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setOrders(response.data);
    } catch (err) {
      setError('Failed to fetch order data');
      console.error('Error fetching orders:', err);
    } finally {
      setIsLoading(false); // Stop loading after the fetch is complete
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // Ensure layout is always rendered, even if loading or there's an error
  return (
    <PageLayout>
      <div className="container5 overflow-hidden">
        <div className="mt-0 ms-1 subCont5 pt-0">
          <h1>Your Orders</h1>
          <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p>

          <h2>Order Summary (LaaS)</h2>

          {/* Show loading spinner while waiting for data */}
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : orders.length === 0 ? (
            <p>You have no orders at the moment.</p>
          ) : (
            <>
              {orders.map((order) => (
                <div key={order.id} className="mb-4">
                  <h3>Order number: {order.id}</h3>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Keyword</th>
                          <th>Monthly Value</th>
                          <th>Type of Link</th>
                          <th>Publisher Website</th>
                          <th>Domain Authority</th>
                          <th>Exclusivity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.wordToLinks.map((item, index) => (
                          <tr key={index}>
                            <td className="d-flex justify-content-between align-items-center">
                              {item.keyword}
                              <span className="badge bg-secondary ms-2" style={{ fontSize: '0.8em' }}>
                                view
                              </span>
                            </td>
                            <td>${item.monthlyValue}</td>
                            <td>{item.typeOfLink}</td>
                            <td>{item.publisherWebsite}</td>
                            <td>{item.domainAuthority}</td>
                            <td>{item.exclusivity ? 'Yes' : 'No'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-end mt-2" style={{ marginRight: '15px' }}>
                    <strong>Total: ${calculateTotal(order.wordToLinks)}</strong>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

const calculateTotal = (wordToLinks) => {
  if (!wordToLinks) return 0;
  return wordToLinks.reduce((total, item) => total + item.monthlyValue, 0);
};

export default YourOrders;
