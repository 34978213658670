import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const translations = {
  home: {
    en: 'Home',
    ro: 'Acasă'
  },
  cart: {
    en: 'Cart',
    ro: 'Coș'
  },
  profile: {
    en: 'Profile',
    ro: 'Profil'
  },
  search: {
    en: 'Search',
    ro: 'Căutare'
  },
  settings: {
    en: 'Settings',
    ro: 'Setări'
  },
  logout: {
    en: 'Logout',
    ro: 'Deconectare'
  }
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 