import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "./PublisherStep1.css";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Statistics from "./Statistics";
import Theme from "./Theme";
import BasicData from "./BasicsData";
import { Col } from "reactstrap";
import PageLayout from './components/PageLayout';
import { BASE_API_URL } from '../src/config/constants';
import { Alert } from "react-bootstrap";

const PublisherStep1 = () => {
  const [formData, setFormData] = useState({
    basicData: {},
    themeData: {},
  });
  const [activeTab, setActiveTab] = useState("basic");
  const navigate = useNavigate();
  const updateData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${activeTab}Data`]: newData,
    }));
  };

  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState('');
  const [errors, setErrors] = useState({
    basicData: {
      domainCalculated: '',
      domain: '',
      language: '',
      country: '',
      websiteType: '',
      occurrences: '',
      price: '',
      indexing: '',
      customPrice: ''
    },
    themeData: {
      description: '',
      tags: '',
      uniqueUsers: '',
      acceptedCategories: '',
      blockedWebsites: ''
    }
  });

  const validateCurrentTab = () => {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/$/;
    const currentData = formData[`${activeTab}Data`];
    let newErrors = {};
    let isValid = true;
  
    console.log(currentData);
    switch (activeTab) {
      case "basic":
        if (!urlPattern.test(currentData.domain)) 
          if (currentData && currentData.domain && !currentData.domain.endsWith("/")) {
            currentData.domain = currentData.domain + "/";
          }
      
        newErrors.basicData = {
          domainCalculated: !formData?.basicData?.domainStatus?.isCalculated ? "" : "",  // removed 
          domain: !currentData.domain ? "Please fill Domain" : 
                  !urlPattern.test(currentData.domain) ? "Invalid domain name. Please use example.com format or example.com/" : "",
          language: !currentData.language || currentData.language === "" ? "Please select a language" : "",
          country: !currentData.country ? "Please select Country" : "",
          websiteType: !currentData.websiteType ? "Please select Website Type" : "",
          customPrice: (currentData.useCustomPrice && !currentData?.customPrice) ? "Please insert custom price" : ""
        };
        if (
          newErrors.basicData.domainCalculated ||
          !formData?.basicData?.domainStatus?.isCalculated ||
          newErrors.basicData.domain ||
          newErrors.basicData.language ||
          newErrors.basicData.country ||
          newErrors.basicData.websiteType ||
          newErrors.basicData.customPrice
        ) {
          isValid = false;
        }
        break;
  
      case "theme":
        newErrors.themeData = {
          description: !currentData.description ? "Please add a description" : "",
          tags: !currentData.tags ? "Please add at least one tag" : ""
        };

        if (
          newErrors.themeData.description ||
          newErrors.themeData.tags
        ) {
          isValid = false;
        }
        break;
    }
  
    setErrors(newErrors);
    return isValid;
  };

  const onNext = () => {
    if (validateCurrentTab()) {
      if (activeTab === "basic") {
        setActiveTab("theme");
      }
    }
  };

  const onBack = () => {
    if (activeTab === "theme") {
      formData.basicData.domainStatus.isCalculated = true;
      setActiveTab("basic");
    }
  };

  const onFinalize = async () => {
    if(!validateCurrentTab()){
      return;
    }
    try {
      const data = new FormData();
      var customBasePrice = undefined;
      if (formData.basicData.customPrice) {
        var customPriceNumeric = parseFloat(formData.basicData.customPrice);
        var customAdverlinkRevenue = customPriceNumeric * 2 / 3;
        console.log('customAdverlinkRevenue', customAdverlinkRevenue);
        customBasePrice = customPriceNumeric + customAdverlinkRevenue;
      }
      console.log('istrialmode',formData.basicData.isTrialMode);
      console.log('customprice',formData.basicData.customPrice);
      console.log('baseprice',formData.basicData.basePrice);
      console.log('basicData',formData.basicData);
      data.append("Domain", formData.basicData.domain);
      data.append("Language", formData.basicData.language);
      data.append("Country", formData.basicData.country);
      data.append("WebsiteType", formData.basicData.websiteType);
      data.append("Indexing", formData.basicData.indexing !== undefined ? formData.basicData.indexing : false);
      console.log('customBasePrice',customBasePrice);
      data.append("Price", formData.basicData.isTrialMode ? 0.001 : (customBasePrice || formData.basicData.basePrice || formData.basicData.price || 0));
      data.append("Description", formData.themeData.description);
      data.append("Tags", formData.themeData.tags);
      data.append("AcceptedCategories", formData.themeData.acceptedCategories);
      data.append("BlockedWebsites", formData.themeData.blockedWebsites);
      data.append("UniqueUsers", formData.themeData.uniqueUsers);
      data.append("TrustFlow", formData.basicData.trustFlow);
      data.append("URMedium", formData.basicData.URMedium);
      data.append("Dofollow", formData.basicData.Dofollow);
      data.append("TrafficTracking", formData.basicData.trafficTracking);
      data.append("Occurences", formData.basicData.occurrences);
      data.append("DomainAuthority", formData.basicData.domainAuthority);

      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
  
      const response = await fetch(
        `${BASE_API_URL}api/userdomains/submit`,
        requestOptions
      );

      if (response.ok) {
        setSuccess("Data submitted successfully");
        navigate("/publisher-step2");
      } else {
        const errorData = await response.json();
        console.error("Error submitting data:", errorData);
        setError("Failed to submit data");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setError("Failed to submit data");
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "theme":
        return <Theme 
          data={formData.themeData} 
          updateData={updateData} 
          errors={errors.themeData} 
          setErrors={setErrors}
          domain={formData.basicData.domain} 
        />;
      default:
        return <BasicData data={formData.basicData} updateData={updateData} errors={errors.basicData} setErrors={setErrors} />;
    }
  };

  return (
    <PageLayout>
      <div className="main mt-0 pt-0">
        <div className="header">
          <h5>Publisher</h5>
        </div>
        <p>
          (website owner, blog owner, corporate websites, personal websites)
        </p>
        {error && (
          <Alert variant="danger" onClose={() => setError('')} dismissible>
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="success" onClose={() => setSuccess('')} dismissible>
            {success}
          </Alert>
        )}
        <div className="nav-tabs">
          <a
            href="#."
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("basic");
            }}
            className={activeTab === "basic" ? "nav-tab active" : "nav-tab"}
          >
            Basic data
          </a>
          <a
            href="#."
            onClick={(e) => {
              e.preventDefault();
              if (activeTab === "theme" || validateCurrentTab()) {
                setActiveTab("theme");
              }
            }}
            className={activeTab === "theme" ? "nav-tab active" : "nav-tab"}
          >
            Theme
          </a>
        </div>

        <form style={{ width: "70%" }} onSubmit={(e) => e.preventDefault()}>
          {renderActiveTab()}
        </form>
        <div className="form-actions mb-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={onBack}
            disabled={activeTab === "basic"}
            style={{ 
              padding: '10px 10px',
              fontSize: '0.85rem',
              minWidth: '60px',
              maxWidth: '20%'
            }}
          >
            Back
          </button>
          {activeTab === "theme" ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={onFinalize}
              style={{ 
                padding: '10px 10px',
                fontSize: '0.85rem',
                minWidth: '60px',
                maxWidth: '20%'
              }}
            >
              Finalize
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={onNext}
              style={{ 
                padding: '10px 10px',
                fontSize: '0.85rem',
                minWidth: '60px',
                maxWidth: '20%'
              }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default PublisherStep1;
