import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import Checkout from './Checkout';
import { useCart } from './CartContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components
import targetUrlImg from './assets/images/targeturl.png';

const CartPage = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();
  const [orderData, setOrderData] = useState(cartItems);
  const [showCheckout, setShowCheckout] = useState(false);
  const [walletAmount, setWalletAmount] = useState(0); // State to store wallet amount
  const [isConfirming, setIsConfirming] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // State for modal visibility
  const navigate = useNavigate();
  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState(''); //bootstrap alert

  const [companyDetailsExist, setCompanyDetailsExist] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setOrderData(cartItems);
  }, [cartItems]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = getUserIdFromToken(token);
    if (userId) {
      fetchWalletAmount(userId, token);
    }
  }, []);


  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = getUserIdFromToken(token);

    const fetchCompanyDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BASE_API_URL}api/companydetails/${userId}/is-company-details-complete`);
        if (response.ok) {
          const result = await response.json();
          setCompanyDetailsExist(result);
        } else {
          throw new Error('Failed to check company details completeness');
        }
      } catch (error) {
        console.error('Error fetching company details completeness:', error);
        setCompanyDetailsExist(false);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, []);

  // Calculate price without VAT and VAT per item
  const calculatePriceWithoutVAT = (monthlyValue) => monthlyValue / 1.19;
  const calculateVAT = (monthlyValue) => monthlyValue - calculatePriceWithoutVAT(monthlyValue);

  // Calculate total price including VAT
  const calculateTotal = () => {
    return orderData.reduce((total, item) => total + item.monthlyValue, 0);
  };

  // Calculate total price without VAT
  const calculateTotalWithoutVAT = () => {
    return orderData.reduce((total, item) => total + calculatePriceWithoutVAT(item.monthlyValue), 0);
  };

  // Calculate total VAT
  const calculateTotalVAT = () => {
    return orderData.reduce((total, item) => total + calculateVAT(item.monthlyValue), 0);
  };

  const handleDelete = (item) => {
    const index = cartItems.findIndex(cartItem => cartItem.id === item.id); // Use a unique property to find the index
    if (index !== -1) {
      removeFromCart(index);
    }
  };

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  // Function to fetch user's wallet total amount
  const fetchWalletAmount = async (userId, token) => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/CodeAmount/get-total-amount`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setWalletAmount(response.data.totalAmount); // Store wallet amount
      }
    } catch (error) {
      //console.error("Error fetching wallet amount:", error);
      setError("Failed to fetch wallet amount.");
    }
  };

  const handleOrderAndPay = async () => {
    const total = calculateTotal();
    const token = localStorage.getItem('token');
    const userId = getUserIdFromToken(token);

    if (!userId) {
      console.error("User ID not found");
      return;
    }
    setShowCheckout(true);
    try {
      const payload = {
        UserId: userId,
        WordToLinks: orderData.map(product => ({
          Keyword: product.keyword,
          Url: product.url,
          Title: product.title,
          Exclusivity: product.exclusivity,
          TypeOfLink: product.typeOfLink,
          PublisherWebsite: product.publisherWebsite,
          MonthlyValue: product.monthlyValue,
          DomainAuthority: product.domainAuthority
        })),
      };
 

      if (total <= 0) {
        const response = await axios.post(`${BASE_API_URL}api/Order/${userId}`, payload, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 200 || response.status === 201) {
          // Set the order as paid only for free orders
          const orderId = response.data.id;
          const updateResponse = await axios.put(`${BASE_API_URL}api/Order/${orderId}/setPaid`, null, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          if (updateResponse.status !== 200) {
            throw new Error('Failed to update order payment status');
          }

          clearCart();
          setSuccess('Free order placed and marked as paid successfully!');
          navigate('/Advertiser-step1');
        } else {
          // For paid orders, proceed to checkout without setting as paid
          setShowCheckout(true);
        }
      } else {
        //throw new Error('Failed to create order');
      }
    } catch (error) {
      console.error('Error processing order:', error);
      setError('An error occurred while processing your order. Please try again.');
    }
  };

  const handlePayWithWallet = () => {
    // Open the confirmation modal instead of using window.confirm
    setShowConfirmModal(true);
  };

  const handleConfirmPayWithWallet = async () => {
    setShowConfirmModal(false); // Close the modal
    setIsConfirming(true);

    const total = calculateTotal();
    const token = localStorage.getItem('token');
    const userId = getUserIdFromToken(token);
 
    if (!userId || walletAmount < total) {
      setError('Insufficient wallet balance.');
      return;
    }

    try {
      const payload = {
        UserId: userId,
        WordToLinks: orderData.map(product => ({
          Keyword: product.keyword,
          Url: product.url,
          Title: product.title,
          Exclusivity: product.exclusivity,
          TypeOfLink: product.typeOfLink,
          PublisherWebsite: product.publisherWebsite,
          MonthlyValue: product.monthlyValue,
          DomainAuthority: product.domainAuthority
        })),
      };
 
      const response = await axios.post(`${BASE_API_URL}api/Order/${userId}/forWallet`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
 
      if (response.status === 200 || response.status === 201) {
        const orderId = response.data.id;
        const updateResponse = await axios.put(`${BASE_API_URL}api/Order/${orderId}/setPaid`, null, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
 
        if (updateResponse.status !== 200) {
          throw new Error('Failed to update order payment status');
        }
 
        const walletUpdateResponse = await axios.put(`${BASE_API_URL}api/CodeAmount/decrease-amount`, parseFloat(calculateTotal().toFixed(2)), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
 
        if (walletUpdateResponse.status === 200) {
          clearCart();
          navigate('/Advertiser-step1');
          setSuccess('Order placed and paid with wallet successfully!');
        } else {
          throw new Error('Failed to decrease wallet amount');
        }
      }
    } catch (error) {
      console.error('Error processing wallet payment:', error);
      setError('An error occurred while processing your payment. Please try again.');
    }
  };

  const handleCancelPayWithWallet = () => {
    setShowConfirmModal(false); // Close the modal without doing anything
  };

  if (showCheckout) {
    return (
      <Checkout
        amount={calculateTotal()}
        products={orderData}
      />
    );
  }
  return (
    <>
      <Navbar />
      <div className="container5 overflow-hidden">
        <Sidebar />
        <div className="mt-0 ms-4 subCont5 pt-0">
          {/* Alert for error messages */}
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}
                {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}
          <h1>Advertiser</h1>
          <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p>

          <h2>Order summary (LaaS)</h2>

          <div className="table-responsive">
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>Keyword</th>
        <th>Target URL</th> {/* New column for Target URL */}
        <th>Type of Link</th>
        <th>Publisher Website</th>
        <th>Domain Authority</th>
        <th>Monthly Value</th>
        <th>Exclusivity</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      {orderData.map((item, index) => (
        <tr key={index}>
          <td className="d-flex justify-content-between align-items-center">
            {item.keyword}
            <span className="badge bg-secondary ms-2" style={{ fontSize: '0.8em' }}>view</span>
          </td>
          {/* Corrected Target URL column with an icon */}
          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
  {item.targetUrl && (
    <a href={item.targetUrl} target="_blank" rel="noopener noreferrer">
      <img
        src={targetUrlImg}  // Use the imported image as the src
        alt="Target url"     // Alt text for the image
        style={{ width: '20px', height: '20px' }} // Set image size
        title={item.targetUrl}  // Title shows on hover
      />
    </a>
  )}
</td>
          <td>{item.typeOfLink}</td>
          <td>{item.publisherWebsite}</td>
          <td>{item.domainAuthority}</td>
          <td>${item.monthlyValue.toFixed(2)}</td>
          <td>{item.exclusivity ? 'Yes' : 'No'}</td>
          <td>
            <button
              className="text-primary"
              onClick={(e) => {
                e.preventDefault();
                handleDelete(item);
              }}
            >
              Delete
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


          <p>Total for {orderData.length} links in native articles</p>
          <h3>Price without VAT: ${calculateTotalWithoutVAT().toFixed(2)} USD</h3>
          <h3>VAT: ${calculateTotalVAT().toFixed(2)} USD</h3>
          <h3>TOTAL: ${calculateTotal().toFixed(2)} USD / Month</h3>
          <p>These hyperlinks are Links as a Service (LaaS), the links will remain active as long as you pay monthly.</p>

          <div className="mt-4 d-flex justify-content-between" style={{ maxWidth: '400px', marginBottom: '20px' }}>
            <button
              className="btn btn-primary flex-fill me-2"
              onClick={handleOrderAndPay}
              style={{ flexBasis: '30%' }} // Adjust percentage as needed
              disabled={!companyDetailsExist || orderData.length === 0}
            >
              {calculateTotal() === 0 ? 'Place Order' : 'Order and Pay'}
            </button>
            <button
              className="btn btn-success flex-fill me-2"
              disabled={!companyDetailsExist || orderData.length === 0 || walletAmount < calculateTotal() || isConfirming} // Disable if wallet amount is insufficient
              onClick={handlePayWithWallet}
              style={{ flexBasis: '30%' }} // Adjust percentage as needed
            >
              Pay with Wallet
            </button>
            <button
              className="btn w-100"
              style={{ backgroundColor: 'green', color: 'white', flexBasis: '30%' }} // Adjust percentage as needed
              onClick={() => navigate('/Advertiser-step1')}
            >
              Continue Shopping
            </button>
          </div>
          {(!loading && !companyDetailsExist) && (
          <div className="text-danger">
            You must complete <a href="/MyAccount">My Account</a> and <a href="/company-details">Billing Details</a> first!
          </div>
        )}

        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelPayWithWallet} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to pay with your wallet?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelPayWithWallet}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmPayWithWallet}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CartPage;