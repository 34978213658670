import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Correct import
import { useCart } from './CartContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import toast from 'react-hot-toast';

const fetchRecommendedPrice = async (entry, domainAuthority, linkType, exclusivity) => {
    try {
        const response = await axios.get(`${BASE_API_URL}api/priceCalculator/calculate-base-price-advertiser`, {
            params: {
                exclusivity,
                price: entry.price,
                linkType
            }
        });
        return {
            basePrice: response.data.advertiserBasePrice,
            totalPrice: response.data.advertiserTotalPrice
        };
    } catch (error) {
        console.error("Error fetching recommended price:", error);
        return null;
    }
};

export default function Modal({ entry, onClose, vatRate, onSuccess }) {
    const [isPriceLoading, setIsPriceLoading] = useState(false);
    const { addToCart } = useCart();
    const [basePrice, setBasePrice] = useState(null);
    const [totalPrice, setTotalPrice] = useState(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        keyword: '',
        advertiserUrl: '',
        title: '',
        exclusivity: false,
        linkType: 'NormalFollow',
    });

    const [errors, setErrors] = useState({
        keyword: false,
        advertiserUrl: false,
        title: false
    });

    const [protocol, setProtocol] = useState('https'); // Schimbat la https

    useEffect(() => {
        if (entry && entry.domainAuthority) {
            setIsPriceLoading(true);
            fetchRecommendedPrice(entry, entry.domainAuthority, formData.linkType, formData.exclusivity)
            .then(({ basePrice, totalPrice }) => {
                setBasePrice(basePrice);
                setTotalPrice(totalPrice);
                setIsPriceLoading(false);
            });
        }
    }, [entry, formData.linkType, formData.exclusivity]);

    const calculateTotalWithVAT = (basePrice) => {
        if (!vatRate) return basePrice;
        const vatAmount = basePrice * (vatRate / 100);
        return basePrice + vatAmount;
    };

    const handleURLInput = (value) => {
        let updatedURL = value.trim();
        
        // Remove any existing protocol and www
        updatedURL = updatedURL.replace(/^(https?:\/\/)/, '');
    
        // Add selected protocol
        if (updatedURL.length > 0) {
            updatedURL = `${protocol}://${updatedURL}`;
        }
    
        return updatedURL;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'radio') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                linkType: name,
            }));
        } else if (name === 'advertiserUrl') {
            const updatedURL = handleURLInput(value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: updatedURL,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    useEffect(() => {
        if (entry) {
            setFormData(prevFormData => ({
                ...prevFormData,
                ...entry,
                advertiserUrl: '',
                linkType: entry.linkType || 'NormalFollow'
            }));
        }
    }, [entry]);

    const getUserIdFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.nameid; // Adjust based on your token's structure
        } catch (error) {
            console.error("Invalid token", error);
            return null;
        }
    };

    const validateForm = () => {
        const { keyword, advertiserUrl, title } = formData;
        let valid = true;
        const newErrors = { keyword: false, advertiserUrl: false, title: false };
    
        if (!keyword.trim()) {
            newErrors.keyword = true;
            valid = false;
        }
    
        if (!advertiserUrl.trim()) {
            newErrors.advertiserUrl = true;
            valid = false;
        } else {
            const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '(www\\.)?' + // optional www
                '([a-zA-Z0-9\\-\\.]+)\\.([a-zA-Z]{2,6})' + // domain
                '|[0-9a-fA-F]{1,4}(:[0-9a-fA-F]{1,4}){7}' + // ipv6
                '|localhost' + // localhost
                '(\\:[0-9]{1,5})?' + // port
                '(\\/.*)?$', // path
                'i'); // case-insensitive
    
            if (!urlPattern.test(advertiserUrl)) {
                newErrors.advertiserUrl = true;
                valid = false;
            }
        }
    
        if (!title.trim()) {
            newErrors.title = true;
            valid = false;
        }
    
        setErrors(newErrors);
        return valid;
    };
    

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const linkTypes = {
            NormalFollow: 'NormalFollow',
            NoFollow: 'NoFollow',
            UGC: 'UGC',
            Sponsored: 'Sponsored',
            GeneralKeyword: 'GeneralKeyword'
        };

        const selectedLinkType = linkTypes[formData.linkType] || 'NormalFollow';
        const userId = getUserIdFromToken(token);

        const payload = {
            UserId: userId,
            WordToLinks: [
                {
                    Keyword: formData.keyword,
                    Url: formData.advertiserUrl,
                    Title: formData.title,
                    Exclusivity: formData.exclusivity,
                    TypeOfLink: selectedLinkType,
                    PublisherWebsite: entry.domain,
                    MonthlyValue: basePrice,
                    TargetUrl: entry.isFromArticle ? entry.articleUrl : null
                }
            ]
        };

        try {
            addToCart({
                id: userId,
                keyword: formData.keyword,
                url: formData.advertiserUrl,
                title: formData.title,
                exclusivity: formData.exclusivity,
                typeOfLink: selectedLinkType,
                publisherWebsite: entry.domain,
                monthlyValue: basePrice,
                domainAuthority: entry.domainAuthority,
                targetUrl: entry.isFromArticle ? entry.articleUrl : null
            });

            // Show success message
            toast.success('Link has been added to your cart!', {
                duration: 3000,
                position: 'top-center',
                style: {
                    background: '#4CAF50',
                    color: '#fff',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            });
            
            // Close the modal
            onClose();
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to add link to cart. Please try again.', {
                duration: 3000,
                position: 'top-center',
                style: {
                    background: '#f44336',
                    color: '#fff',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            });
        }
    };

    // Add this helper function for tooltips
    const renderTooltip = (content) => (
        <Tooltip>
            {content}
        </Tooltip>
    );

    return (
        <>
            <div className="modal overflow-auto" style={{ display: 'block', backgroundColor: 'rgb(121, 121, 121, 0.60)' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <div className="d-flex p-1 justify-content-end">
                                <button type="button" className="close border bg-transparent" onClick={onClose}>
                                    <span style={{ fontWeight: '700', fontSize: '20px' }} className='text-dark'>X</span>
                                </button>
                            </div>
                            <h2 style={{ fontWeight: '700' }} className="modal-title px-5">Add your keyword**</h2>
                            <div className="modal-body px-5">
                                <div className="form-group">
                                    <div className='d-flex justify-content-between'>
                                        <h6 htmlFor="keyword">Keyword you want to optimize for:</h6>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip("Enter the keyword you want to optimize your website for. This will be the clickable text in the link.")}
                                        >
                                            <p className="font-italic me-2 mb-0" style={{ cursor: 'pointer' }}>𝒊</p>
                                        </OverlayTrigger>
                                    </div>
                                    <input
                                        placeholder="Keyword"
                                        type="text"
                                        id="keyword"
                                        name="keyword"
                                        value={formData.keyword}
                                        onChange={handleChange}
                                        style={{ borderColor: errors.keyword ? 'red' : '' }}
                                    />
                                    {errors.keyword && <p className="text-danger">Keyword is required</p>}
                                </div>
                                <div className="form-group">
                                    <div className='d-flex justify-content-between'>
                                        <h6 htmlFor="advertiserUrl">Add URL to your website:</h6>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip("Enter the URL of your website where you want to receive the link")}
                                        >
                                            <p className="font-italic me-2 mb-0" style={{ cursor: 'pointer' }}>𝒊</p>
                                        </OverlayTrigger>
                                    </div>
                                    <input
                                        placeholder="Your website URL"
                                        type="text"
                                        id="advertiserUrl"
                                        name="advertiserUrl"
                                        value={formData.advertiserUrl}
                                        onChange={handleChange}
                                        style={{ borderColor: errors.advertiserUrl ? 'red' : '' }}
                                    />
                                    {errors.advertiserUrl && <p className="text-danger">Valid URL is required</p>}
                                </div>
                                <div className="form-group">
                                <div className='d-flex justify-content-between'>
                                    <h6 htmlFor="title">Title:</h6>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={renderTooltip("Enter the title attribute for the link. This appears when hovering over the link.")}
                                    >
                                        <p className="font-italic me-2 mb-0" style={{ cursor: 'pointer' }}>𝒊</p>
                                    </OverlayTrigger>
                                </div>
                                <input
                                    placeholder="Title ahref"
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    style={{ borderColor: errors.title ? 'red' : '' }}
                                />
                                {errors.title && <p className="text-danger">Title is required</p>}
                            </div>
                                <div className="form-group">
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="NormalFollow"
                                            name="NormalFollow"
                                            checked={formData.linkType === 'NormalFollow'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="NormalFollow">
                                                Dofollow Link
                                            </label>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={renderTooltip("A regular link that passes SEO value to the target page")}
                                            >
                                                <p className="font-italic my-auto mb-0" style={{ cursor: 'pointer' }}>𝒊</p>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="NoFollow"
                                            name="NoFollow"
                                            checked={formData.linkType === 'NoFollow'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="NoFollow">
                                                Nofollow Link
                                            </label>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={renderTooltip("A link that doesn't pass SEO value but may still drive traffic")}
                                            >
                                                <p className="font-italic my-auto mb-0" style={{ cursor: 'pointer' }}>𝒊</p>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="UGC"
                                            name="UGC"
                                            checked={formData.linkType === 'UGC'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="UGC">
                                                UGC Link
                                            </label>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={renderTooltip("User Generated Content link - indicates content created by users")}
                                            >
                                                <p className="font-italic my-auto mb-0" style={{ cursor: 'pointer' }}>𝒊</p>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="Sponsored"
                                            name="Sponsored"
                                            checked={formData.linkType === 'Sponsored'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="Sponsored">
                                                Sponsored Link
                                            </label>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={renderTooltip("Indicates paid or sponsored content")}
                                            >
                                                <p className="font-italic my-auto mb-0" style={{ cursor: 'pointer' }}>𝒊</p>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                                
                                <div style={{ color: 'black', marginTop: '15px' }} className="checkbox-group form-group">
                                    <input
                                        style={{ width: '20px' }}
                                        type="checkbox"
                                        id="exclusivity"
                                        name="exclusivity"
                                        checked={formData.exclusivity}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="exclusivity">
                                        I want Exclusivity (+40% of the base price)
                                    </label>
                                </div>

                                <div 
                                style={{
                                    width: '60%', 
                                    margin: '0 auto', 
                                    textAlign: 'center', 
                                    backgroundColor: '#f8f9fa', 
                                }}
                            >
                                <div style={{ marginBottom: '' }}>
                                    <span 
                                        style={{ 
                                            fontSize: '28px', 
                                            fontWeight: 'bold', 
                                            color: '#0d6efd' 
                                        }}
                                    >
                                        ${basePrice}
                                    </span>
                                    <p style={{ fontSize: '14px', color: 'gray', marginTop: '5px' }}>
                                    *price without VAT
                                    </p>

                                </div>
                                    <button 
                                        onClick={() => handleSubmit()} 
                                        type="button" 
                                        className="btn btn-primary" 
                                        disabled={isPriceLoading || basePrice === null}
                                        style={{
                                            fontSize: '16px', 
                                            fontWeight: '500', 
                                            padding: '10px 20px', 
                                            borderRadius: '5px', 
                                            backgroundColor: '#0d6efd'
                                        }}
                                    >
                                        {isPriceLoading ? 'Calculating Price...' : 'Add your keyword'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
