import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import PageLayout from './components/PageLayout';
import './YourOrders.css';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-hot-toast';
import format from 'date-fns/format';
import TopUpCheckout from './TopUpCheckout';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
const stripePromise = loadStripe('pk_live_51N3JFnL4S5a1WxAjiPBiwb4ShFOftVd2QS4TPJH9dSqSCLylagT0XFSYbPKCppctTwT4WeXwf2wed4Ya05mVYchL00sGrXx9vV');

const YourOrders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    orderNumber: '',
    keyword: '',
    publisherWebsite: '',
    minTotal: '',
    maxTotal: '',
    typeOfLink: '',
    domainAuthority: '',
    startDate: '',
    endDate: ''
  });
  const [publisherWebsites, setPublisherWebsites] = useState([]);
  const navigate = useNavigate();
  const [downloadingOrderId, setDownloadingOrderId] = useState(null);
  const [alertMessages, setAlertMessages] = useState({});
  const [showTopUp, setShowTopUp] = useState(false);
  const [requiredAmount, setRequiredAmount] = useState(0);
  const location = useLocation();

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/Order/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setOrders(response.data);
      return response.data;
    } catch (err) {
      setError('Failed to fetch order data');
      console.error('Error fetching orders:', err);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const ordersData = await fetchOrders();
      const websites = getUniquePublisherWebsites(ordersData);
      setPublisherWebsites(websites);

      // Check for order ID in both URL params and route params
      const params = new URLSearchParams(location.search);
      const queryOrderId = params.get('orderId');
      const routeOrderId = location.pathname.split('/').pop();
      const notificationOrderId = queryOrderId || (routeOrderId !== 'your-orders' ? routeOrderId : null);

      console.log('URL Debug:', {
        pathname: location.pathname,
        queryOrderId,
        routeOrderId,
        notificationOrderId
      });

      if (notificationOrderId) {
        setFilters(prev => ({
          ...prev,
          orderNumber: notificationOrderId
        }));

        // Find the order and calculate required amount
        const order = ordersData.find(o => o.id.toString() === notificationOrderId);
        if (order) {
          const total = calculateTotal(order.wordToLinks);
          setRequiredAmount(total);
        }
      }
    };
    
    loadData();
  }, [location]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const filteredOrders = orders.filter(order => {
    const orderDate = new Date(order.dateCreated);
    return (
      (!filters.orderNumber || order.id.toString().includes(filters.orderNumber)) &&
      (!filters.keyword || order.wordToLinks.some(link => 
        link.keyword.toLowerCase().includes(filters.keyword.toLowerCase())
      )) &&
      (!filters.publisherWebsite || order.wordToLinks.some(link =>
        link.publisherWebsite.toLowerCase().includes(filters.publisherWebsite.toLowerCase())
      )) &&
      (!filters.minTotal || calculateTotal(order.wordToLinks) >= Number(filters.minTotal)) &&
      (!filters.maxTotal || calculateTotal(order.wordToLinks) <= Number(filters.maxTotal)) &&
      (!filters.typeOfLink || order.wordToLinks.some(link =>
        link.typeOfLink.toLowerCase().includes(filters.typeOfLink.toLowerCase())
      )) &&
      (!filters.domainAuthority || order.wordToLinks.some(link =>
        link.domainAuthority.toString().includes(filters.domainAuthority)
      )) &&
      (!filters.startDate || orderDate >= new Date(filters.startDate)) &&
      (!filters.endDate || orderDate <= new Date(filters.endDate))
    );
  });

  const getUserName = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.sub;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const handleDownloadInvoice = async (orderId) => {
    try {
      setDownloadingOrderId(orderId);
      const token = localStorage.getItem('token');
      const email = getUserName(token);
      
      const order = orders.find(o => o.id === orderId);
      if (!order?.isPaid) {
        setAlertMessages(prev => ({
          ...prev,
          [orderId]: 'Cannot download invoice: Order is not paid'
        }));
        return;
      }

      // Get all invoices
      const response = await axios.get(`${BASE_API_URL}api/billing/get-all-invoices-by-email`, {
        params: { email }
      });

      if (response.status === 200 && response.data.length > 0) {
        // Just take the first invoice from the list
        const invoice = response.data[0];
        
        const requestUrl = `${BASE_API_URL}api/Billing/get-invoice-pdf?` +
          `Met=FacturiHtml&` +
          `Act=Facturi&` +
          `Id_fact=${encodeURIComponent(invoice.facturi_key)}&` +
          `Serie=${encodeURIComponent(invoice.facturi_serie)}&` +
          `Nr=${encodeURIComponent(invoice.facturi_serie_numar)}&` +
          `Select_language=1&` +
          `Select_currency=${encodeURIComponent(invoice.facturi_moneda)}&` +
          `Select_format=1`;

        const pdfResponse = await fetch(requestUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/pdf'
          }
        });

        if (!pdfResponse.ok) {
          throw new Error('Failed to download invoice');
        }

        const blob = await pdfResponse.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoice-${invoice.facturi_serie}-${invoice.facturi_serie_numar}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error('No invoices found');
      }
    } catch (error) {
      console.error('Error downloading invoice:', error);
      setAlertMessages(prev => ({
        ...prev,
        [orderId]: 'Failed to download invoice. Please try again later.'
      }));
    } finally {
      setDownloadingOrderId(null);
    }
  };

  const formatDate = (date) => {
    return format(new Date(date), 'MM/dd/yyyy HH:mm');
  };

  const handleTopUp = () => {
    if (filteredOrders.length === 1) {
      const orderAmount = calculateTotal(filteredOrders[0].wordToLinks);
      setRequiredAmount(orderAmount);
    }
    setShowTopUp(true);
  };

  const handleTopUpSuccess = async (type, data) => {
    if (type === 'payment_created') {
      console.log('Top-up payment created:', data);
    } else if (type === 'payment_method_updated') {
      toast.success('Payment successful!', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#10B981',
          color: 'white',
        },
        icon: '🎉'
      });
      await fetchOrders();
      setShowTopUp(false);
    }
  };

  const handleTopUpCancel = () => {
    setShowTopUp(false);
  };

  const handleGoToSubscription = (orderId) => {
    navigate(`/Subscriptions?orderId=${orderId}`);
  };

  return (
    <PageLayout pageType='your-orders'>
      {showTopUp ? (
        <Elements stripe={stripePromise}>
          <TopUpCheckout
            amount={requiredAmount}
            onSuccess={handleTopUpSuccess}
            onError={(error) => {
              toast.error(error.message);
              setShowTopUp(false);
            }}
            onCancel={handleTopUpCancel}
          />
        </Elements>
      ) : (
        <div className="your-orders-container">
          <div className="your-orders-header">
            <h5>Your Orders</h5>
            <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p>
          </div>

          <div className="your-orders-filters">
            <h5>Filters</h5>
            <div className="your-orders-filters-grid">
              <input
                type="text"
                name="orderNumber"
                placeholder="Order Number"
                value={filters.orderNumber}
                onChange={handleFilterChange}
              />
              <input
                type="text"
                name="keyword"
                placeholder="Keyword"
                value={filters.keyword}
                onChange={handleFilterChange}
              />
              <select
                name="publisherWebsite"
                value={filters.publisherWebsite}
                onChange={handleFilterChange}
                className="your-orders-select"
              >
                <option value="">All Publisher Websites</option>
                {publisherWebsites.map((website, index) => (
                  <option key={index} value={website}>
                    {website}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="typeOfLink"
                placeholder="Type of Link"
                value={filters.typeOfLink}
                onChange={handleFilterChange}
              />
              <input
                type="number"
                name="domainAuthority"
                placeholder="Domain Authority"
                value={filters.domainAuthority}
                onChange={handleFilterChange}
              />
              <input
                type="number"
                name="minTotal"
                placeholder="Min Total"
                value={filters.minTotal}
                onChange={handleFilterChange}
              />
              <input
                type="number"
                name="maxTotal"
                placeholder="Max Total"
                value={filters.maxTotal}
                onChange={handleFilterChange}
              />
            </div>

            <div className="date-filters-row">
              <div className="date-filter-group">
                <label htmlFor="startDate">Order date from:</label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={filters.startDate}
                  onChange={handleFilterChange}
                  className="form-control"
                />
              </div>
              <div className="date-filter-group">
                <label htmlFor="endDate">Order date to:</label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={filters.endDate}
                  onChange={handleFilterChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <h5>Order Summary (LaaS)</h5>

          {isLoading ? (
            <div className="your-orders-loading">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : filteredOrders.length === 0 ? (
            <p className="your-orders-empty">You have no orders at the moment.</p>
          ) : (
            <div className="your-orders-list">
              {filteredOrders.map((order) => (
                <div 
                  key={order.id} 
                  id={`order-${order.id}`}
                >
                  <div className="d-flex flex-column">
                    {alertMessages[order.id] && (
                      <div className="alert alert-danger alert-dismissible fade show mb-2" role="alert">
                        {alertMessages[order.id]}
                        <button 
                          type="button" 
                          className="btn-close" 
                          onClick={() => setAlertMessages(prev => ({
                            ...prev,
                            [order.id]: null
                          }))}
                          aria-label="Close"
                        ></button>
                      </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-center gap-3">
                        <h3 className="mb-0">Order number: {order.id}</h3>
                        <div className="order-date">
                          {formatDate(order.dateCreated)}
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => handleGoToSubscription(order.id)}
                          style={{ 
                            fontSize: '0.8rem',
                            minWidth: '110px',
                            width: 'auto'
                          }}
                        >
                          View Subscription
                        </button>
                        {new URLSearchParams(location.search).get('orderId') === order.id.toString() && (
                          <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => handleTopUp()}
                            style={{ 
                              fontSize: '0.8rem',
                              minWidth: '110px',
                              width: 'auto'
                            }}
                          >
                            Top Up Wallet (${calculateTotal(order.wordToLinks)})
                          </button>
                        )}
                        <button
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => handleDownloadInvoice(order.id)}
                          disabled={!order.isPaid || downloadingOrderId === order.id}
                          title={!order.isPaid ? "Cannot download invoice for unpaid order" : "Download invoice"}
                          style={{ 
                            fontSize: '0.8rem',
                            minWidth: '110px',
                            width: 'auto'
                          }}
                        >
                          {downloadingOrderId === order.id ? (
                            <span className="d-flex align-items-center justify-content-center gap-1">
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                          ) : (
                            'Download Invoice'
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="your-orders-table-wrapper">
                      <table className="your-orders-table">
                        <thead>
                          <tr>
                            <th>Keyword</th>
                            <th>Monthly Value</th>
                            <th>Type of Link</th>
                            <th>Publisher Website</th>
                            <th>Domain Authority</th>
                            <th>Exclusivity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.wordToLinks.map((item, index) => (
                            <tr key={index}>
                              <td data-label="Keyword">
                                <div className="your-orders-keyword">
                                  {item.keyword}
                                </div>
                              </td>
                              <td data-label="Monthly Value">${item.monthlyValue}</td>
                              <td data-label="Type of Link">{item.typeOfLink}</td>
                              <td data-label="Publisher Website">{item.publisherWebsite}</td>
                              <td data-label="Domain Authority">{item.domainAuthority}</td>
                              <td data-label="Exclusivity">{item.exclusivity ? 'Yes' : 'No'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="your-orders-total">
                      <strong>Total: ${calculateTotal(order.wordToLinks)}</strong>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </PageLayout>
  );
};

const calculateTotal = (wordToLinks) => {
  if (!wordToLinks) return 0;
  return wordToLinks.reduce((total, item) => total + item.monthlyValue, 0);
};

const getUniquePublisherWebsites = (orders) => {
  if (!orders || !Array.isArray(orders)) return [];
  
  const websites = new Set();
  orders.forEach(order => {
    if (order.wordToLinks && Array.isArray(order.wordToLinks)) {
      order.wordToLinks.forEach(link => {
        if (link.publisherWebsite) {
          websites.add(link.publisherWebsite);
        }
      });
    }
  });
  return Array.from(websites).sort();
};

export default YourOrders;
