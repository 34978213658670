import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_API_URL } from '../src/config/constants';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import './LinksReports.css'; // You can create a similar CSS file for styling
import PageLayout from './components/PageLayout';
import targetUrlImg from './assets/images/targeturl.png';
import MySpinner from './components/MySpinner'; // Import your spinner component
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap

const LinksReports = () => {
  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState(''); //bootstrap alert
  const [linksReports, setLinksReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);  // New state for loading

  useEffect(() => {
    const fetchLinksReports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BASE_API_URL}api/linksreports/get-links-for-publisher`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setLinksReports(response.data);
      } catch (error) {
        setError("Failed to fetch links reports.");
        console.error("Error fetching links reports:", error);
      } finally {
        setIsLoading(false);  // Stop loading once the request is completed
      }
    };

    fetchLinksReports();
  }, []);

  return (
    <>
      <PageLayout>
        <div className="container-fluid">
          <div className="">
            <div className="">
              <h3 className="my-4">Links Reports</h3>
              {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}
              {isLoading ? (  // Show spinner if still loading
                <MySpinner /> // Use MySpinner component here
              ) : (
                <>
                  {linksReports.length === 0 ? (
                    <p>No links reports available.</p>  // Handle empty data case
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th>Keyword</th>
                            <th>URL</th>
                            <th>Title</th>
                            <th>Exclusivity</th>
                            <th>Link Type</th>
                            <th>Publisher Website</th>
                            <th>Creation Date</th>
                            <th>TargetUrl</th>
                            <th>Domain Authority</th>
                            <th>Monthly Value</th>
                            <th>Active Status</th>
                            <th>Occurrences</th>
                          </tr>
                        </thead>
                        <tbody>
                          {linksReports.map((link, index) => (
                            <tr key={index}>
                              <td>{link.keyword}</td>
                              <td><a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a></td>
                              <td>{link.title}</td>
                              <td>{link.exclusivity ? 'Yes' : 'No'}</td>
                              <td>{link.typeOfLink}</td>
                              <td>{link.publisherWebsite}</td>
                              <td>{link.dateCreated}</td>
                              <td>
                                {link.targetUrl && (
                                  <a href={link.targetUrl} target="_blank" rel="noopener noreferrer">
                                    <img src={targetUrlImg} alt="Target url" style={{ width: '16px', height: '16px' }} title={link.targetUrl} />
                                  </a>
                                )}
                              </td>
                              <td>{link.domainAuthority}</td>
                              <td>${link.monthlyValue}</td>
                              <td>{link.isActive ? 'Active' : 'Inactive'}</td>
                              <td>{link.occurences}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default LinksReports;
