import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService';
import {jwtDecode} from 'jwt-decode';
import './Login.css';
import logoImage from './assets/images/Link-Building-Software.png';
import backgroundImage from './assets/images/Adverlink-Image-of-a-woman-looking-at-a-a-web.jpg';
import googleLogo from './assets/images/google-logo.png';
import { BASE_API_URL } from '../src/config/constants';

const Login = () => {
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [messages, setMessages] = useState({ error: '', success: '' });
  const { login, googleSignIn, token, userType } = useAuth();
  const navigate = useNavigate();
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const handleCredentialResponse = async (response) => {
    console.log('Encoded JWT ID token: ' + response.credential);
    try {
      setIsGoogleLoading(true);
      await googleSignIn(response.credential);
      const token = localStorage.getItem('token');
      const userType = token ? getUserTypeFromToken(token) : null;
      
      if (!userType) {
        navigate('/chooseType');
      } else if (userType === 'sysadmin') {
        navigate('/AdminPanel');
      } else {
        navigate(`/${userType}`);
      }
    } catch (error) {
      setMessages({ error: 'Failed to sign in with Google. Please try again.', success: '' });
    } finally {
      setIsGoogleLoading(false);
    }
  };

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: '1048634306191-k05g3uogid9d6vpp2ltl4pquon2r79cq.apps.googleusercontent.com',
        callback: handleCredentialResponse,
        context: 'signin',
        ux_mode: 'popup',
        login_uri: `${BASE_API_URL}api/authentication/google-signin`,
        auto_prompt: false,
      });
    };

    if (window.google && window.google.accounts) {
      initializeGoogleSignIn();
    } else {
      window.addEventListener('load', initializeGoogleSignIn);
    }

    return () => {
      window.removeEventListener('load', initializeGoogleSignIn);
    };
  }, [googleSignIn, navigate]);

  useEffect(() => {
    if (token) {
      if (userType === 'sysadmin')
        navigate(`/AdminPanel`);
      else
        navigate(`/${userType || 'advertiser'}`);
    }
  }, [token, userType, navigate]);
  
  const getUserTypeFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userType; 
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };
  
  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(loginData);
      setMessages({ success: 'Login successful!', error: '' });
    } catch (error) {
      setMessages({ error: 'Invalid email or password.', success: '' });
    }
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    navigate('/forgot-password');
  };

  const onSignup = (e) => {
    e.preventDefault();
    navigate('/register');
  };

  const handleGoogleSignIn = () => {
    // Create a new Google Sign-In button element
    const googleButton = document.createElement('div');
    // Add it to the document temporarily
    document.body.appendChild(googleButton);
    
    // Render the button (it will be hidden)
    window.google.accounts.id.renderButton(
      googleButton,
      { theme: 'outline', size: 'large' }
    );
    
    // Click the hidden button
    googleButton.querySelector('div[role="button"]').click();
    
    // Remove the temporary button
    document.body.removeChild(googleButton);
  };

  return (
    <div className="login-container">
      <div className="login-left-side">
        <div className="login-content">
          <div className="login-logo-container">
            <img src={logoImage} alt="AdverLink" />
          </div>
          <h2 className="login-welcome-text">Welcome</h2>
          <p>Enter your login credentials to access your account</p>

          <form onSubmit={handleSubmit}>
            <button 
              type="button"
              onClick={handleGoogleSignIn}
              className="google-signin-btn"
              disabled={isGoogleLoading}
            >
              {isGoogleLoading ? (
                <>
                  <div className="spinner-border spinner-border-sm text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <span>Signing in...</span>
                </>
              ) : (
                <>
                  <img src={googleLogo} alt="Google" className="google-icon" />
                  <span>Continue with Google</span>
                </>
              )}
            </button>
            <div className="login-divider">
              <span>or</span>
            </div>
            <div className="login-form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={loginData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="login-form-group">
              <div className="login-password-container">
                <label htmlFor="password">Password:</label>
                <a href="#" onClick={onForgotPassword}>Forgot Password?</a>
              </div>
              <input
                type="password"
                id="password"
                name="password"
                value={loginData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                required
              />
            </div>
            <div className="login-remember-me">
              <input type="checkbox" id="remember-me" name="remember-me" />
              <label htmlFor="remember-me">Remember me</label>
            </div>
            <button type="submit" className="login-btn">Log in</button>
          </form>
          {messages.error && <div className="login-error-message">{messages.error}</div>}
          {messages.success && <div className="login-success-message">{messages.success}</div>}
          <div className="login-signup-footer">
            Don't have an account? <a href="#" onClick={onSignup}>Signup for free</a>
          </div>
        </div>
      </div>
      <div className="login-right-side">
        <img src={backgroundImage} alt="Background Image" />
      </div>
    </div>
  );
};

export default Login;
