import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../../../config/constants';
import SendNotification from '../modals/SendNotification';

const NotificationSender = () => {
    const [inactiveOrders, setInactiveOrders] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [showInactiveUsers, setShowInactiveUsers] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchInactiveOrders();
    }, []);

    const fetchInactiveOrders = async () => {
        try {
            const response = await axios.get(
                `${BASE_API_URL}api/AdminPanel/GetOrdersNotActivated`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setInactiveOrders(response.data);
        } catch (error) {
            console.error('Error fetching inactive orders:', error);
        }
    };

    const handleUserSelect = (user, isChecked, publisherInfo) => {
        // Actualizăm doar starea locală
        if (isChecked) {
            setSelectedRecipients(prev => [...prev, publisherInfo.userId]);
        } else {
            setSelectedRecipients(prev => prev.filter(id => id !== publisherInfo.userId));
        }
    };

    const handleSend = async (notificationData) => {
        try {
            const response = await axios.post(
                `${BASE_API_URL}api/AdminPanel/SendNotificationToAllUsers`,
                {
                    ...notificationData,
                    userIDs: selectedRecipients
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            if (response.status === 200) {
                alert('Notification sent successfully!');
                setSelectedRecipients([]);
            }
        } catch (error) {
            console.error('Error sending notification:', error);
            alert('Failed to send notification. Please try again.');
        }
    };

    // Funcție pentru a grupa linkurile după publisher
    const groupByPublisher = (orders) => {
        const publisherGroups = {};
        
        orders.forEach(order => {
            order.inactiveLinks.forEach(link => {
                if (!link.domain?.publisher) return;
                
                const publisherId = link.domain.publisher.userId;
                if (!publisherGroups[publisherId]) {
                    publisherGroups[publisherId] = {
                        publisher: link.domain.publisher,
                        links: [],
                        advertisers: new Set()
                    };
                }
                
                publisherGroups[publisherId].links.push({
                    ...link,
                    advertiser: order.user,
                    orderDate: order.dateCreated
                });
                publisherGroups[publisherId].advertisers.add(order.user.email);
            });
        });

        return Object.values(publisherGroups);
    };

    // Adăugăm un handler pentru când se schimbă recipienții în SendNotification
    const handleRecipientsChange = (newRecipients) => {
        // Actualizăm selectedRecipients cu noile ID-uri
        setSelectedRecipients(newRecipients.map(r => r.userId));
    };

    return (
        <div className="notification-sender-container p-4 bg-white rounded shadow-sm">
            <div className="d-flex justify-content-end mb-2 gap-2">
                <button 
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                        const publishers = groupByPublisher(inactiveOrders);
                        const publisherIds = publishers.map(group => group.publisher.userId);
                        setSelectedRecipients(publisherIds);
                    }}
                >
                    Select All
                </button>
                <button 
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => setSelectedRecipients([])}
                >
                    Clear All
                </button>
                <button 
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => setShowInactiveUsers(!showInactiveUsers)}
                >
                    {showInactiveUsers ? 'Hide' : 'Show'} Users with Inactive Links
                </button>
            </div>

            {showInactiveUsers && (
                <div className="card p-3 mb-4">
                    <h5>
                        Users with Inactive Links ({groupByPublisher(inactiveOrders).length} publishers, {
                            inactiveOrders.reduce((total, order) => 
                                total + order.inactiveLinks.length, 0)
                        } links)
                    </h5>
                    <div 
                        className="border rounded p-2" 
                        style={{ 
                            overflowY: 'auto',
                            resize: 'vertical',
                            minHeight: '100px',
                            maxHeight: '800px',
                        }}
                    >
                        {groupByPublisher(inactiveOrders).map((group) => (
                            <div key={group.publisher.userId} className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={group.publisher.userId}
                                    id={`publisher-${group.publisher.userId}`}
                                    checked={selectedRecipients.includes(group.publisher.userId)}
                                    onChange={(e) => handleUserSelect(
                                        null,
                                        e.target.checked,
                                        group.publisher
                                    )}
                                />
                                <label className="form-check-label" 
                                       htmlFor={`publisher-${group.publisher.userId}`}>
                                    <div>
                                        <strong>{group.publisher.email}</strong>
                                        {group.publisher.fullName && 
                                            ` (${group.publisher.fullName})`}
                                    </div>
                                    <div className="mt-2">
                                        <small className="text-muted d-block mb-1">
                                            Inactive Links ({group.links.length}):
                                        </small>
                                        {group.links.map((link) => (
                                            <small key={link.wtlId} className="d-block text-muted ms-3">
                                                • Publisher domain: {link.publisherWebsite} - {link.keyword} 
                                                (DA: {link.domain?.domainAuthority || 'N/A'})
                                                <br />
                                                <span className="ms-3">
                                                    Advertiser: {link.advertiser.email}
                                                    {link.advertiser.company && ` (${link.advertiser.company})`}
                                                    <br />
                                                    Created: {new Date(link.orderDate).toLocaleDateString()}
                                                </span>
                                            </small>
                                        ))}
                                    </div>
                                </label>
                            </div>
                        ))}
                        {inactiveOrders.length === 0 && (
                            <p className="text-muted m-2">No users with inactive links found.</p>
                        )}
                        {inactiveOrders.length > 0 && 
                         groupByPublisher(inactiveOrders).length === 0 && (
                            <p className="text-muted m-2">No valid publisher information found.</p>
                        )}
                    </div>
                </div>
            )}

            <SendNotification 
                show={true} 
                onClose={() => {}} 
                onSend={handleSend}
                standalone={true}
                selectedRecipients={selectedRecipients}
                onRecipientsChange={handleRecipientsChange}
            />
        </div>
    );
};

export default NotificationSender; 