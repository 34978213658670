import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Publisher.css';
import Navbar from './components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './components/Sidebar';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './authService';
import PageLayout from './components/PageLayout';
import { Upload } from 'lucide-react'; // Importing Upload icon
import { BASE_API_URL } from './config/constants';
import { faUser, faLink, faPlug } from '@fortawesome/free-solid-svg-icons'; // Importing FontAwesome icons

const Publisher = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const navigateToStep1 = () => {
    navigate('/publisher-step1');
  };

  const getIsActiveFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.isActive;
    } catch (error) {
      return 0;
    }
  };
  const isActive = getIsActiveFromToken(token);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
        setUploadMessage('Please select a file before uploading.');
        return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    setUploading(true);

    try {
        // Call the UploadCsv endpoint with the appropriate URL
        const response = await fetch(`${BASE_API_URL}api/CsvUpload/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Include the authorization header
            },
            body: formData,
        });

        if (response.ok) {
            setUploadMessage('CSV file uploaded and processed successfully!');
            // Optionally, reset the file input
            setSelectedFile(null);
        } else {
            const errorText = await response.text(); // Get the error message from the response
            setUploadMessage(errorText || 'File upload failed. Please try again.');
        }
    } catch (error) {
        console.error('Error uploading file:', error);
        setUploadMessage('An error occurred while uploading the file.');
    } finally {
        setUploading(false);
    }
};


  return (
    <PageLayout>
    <h2 className="ms-4">Get Started in 3 Easy Steps</h2>
    <div className="how-to-use-container container px-3">
        <div className="steps">
            <div className="step">
                <FontAwesomeIcon icon={faUser} className="step-icon" />
                <div className="step-content">
                    <h3>Step 1: Set Up Your Profile</h3>
                    <p>
                        Complete your profile to attract relevant advertisers and get started.
                        <a href="/company-details" rel="noopener noreferrer"> Click here to set up your profile.</a>
                    </p>
                </div>
            </div>
            <div className="step">
                <FontAwesomeIcon icon={faLink} className="step-icon" />
                <div className="step-content">
                    <h3>Step 2: Add Your Website and Accept Campaigns</h3>
                    <p>
                        Add your website and start accepting campaigns. 
                        <a href="/publisher-step1" rel="noopener noreferrer"> Click here to add your website.</a>
                    </p>
                </div>
            </div>
            <div className="step">
                <FontAwesomeIcon icon={faPlug} className="step-icon" />
                <div className="step-content">
                    <h3>Step 3: Install Our Plugin for Automatic Link Insertion</h3>
                    <p>
                        Easily integrate advertiser links into your articles with our plugin. 
                        <a href="/Download" rel="noopener noreferrer"> Click here to download the plugin.</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <button className="btn btn-primary ms-3 w-50" onClick={navigateToStep1}>Add your website</button>
</PageLayout>
  );
};

export default Publisher;
