import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import './Chatbot.css';
import { jwtDecode } from 'jwt-decode';
import { BASE_API_URL } from '../config/constants';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isHumanSupport, setIsHumanSupport] = useState(false);
  const [consultantName, setConsultantName] = useState('');
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const messagesEndRef = useRef(null);
  const [connection, setConnection] = useState(null);
  const userId = useRef(null);

  // Funcție pentru a obține ID-ul utilizatorului din token
  const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;

    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Obținem ID-ul utilizatorului din token
    userId.current = getUserIdFromToken();
    
    if (!userId.current) {
      console.error('No user ID found in token');
      return;
    }

    console.log('Initializing SignalR connection...');
    setConnectionStatus('connecting');

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${BASE_API_URL}chatHub`, {
        accessTokenFactory: () => localStorage.getItem('token'),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect([0, 2000, 5000, 10000, 30000, 60000])
      .build();

    newConnection.on('ReceiveMessage', (message) => {
      console.log('Received message from consultant:', message);
      const consultantMessage = {
        id: Date.now(),
        text: message,
        sender: 'consultant',
        timestamp: new Date().toISOString()
      };
      setMessages(prevMessages => [...prevMessages, consultantMessage]);
      setIsTyping(false);
    });

    newConnection.on('ConsultantStatusChanged', (isOnline, consultantName) => {
      console.log('Consultant status changed:', { isOnline, consultantName });
      if (isOnline) {
        setConsultantName(consultantName);
        setIsHumanSupport(true);
      }
    });

    newConnection.onreconnecting((error) => {
      console.log('Reconnecting due to error:', error);
      setConnectionStatus('connecting');
    });

    newConnection.onreconnected((connectionId) => {
      console.log('Reconnected successfully with ID:', connectionId);
      setConnectionStatus('connected');
    });

    newConnection.onclose((error) => {
      console.log('Connection closed:', error);
      setConnectionStatus('disconnected');
      
      // Attempt to reconnect after a delay
      setTimeout(() => {
        console.log('Attempting to reconnect...');
        newConnection.start()
          .then(() => {
            console.log('Reconnected successfully');
            setConnectionStatus('connected');
            const token = localStorage.getItem('token');
            const decodedToken = jwtDecode(token);
            const userEmail = decodedToken.sub;
            return newConnection.invoke('ConnectAsClient', userId.current, userEmail);
          })
          .then(() => {
            console.log('Successfully re-registered as client');
          })
          .catch(err => {
            console.error('Failed to reconnect:', err);
            setConnectionStatus('disconnected');
          });
      }, 5000);
    });

    newConnection.start()
      .then(() => {
        console.log('Connected to SignalR hub successfully');
        setConnectionStatus('connected');
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        // Folosim email-ul (sub) în loc de name
        const userEmail = decodedToken.sub;
        console.log('Connecting as client:', { userId: userId.current, userEmail });
        return newConnection.invoke('ConnectAsClient', userId.current, userEmail);
      })
      .then(() => {
        console.log('Successfully registered as client');
      })
      .catch(err => {
        console.error('Error in SignalR connection:', err);
        setConnectionStatus('disconnected');
      });

    setConnection(newConnection);

    return () => {
      if (newConnection) {
        console.log('Cleaning up SignalR connection');
        newConnection.stop();
      }
    };
  }, []);

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = {
      id: Date.now(),
      text: input,
      sender: 'user',
      timestamp: new Date().toISOString()
    };

    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);

    try {
      if (isHumanSupport) {
        if (!connection || connectionStatus !== 'connected') {
          console.error('Cannot send message: Connection not available', { connectionStatus });
          throw new Error('Not connected to chat server');
        }
        console.log('Sending message to consultant:', { message: input, userId: userId.current });
        await connection.invoke('SendMessageToConsultant', input, userId.current);
      } else {
        // Trimite mesajul către bot (folosind API-ul existent)
        const response = await axios.post(`${BASE_API_URL}api/chatbot/message`, {
          message: input
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        setTimeout(() => {
          const botMessage = {
            id: Date.now() + 1,
            text: response.data.reply,
            sender: 'bot',
            timestamp: new Date().toISOString()
          };

          setMessages(prevMessages => [...prevMessages, botMessage]);
          setIsTyping(false);
        }, 500);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = {
        id: Date.now() + 1,
        text: 'Sorry, I encountered an issue. Please try again later.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
      setIsTyping(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const handleHumanSupport = async () => {
    try {
      console.log('Initiating human support connection...');
      // Simulăm conectarea la un consultant uman
      const consultant = "Consultant Adverlink";
      setConsultantName(consultant);
      setIsHumanSupport(true);

      const humanMessage = {
        id: Date.now(),
        text: `Hello! I'm the Adverlink consultant. Please note that I'm in testing phase. How can I help you today?`,
        sender: 'consultant',
        timestamp: new Date().toISOString()
      };
      setMessages(prevMessages => [...prevMessages, humanMessage]);
    } catch (error) {
      console.error('Error connecting to human support:', error);
      const errorMessage = {
        id: Date.now(),
        text: "Sorry, but we can't connect you to a human consultant right now. Please try again later.",
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    }
  };

  const switchBackToBot = () => {
    setIsHumanSupport(false);
    setConsultantName('');
    
    const botMessage = {
      id: Date.now(),
      text: "Hello! I'm Aysa Dema Bot. How can I help you?",
      sender: 'bot',
      timestamp: new Date().toISOString()
    };
    setMessages(prevMessages => [...prevMessages, botMessage]);
  };

  return (
    <div className="chatbot-container">
      {isOpen ? (
        <div className="chatbot-window">
          <div className="chatbot-header">
            <div className="header-left">
              <h3>{isHumanSupport ? consultantName : 'Aysa Dema Bot'}</h3>
              {isHumanSupport ? (
                <button 
                  onClick={switchBackToBot} 
                  className="switch-to-bot-button"
                >
                  Switch back to Aysa Dema Bot
                </button>
              ) : (
                <button 
                  onClick={handleHumanSupport} 
                  className="human-support-header-button"
                >
                  Talk to a Consultant
                </button>
              )}
            </div>
            <button onClick={toggleChat} className="close-button">×</button>
          </div>
          <div className="chatbot-messages">
            {messages.map(message => (
              <div 
                key={message.id} 
                className={`message ${
                  message.sender === 'user' 
                    ? 'user-message' 
                    : message.sender === 'consultant'
                    ? 'consultant-message'
                    : 'bot-message'
                }`}
              >
                <div className="message-content">{message.text}</div>
                <div className="message-timestamp">
                  {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="message bot-message typing">
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="chatbot-input">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={isHumanSupport ? "Type a message for the consultant..." : "Type a message..."}
              disabled={isHumanSupport && connectionStatus !== 'connected'}
            />
            <button 
              onClick={sendMessage}
              disabled={isHumanSupport && connectionStatus !== 'connected'}
            >
              Send
            </button>
          </div>
        </div>
      ) : (
        <button onClick={toggleChat} className="chatbot-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default Chatbot;