import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './authService';
import { PaymentProvider } from './PaymentContext';
import Login from './Login';
import Publisher from './Publisher';
import PublisherStep1 from './PublisherStep1';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import EmailVerification from './EmailVerification';
import PublisherStep2 from './PublisherStep2';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Advertiser from './Advertiser';
import AdvertiserStep1 from './AdvertiserStep1';
import ChooseUserType from './ChooseUserType';
import NewPassword from './NewPassword';
import AdvertiserLanding from './AdvertiserLanding';
import YourOrders from './YourOrders';
import CompanyDetails from './CompanyDetails';
import Checkout from './Checkout';
import {jwtDecode} from 'jwt-decode';
import MyAccount from './MyAccount';
import LinksReports from './LinksReports';
import Subscription from './Subscription';
import KeywordsReports from './KeywordsReports';
import Download from './Download';
import HowToUseIt from './HowToUseIt';
import Invoices from './Invoices';
import { CartProvider } from './CartContext';
import Cart from './Cart';
import CartPage from './Cart';
import AdminPanel from './AdminPanel';
import HiddenUsersPanel from './HiddenUsersPanel';
import AdminLinksReports from './AdminLinksReports';
import Wallet from './Wallet';
import PublisherReports from './PublisherReports';
import { NotificationProvider, useNotifications } from './components/NotificationContext';


const getUserTypeFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};


const ProtectedRoute = ({ children, allowedUserTypes }) => {
  const token = localStorage.getItem('token');
  const userType = token ? getUserTypeFromToken(token) : null;

  console.log(token);
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (!allowedUserTypes.includes(userType)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function AppRoutes() {
  const getUserTypeFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userType;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const userType =  getUserTypeFromToken(token);
  useEffect(() => {
    setLoading(false);
  }, [token, userType]);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }


  const isAuthenticated = token || localStorage.getItem('token');
console.log('tokenul meu', token);
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/login" element={!token ? <Login /> : <Navigate to={`/${userType}`} replace />} />
  <Route path="/register" element={!token ? <Register /> : <Navigate to={`/${userType}`} replace />} />
  <Route path="/forgot-password" element={!token ? <ForgotPassword /> : <Navigate to={`/${userType}`} replace />} />
  <Route path="/newpassword/:key/:email" element={!token ? <NewPassword /> : <Navigate to={`/${userType}`} replace />} />
      <Route path="/emailverify" element={<EmailVerification />} />
      {/* TODO */}
      <Route path="/MyAccount" element={<MyAccount />} /> 
      <Route path="/LinksReports" element={<LinksReports />} />
      <Route path="/Subscription" element={<Subscription />} />
      <Route path="/KeywordsReports" element={<KeywordsReports />} />
      <Route path="/Download" element={<Download />} />
      <Route path="/HowToUseIt" element={<HowToUseIt />} />
      <Route path="/Invoices" element={<ProtectedRoute allowedUserTypes={['advertiser']}> <Invoices /> </ProtectedRoute>} />
      <Route path="/PublisherInvoices" element={<ProtectedRoute allowedUserTypes={['publisher']}><PublisherReports /></ProtectedRoute>} />
      <Route path="/Cart" element={<ProtectedRoute allowedUserTypes={['publisher', 'advertiser']}><CartPage /></ProtectedRoute>} />
      {/* Routes for both user types */}
      <Route path="/your-orders" element={<ProtectedRoute allowedUserTypes={['publisher', 'advertiser']}><YourOrders /></ProtectedRoute>} />
      <Route path="/company-details" element={<ProtectedRoute allowedUserTypes={['publisher', 'advertiser']}><CompanyDetails /></ProtectedRoute>} />

      {/* Publisher routes */}
      <Route path="/publisher-step1" element={<ProtectedRoute allowedUserTypes={['publisher']}><PublisherStep1 /></ProtectedRoute>} />
      <Route path="/publisher-step2" element={<ProtectedRoute allowedUserTypes={['publisher']}><PublisherStep2 /></ProtectedRoute>} />
      <Route path="/publisher" element={<ProtectedRoute allowedUserTypes={['publisher']}><Publisher /></ProtectedRoute>} />

      {/* Advertiser routes */}
      <Route path="/advertiser" element={<ProtectedRoute allowedUserTypes={['advertiser']}><AdvertiserLanding /></ProtectedRoute>} />
      <Route path="/advertiser-step1" element={<ProtectedRoute allowedUserTypes={['advertiser']}><AdvertiserStep1 /></ProtectedRoute>} />
      <Route path="/checkout" element={<ProtectedRoute allowedUserTypes={['advertiser']}><Checkout /></ProtectedRoute>} />
      <Route path="/AdminPanel" element={<ProtectedRoute allowedUserTypes={['sysadmin']}><AdminPanel /></ProtectedRoute>} />
      <Route path="/AdminLinksReports" element={<ProtectedRoute allowedUserTypes={['sysadmin']}><AdminLinksReports /></ProtectedRoute>} />
      <Route path="/wallet" element={<ProtectedRoute allowedUserTypes={['advertiser']}><Wallet /></ProtectedRoute>} />
      {/* Default routes */}
      <Route path="/chooseType" element={token && !userType ? <ChooseUserType /> : <Navigate to="/" replace />} />

      <Route path="/hidden-users" element={<ProtectedRoute allowedUserTypes={['sysadmin']}><HiddenUsersPanel /></ProtectedRoute>} />
      <Route path="/" element={
        token ? (
          userType === 'publisher' ? <Navigate to="/publisher" replace /> :
          userType === 'advertiser' ? <Navigate to="/advertiser" replace /> :
          userType === 'sysadmin' ? <Navigate to="/AdminPanel" replace /> :
          <Navigate to="/chooseType" replace />
        ) : <Navigate to="/login" replace />
      } />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}



function App() {
  return (
    <Router>
      <AuthProvider>
      <NotificationProvider>
        <CartProvider>
        <PaymentProvider>
          <AppRoutes />
        </PaymentProvider>
        </CartProvider>
      </NotificationProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
