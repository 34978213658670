
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import './Sidebar.css';
const getUserTypeFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType; // Assuming 'userType' is the custom claim you set
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
}

const getIsActiveFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.isActive;
  } catch (error) {
    return 0;
  }
}

const Sidebar = () => {
  const token = localStorage.getItem('token');
  const userType = getUserTypeFromToken(token);  
  const isActive = token ?  getIsActiveFromToken(token) : 0; 
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isBillingSubMenuVisible, setBillingSubMenuVisible] = useState(false);
console.log(isActive);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const toggleBillingSubMenu = () => {
    setBillingSubMenuVisible(!isBillingSubMenuVisible);
  };

  return (
    <>
      <div className={`sidebar ${isSidebarVisible ? 'show' : 'hidden'}`}>
        {userType === 'advertiser' && <strong>My Keywords</strong>}
        {userType === 'publisher' && <strong>My Portals</strong>}
        {userType === 'publisher' && <a href="publisher" className='text-decoration-none my-3'>Add Website</a>}
        {userType === 'publisher' && <a href="publisher-step2" className='text-decoration-none my-3'>Websites</a>}
        {userType === 'publisher' && <a href="/LinksReports" className='text-decoration-none mb-3'>Links reports</a>}
        {userType === 'advertiser' && <a href="/Advertiser-step1" className='text-decoration-none my-3'>Order links</a>}
        {userType === 'advertiser' && <a href="/your-orders" className='text-decoration-none my-3'>Your orders</a>}
        {userType === 'advertiser' && <a href="KeywordsReports" className='text-decoration-none my-3'>Keywords reports</a>}
        <hr />
        <strong>My Account</strong>
        {userType === 'sysadmin' && <a href="/AdminPanel" className='text-decoration-none my-3'>Admin Panel</a>}
        {userType === 'sysadmin' && <a href="/AdminLinksReports" className='text-decoration-none my-3'>Admin Links Reports</a>}
        <a href="/MyAccount" className='text-decoration-none my-3'>My account</a>
        {/* <a href="#" className='text-decoration-none mb-3'>Platform language</a> */}
        {/* <a href="#" className='text-decoration-none mb-3'>Users</a> */}
        <a href="#" className='text-decoration-none mb-3' onClick={toggleBillingSubMenu}>Billing</a>
        {isBillingSubMenuVisible && (
          <div className="submenu">
            { userType == 'advertiser' && <a href="/Subscription" className='text-decoration-none my-3'>Subscription</a> }
            <a href="/company-details" className='text-decoration-none my-3'>Billing details</a>
            { userType == 'advertiser ' && <a href="Invoices" className='text-decoration-none my-3'>Invoices</a>}
            { userType == 'publisher' && <a href="PublisherInvoices" className='text-decoration-none my-3'>Invoices</a>}
          </div>
        )}
{ userType === 'publisher' && (
    <>
        <strong>Wordpress Plugin</strong>
        <a href="Download" className='text-decoration-none my-3'>Download</a>
        <a href="HowToUseIt" className='text-decoration-none mb-3'>How to use it</a>
    </>
)}

{userType === 'advertiser' && (
          <>
            <hr />
            <strong>Wallet</strong>
            <a href="/Wallet" className='text-decoration-none my-3'>Wallet</a>
          </>
        )}
      </div>
      <button 
        style={{ borderRadius: '50%' }} 
        className="sidebar-toggle-button" 
        onClick={toggleSidebar}
      >
        {isSidebarVisible ? '<' : '>'}
      </button>
    </>
  );
};

export default Sidebar;
