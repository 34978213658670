import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_API_URL } from './config/constants';
import toast from 'react-hot-toast';
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap
import MySpinner from './components/MySpinner';
import { Spinner } from 'reactstrap';

const BasicData = ({ data, updateData, errors, setErrors }) => {
  const initialFormData = {
    ...data,
    occurrences: data.occurrences || 3,
    domainStatus: { domain: '', isCalculated: false }, // Initialize domainStatus in formData
  };
  const [formData, setFormData] = useState(initialFormData);
  const [recommendedPrice, setRecommendedPrice] = useState(null);
  const [basePrice, setBasePrice] = useState(null);
  const [useCustomPrice, setUseCustomPrice] = useState(false); // New state for custom price checkbox
  const [isTrialMode, setIsTrialMode] = useState(false);
  const [cancelToken, setCancelToken] = useState(null); // Cancel token state
  const [countries, setCountries] = useState([]);

  const [loadingAuthority, setLoadingAuthority] = useState(false);
  
  const [error, setError] = useState(''); // State to manage error messages
  
  console.log(formData);
  useEffect(() => {
    if (JSON.stringify(formData) !== JSON.stringify(data)) {
      updateData({...formData, isTrialMode});
    }
  }, [formData, isTrialMode]);

    // Fetch countries from the API
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}api/StaticValues/countries`);
        setCountries(response.data); // Set the countries from API response
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    // Call fetchCountries on component mount
    useEffect(() => {
      fetchCountries();
    }, []);

  const fetchDomainAuthority = async (domain) => {
    try {
      setLoadingAuthority(true);
      // Cancel any ongoing request
      if (cancelToken) {
        cancelToken.cancel();
      }

      // Create a new cancel token
      const newCancelToken = axios.CancelToken.source();
      setCancelToken(newCancelToken);

      const response = await axios.get(`${BASE_API_URL}api/seo/domain-authority`, {
        params: { target: domain },
        cancelToken: newCancelToken.token,
      });

      const authorityScore = response.data.data[0].authority_score;
      console.log(authorityScore);

      setFormData((prevFormData) => ({
        ...prevFormData,
        domainAuthority: authorityScore,
        domainStatus: { domain, isCalculated: true }, // Update domainStatus in formData
      }));

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous request canceled');
      } else {
        console.error('Error fetching domain authority:', error);
      }
    }
    finally {
      setLoadingAuthority(false);
    }
  };


  useEffect(() => {
    if (formData.domainAuthority) {
      fetchRecommendedPrice(formData.domainAuthority, formData.linkType, formData.exclusivity);
      if (formData.domain) {
        fetchDomainAuthority(formData.domain);
      }
    }
  }, [formData.domainAuthority]);

  const fetchRecommendedPrice = async (domainAuthority, linkType, exclusivity, price) => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/priceCalculator/calculate`, {
        params: {
          domainAuthority,
          exclusivity: false,
          linkType,
          price
        }
      });
      console.log(response);
      setFormData((prevFormData) => ({
        ...prevFormData,
        price: response.data.publisherRevenue,
        basePrice: response.data.basePrice
      }));
      setRecommendedPrice(response.data.publisherRevenue);
      setBasePrice(response.data.basePrice);
    } catch (error) {
      console.error("Error fetching recommended price:", error);
    }
  };

  const handleTrialModeChange = (e) => {
    const { checked } = e.target;
    setIsTrialMode(checked);
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customPrice: 0.001,
        useCustomPrice: false
      }));
      setUseCustomPrice(false);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customPrice: '',
        useCustomPrice: false // Reset this to false when trial mode is unchecked
      }));
      setUseCustomPrice(false); // Ensure the custom price checkbox is unchecked
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle numeric input only for custom price
    if (name === 'customPrice') {
      // Check if value is empty or valid (not negative and does not start with zero)
      if (value !== '' && (isNaN(value) || (Number(value) < 0) || /^0\d/.test(value))) {
          return; // Ignore non-numeric input, negative numbers, or numbers starting with zero
      }
  }

    // if (e.target.name == 'domain')
    //   setErrors({ ...errors, domain: '' });
    // else if (e.target.name == 'language')
    //   setErrors({ ...errors, language: '' });
    // else if (e.target.name == 'country')
    //   setErrors({ ...errors, country: '' });
    // else if (e.target.name == 'websiteType');
    //   setErrors({ ...errors, websiteType: '' });
    setErrors(prevErrors => ({
      ...prevErrors,
      basicData: {
        ...prevErrors.basicData,
        [name]: '',
        domainCalculated: ''
      }
    }));

    setFormData((prevFormData) => {
      const newState = { ...prevFormData, [name]: type === 'checkbox' ? checked : value };

      // Reset domain authority and domainStatus if the domain changes
      if (name === 'domain') {
        newState.domainAuthority = null;
        newState.domainStatus = { domain: value, isCalculated: false };
      }

      return newState;
    });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setUseCustomPrice(checked);
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        useCustomPrice : true,
        customPrice: '', // Reset custom price
        isTrialMode: false // Disable free mode
      }));
      setIsTrialMode(false); // Ensure the trial mode state is updated
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        useCustomPrice: false,
        customPrice: '', // Clear custom price if checkbox is unchecked
      }));
    }
  };

  const handleBlur = async() => {
    try {
      const token = localStorage.getItem('token'); // Replace 'yourAuthToken' with your actual token key

      const requestOptions = {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
  
        const response = await fetch(
          `${BASE_API_URL}api/userdomains/validate?domainName=${encodeURIComponent(formData.domain)}`,
          requestOptions
        );

      if (!response.ok) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          domainAuthority: 0,
          domainStatus: { domain: '', isCalculated: false }, // Update domainStatus in formData
        }));
        setRecommendedPrice(0);
        setError("Invalid or existing domain");
        return false;
      }

    } catch (error) {
      setError("An error occurred while validating the domain.");
      return false;
    }
    if (formData.domain) {
      fetchDomainAuthority(formData.domain);
    }
  };

  const handleCustomPriceBlur = () => {
    if (formData.customPrice && formData.customPrice < 3) {
      setError('Custom price must be greater than or equal to 3');
      setFormData((prevFormData) => ({
        ...prevFormData,
        customPrice: 3, // Set to minimum valid value if invalid
      }));
    }
  };

  return (
    <div style={{ width: '130%' }}>
      <div className="form-group">
        {/* Alert for error messages */}
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}
        <p htmlFor="domain">Domain *</p>
        <input
          placeholder='Enter your Domain'
          type="text"
          id="domain"
          name="domain"
          value={formData.domain || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          className={`form-control ${errors && errors.domain ? 'is-invalid' : ''}`}
        />
        {errors && errors.domain && <div className="text-danger">{errors.domain}</div>}
        {errors && errors.domainCalculated && <div className="text-danger">{errors.domainCalculated}</div>}
      </div>
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  <p htmlFor="domainAuthority" style={{ margin: 0, lineHeight: '1.5' }}>Domain authority:</p>
  <label
  id="domainAuthority"
  name="domainAuthority"
  style={{
    padding: '13px 0px 10px 0px',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    display: 'inline-flex',
    alignItems: 'center',
    height: '1.5em', // Adjust this height to match the line height of the text
  }}
>
  {loadingAuthority ? (
    <div className="text-center">
      <div
        className="spinner-border text-primary"
        role="status"
        style={{ width: '1.5rem', height: '1.5rem' }} // Smaller size
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    formData.domainAuthority ? (
      <span>{formData.domainAuthority}</span> 
    ) : (
      'N/A'
    )
  )}
</label>

</div>
<div className="form-group">
      <p style={{ fontSize: '22px', fontWeight: 'bold', color: 'green' }}>
  Monthly revenue per link: {recommendedPrice !== null ? `$${recommendedPrice}` : 'Waiting for domain...'}
</p>
      </div>
      <div className="form-group">
        <p htmlFor="language">Language of publication *</p>
        <select className={`w-100 form-control ${errors && errors.language ? 'is-invalid' : ''}`} id="language" name="language" value={formData.language || ''} onChange={handleChange} required >
          <option value="">Choose your language</option>
          <option value="Bulgarian">Bulgarian</option>
          <option value="Croatian">Croatian</option>
          <option value="Czech">Czech</option>
          <option value="Danish">Danish</option>
          <option value="Dutch">Dutch</option>
          <option value="English">English</option>
          <option value="Estonian">Estonian</option>
          <option value="Finnish">Finnish</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Greek">Greek</option>
          <option value="Hungarian">Hungarian</option>
          <option value="Irish">Irish</option>
          <option value="Italian">Italian</option>
          <option value="Latvian">Latvian</option>
          <option value="Lithuanian">Lithuanian</option>
          <option value="Maltese">Maltese</option>
          <option value="Polish">Polish</option>
          <option value="Portuguese">Portuguese</option>
          <option value="Romanian">Romanian</option>
          <option value="Slovak">Slovak</option>
          <option value="Slovenian">Slovenian</option>
          <option value="Spanish">Spanish</option>
        </select>
        {errors && errors.language && <div className="text-danger">{errors.language}</div>}
      </div>
      <div className="form-group">
        <p htmlFor="country">Country *</p>
        <select
          id="country"
          name="country"
          value={formData.country || ''}
          onChange={handleChange}
          required
          className={`form-control ${errors && errors.country ? 'is-invalid' : ''}`}
        >
          <option value="">Country of Origin</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        {errors && errors.country && <div className="text-danger">{errors.country}</div>}
      </div>
      <div className="form-group">
        <p htmlFor="website-type">Type of website *</p>
        <select id="website-type" name="websiteType" value={formData.websiteType || ''} onChange={handleChange} required className={`form-control ${errors && errors.websiteType ? 'is-invalid' : ''}`}>
          <option value="">Choose your website type</option>
          <option value="Blog">Blog</option>
          <option value="General Community Portal-countrywide">General Community Portal-countrywide</option>
          <option value="Thematic portal-countrywide">Thematic portal-countrywide</option>
          <option value="General Community Portal-regional">General Community Portal-regional</option>
          <option value="Thematic portal-regional">Thematic portal-regional</option>
          <option value="Corporate website">Corporate website</option>
          <option value="Others">Others</option>
        </select>
        {errors && errors.websiteType && <div className="text-danger">{errors.websiteType}</div>}
      </div>
      <div className="form-group">
        <p htmlFor="occurrences">Occurrences *</p>
        <input
          type="number"
          id="occurrences"
          name="occurrences"
          value={formData.occurrences || 3} // Set default occurrences to 3
          onChange={handleChange}
          min="1" // Ensure value is higher than 0
          required
        />
      </div>
      <div className="form-group" style={{ display: 'none' }}>
        <p htmlFor="price">Price *</p>
        <input
          type="number"
          id="price"
          name="price"
          value={formData.price || recommendedPrice || ''}
          onChange={handleChange}
          required
        />
      </div>
      <div style={{ color: '#8f8f8f' }} className="checkbox-group form-group">
        <input style={{ width: '20px' }} type="checkbox" id="indexing" name="indexing" checked={formData.indexing || false} onChange={handleChange} />
        YES, I hereby grant explicit permission for the AdverLink Bot to crawl this website.
      </div>
      <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
  <h3 htmlFor="useCustomPrice">Set your own price</h3>
  <input
    type="checkbox"
    id="useCustomPrice"
    name="useCustomPrice"
    checked={useCustomPrice}
    onChange={handleCheckboxChange}
    style={{ marginRight: '8px', width: '5%' }}
  />
</div>
    {useCustomPrice && (
      <div className="form-group">
        <p htmlFor="customPrice">Custom price $</p>
        <input
  type="text"
  id="customPrice"
  name="customPrice"
  value={formData.customPrice || ''}
  onChange={handleChange}
  onBlur={handleCustomPriceBlur}
  required
  disabled={isTrialMode}
  pattern="\d*"
  title="Please enter a valid number"
  className={`form-control ${errors && errors.customPrice ? 'is-invalid' : ''}`}
/>
{errors && errors.customPrice && <div className="text-danger">{errors.customPrice}</div>}
      </div>
    )}
      <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
            <label htmlFor="trialMode" style={{ marginRight: '8px' }}>Free ($0)</label>
            <input
              type="checkbox"
              id="trialMode"
              name="trialMode"
              checked={isTrialMode}
              onChange={handleTrialModeChange}
              style={{ width: '5%' }}
            />
          </div>
    </div>
  );
}

export default BasicData;
