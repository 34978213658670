import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import './CheckoutForm.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService';
import { BASE_API_URL } from './config/constants';
import { useCart } from './CartContext';
import { jwtDecode } from 'jwt-decode';

const CheckoutForm = ({ 
  clientSecret, 
  paymentId, 
  amount,
  stripeProducts = [],
  products = [],
  paymentType,
  onPaymentMethodId 
}) => {
  const { clearCart } = useCart();
  const { user } = useAuth(); 
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState(user?.sub || '');
  const [companyDetailsExist, setCompanyDetailsExist] = useState(true);

  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardExpiryError, setCardExpiryError] = useState(false);
  const [cardCvcError, setCardCvcError] = useState(false);

  const token = localStorage.getItem('token');

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid; 
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const getUserName = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.sub; 
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const fetchCompanyDetails = async () => {
    if (paymentType === 'subscription') {
      try {
        const response = await fetch(`${BASE_API_URL}api/companydetails/${user.nameid}/is-company-details-complete`);
        if (response.ok) {
          const result = await response.json();
          setCompanyDetailsExist(result);
        } else {
          throw new Error('Failed to check company details completeness');
        }
      } catch (error) {
        console.error('Error fetching company details completeness:', error);
        setMessage('Error checking company details completeness. Please try again.');
        setCompanyDetailsExist(false);
      }
    }
  };

  useEffect(() => {
    if (paymentType === 'subscription') {
      fetchCompanyDetails();
    }
  }, [user?.nameid, paymentType]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setCardNumberError(false);
    setCardExpiryError(false);
    setCardCvcError(false);

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    let formValid = true;

    if (!cardNumber._complete) {
      setCardNumberError(true);
      formValid = false;
    }

    if (!cardExpiry._complete) {
      setCardExpiryError(true);
      formValid = false;
    }

    if (!cardCvc._complete) {
      setCardCvcError(true);
      formValid = false;
    }

    if (!formValid) {
      setMessage('Please complete all required card fields.');
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {
          name: name,
          email: email,
        },
      });

      if (error) {
        setMessage(`Payment method creation failed: ${error.message}`);
        setIsProcessing(false);
        return;
      }

      if (paymentType === 'subscription') {
        await handleSubscriptionPayment(paymentMethod);
      } else {
        await handleTopUpPayment(paymentMethod);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(`An error occurred: ${error.message}`);
      setIsProcessing(false);
    }
  };

  const handleSubscriptionPayment = async (paymentMethod) => {
    const userId = getUserIdFromToken(token);
    let orderId;

    try {
      // Create order for subscription
      const payload = {
        UserId: userId,
        StripeSubscriptionId: paymentId,
        WordToLinks: products.map(product => ({
          Keyword: product.keyword,
          Url: product.url,
          Title: product.title,
          Exclusivity: product.exclusivity,
          TypeOfLink: product.typeOfLink,
          PublisherWebsite: product.publisherWebsite,
          MonthlyValue: product.monthlyValue,
          DomainAuthority: product.domainAuthority,
          TargetUrl: product.targetUrl
        })),
        Products: stripeProducts
      };

      const orderResponse = await fetch(`${BASE_API_URL}api/Order/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });

      if (!orderResponse.ok) {
        throw new Error('Failed to create order');
      }

      const orderData = await orderResponse.json();
      orderId = orderData.id;

      if (!orderId) {
        throw new Error('Order ID was wrong');
      }

      await handlePaymentMethodUpdate(paymentMethod.id);
      await handlePaymentConfirmation(paymentMethod.id, orderId);

      clearCart();
      navigate('/payment-confirmation', {
        state: {
          amount,
          paymentType: 'subscription',
          products
        }
      });

    } catch (error) {
      throw error;
    }
  };

  const handleTopUpPayment = async (paymentMethod) => {
    try {
      // First update payment method
      await handlePaymentMethodUpdate(paymentMethod.id);
      
      // Confirm the payment with Stripe
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });
  
      if (confirmError) {
        throw new Error(`Payment failed: ${confirmError.message}`);
      }
  
      // If payment succeeded, call the confirm endpoint
      if (paymentIntent.status === 'succeeded') {
        const confirmResponse = await fetch(`${BASE_API_URL}api/codeamount/confirm-top-up?paymentIntentId=${paymentIntent.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (!confirmResponse.ok) {
          throw new Error('Failed to confirm top-up');
        }
  
        const confirmResult = await confirmResponse.json();
        setMessage(`Top-up successful! Added $${confirmResult.amount} to your wallet`);
        navigate('/payment-confirmation', {
          state: {
            amount,
            paymentType: 'topup'
          }
        });
      } else {
        throw new Error('Payment was not successful');
      }
    } catch (error) {
      throw error;
    }
  };

  const handlePaymentMethodUpdate = async (paymentMethodId) => {
    const updateResponse = await fetch(`${BASE_API_URL}api/checkout/update-payment-method`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        email: getUserName(token),
        PaymentMethodId: paymentMethodId
      }),
    });

    if (!updateResponse.ok) {
      throw new Error('Failed to update payment method');
    }

    onPaymentMethodId?.(paymentMethodId);
  };

  const handlePaymentConfirmation = async (paymentMethodId, orderId = null) => {
    const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
    });

    if (confirmError) {
      throw new Error(`Payment failed: ${confirmError.message}`);
    }

    if (paymentIntent.status === 'succeeded' && orderId) {
      const updateOrderResponse = await fetch(`${BASE_API_URL}api/Order/${orderId}/setPaid`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!updateOrderResponse.ok) {
        throw new Error('Failed to update order payment status');
      }
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true,
  };

  const renderOrderSummary = () => {
    if (paymentType === 'subscription') {
      return (
        <div className="order-details">
          <p>Total Amount: ${Number(amount).toFixed(2)} / Month</p>
          <h3>Products:</h3>
          <ul>
            {products.map((product, index) => (
              <li key={index}>
                {product.keyword} - ${Number(product.monthlyValue).toFixed(2)} / Month
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="order-details">
          <p>Amount to Top Up: ${Number(amount).toFixed(2)}</p>
        </div>
      );
    }
  };

  return (
    <div className="checkout-form">
      <h2>Order Summary</h2>
      {renderOrderSummary()}
      <form onSubmit={handleSubmit}>
        <h2>Payment Details</h2>
        <div className="form-row">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            readOnly={!!user?.sub}
          />
        </div>
        <div className={`form-row ${cardNumberError ? 'error' : ''}`}>
          <label htmlFor="card-number">Card Number</label>
          <CardNumberElement id="card-number" options={cardElementOptions} />
        </div>
        <div className={`form-row ${cardExpiryError ? 'error' : ''}`}>
          <label htmlFor="card-expiry">Expiration Date</label>
          <CardExpiryElement id="card-expiry" options={cardElementOptions} />
        </div>
        <div className={`form-row ${cardCvcError ? 'error' : ''}`}>
          <label htmlFor="card-cvc">CVC</label>
          <CardCvcElement id="card-cvc" options={cardElementOptions} />
        </div>
        {paymentType === 'subscription' && !companyDetailsExist && (
          <div className="error-message">
            You must complete company details first! 
            Please visit <a href="/MyAccount">My Account</a> or <a href="/company-details">Company Details</a>.
          </div>
        )}
        <button 
          className="submit-button" 
          type="submit" 
          disabled={!stripe || isProcessing || (paymentType === 'subscription' && !companyDetailsExist)}
        >
          {isProcessing ? 'Processing...' : paymentType === 'subscription' ? 
            `Pay $${Number(amount).toFixed(2)} / Month` : `Top Up $${Number(amount).toFixed(2)}`}
        </button>
      </form>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default CheckoutForm;