// CartContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  // Load cart items from localStorage on initial render
  useEffect(() => {
    console.log('CartProvider: Initializing...');
    const savedCart = localStorage.getItem('cartItems');
    console.log('CartProvider: Loading saved cart from localStorage:', savedCart);
    
    if (savedCart) {
      try {
        const parsedCart = JSON.parse(savedCart);
        console.log('CartProvider: Successfully parsed cart:', parsedCart);
        setCartItems(parsedCart);
      } catch (error) {
        console.error('CartProvider: Error parsing cart from localStorage:', error);
        localStorage.removeItem('cartItems');
      }
    }
  }, []);

  // Save cart items to localStorage whenever they change
  useEffect(() => {
    console.log('CartProvider: Cart items changed, saving to localStorage:', cartItems);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    console.log('CartProvider: Adding item to cart:', item);
    console.log('CartProvider: Current cart items:', cartItems);
    
    // Check if item already exists in cart based on multiple properties
    const existingItemIndex = cartItems.findIndex(cartItem => 
      cartItem.keyword === item.keyword &&
      cartItem.url === item.url &&
      cartItem.publisherWebsite === item.publisherWebsite &&
      cartItem.targetUrl === item.targetUrl &&
      cartItem.typeOfLink === item.typeOfLink &&
      cartItem.exclusivity === item.exclusivity
    );
    
    console.log('CartProvider: Existing item index:', existingItemIndex);
    
    if (existingItemIndex !== -1) {
      console.log('CartProvider: Item exists, updating at index:', existingItemIndex);
      // Update existing item
      const updatedCart = [...cartItems];
      updatedCart[existingItemIndex] = item;
      console.log('CartProvider: Updated cart:', updatedCart);
      setCartItems(updatedCart);
    } else {
      console.log('CartProvider: Item does not exist, adding new item');
      // Add new item
      const newCart = [...cartItems, item];
      console.log('CartProvider: New cart:', newCart);
      setCartItems(newCart);
    }
  };

  const removeFromCart = (index) => {
    console.log('CartProvider: Removing item at index:', index);
    const updatedCart = cartItems.filter((_, i) => i !== index);
    console.log('CartProvider: Cart after removal:', updatedCart);
    setCartItems(updatedCart);
  };

  const clearCart = () => {
    console.log('CartProvider: Clearing cart');
    setCartItems([]);
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};