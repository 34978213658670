import React, { useState, useMemo } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useAuth } from './authService';
import { jwtDecode } from 'jwt-decode';
import './CheckoutForm.css';
import { BASE_API_URL } from '../src/config/constants';

const TopUpCheckout = ({ 
  clientSecret,
  paymentId,
  amount,
  paymentType,
  onSuccess,
  onError,
  onCancel
}) => {
  const { user } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  
  // Form states
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState(user?.sub || '');
  
  // Validation states
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardExpiryError, setCardExpiryError] = useState(false);
  const [cardCvcError, setCardCvcError] = useState(false);

  const token = localStorage.getItem('token');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || isProcessing) {
      return;
    }

    // Reset validation states
    setCardNumberError(false);
    setCardExpiryError(false);
    setCardCvcError(false);
    setMessage('');

    // Validate card fields
    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    let formValid = true;

    if (!cardNumber._complete) {
      setCardNumberError(true);
      formValid = false;
    }

    if (!cardExpiry._complete) {
      setCardExpiryError(true);
      formValid = false;
    }

    if (!cardCvc._complete) {
      setCardCvcError(true);
      formValid = false;
    }

    if (!formValid) {
      setMessage('Please complete all required card fields.');
      return;
    }

    setIsProcessing(true);

    try {
      // First create/get intent with customer info
      const intentResponse = await fetch(`${BASE_API_URL}/api/codeamount/create-top-up-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          amount: amount,
          email: email
        })
      });

      if (!intentResponse.ok) {
        throw new Error('Failed to create payment intent');
      }

      const { clientSecret: updatedClientSecret } = await intentResponse.json();

      // Create payment method
      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: name,
          email: email,
        },
      });

      if (paymentMethodError) {
        throw new Error(`Payment method creation failed: ${paymentMethodError.message}`);
      }

      // Confirm the payment with the updated client secret
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
        updatedClientSecret,
        {
          payment_method: paymentMethod.id,
          setup_future_usage: 'off_session'
        }
      );

      if (confirmError) {
        throw new Error(`Payment failed: ${confirmError.message}`);
      }

      if (paymentIntent.status === 'succeeded') {
        // Call your backend to confirm the top-up
        const confirmResponse = await fetch(`${BASE_API_URL}/api/codeamount/confirm-top-up?paymentIntentId=${paymentIntent.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!confirmResponse.ok) {
          throw new Error('Failed to confirm top-up');
        }

        const confirmResult = await confirmResponse.json();
        onSuccess('payment_completed', confirmResult);
        setMessage('Top-up successful!');
      }
    } catch (error) {
      console.error('Payment error:', error);
      setMessage(error.message);
      onError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true,
  };

  return (
    <div className="checkout-form">
      <div className="order-summary">
        <h3>Order Summary</h3>
        <div className="amount-display">
          Amount to Top Up: ${amount}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <h3>Payment Details</h3>
        <div className="form-row">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name on card"
            required
          />
        </div>

        <div className="form-row">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            required
            readOnly={!!user?.sub}
          />
        </div>

        <div className={`form-row ${cardNumberError ? 'error' : ''}`}>
          <label>Card Number</label>
          <CardNumberElement options={cardElementOptions} />
          {cardNumberError && <div className="error-message">Please enter a valid card number</div>}
        </div>

        <div className="form-row-group">
          <div className={`form-row ${cardExpiryError ? 'error' : ''}`}>
            <label>Expiration Date</label>
            <CardExpiryElement options={cardElementOptions} />
            {cardExpiryError && <div className="error-message">Please enter a valid expiration date</div>}
          </div>

          <div className={`form-row ${cardCvcError ? 'error' : ''}`}>
            <label>CVC</label>
            <CardCvcElement options={cardElementOptions} />
            {cardCvcError && <div className="error-message">Please enter a valid CVC</div>}
          </div>
        </div>

        <div className="button-group">
          <button
            type="submit"
            className="submit-button"
            disabled={!stripe || isProcessing}
          >
            {isProcessing ? 'Processing...' : `Top Up $${amount}`}
          </button>
          <button
            type="button"
            className="topup-cancel-button"
            onClick={onCancel}
            disabled={isProcessing}
          >
            Cancel
          </button>
        </div>

        {message && (
          <div className={`message ${message.includes('error') || message.includes('failed') ? 'error' : 'success'}`}>
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

export default TopUpCheckout;