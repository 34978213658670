import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import ArrowSpan from "./ArrowSpan";
import PortalLinkCell from "./PortalLinkCell";
import { useCart } from "../CartContext";
import { jwtDecode } from "jwt-decode";
import {linkTypes} from "./LinkTypes"; // Adjust the path as necessary
import { LinkType, getLinkTypeMultiplier, getVAT } from './SmallCalculatorHelper';
import { BASE_API_URL } from "../config/constants";

const ProgressSteps = () => {
  return (
    <Row className="align-items-center my-3">
      <Col xs={12} md={8}>
        <div className="d-flex align-items-center justify-content-between position-relative">
          <div className="position-absolute" style={{ 
            height: "8px", 
            background: "#dee2e6",
            top: "35px",
            left: "10%",
            right: "10%",
            zIndex: 1
          }}></div>
          
          <div className="position-absolute" style={{ 
            height: "8px", 
            background: "#198754",
            width: "70%",
            top: "35px",
            left: "10%",
            zIndex: 2
          }}></div>

          <div className="text-center" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-success text-white d-flex align-items-center justify-content-center mx-auto mb-2" 
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
              </svg>
            </div>
            <h6 className="mb-1">Keyword & Strategy</h6>
            <h6 className="mb-0">Details</h6>
          </div>

          <div className="text-center" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-success text-white d-flex align-items-center justify-content-center mx-auto mb-2"
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
              </svg>
            </div>
            <h6 className="mb-1">Filtered websites</h6>
            <h6 className="mb-0">and articles</h6>
          </div>

          <div className="text-center" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center mx-auto mb-2"
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
              </svg>
            </div>
            <h6 className="mb-1">Order Confirmation</h6>
            <h6 className="mb-0">& details</h6>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const EditableTitle = ({ initialValue, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleBlur = () => {
    setIsEditing(false);
    if (value !== initialValue) {
      onSave(value);
    }
  };

  return isEditing ? (
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      autoFocus
      className="form-control form-control-sm"
    />
  ) : (
    <div
      onClick={() => setIsEditing(true)}
      className="editable-cell"
      style={{
        cursor: 'pointer',
        padding: '4px',
        border: '1px dashed #ccc',
        borderRadius: '4px',
        minHeight: '30px'
      }}
    >
      {value}
      <small className="d-block text-muted" style={{ fontSize: '0.7rem' }}>
        (Click to edit)
      </small>
    </div>
  );
};

// Link Type Select Component
const LinkTypeSelect = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="form-select form-select-sm"
    style={{ 
      cursor: 'pointer',
      border: '1px dashed #ccc'
    }}
  >
    <option value="NormalFollow">Follow</option>
    <option value="NoFollow">No Follow</option>
    <option value="UGC">UGC</option>
    <option value="Sponsored">Sponsored</option>
    <option value="GeneralKeyword">General Keyword</option>
    {/* <option value="GeneralKeyword">General Keyword</option> */}
  </select>
);
const BulkOrder = () => {
  const { addToCart, cartItems } = useCart();
  const isItemInCart = (item) => {
    return cartItems.some(cartItem => 
      cartItem.keyword === item.keyword && 
      cartItem.url === item.yourUrlToBeLinked && 
      cartItem.publisherWebsite === item.portal
    );
  };

  const handleTitleChange = (id, newTitle) => {
    setSelectedItems(prevState =>
      prevState.map(item =>
        item.id === id ? { ...item, title: newTitle } : item
      )
    );
  };

  const handleLinkTypeChange = (id, newLinkType) => {
    setSelectedItems(prevState =>
      prevState.map(item =>
        item.id === id ? { ...item,
           linkType: newLinkType,
           price: item.basePrice * getLinkTypeMultiplier(newLinkType)
           } : item
      )
    );
    console.log('hahahaha', id);
    setHighlightedId(id);
  };
  const [highlightedId, setHighlightedId] = useState(null);
  useEffect(() => {
    if (highlightedId !== null) {
      const timer = setTimeout(() => {
        setHighlightedId(null); // Reset after 1.5 seconds
      }, 1500); // 1.5 seconds
      return () => clearTimeout(timer); // Cleanup timer if the component unmounts
    }
  }, [highlightedId]);
  
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState({});
  const [finalFormData, setFinalFormData] = useState(null);


  const jobId = location.pathname.split('/').pop(); // Extract jobId from URL
  useEffect(() => {
    const fetchJobStatus = async () => {
      if (!jobId) {
        navigate('/bulkorder1'); // Redirect if no jobId
        return;
      }

      setLoading(true);
      try {
        const response = await fetch(`${BASE_API_URL}search/status/${jobId}`);

        console.log(response);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data);
        setFinalFormData(data);
      } catch (err) {
        // setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobStatus();
  }, [jobId, navigate]);
  
  const token = localStorage.getItem('token');
  const getUserIdFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.nameid; // Adjust based on your token's structure
    } catch (error) {
        console.error("Invalid token", error);
        return null;
    }
};
const userId = getUserIdFromToken(token);
const [selectedItems, setSelectedItems] = useState([]);

useEffect(() => {
  console.log(finalFormData);
  if (!finalFormData || !finalFormData.result) {
    return;
  }

  const updatedItems = finalFormData.result.chatgptResults.map((item, index) => {
    // Flatten rankedArticles for the current item
    const rankedArticles = item.item.rankedArticles || [];
    if (rankedArticles.length === 0) {
      console.warn(`No ranked articles found for item: ${item}`);
      return null;
    }

    // Get the first article as default
    const firstArticle = rankedArticles[0];

    // Extract all unique domains
    const allDomains = [...new Set(rankedArticles.map((article) => article.domain))];

    // Create array of target URLs for the first article's domain
    const currentDomainTargetUrls = firstArticle.targetUrls || [];

    // Store all target URLs by domain for reference
    const targetUrlsByDomain = rankedArticles.reduce((acc, article) => {
      if (article.targetUrls && article.targetUrls.length > 0) {
        acc[article.domain] = article.targetUrls;
      }
      return acc;
    }, {});

    // Calculate initial price based on link type
    const linkTypeMultiplier = getLinkTypeMultiplier("NormalFollow"); // TODO: Adjust as needed
    const initialPrice = firstArticle.price * linkTypeMultiplier;

    // Return structured data for this item
    return {
      id: index,
      ...item,
      loading: false,
      rankedArticles: rankedArticles,
      portal: firstArticle.domain,
      targetUrl: firstArticle.targetUrls?.[0] || "",
      allDomains: allDomains,
      domainTargetUrls: currentDomainTargetUrls,
      targetUrlsByDomain: targetUrlsByDomain,
      domainAuthority: firstArticle.domainAuthority || 0,
      basePrice: firstArticle.price,
      price: initialPrice,
      error: false,

      keyword: item.item.keyword || "Unknown",
      linkType: finalFormData.result.typeOfLink || "Follow",
      sentiment: finalFormData.result.sentiment || "Neutral",
      language: finalFormData.result.language || "Unknown",
      country: finalFormData.result.country || "Unknown",
      theme: finalFormData.result.theme || "General",
    };
  });

  // Filter out any null values in case of missing rankedArticles
  const validItems = updatedItems.filter((item) => item !== null);
  console.log(validItems);
  // Update the state
  setSelectedItems(validItems);
  console.log("Updated Selected Items Array:", validItems);
}, [finalFormData]);

  
const fetchRankedArticles = async (item) => {
  const singleRankRequest = {
    Item: {
      Keyword: item.keyword,
      Articles: item.rankedArticles,
    },
    DesiredSentiment: finalFormData.sentiment,
  };

  try {
    setLoadingRows((prev) => ({ ...prev, [item.id]: true })); // Set loading for this row
    const response = await fetch(`${BASE_API_URL}search/single-rank-articles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(singleRankRequest),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ranked articles for keyword: ${item.keyword}`);
    }

    const rankingData = await response.json();

    // Update the item with ranked articles
    setSelectedItems((prevItems) =>
      prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? {
              ...prevItem,
              rankedArticles: rankingData.rankedArticles || [],
            }
          : prevItem
      )
    );
  } catch (error) {
    console.error(`Error fetching ranked articles for keyword: ${item.keyword}`, error);
  } finally {
    setLoadingRows((prev) => ({ ...prev, [item.id]: false })); // Mark row as not loading
  }
};

const fetchRankedArticle = async (item) => {
  const requestPayload = {
    Item: {
      Keyword: item.keyword,
      Articles: item.articles,
    },
    YourUrl: finalFormData.url,
    DesiredSentiment: item.sentiment,
  };
  console.log(requestPayload);

  try {
    const response = await fetch(`${BASE_API_URL}Search/single-rank-articles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch ranked article');
    }

    const rankedData = await response.json();
    const rankedArticles = rankedData.item.rankedArticles || [];
    
    if (rankedArticles.length === 0) {
      throw new Error('No ranked articles received');
    }

    // Get the first article as default
    const firstArticle = rankedArticles[0];
    
    // Extract all unique domains
    const allDomains = [...new Set(rankedArticles.map(article => article.domain))];
    
    // Create array of target URLs for current domain
    const currentDomainTargetUrls = firstArticle.targetUrls || [];

    // Store all target URLs by domain for reference
    const targetUrlsByDomain = rankedArticles.reduce((acc, article) => {
      if (article.targetUrls && article.targetUrls.length > 0) {
        acc[article.domain] = article.targetUrls;
      }
      return acc;
    }, {});

    // Calculate initial price based on link type
    const linkTypeMultiplier = getLinkTypeMultiplier(item.linkType);
    const initialPrice = firstArticle.price * linkTypeMultiplier;
    return {
      ...item,
      loading: false,
      rankedArticles: rankedArticles,
      portal: firstArticle.domain,
      targetUrl: firstArticle.targetUrls?.[0] || "",
      allDomains: allDomains,
      domainTargetUrls: currentDomainTargetUrls, // Array of target URLs for current domain
      targetUrlsByDomain: targetUrlsByDomain, // Object mapping domains to their target URLs
      domainAuthority: firstArticle.domainAuthority || 0,
      basePrice: firstArticle.price,
      price: initialPrice,
      error: false
    };
  } catch (error) {
    console.error('Error fetching ranked article:', error);
    return {
      ...item,
      loading: false,
      error: true
    };
  }
};

  
const rankArticle = async (item) => {
  const updatedItem = await fetchRankedArticle(item); // Fetch ranked article data
  console.log("updated item", updatedItem);

  // Check if all required fields are valid (i.e., not null or undefined)
  const hasData =
    updatedItem.rankedArticles

  // Check if there's an error in the response
  const hasError = updatedItem.error;

  setSelectedItems((prevItems) => {
    if (hasData && !hasError) {
      // If all required data is present and no error, update the item
      return prevItems.map((prevItem) =>
        prevItem.id === item.id ? { ...prevItem, ...updatedItem, loading: false } : prevItem
      );
    } else {
      // If no valid data or there's an error, remove the item
      return prevItems.filter((prevItem) => prevItem.id !== item.id);
    }
  });
};

const [allLoaded, setAllLoaded] = useState(false);


// Function to call `rank-articles-single` for each article
useEffect(() => {
  if (selectedItems.some((item) => item.loading)) {
    selectedItems.forEach((item) => {
      if (item.loading) {
        rankArticle(item); // Trigger ranking for each loading item
      }
    });
  } else {
    setAllLoaded(true); // All items have finished loading
  }
}, []);

const handleChangeDomain = (id) => {
  setSelectedItems(prevState =>
    prevState.map(item => {
      if (item.id === id) {
        const currentDomainIndex = item.allDomains.indexOf(item.portal);
        const nextDomainIndex = (currentDomainIndex + 1) % item.allDomains.length;
        const newDomain = item.allDomains[nextDomainIndex];
        
        // Find the ranked article data for the new domain
        const newDomainData = item.rankedArticles.find(ra => ra.domain === newDomain);
        
        if (!newDomainData) {
          return item;
        }

        // Get target URLs for the new domain from the stored mapping
        const newDomainTargetUrls = newDomainData.targetUrls || [];
        
        // Calculate new price with link type multiplier
        const linkTypeMultiplier = getLinkTypeMultiplier(item.linkType);
        const newPrice = newDomainData.price * linkTypeMultiplier;

        return {
          ...item,
          portal: newDomain,
          targetUrl: newDomainTargetUrls[0] || "",
          domainAuthority: newDomainData.domainAuthority || item.domainAuthority,
          basePrice: newDomainData.price,
          price: newPrice,
          domainTargetUrls: newDomainTargetUrls
        };
      }
      return item;
    })
  );
};



  const handleCheck = (id) => {
    setSelectedItems(prevState =>
      prevState.map(item => {
        if (item.id === id) {
          const updatedItem = { ...item, checked: !item.checked };
          console.log("Updated item:", updatedItem); // Debugging line to check the updated item
          return updatedItem;
        }
        return item;
      })
    );
  };

  const handleGetOtherArticle = (id) => {
    setSelectedItems((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          const currentTargetUrls = item.domainTargetUrls;
          if (!Array.isArray(currentTargetUrls) || currentTargetUrls.length <= 1) {
            return item;
          }

          // Find the current targetUrl's index in the array
          const currentUrlIndex = currentTargetUrls.indexOf(item.targetUrl);
          
          // Get the next index, cycling back to 0 if at the end
          const nextIndex = (currentUrlIndex + 1) % currentTargetUrls.length;
          
          // Get the next URL
          const newTargetUrl = currentTargetUrls[nextIndex];
          
          return {
            ...item,
            targetUrl: newTargetUrl,
          };
        }
        return item;
      })
    );
  };
  
  const generateGUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  };
  const handleAddToCart = () => {
    const itemsToAdd = selectedItems
      .filter(item => item.checked) // Only select checked items
      .filter(item => !isItemInCart(item))
      .map(item => {
        return {
          id: generateGUID(),
          keyword: item.keyword,
          url: item.yourUrlToBeLinked,
          title: item.title,
          exclusivity: false,
          typeOfLink: item.linkType,
          publisherWebsite: item.portal,
          monthlyValue: item.price + getVAT(item.price),
          domainAuthority: item.domainAuthority,
          targetUrl: item.targetUrl,
        };
      });
  
    console.log('Items being added to cart:', itemsToAdd);
  
    itemsToAdd.forEach(item => addToCart(item));
  };
// Function to calculate the price without VAT
const calculatePriceWithoutVAT = (monthlyValue) => monthlyValue;

// Function to calculate VAT for each item
const calculateVAT = (monthlyValue) => getVAT(monthlyValue);

// Calculate the total without VAT (for all selected items)
const calculateTotalWithoutVAT = () => {
  return selectedItems.reduce((total, item) => {
    if (item.checked) {
      return total + calculatePriceWithoutVAT(item.price);
    }
    return total;
  }, 0);
};

// Calculate the total VAT for all selected items
const calculateTotalVAT = () => {
  return selectedItems.reduce((total, item) => {
    if (item.checked) {
      return total + calculateVAT(item.price);
    }
    return total;
  }, 0);
};

// Calculate the total including VAT
const calculateTotal = () => {
  const totalWithoutVAT = calculateTotalWithoutVAT();
  const totalVAT = calculateTotalVAT();
  return totalWithoutVAT + totalVAT;
};

  return (
    <PageLayout>
      <Container fluid style={{padding: '0 20px'}}>
        <ProgressSteps />

        <Row>
          <p className="h4 font-weight-bold mt-3">
            Please select the websites and articles you wish to order
          </p>
          <p className="h6 mt-2">
            Please select the websites and articles that best fit your link-building strategy. Review key metrics such as Domain Authority, Traffic Value,
            and other important metrics to ensure optimal placements for your content.
          </p>
          <Col md={12}>
          {selectedItems.length === 0 ? (
 <div className="alert alert-warning d-flex  align-items-center" role="alert">
 <span>No matching domain for the current settings, change the settings and try again.</span>
 <button 
 style={{maxWidth:'10%', marginLeft:'15px', marginBottom:'10px'}}
        className="btn btn-sm btn-primary rounded-pill shadow-sm px-3"
        onClick={() => {
          navigate('/bulkorder1');
          console.log('Back button clicked'); // Replace this with your navigation or reset logic
        }}
      >
        Go Back
      </button>
</div>
      ) : (
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>
                    Portal
                    <span className='d-flex justify-content-between align-items-center'>
                      <small style={{ fontWeight: 'lighter' }}>
                        Type <br />
                        Country: Region<br />
                        Theme
                      </small>
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Domain Authority
                      <ArrowSpan />
                    </span>
                  </th>
                  {/* <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Traffic Value
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Organic Traffic
                      <ArrowSpan />
                    </span>
                  </th> */}
                  {/* <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Organic Keywords
                      <ArrowSpan />
                    </span>
                  </th> */}
                  {/* <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Relevance Score
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Spam Score
                      <ArrowSpan />
                    </span>
                  </th> */}
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Keyword
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Type of Link
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Sentiment
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Country
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Language
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Theme
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Anchor Title
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Price / Month
                      <ArrowSpan />
                    </span>
                  </th>
                  <th>
                    <span className='d-flex justify-content-between align-items-center'>
                      Order
                      <ArrowSpan />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
  {selectedItems.map((item) => {
    const inCart = isItemInCart(item);
    return (
      <tr 
        key={item.id}
        style={{
          backgroundColor: inCart ? '#f5f5f5' : 'white',
          opacity: inCart ? 0.7 : 1,
          pointerEvents: inCart ? 'none' : 'auto'
        }}
      >
        <td className="text-center">
          {item.loading ? (
            <span>Loading...</span> // Display loading text or replace with spinner component if needed
          ) : (
            <PortalLinkCell 
              item={item}
              handleGetOtherArticle={handleGetOtherArticle}
              handleChangeDomain={handleChangeDomain}
              isChangeDomainDisabled={item.allDomains.length <= 1}
              isGetOtherArticleDisabled={item.domainTargetUrls.length <= 1}
            />
          )}
        </td>
        
        <td className="text-center">{item.loading ? '...' : item.domainAuthority}</td>
        <td className="text-center">{item.loading ? 'Loading...' : item.keyword}</td>
        
        <td className="text-center">
          {item.loading ? (
            'Loading...'
          ) : (
            <LinkTypeSelect
              value={item.linkType}
              onChange={(newValue) => handleLinkTypeChange(item.id, newValue)}
            />
          )}
        </td>
        
        <td className="text-center">{item.loading ? 'Loading...' : item.sentiment}</td>
        <td className="text-center">{item.loading ? 'Loading...' : item.country}</td>
        <td className="text-center">{item.loading ? 'Loading...' : item.language}</td>
        <td className="text-center">{item.loading ? 'Loading...' : item.theme}</td>
        
        <td className="text-center">
          {item.loading ? (
            'Loading...'
          ) : (
            <EditableTitle
              initialValue={item.keyword}
              onSave={(newValue) => handleTitleChange(item.id, newValue)}
            />
          )}
        </td>

        <td
          className="text-center"
          style={{
            color: highlightedId === item.id ? 'green' : 'inherit',
            transition: 'color 2s ease',
          }}
        >
          {item.loading ? 'Loading...' : `$${item.price} USD`}
        </td>
        
        <td className="text-center">
          <input
            type="checkbox"
            checked={item.checked}
            onChange={() => handleCheck(item.id)}
            className="mx-auto"
            disabled={inCart || item.loading} // Disable checkbox if loading or already in cart
          />
        </td>
      </tr>
    );
  })}
</tbody>
            </Table>
      )}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="p-1">
              <div className="mb-4">
              <p className="mb-0" style={{ fontSize: '16px', color: '#1A1A1A' }}>
  Price without VAT: ${calculateTotalWithoutVAT().toFixed(2)}
</p>
<p className="mb-4" style={{ fontSize: '16px', color: '#1A1A1A' }}>
  VAT: ${calculateTotalVAT().toFixed(2)}
</p>

<h4 className="mb-4 text-success">
  Total: ${calculateTotal().toFixed(2)} / Month
</h4>

                <small className="text-muted d-block mb-4">
                  These hyperlinks are Links as a Service (LaaS),<br/> the links will
                  remain active as long as you pay monthly.
                </small>
              </div>

              <div className="d-flex gap-3">
                <Button onClick={handleAddToCart} variant="primary" className="px-1 py-2" style={{ width: '150px' }}>
                  Add to Cart
                </Button>
                {/* <Button variant="primary" className="px-1 py-1" style={{ width: '150px' }}>
                  Pay with Wallet
                </Button> */}
                <Button variant="outline-secondary" className="px-2 py-1" style={{ width: '170px' }}>
                  Continue Shopping
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};

export default BulkOrder;
