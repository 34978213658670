import React from 'react';
import { Row, Col } from "react-bootstrap";

const ProgressSteps = () => {
  return (
    <Row className="align-items-center my-3">
      <Col xs={12} md={8}> {/* Adjusted the width to make it narrower */}
        <div className="d-flex align-items-center justify-content-between position-relative">
          {/* Background progress line - Hidden on smaller screens */}
          <div className="position-absolute d-none d-md-block" style={{ 
            height: "8px", 
            background: "#dee2e6",
            top: "35px",
            left: "10%", 
            right: "10%",
            zIndex: 1
          }}></div>
          
          {/* Active progress line - Hidden on smaller screens */}
          <div className="position-absolute d-none d-md-block" style={{ 
            height: "8px", 
            background: "#198754",
            width: "30%", 
            top: "35px",
            left: "10%", 
            zIndex: 2
          }}></div>

          {/* First Section - Always Visible */}
          <div className="text-center" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-success text-white d-flex align-items-center justify-content-center mx-auto mb-2" 
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
              </svg>
            </div>
            <h6 className="mb-1">Keyword & Strategy</h6>
            <h6 className="mb-0">Details</h6>
          </div>

          {/* Second Section - Hidden on Smaller Screens */}
          <div className="text-center d-none d-md-block" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center mx-auto mb-2"
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
              </svg>
            </div>
            <h6 className="mb-1">Filtered websites</h6>
            <h6 className="mb-0">and articles</h6>
          </div>

          {/* Third Section - Hidden on Smaller Screens */}
          <div className="text-center d-none d-md-block" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center mx-auto mb-2"
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
              </svg>
            </div>
            <h6 className="mb-1">Order Confirmation</h6>
            <h6 className="mb-0">& details</h6>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProgressSteps;
