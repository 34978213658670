// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure tooltips appear above all other elements */
.MuiTooltip-popper {
  z-index: 9999 !important;
}

/* Make sure tooltip content is visible over inputs */
.MuiTooltip-tooltip {
  z-index: 9999 !important;
  position: relative !important;
  font-size: 14px !important;
  padding: 10px 12px !important;
  max-width: 300px !important;
  line-height: 1.4 !important;
} `, "",{"version":3,"sources":["webpack://./src/styles/tooltips.css"],"names":[],"mappings":"AAAA,oDAAoD;AACpD;EACE,wBAAwB;AAC1B;;AAEA,qDAAqD;AACrD;EACE,wBAAwB;EACxB,6BAA6B;EAC7B,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,2BAA2B;AAC7B","sourcesContent":["/* Ensure tooltips appear above all other elements */\n.MuiTooltip-popper {\n  z-index: 9999 !important;\n}\n\n/* Make sure tooltip content is visible over inputs */\n.MuiTooltip-tooltip {\n  z-index: 9999 !important;\n  position: relative !important;\n  font-size: 14px !important;\n  padding: 10px 12px !important;\n  max-width: 300px !important;\n  line-height: 1.4 !important;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
