// SubscriptionPanel.jsx
import React, { useEffect, useState } from 'react';
import { BASE_API_URL } from '../src/config/constants';
import PageLayout from './components/PageLayout';
import Modal from './Modal';
import './SubscriptionPanel.css';
import SubscriptionPanelModal from './SubscriptionPanelModal';
import ProductRemovalModal from './SubscriptiopnPanel/ProductRemovealModal';
import SubscriptionPanelPageLayout from './SubscriptiopnPanel/SubscriptionPanelPageLayout';

const SubscriptionPanel = () => {
    const token = localStorage.getItem('token');
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('all');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
    const [productToRemove, setProductToRemove] = useState(null);
    const [isProductRemovalModalOpen, setIsProductRemovalModalOpen] = useState(false);

    useEffect(() => {
        fetchSubscriptions();
    }, [token]);

    const fetchSubscriptions = async () => {
        setLoading(true);
        try {
            const [activeResponse, canceledResponse] = await Promise.all([
                fetch(`${BASE_API_URL}api/Order/get-subscriptions`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                }),
                fetch(`${BASE_API_URL}api/Order/get-canceled-subscriptions`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                })
            ]);
    
            if (!activeResponse.ok) {
                throw new Error('Failed to fetch active subscriptions');
            }
    
            if (!canceledResponse.ok && canceledResponse.status !== 204) {
                throw new Error('Failed to fetch canceled subscriptions');
            }
    
            // Parse the JSON for active subscriptions
            const activeSubscriptions = await activeResponse.json();
    
            // Handle 204 response for canceled subscriptions
            let canceledSubscriptions = [];
            if (canceledResponse.status !== 204) {
                canceledSubscriptions = await canceledResponse.json();
            }
    
            // Combine and set subscriptions
            setSubscriptions([
                ...activeSubscriptions.map(sub => ({ ...sub, status: 'active', expanded: false })),
                ...canceledSubscriptions.map(sub => ({ ...sub, status: 'canceled', expanded: false }))
            ]);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
            setError('Failed to fetch subscriptions. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleExpand = (subscriptionId) => {
        setSubscriptions(prevSubscriptions =>
            prevSubscriptions.map(subscription =>
                subscription.id === subscriptionId
                    ? { ...subscription, expanded: !subscription.expanded }
                    : { ...subscription, expanded: false }
            )
        );
    };

    const handleOpenModal = (subscriptionId) => {
        setSubscriptionToCancel(subscriptionId);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSubscriptionToCancel(null);
    };

    const handleConfirmCancel = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}api/Order/remove-subscription/${subscriptionToCancel}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Failed to remove subscription');
            }

            await fetchSubscriptions();
            handleCloseModal();
        } catch (error) {
            console.error('Error removing subscription:', error);
            setError('Failed to remove subscription. Please try again later.');
        }
    };

    const handleRemoveProduct = async (subscriptionId, productId, productName) => {
        setProductToRemove({
            subscriptionId,
            productId,
            name: productName
        });
        setIsProductRemovalModalOpen(true);
    };

    const handleConfirmProductRemoval = async () => {
        try {
            const response = await fetch(
                `${BASE_API_URL}api/Order/remove-product/${productToRemove.subscriptionId}/${productToRemove.productId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to remove product');
            }

            await fetchSubscriptions();
            setIsProductRemovalModalOpen(false);
            setProductToRemove(null);
        } catch (error) {
            console.error('Error removing product:', error);
            setError('Failed to remove product. Please try again later.');
        }
    };

    const handleCloseProductRemovalModal = () => {
        setIsProductRemovalModalOpen(false);
        setProductToRemove(null);
    };

    const filteredSubscriptions = subscriptions.filter(sub => filter === 'all' || sub.status === filter);

    return (
        <SubscriptionPanelPageLayout>
            <div>
                <h1>Manage Subscriptions</h1>
                <div className="filter-buttons">
                    <button onClick={() => setFilter('all')} className={filter === 'all' ? 'active' : ''}>All</button>
                    <button onClick={() => setFilter('active')} className={filter === 'active' ? 'active' : ''}>Active</button>
                    <button onClick={() => setFilter('canceled')} className={filter === 'canceled' ? 'active' : ''}>Canceled</button>
                </div>
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : (
                    <div className="subscription-grid">
                        {filteredSubscriptions.map((subscription) => (
                            <div
                                key={subscription.id}
                                className={`subscription-item ${subscription.expanded ? 'expanded' : ''} ${subscription.status}`}
                                onClick={() => handleExpand(subscription.id)}
                            >
                                <div className="subscription-header">
                                    <span className="price">${subscription.amount} USD</span>
                                    <span className={`status ${subscription.status}`}>{subscription.status}</span>
                                </div>
                                <div className="subscription-dates">
                                    {new Date(subscription.startDate).toLocaleDateString()} - {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                                </div>
                                {subscription.status === 'active' && (
                                    <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenModal(subscription.id);
                                    }} className="cancel-button">
                                        Cancel Subscription
                                    </button>
                                )}
                                {subscription.expanded && (
                                    <ul className="product-list">
                                        {subscription.products.map((product) => (
                                            <li key={product.id}>
                                                <span>{product.name} on {product.platform}</span>
                                                {subscription.status === 'active' && (
                                                    <button 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveProduct(
                                                                subscription.id, 
                                                                product.productId,
                                                                product.name
                                                            );
                                                        }} 
                                                        className="remove-button"
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <SubscriptionPanelModal 
                    isOpen={isModalOpen} 
                    onClose={handleCloseModal} 
                    onConfirm={handleConfirmCancel} 
                    message="Are you sure you want to cancel this order?" 
                />
                <ProductRemovalModal
                    isOpen={isProductRemovalModalOpen}
                    onClose={handleCloseProductRemovalModal}
                    onConfirm={handleConfirmProductRemoval}
                    productName={productToRemove?.name}
                />
            </div>
        </SubscriptionPanelPageLayout>
    );
};

export default SubscriptionPanel;