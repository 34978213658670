import React, { useState } from 'react';
import PublishersList from './PublishersList';
import AdvertisersList from './AdvertisersList';
import NotificationsManager from './NotificationsManager';
import VouchersManager from './VouchersManager';

export default function AdminDashboard() {
    const [currentView, setCurrentView] = useState('publishers');
    const [selectedPublisher, setSelectedPublisher] = useState(null);
    const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);

    const tabs = [
        {
            id: 'publishers',
            label: 'Publishers',
            icon: 'globe'
        },
        {
            id: 'advertisers',
            label: 'Advertisers',
            icon: 'ad'
        },
        {
            id: 'notifications',
            label: 'Notifications',
            icon: 'bell'
        },
        {
            id: 'vouchers',
            label: 'Vouchers',
            icon: 'ticket-alt'
        }
    ];

    const renderHeaderActions = () => {
        return (
            <div className="d-flex gap-2">
                <button className="btn btn-outline-primary btn-sm">
                    <i className="fas fa-upload me-1"></i>
                    Import
                </button>
                <button className="btn btn-primary btn-sm">
                    <i className="fas fa-plus me-1"></i>
                    Add
                </button>
            </div>
        );
    };

    const handleShowPublisherDetails = (publisher) => {
        setSelectedPublisher(publisher);
    };

    const handleShowAdvertiserDetails = (advertiser) => {
        setSelectedAdvertiser(advertiser);
    };

    return (
        <div className="bg-light min-h-screen">
            {/* Navbar */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Link Marketplace Admin</a>
                    <div className="d-flex align-items-center">
                        <span className="text-white me-3">Admin User</span>
                        <i className="fas fa-user-circle text-white fs-4"></i>
                    </div>
                </div>
            </nav>

            <div className="container-fluid">
                {/* Bootstrap Nav Tabs */}
                <ul className="nav nav-tabs mt-4" role="tablist">
                    {tabs.map(tab => (
                        <li className="nav-item" key={tab.id}>
                            <button 
                                className={`nav-link ${currentView === tab.id ? 'active' : ''}`}
                                onClick={() => {
                                    setCurrentView(tab.id);
                                    setSelectedPublisher(null);
                                    setSelectedAdvertiser(null);
                                }}
                            >
                                <i className={`fas fa-${tab.icon} me-2`}></i>
                                {tab.label}
                            </button>
                        </li>
                    ))}
                </ul>

                {/* Header with Dynamic Title and Actions */}
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <h2>{currentView.charAt(0).toUpperCase() + currentView.slice(1)} Management</h2>
                    {renderHeaderActions()}
                </div>

                {/* Main Content */}
                {currentView === 'publishers' && (
                    <PublishersList 
                        showHeader={false}
                        onShowDetails={handleShowPublisherDetails}
                    />
                )}
                {currentView === 'advertisers' && (
                    <AdvertisersList 
                        showHeader={false}
                        onShowDetails={handleShowAdvertiserDetails}
                    />
                )}
                {currentView === 'notifications' && <NotificationsManager />}
                {currentView === 'vouchers' && <VouchersManager />}
            </div>
        </div>
    );
}