import React, { useState } from 'react';
import { 
    Bell, AlertCircle, CheckCircle, Clock, 
    AlertTriangle, Info, Filter, CheckSquare, 
    Archive, Trash2, MessageCircle, Send
} from 'lucide-react';
import SendNotification from '../modals/SendNotification';

const StatCard = ({ title, value, subtext, trend, icon: Icon }) => (
    <div className="card">
        <div className="card-body p-4">
            <div className="d-flex justify-content-between align-items-start">
                <div>
                    <h6 className="text-muted">{title}</h6>
                    <h3 className="mb-0">{value}</h3>
                    <small className={`text-${trend?.type || 'muted'}`}>{subtext}</small>
                </div>
                {Icon && <Icon className="text-muted" size={20} />}
            </div>
        </div>
    </div>
);

export default function NotificationsManager() {
    const [showSendModal, setShowSendModal] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [filters, setFilters] = useState({
        type: 'all',
        status: 'all',
        priority: 'all'
    });

    // Mock stats data
    const stats = {
        unread: {
            count: 24,
            change: -5
        },
        urgent: {
            count: 3,
            change: 1
        },
        average: {
            time: '2.5h',
            change: -15
        },
        resolution: {
            rate: '94%',
            change: 5
        }
    };

    const handleSendNotification = (notificationData) => {
        console.log('Sending notification:', notificationData);
        // API call would go here
        setShowSendModal(false);
    };

    const getNotificationIcon = (type) => {
        const icons = {
            alert: <AlertCircle size={20} className="text-danger" />,
            success: <CheckCircle size={20} className="text-success" />,
            warning: <AlertTriangle size={20} className="text-warning" />,
            info: <Info size={20} className="text-info" />,
            message: <MessageCircle size={20} className="text-primary" />
        };
        return icons[type] || icons.info;
    };

    return (
        <div className="container-fluid p-0">
            {/* Filters */}
            <div className="row g-3 mb-4">
                <div className="col-md-3">
                    <select 
                        className="form-select"
                        value={filters.type}
                        onChange={(e) => setFilters({...filters, type: e.target.value})}
                    >
                        <option value="all">All Types</option>
                        <option value="alert">Alerts</option>
                        <option value="warning">Warnings</option>
                        <option value="info">Information</option>
                        <option value="success">Success</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <select 
                        className="form-select"
                        value={filters.status}
                        onChange={(e) => setFilters({...filters, status: e.target.value})}
                    >
                        <option value="all">All Status</option>
                        <option value="unread">Unread</option>
                        <option value="read">Read</option>
                        <option value="archived">Archived</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <select 
                        className="form-select"
                        value={filters.priority}
                        onChange={(e) => setFilters({...filters, priority: e.target.value})}
                    >
                        <option value="all">All Priority</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <button className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                        <Filter className="w-4 h-4 me-2" />
                        Apply Filters
                    </button>
                </div>
            </div>

            {/* Statistics Cards */}
            <div className="row g-4 mb-4">
                <div className="col-md-3">
                    <StatCard
                        title="Unread Notifications"
                        value={stats.unread.count}
                        subtext={`${stats.unread.change}% from yesterday`}
                        trend={{ type: 'success' }}
                        icon={Bell}
                    />
                </div>
                <div className="col-md-3">
                    <StatCard
                        title="Urgent Notifications"
                        value={stats.urgent.count}
                        subtext={`+${stats.urgent.change} new urgent`}
                        trend={{ type: 'warning' }}
                        icon={AlertCircle}
                    />
                </div>
                <div className="col-md-3">
                    <StatCard
                        title="Average Response Time"
                        value={stats.average.time}
                        subtext={`${stats.average.change}% faster than last week`}
                        trend={{ type: 'success' }}
                        icon={Clock}
                    />
                </div>
                <div className="col-md-3">
                    <StatCard
                        title="Resolution Rate"
                        value={stats.resolution.rate}
                        subtext={`+${stats.resolution.change}% this month`}
                        trend={{ type: 'success' }}
                        icon={CheckCircle}
                    />
                </div>
            </div>

            {/* Action Buttons */}
            <div className="d-flex gap-2 mb-4">
                <button className="btn btn-primary d-flex align-items-center">
                    <CheckSquare className="w-4 h-4 me-2" />
                    Mark All Read
                </button>
                <button className="btn btn-primary d-flex align-items-center">
                    <Archive className="w-4 h-4 me-2" />
                    Archive Selected
                </button>
                <div className="ms-auto d-flex gap-2">
                    <button 
                        className="btn btn-primary d-flex align-items-center"
                        onClick={() => setShowSendModal(true)}
                    >
                        <Send className="w-4 h-4 me-2" />
                        Send Notification
                    </button>
                    <button className="btn btn-primary d-flex align-items-center">
                        <Trash2 className="w-4 h-4 me-2" />
                        Clear All
                    </button>
                </div>
            </div>

            {/* Notifications List */}
            <div className="notifications-list">
                {/* Urgent Notifications */}
                <div className="notification-group mb-4">
                    <h6 className="text-danger mb-3 d-flex align-items-center">
                        <AlertCircle className="w-4 h-4 me-2" />
                        Urgent Notifications
                    </h6>
                    
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="d-flex align-items-start">
                                {getNotificationIcon('alert')}
                                <div className="ms-3 flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-1">System Alert: High Server Load</h6>
                                        <small className="text-muted">2 hours ago</small>
                                    </div>
                                    <p className="mb-2">Server load has exceeded 90% capacity. Immediate action required.</p>
                                    <div className="d-flex align-items-center">
                                        <span className="badge bg-danger me-2">Urgent</span>
                                        <span className="badge bg-secondary">System</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Today's Notifications */}
                <div className="notification-group mb-4">
                    <h6 className="text-muted mb-3">Today</h6>
                    
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="d-flex align-items-start">
                                {getNotificationIcon('success')}
                                <div className="ms-3 flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-1">New Publisher Approved</h6>
                                        <small className="text-muted">4 hours ago</small>
                                    </div>
                                    <p className="mb-2">TechBlog Media has been approved and is ready to accept campaigns.</p>
                                    <div className="d-flex align-items-center">
                                        <span className="badge bg-success me-2">Success</span>
                                        <span className="badge bg-secondary">Publisher</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="d-flex align-items-start">
                                {getNotificationIcon('info')}
                                <div className="ms-3 flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-1">Campaign Performance Update</h6>
                                        <small className="text-muted">6 hours ago</small>
                                    </div>
                                    <p className="mb-2">Q1 Product Launch campaign has reached 50% of its budget with exceptional performance.</p>
                                    <div className="d-flex align-items-center">
                                        <span className="badge bg-info me-2">Info</span>
                                        <span className="badge bg-secondary">Campaign</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Earlier Notifications */}
                <div className="notification-group">
                    <h6 className="text-muted mb-3">Earlier</h6>
                    
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="d-flex align-items-start">
                                {getNotificationIcon('warning')}
                                <div className="ms-3 flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-1">Budget Warning</h6>
                                        <small className="text-muted">Yesterday</small>
                                    </div>
                                    <p className="mb-2">Campaign "Summer Sale" is approaching its budget limit. Consider reviewing allocation.</p>
                                    <div className="d-flex align-items-center">
                                        <span className="badge bg-warning me-2">Warning</span>
                                        <span className="badge bg-secondary">Budget</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Load More */}
            <div className="text-center mt-4">
                <button className="btn btn-outline-primary">
                    Load More Notifications
                </button>
            </div>

            {/* Send Notification Modal */}
            {showSendModal && (
                < SendNotification
                    show={showSendModal}
                    onClose={() => setShowSendModal(false)}
                    onSend={handleSendNotification}
                />
            )}
        </div>
    );
}