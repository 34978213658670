// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  width: 100%;
  padding: 1px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  text-align: center; /* Adjust text alignment if needed */
}`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB,EAAE,oCAAoC;AAC1D","sourcesContent":[".footer {\n  width: 100%;\n  padding: 1px;\n  background-color: #f8f9fa;\n  border-top: 1px solid #e9ecef;\n  text-align: center; /* Adjust text alignment if needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
