import React from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../Footer';
import './SubscriptionPanelPageLayout.css'; // Update the CSS import path

const SubscriptionPanelPageLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="subscription-panel-layout">
        <Sidebar />
        <div className="subscription-panel-content"> {/* Apply custom class here */}
          {children} {/* Content of each page will be passed here */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubscriptionPanelPageLayout;
