import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_API_URL } from './config/constants';
import toast from 'react-hot-toast';
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap
import MySpinner from './components/MySpinner';
import { Spinner } from 'reactstrap';
import './styles/basicdata.css'; // Updated import path
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const BasicData = ({ data, updateData, errors, setErrors }) => {
  // Custom tooltip style with larger text
  const tooltipStyle = {
    tooltip: {
      fontSize: '14px',
      padding: '10px 12px',
      maxWidth: '300px',
      lineHeight: '1.4',
      zIndex: 9999
    }
  };

  // Function to determine tooltip placement based on screen width
  const getTooltipPlacement = () => {
    return window.innerWidth <= 768 ? "bottom" : "right";
  };

  const initialFormData = {
    ...data,
    occurrences: data.occurrences || 3,
    domainStatus: { domain: '', isCalculated: false }, // Initialize domainStatus in formData
  };
  const [formData, setFormData] = useState(initialFormData);
  const [recommendedPrice, setRecommendedPrice] = useState(null);
  const [basePrice, setBasePrice] = useState(null);
  const [useCustomPrice, setUseCustomPrice] = useState(false); // New state for custom price checkbox
  const [isTrialMode, setIsTrialMode] = useState(false);
  const [cancelToken, setCancelToken] = useState(null); // Cancel token state
  const [countries, setCountries] = useState([]);
  const [themes, setThemes] = useState([]);

  const [loadingAuthority, setLoadingAuthority] = useState(false);
  
  const [error, setError] = useState(''); // State to manage error messages
  
  console.log(formData);
  useEffect(() => {
    if (JSON.stringify(formData) !== JSON.stringify(data)) {
      updateData({...formData, isTrialMode});
    }
  }, [formData, isTrialMode]);

    // Fetch countries from the API
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}api/StaticValues/countries`);
        setCountries(response.data); // Set the countries from API response
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    // Fetch themes from the API
    const fetchThemes = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}api/theme`);
        if (!response.ok) {
          throw new Error('Failed to fetch themes');
        }
        const data = await response.json();
        setThemes(data);
      } catch (err) {
        console.error('Error fetching themes:', err);
      }
    };

    // Call fetchCountries and fetchThemes on component mount
    useEffect(() => {
      fetchCountries();
      fetchThemes();
    }, []);

  const fetchDomainAuthority = async (domain) => {
    try {
      setLoadingAuthority(true);
      // Cancel any ongoing request
      if (cancelToken) {
        cancelToken.cancel();
      }

      // Create a new cancel token
      const newCancelToken = axios.CancelToken.source();
      setCancelToken(newCancelToken);

      const response = await axios.get(`${BASE_API_URL}api/seo/domain-authority`, {
        params: { target: domain },
        cancelToken: newCancelToken.token,
      });

      if (!response.data || !response.data.data || !response.data.data[0] || response.data.data[0].authority_score === undefined) {
        setError('Could not calculate domain authority. Please try again.');
        setFormData((prevFormData) => ({
          ...prevFormData,
          domainAuthority: null,
          domainStatus: { domain, isCalculated: false },
        }));
        return;
      }

      const authorityScore = response.data.data[0].authority_score;
      console.log(authorityScore);

      setFormData((prevFormData) => ({
        ...prevFormData,
        domainAuthority: authorityScore,
        domainStatus: { domain, isCalculated: true },
      }));
      setError(''); // Only clear error on successful calculation

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous request canceled');
      } else {
        console.error('Error fetching domain authority:', error);
        setError('Could not calculate domain authority. Please try again.');
        setFormData((prevFormData) => ({
          ...prevFormData,
          domainAuthority: null,
          domainStatus: { domain, isCalculated: false },
        }));
      }
    }
    finally {
      setLoadingAuthority(false);
    }
  };


  useEffect(() => {
    if (formData.domainAuthority) {
      fetchRecommendedPrice(formData.domainAuthority, formData.linkType, formData.exclusivity);
      if (formData.domain) {
        fetchDomainAuthority(formData.domain);
      }
    }
  }, [formData.domainAuthority]);

  const fetchRecommendedPrice = async (domainAuthority, linkType, exclusivity, price) => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/priceCalculator/calculate`, {
        params: {
          domainAuthority,
          exclusivity: false,
          linkType,
          price
        }
      });
      console.log(response);
      setFormData((prevFormData) => ({
        ...prevFormData,
        price: response.data.publisherRevenue,
        basePrice: response.data.basePrice
      }));
      setRecommendedPrice(response.data.publisherRevenue);
      setBasePrice(response.data.basePrice);
    } catch (error) {
      console.error("Error fetching recommended price:", error);
    }
  };

  const handleTrialModeChange = (e) => {
    const { checked } = e.target;
    setIsTrialMode(checked);
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customPrice: 0.001,
        useCustomPrice: false
      }));
      setUseCustomPrice(false);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customPrice: '',
        useCustomPrice: false // Reset this to false when trial mode is unchecked
      }));
      setUseCustomPrice(false); // Ensure the custom price checkbox is unchecked
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'customPrice') {
      if (value !== '' && (isNaN(value) || (Number(value) < 0) || /^0\d/.test(value))) {
        return;
      }
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      basicData: {
        ...prevErrors.basicData,
        [name]: '',
        domainCalculated: ''
      }
    }));

    setFormData((prevFormData) => {
      const newState = { ...prevFormData, [name]: type === 'checkbox' ? checked : value };

      if (name === 'domain') {
        newState.domainAuthority = null;
        newState.domainStatus = { domain: value, isCalculated: false };
      }

      return newState;
    });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setUseCustomPrice(checked);
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        useCustomPrice : true,
        customPrice: '', // Reset custom price
        isTrialMode: false // Disable free mode
      }));
      setIsTrialMode(false); // Ensure the trial mode state is updated
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        useCustomPrice: false,
        customPrice: '', // Clear custom price if checkbox is unchecked
      }));
    }
  };

  const handleDomainVerify = async () => {
    if (!formData.domain) {
      setError("Please enter a domain first");
      return;
    }

    // Clean the domain input - remove protocol and www if present
    let cleanDomain = formData.domain.trim().toLowerCase();
    cleanDomain = cleanDomain.replace(/^(https?:\/\/)?(www\.)?/, '');

    // More permissive domain format validation that allows protocols, www, and trailing slash
    const domainRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+\/?$/;
    if (!domainRegex.test(formData.domain)) {
      setError("Invalid domain format. Please use the right format.");
      return;
    }

    try {
      setLoadingAuthority(true);
      const token = localStorage.getItem('token');

      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const response = await fetch(
        `${BASE_API_URL}api/userdomains/validate?domainName=${encodeURIComponent(cleanDomain)}`,
        requestOptions
      );

      const responseText = await response.text();
      console.log('Response:', responseText);

      if (!response.ok) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          domainAuthority: 0,
          domainStatus: { domain: '', isCalculated: false },
        }));
        setRecommendedPrice(0);
        setError(responseText);
        return;
      }

      // If validation successful, fetch domain authority
      await fetchDomainAuthority(cleanDomain);

    } catch (error) {
      console.error("Domain verification error:", error);
      setError("An error occurred while validating the domain. Please try again.");
    } finally {
      setLoadingAuthority(false);
    }
  };

  const handleCustomPriceBlur = () => {
    if (formData.customPrice && formData.customPrice < 3) {
      setError('Custom price must be greater than or equal to 3');
      setFormData((prevFormData) => ({
        ...prevFormData,
        customPrice: 3, // Set to minimum valid value if invalid
      }));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleDomainVerify();
    }
  };

  return (
    <div style={{ width: '130%' }}>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <p htmlFor="domain">Domain *</p>
          <Tooltip 
            title="Enter your website's domain name (e.g., example.com)" 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <div className="domain-input-container" style={{ 
          display: 'flex', 
          gap: '0px', 
          alignItems: 'flex-start', 
          maxWidth: '90%'
        }}>
          <div style={{ 
            flex: '1 1 auto', 
            position: 'relative', 
            minWidth: '70%'
          }}>
            <input
              style={{ width: '100%', marginTop: '5px' }}
              placeholder='Enter your Domain (e.g., example.com)'
              type="text"
              id="domain"
              name="domain"
              value={formData.domain || ''}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              required
              className={`form-control ${errors && errors.domain ? 'is-invalid' : ''}`}
            />
            <small className="text-muted" style={{ display: 'block', marginTop: '5px' }}>
              Accepted formats: example.com, subdomain.example.com, www.example.com, https://example.com, https://www.example.com
            </small>
            {error && <div className="text-danger mt-2">{error}</div>}
          </div>
          <button 
            className="btn btn-primary btn-lg verify-domain-btn" 
            onClick={handleDomainVerify}
            style={{ 
              marginTop: '5px',
              marginLeft: '-15px',
              whiteSpace: 'nowrap',
              minWidth: '160px',
              height: '45px'
            }}
            disabled={!formData.domain || loadingAuthority}
          >
            {loadingAuthority ? (
              <div className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
                <div className="spinner-border spinner-border-sm" role="status" style={{ width: '20px', height: '20px' }}>
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              'VERIFY DOMAIN'
            )}
          </button>
        </div>
        {errors && errors.domain && <div className="text-danger">{errors.domain}</div>}
        {errors && errors.domainCalculated && <div className="text-danger">{errors.domainCalculated}</div>}
      </div>
      <div className="form-group tooltip-container" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <div className="d-flex align-items-center justify-content-between" style={{ maxWidth: '90%', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p htmlFor="domainAuthority" style={{ margin: 0, lineHeight: '1.5', marginRight: '8px' }}>Domain authority:</p>
            <Tooltip 
              title="SEO authority of your website, measured by SEMrush" 
              arrow 
              placement={getTooltipPlacement()}
              componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
            >
              <InfoIcon fontSize="small" color="primary" style={{ marginRight: '8px' }} />
            </Tooltip>
            <label
              id="domainAuthority"
              name="domainAuthority"
              style={{
                padding: '0 8px',
                borderRadius: '4px',
                backgroundColor: '#f9f9f9',
                display: 'inline-flex',
                alignItems: 'center',
                height: '1.5em'
              }}
            >
              {loadingAuthority ? (
                <div className="text-center">
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{ width: '1.5rem', height: '1.5rem' }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                formData.domainAuthority ? (
                  <span>{formData.domainAuthority}</span>
                ) : (
                  'N/A'
                )
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="form-group">
        <p style={{ fontSize: '22px', fontWeight: 'bold', color: 'green' }}>
          Monthly revenue per link: {recommendedPrice !== null ? `$${recommendedPrice}` : 'Waiting for domain...'}
        </p>
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <p htmlFor="language">Language of publication *</p>
          <Tooltip 
            title="Select the primary language of your website's content" 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <select 
          style={{ maxWidth: '90%', marginTop: '5px' }}
          className={`form-control ${errors && errors.language ? 'is-invalid' : ''}`} 
          id="language" 
          name="language" 
          value={formData.language || ''} 
          onChange={handleChange} 
          required
        >
          <option value="">Choose your language</option>
          <option value="Bulgarian">Bulgarian</option>
          <option value="Croatian">Croatian</option>
          <option value="Czech">Czech</option>
          <option value="Danish">Danish</option>
          <option value="Dutch">Dutch</option>
          <option value="English">English</option>
          <option value="Estonian">Estonian</option>
          <option value="Finnish">Finnish</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Greek">Greek</option>
          <option value="Hungarian">Hungarian</option>
          <option value="Irish">Irish</option>
          <option value="Italian">Italian</option>
          <option value="Latvian">Latvian</option>
          <option value="Lithuanian">Lithuanian</option>
          <option value="Maltese">Maltese</option>
          <option value="Polish">Polish</option>
          <option value="Portuguese">Portuguese</option>
          <option value="Romanian">Romanian</option>
          <option value="Slovak">Slovak</option>
          <option value="Slovenian">Slovenian</option>
          <option value="Spanish">Spanish</option>
        </select>
        {errors && errors.language && <div className="text-danger">{errors.language}</div>}
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <p htmlFor="country">Country *</p>
          <Tooltip 
            title="Choose the country where your website is based or primarily targeted" 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <select
          style={{ maxWidth: '90%', marginTop: '5px' }}
          id="country"
          name="country"
          value={formData.country || ''}
          onChange={handleChange}
          required
          className={`form-control ${errors && errors.country ? 'is-invalid' : ''}`}
        >
          <option value="">Country of Origin</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        {errors && errors.country && <div className="text-danger">{errors.country}</div>}
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <p htmlFor="website-type">Type of website *</p>
          <Tooltip 
            title="Select the category that best describes your website" 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <select 
          style={{ maxWidth: '90%', marginTop: '5px' }}
          id="website-type" 
          name="websiteType" 
          value={formData.websiteType || ''} 
          onChange={handleChange} 
          required 
          className={`form-control ${errors && errors.websiteType ? 'is-invalid' : ''}`}
        >
          <option value="">Choose your website type</option>
          {themes.map((category, idx) => (
            <optgroup label={category.label} key={idx}>
              {category.options.map((option) => (
                <option 
                  key={option.name} 
                  value={option.name}
                >
                  {option.name} ({option.count})
                </option>
              ))}
            </optgroup>
          ))}
        </select>
        {errors && errors.websiteType && <div className="text-danger">{errors.websiteType}</div>}
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2" style={{ maxWidth: '90%' }}>
          <p htmlFor="occurrences">Occurrences *</p>
          <Tooltip 
            title="Maximum number of links Adverlink can insert per article" 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <input
          style={{ maxWidth: '90%', marginTop: '5px' }}
          type="number"
          id="occurrences"
          name="occurrences"
          value={formData.occurrences || 3}
          onChange={handleChange}
          min="1"
          required
        />
      </div>
      <div className="form-group" style={{ display: 'none' }}>
        <p htmlFor="price">Price *</p>
        <input
          type="number"
          id="price"
          name="price"
          value={formData.price || recommendedPrice || ''}
          onChange={handleChange}
          required
        />
      </div>
      <div style={{ color: '#8f8f8f' }} className="checkbox-group form-group tooltip-container">
        <div className="d-flex align-items-center" style={{ maxWidth: '90%' }}>
          <input style={{ width: '20px', marginRight: '8px' }} type="checkbox" id="indexing" name="indexing" checked={formData.indexing || false} onChange={handleChange} />
          <div className="d-flex align-items-center">
            <span>YES, I hereby grant explicit permission for the AdverLink Bot to crawl this website.</span>
            <Tooltip 
              title="Adverlink only crawls public content and articles where links are placed" 
              arrow 
              placement={getTooltipPlacement()}
              componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
            >
              <InfoIcon fontSize="small" color="primary" style={{ marginLeft: '8px' }} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="form-group tooltip-container">
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '90%' }}>
          <h3 htmlFor="useCustomPrice" style={{ margin: 0, marginRight: '8px' }}>Set your own price</h3>
          <Tooltip 
            title="Check this if you want to set a custom price instead of using Adverlink's calculated price based on your domain authority. Be aware this may affect advertiser interest." 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" style={{ marginRight: '8px' }} />
          </Tooltip>
          <input
            type="checkbox"
            id="useCustomPrice"
            name="useCustomPrice"
            checked={useCustomPrice}
            onChange={handleCheckboxChange}
            style={{ width: '20px', height: '20px' }}
          />
        </div>
      </div>

      {useCustomPrice && (
        <div className="form-group tooltip-container">
          <p htmlFor="customPrice">Custom price $</p>
          <input
            style={{ maxWidth: '90%' }}
            type="text"
            id="customPrice"
            name="customPrice"
            value={formData.customPrice || ''}
            onChange={handleChange}
            onBlur={handleCustomPriceBlur}
            required
            disabled={isTrialMode}
            pattern="\d*"
            title="Please enter a valid number"
            className={`form-control ${errors && errors.customPrice ? 'is-invalid' : ''}`}
          />
          {errors && errors.customPrice && <div className="text-danger">{errors.customPrice}</div>}
        </div>
      )}

      <div className="form-group tooltip-container">
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '90%' }}>
          <label htmlFor="trialMode" style={{ margin: 0, marginRight: '8px' }}>Free ($0)</label>
          <Tooltip 
            title="Select this if you want to test Adverlink for free. Contact support@adverlink.net if you wish to return to the monetization model." 
            arrow 
            placement={getTooltipPlacement()}
            componentsProps={{ tooltip: { sx: tooltipStyle.tooltip } }}
          >
            <InfoIcon fontSize="small" color="primary" style={{ marginRight: '8px' }} />
          </Tooltip>
          <input
            type="checkbox"
            id="trialMode"
            name="trialMode"
            checked={isTrialMode}
            onChange={handleTrialModeChange}
            style={{ width: '20px', height: '20px' }}
          />
        </div>
      </div>
    </div>
  );
}

export default BasicData;

