import React from 'react';
import { RefreshCw, Globe } from 'lucide-react';

const PortalLinkCell = ({ item, index, handleGetOtherArticle, handleChangeDomain, isChangeDomainDisabled, isGetOtherArticleDisabled }) => {
  return (
    <td className="relative p-2">
      <div className="flex flex-col gap-2">
        <span>{item.portal}</span>
        
        <div className="flex items-center text-sm text-gray-500">
          <span className="mr-4">
            {item.targetUrl.length > 40 ? `${item.targetUrl.slice(0, 40)}...` : item.targetUrl}
          </span>
          
          <a 
            href={item.targetUrl} 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-flex"
          >
            <svg
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg" 
              className="text-gray-600"
            >
              <g id="Interface / External_Link">
                <path 
                  id="Vector" 
                  d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </a>
        </div>

        <div className="flex items-center justify-between gap-1">
          <button
            disabled={isGetOtherArticleDisabled}
            onClick={() => handleGetOtherArticle(item.id)}
            className={`
              flex items-center justify-center gap-2 
              w-[127px] h-[34px]
              transition-all duration-200
              border border-gray-200
              text-xs font-medium
              ${isGetOtherArticleDisabled 
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                : 'bg-white text-gray-700 hover:bg-gray-50 active:scale-95 shadow-sm hover:shadow'
              }
            `}
            style={{
              borderRadius: '8px',
              marginBottom: '4px',
              marginRight: '4px'
            }}
          >
            <RefreshCw 
              size={20} 
              className={isGetOtherArticleDisabled ? 'text-gray-400' : 'text-blue-500'}
            />
            <span> GET OTHER ARTICLE</span>
          </button>

          <button
            disabled={isChangeDomainDisabled}
            onClick={() => handleChangeDomain(item.id)}
            className={`
              flex items-center justify-center gap-2 
              w-[127px] h-[34px]
              transition-all duration-200
              border border-gray-200
              text-xs font-medium
              ${isChangeDomainDisabled 
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                : 'bg-white text-gray-700 hover:bg-gray-50 active:scale-95 shadow-sm hover:shadow'
              }
            `}
            style={{
              borderRadius: '8px'
            }}
          >
            <Globe 
              size={20} 
              className={isChangeDomainDisabled ? 'text-gray-400' : 'text-green-500'}
              style={{ marginTop: '-3px' }} // Adjust this value to move the icon upward
            />
            <span> CHANGE DOMAIN</span>
          </button>
        </div>
      </div>
    </td>
  );
};

export default PortalLinkCell;
