// StripePayment.js
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { jwtDecode } from 'jwt-decode';
import { BASE_API_URL } from '../../config/constants';
 

const stripePromise = loadStripe('pk_live_51N3JFnL4S5a1WxAjiPBiwb4ShFOftVd2QS4TPJH9dSqSCLylagT0XFSYbPKCppctTwT4WeXwf2wed4Ya05mVYchL00sGrXx9vV');

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

// Generic payment configuration interface
// paymentType: 'subscription' | 'topup' | 'one-time'
// endpoints: {
//   create: string;
//   update?: string;
// }
const StripePayment = ({ 
  amount, 
  paymentType,
  endpoints,
  products = [],
  CheckoutFormComponent,
  onSuccess,
  onError
}) => {
  const [clientSecret, setClientSecret] = useState('');
  const [stripeProducts, setStripeProducts] = useState([]);
  const [paymentId, setPaymentId] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const createPayment = async () => {
      try {
        const basePayload = {
          email: getUserName(token),
          amount: Number(amount).toFixed(2),
        };

        // Add products if they exist
        const payload = products.length > 0 
          ? { 
              ...basePayload, 
              products: products.map(product => ({
                ...product,
                monthlyValue: Number(product.monthlyValue).toFixed(2)
              }))
            } 
          : basePayload;

        const response = await fetch(`${BASE_API_URL}${endpoints.create}`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Payment creation failed');
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
        setStripeProducts(data.products);
        
        // Handle different payment IDs based on type
        if (paymentType === 'subscription') {
          setPaymentId(data.subscriptionId);
        } else {
          setPaymentId(data.paymentIntentId);
        }

        onSuccess?.('payment_created', data);
      } catch (error) {
        console.error('Error creating payment:', error);
        setError(error.message);
        onError?.(error);
      }
    };

    createPayment();
  }, [amount, endpoints.create, paymentType, products, token]);

  const handlePaymentMethodId = async (id) => {
    setPaymentMethodId(id);

    if (!endpoints.update) return;

    try {
      const response = await fetch(`${BASE_API_URL}${endpoints.update}`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          email: getUserName(token),
          paymentMethodId: id
        }),
      });

      if (!response.ok) {
        throw new Error('Payment method update failed');
      }

      onSuccess?.('payment_method_updated', { paymentMethodId: id });
    } catch (error) {
      console.error('Error updating payment method:', error);
      setError(error.message);
      onError?.(error);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutFormComponent 
        clientSecret={clientSecret} 
        paymentId={paymentId}
        paymentType={paymentType}
        amount={amount}
        stripeProducts={stripeProducts}
        products={products}
        onPaymentMethodId={handlePaymentMethodId}
        onSuccess={onSuccess}
        onError={onError}
      />
    </Elements>
  );
};

export default StripePayment;