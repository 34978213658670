import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_API_URL } from '../config/constants';
import toast from 'react-hot-toast';

const fetchRecommendedPrice = async (entry, domainAuthority, linkType, exclusivity) => {
    try {
        const response = await axios.get(`${BASE_API_URL}api/priceCalculator/calculate-base-price-advertiser`, {
            params: {
                exclusivity,
                price: entry.price,
                linkType
            }
        });
        return {
            basePrice: response.data.advertiserBasePrice,
            totalPrice: response.data.advertiserBasePrice
        };
    } catch (error) {
        console.error("Error fetching recommended price:", error);
        return null;
    }
};

const AddToCartAfterRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleAddToCart = async () => {
      try {
        console.log('Starting handleAddToCart...');
        
        // Get query parameters
        const params = new URLSearchParams(location.search);
        const domainId = params.get('domainId');
        const articleUrl = params.get('articleUrl');
        const keyword = params.get('keyword');
        const advertiserUrl = params.get('url'); // Get advertiser URL
        const title = params.get('title'); // Get title
        const typeOfLink = params.get('typeOfLink') || 'NormalFollow';
        const exclusivity = params.get('exclusivity') === 'true';
        const redirect = params.get('redirect') || '/advertiser';

        console.log('URL Parameters:', {
          domainId,
          articleUrl,
          keyword,
          advertiserUrl,
          title,
          typeOfLink,
          exclusivity,
          redirect
        });

        // Get token from localStorage
        const token = localStorage.getItem('token');
        console.log('Token:', token);

        // Create cart item with basic data first
        const cartItem = {
          id: domainId,
          keyword: keyword || 'Default Keyword',
          url: advertiserUrl || '', // Use advertiser URL from parameters
          targetUrl: articleUrl || '', // This will be the article URL
          title: title || keyword || 'Link', // Use title from parameters
          exclusivity: exclusivity, // Use exclusivity from parameters
          typeOfLink: typeOfLink, // Use typeOfLink from parameters
          publisherWebsite: '', // This will be set from API domain
          monthlyValue: 0,
          domainAuthority: 0
        };

        // Try to get domain data from API regardless of authentication
        try {
          console.log('Fetching domain details from API');
          const headers = token ? {
            'Authorization': `Bearer ${token}`
          } : {};
          
          const response = await axios.get(`${BASE_API_URL}api/userdomains/get-for-new-search/${domainId}`, { headers });
          const domainData = response.data;
          console.log('Domain API Response:', domainData);

          // Calculate price based on link type and exclusivity
          const priceData = await fetchRecommendedPrice(
            { price: domainData.price },
            domainData.domainAuthority,
            typeOfLink,
            exclusivity
          );

          if (!priceData) {
            throw new Error('Failed to calculate price');
          }

          // Update cart item with API data
          cartItem.keyword = keyword || domainData.keyword;
          cartItem.url = advertiserUrl || ''; // Keep advertiser URL from parameters
          cartItem.targetUrl = articleUrl; // Keep the article URL as target URL
          cartItem.title = title || domainData.description || keyword || 'Link'; // Use title from parameters if available
          cartItem.publisherWebsite = domainData.domain; // Set the domain from API as publisher website
          cartItem.monthlyValue = priceData.basePrice; // Use calculated base price
          cartItem.domainAuthority = domainData.domainAuthority || 0;
          cartItem.exclusivity = exclusivity; // Keep exclusivity from parameters
          cartItem.typeOfLink = typeOfLink; // Keep typeOfLink from parameters
        } catch (apiError) {
          console.log('API Error, using basic cart item:', apiError);
          // If API call fails, we'll use the basic cart item data
          // Extract domain from articleUrl as fallback
          try {
            const url = new URL(articleUrl);
            cartItem.publisherWebsite = url.hostname;
          } catch (urlError) {
            console.log('Error extracting domain from articleUrl:', urlError);
          }
        }

        console.log('Final cart item:', cartItem);

        // Add to localStorage
        try {
          // Get existing cart or initialize empty array
          let existingCart = [];
          try {
            const savedCart = localStorage.getItem('cartItems');
            if (savedCart) {
              existingCart = JSON.parse(savedCart);
            }
          } catch (parseError) {
            console.log('Error parsing existing cart, starting fresh:', parseError);
          }

          console.log('Existing cart:', existingCart);
          
          // Check if item already exists
          const existingIndex = existingCart.findIndex(item => item.id === cartItem.id);
          if (existingIndex !== -1) {
            // Update existing item
            existingCart[existingIndex] = cartItem;
          } else {
            // Add new item
            existingCart.push(cartItem);
          }
          
          // Save back to localStorage
          localStorage.setItem('cartItems', JSON.stringify(existingCart));
          console.log('Updated localStorage cart:', existingCart);
        } catch (localStorageError) {
          console.error('Error updating localStorage:', localStorageError);
          // Try to save just the new item if there's an error
          localStorage.setItem('cartItems', JSON.stringify([cartItem]));
        }

        // Show success message
        toast.success('Item added to cart successfully!');

        // Handle navigation
        if (!token) {
          // If user is not registered, redirect to register with same parameters
          const registerUrl = `/register?${new URLSearchParams(params).toString()}`;
          console.log('No token found, redirecting to:', registerUrl);
          navigate(registerUrl);
        } else {
          // If user is registered, redirect based on the redirect parameter
          console.log('Redirecting to:', redirect);
          if (redirect === '/cart') {
            // If redirecting to cart, force a complete page refresh
            window.location.href = '/cart';
          } else {
            navigate(redirect);
          }
        }

      } catch (error) {
        console.error('Error in handleAddToCart:', error);
        toast.error('Failed to add item to cart. Please try again.');
        
        // If there's an error, redirect to register page with parameters
        const registerUrl = `/register?${new URLSearchParams(location.search).toString()}`;
        navigate(registerUrl);
      }
    };

    handleAddToCart();
  }, [navigate, location]);

  return (
    <div className="text-center p-5">
      <h2>Adding item to cart...</h2>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default AddToCartAfterRegister; 