import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Advertiserx.css';
import Navbar from './components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faSquareCheck, 
    faChartLine, 
    faGlobe, 
    faSearch, 
    faLink,
    faShieldAlt,
    faRocket,
    faHandshake,
    faArrowRight,
    faCheckCircle,
    faPlay
} from '@fortawesome/free-solid-svg-icons'
import Sidebar from './components/Sidebar';
import { jwtDecode } from 'jwt-decode';
import PageLayout from './components/PageLayout';

const AdvertiserLanding = () => {
  const navigate = useNavigate();

  const navigateToStep1 = () => {
    navigate('/Advertiser-step1');
  };

  const getIsActiveFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.isActive;
    } catch (error) {
      return 0;
    }
  }

  const token = localStorage.getItem('token');
  const isActive = getIsActiveFromToken(token);

  // Generate random positions for SEO links
  const generateSeoLinks = () => {
    const links = [];
    for (let i = 0; i < 20; i++) {
      links.push({
        id: i,
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 5}s`
      });
    }
    return links;
  };

  return (
    <PageLayout>
      <div className="advertiser-landing">
        {/* Hero Section */}
        <div className="hero-section">
          <div className="seo-animation">
            {generateSeoLinks().map(link => (
              <div 
                key={link.id} 
                className="seo-link"
                style={{
                  top: link.top,
                  left: link.left,
                  animationDelay: link.animationDelay
                }}
              />
            ))}
          </div>
          <div className="hero-content">
            <h1>Premium Link Building Platform</h1>
            <p className="hero-subtitle">Access high-quality publishers and place your links in relevant, existing articles</p>
            <button className="cta-button" onClick={navigateToStep1}>
              Browse Publishers
              <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
            </button>
          </div>
        </div>

        {/* Status Alert */}
        {isActive === "False" && (
          <div className="alert alert-danger" role="alert">
            Your account will be approved shortly. Thank you!
          </div>
        )}

        {/* Features Section */}
        <div className="features-section">
          <h2>Platform Benefits</h2>
          <div className="features-grid">
            <div className="feature-card">
              <FontAwesomeIcon icon={faChartLine} className="feature-icon" />
              <h3>High Authority Sites</h3>
              <p>Access publishers with strong domain authority and established presence</p>
            </div>
            <div className="feature-card">
              <FontAwesomeIcon icon={faGlobe} className="feature-icon" />
              <h3>Diverse Publishers</h3>
              <p>Choose from a wide range of publishers across different niches and regions</p>
            </div>
            <div className="feature-card">
              <FontAwesomeIcon icon={faSearch} className="feature-icon" />
              <h3>Relevant Content</h3>
              <p>Place your links in contextually relevant articles for maximum impact</p>
            </div>
            <div className="feature-card">
              <FontAwesomeIcon icon={faLink} className="feature-icon" />
              <h3>Quality Backlinks</h3>
              <p>Get dofollow links from trusted and established websites</p>
            </div>
          </div>
        </div>

        {/* Process Section */}
        <div className="process-section">
          <h2>Quick Start Guide</h2>
          <div className="process-grid">
            <div className="process-item">
              <div className="process-number">1</div>
              <div className="process-content">
                <FontAwesomeIcon icon={faSearch} className="process-icon" />
                <h3>Browse Publishers</h3>
                <p>Explore our curated list of premium publishers</p>
              </div>
              <div className="process-arrow">
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
            <div className="process-item">
              <div className="process-number">2</div>
              <div className="process-content">
                <FontAwesomeIcon icon={faHandshake} className="process-icon" />
                <h3>Select Articles</h3>
                <p>Choose relevant articles for your link placement</p>
              </div>
              <div className="process-arrow">
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
            <div className="process-item">
              <div className="process-number">3</div>
              <div className="process-content">
                <FontAwesomeIcon icon={faCheckCircle} className="process-icon" />
                <h3>Get Links</h3>
                <p>Your links will be placed in high-quality content</p>
              </div>
            </div>
          </div>
        </div>

        {/* Video Section */}
        <div className="video-section">
          <h2>Watch How It Works</h2>
          <div className="video-container">
            <iframe 
              src="https://www.youtube.com/embed/JgDx-ce-cFA" 
              title="How to use AdverLink"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AdvertiserLanding;
