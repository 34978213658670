import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import './styles/dropdowns.css';
import './styles/tooltips.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'react-hot-toast';
import Chatbot from './ChatBot/Chatbot';
const root = ReactDOM.createRoot(document.getElementById('root'));


// Creează un container separat pentru chatbot
const chatbotContainer = document.createElement('div');
chatbotContainer.id = 'chatbot-container';
document.body.appendChild(chatbotContainer);

root.render(
  //<React.StrictMode>
  <GoogleOAuthProvider clientId="1048634306191-k05g3uogid9d6vpp2ltl4pquon2r79cq.apps.googleusercontent.com">
              {/* <div id="g_id_onload"
     data-client_id="1048634306191-k05g3uogid9d6vpp2ltl4pquon2r79cq.apps.googleusercontent.com"
     data-context="signin"
     data-ux_mode="popup"
     data-login_uri="https://localhost:7152/api/authentication/google-signin"
     data-auto_prompt="false">
</div> */}
    <App />
    <Toaster />
  </GoogleOAuthProvider>
  //</React.StrictMode>
);

const chatbotRoot = ReactDOM.createRoot(document.getElementById('chatbot-container'));
chatbotRoot.render(<Chatbot />);

// root.render(
//   <GoogleOAuthProvider clientId="1048634306191-k05g3uogid9d6vpp2ltl4pquon2r79cq.apps.googleusercontent.com">
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </GoogleOAuthProvider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
