import React, { useEffect, useState } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Alert
} from 'reactstrap';
import axios from 'axios';
import { BASE_API_URL } from './config/constants';
import './DomainKeyModal.css'; // Import custom CSS
import MySpinner from './components/MySpinner';
const DomainKeyModal = ({ isOpen, toggle, domain, setWasGenerated }) => {
    const [key, setKey] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Fetch existing key or create if not exists when the modal opens
    useEffect(() => {
        if (isOpen) {
            handleKey();
        }
    }, [isOpen, domain]);

    const handleKey = async () => {
        setLoading(true);
        setError('');
        setSuccess('');
        const token = localStorage.getItem('token');

        try {
            // Try to fetch the existing secret key
            const response = await axios.post(`${BASE_API_URL}api/userdomains/get-secret-key`, 
                { Domain: domain },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Check if the response is successful
            if (response.status === 200) {
                setKey(response.data);
            } else {
                setError('Failed to fetch existing key.');
            }
        } catch (error) {
            // If the key does not exist (404), handle the creation
            if (error.response && error.response.status === 404) {
                try {
                    // Create a new key
                    const createResponse = await axios.post(`${BASE_API_URL}api/userdomains/secret-key`, 
                        { domain: domain },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    // Check if the creation was successful
                    if (createResponse.status === 200) {
                        setWasGenerated(true);
                        setKey(createResponse.data);
                        setSuccess('New key created and saved successfully!');
                    } else {
                        setError('Failed to create key.');
                    }
                } catch (creationError) {
                    console.log(creationError);
                    setError('Failed to create key. Please try again.');
                }
            } else {
                setError('Failed to fetch existing key.');
            }
        }
        setLoading(false);
    };

    const updateKey = async () => {
        setLoading(true);
        setError('');
        setSuccess('');
        const token = localStorage.getItem('token');

        try {
            // Make a PUT request to update the existing secret key
            const response = await axios.put(`${BASE_API_URL}api/userdomains/update-secret-key`, 
                { domain: domain },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Check if the update was successful
            if (response.status === 200) {
                setKey(response.data);
                setSuccess('Key updated successfully!');
            } else {
                setError('Failed to update key.');
            }
        } catch (error) {
            setError('Failed to update key. Please try again.');
        }
        setLoading(false);
    };

    const copyToClipboard = () => {
        if (key) {
            navigator.clipboard.writeText(key)
                .then(() => setSuccess('Key copied to clipboard!'))
                .catch((err) => setError('Failed to copy key. Please try again.'));
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Domain Key</ModalHeader>
            <ModalBody>
                {error && <Alert color="danger">{error}</Alert>}
                {success && <Alert color="success">{success}</Alert>}
                <Form>
                    <FormGroup>
                        <Label for="domain">Domain</Label>
                        <Input type="text" name="domain" id="domain" value={domain} readOnly />
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="key">Secret Key</Label>
                        <div className="input-container">
                            <Input
                                type="text"
                                name="key"
                                id="key"
                                value={key}
                                readOnly
                                onClick={copyToClipboard} // Attach the click handler
                                className="cursor-pointer" // Add cursor-pointer class
                            />
                            <small
                                className="text-muted mt-1 cursor-pointer"
                                onClick={copyToClipboard} // Also attach the click handler to the small text
                            >
                                Click to copy
                            </small>
                        </div>
                    </FormGroup>
                    {loading ? ( // Show loading spinner if loading
                        <MySpinner/>
                    ) : (
                        key && (
                            <Button color="primary" onClick={updateKey} disabled={loading}>Update Key</Button>
                        )
                    )}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default DomainKeyModal;
