import React, { useState, useEffect } from "react";
import FileInput from "./components/FileInput";

const Popularity = ({ data, updateData, errors, setErrors }) => {
  const [formData, setFormData] = useState(data);

  const handleFileSelect = (files) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: files[0],
    }));
  };

  console.log("Popularity data", data);
  // Update parent component data whenever formData changes
  useEffect(() => {
    updateData(formData);
  }, [formData]);

  return (
    <>
      <form style={{ width: "150%" }}>
        <div className="form-group">
          <label htmlFor="">Number of unique users per month *</label>
          <input
  placeholder="Number of visitors"
  type="text" // Keep as text to allow commas and digits
  value={formData.uniqueUsers || ""}
  onChange={(e) => {
    const { value } = e.target;

    // Replace all non-numeric characters except commas
    // Allow commas for thousands separators
    const numericValueWithCommas = value.replace(/[^0-9,]/g, ''); // Keep commas but remove other non-numeric characters

    // Update the form data with the formatted value
    setFormData((prevFormData) => ({
      ...prevFormData,
      uniqueUsers: numericValueWithCommas,
    }));

    // Clear the error for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      popularityData: {
        ...prevErrors.popularityData,
        uniqueUsers: '', // Clear the error message for "uniqueUsers"
      },
    }));
  }}
  onBlur={() => {
    // On blur, strip commas and convert the value to an integer
    if (formData.uniqueUsers) {
      const cleanedValue = formData.uniqueUsers.replace(/,/g, ''); // Remove commas
      const numericValue = parseInt(cleanedValue, 10); // Convert to integer
      if (!isNaN(numericValue)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          uniqueUsers: numericValue.toString(), // Store as string if needed
        }));
      }
    }
  }}
  onKeyDown={(e) => {
    // Prevent entering invalid characters like e, +, or - (for scientific notation)
    if (e.key === 'e' || e.key === '-' || e.key === '+') {
      e.preventDefault();
    }

    // Prevent typing commas if there are any in the wrong places (i.e., at the beginning, end, or after a comma)
    const value = e.target.value;
    const lastChar = value[value.length - 1];
    if (lastChar === ',' && (value[value.length - 2] === ',' || value[0] === ',')) {
      e.preventDefault();
    }
  }}
  name="uniqueUsers"
  required
  className={`form-control ${errors && errors.popularityData?.uniqueUsers ? 'is-invalid' : ''}`}
/>


{errors && errors.uniqueUsers && <div className="text-danger">{errors.uniqueUsers}</div>}

        </div>
        <div className="form-group">
          <label htmlFor="file">
            Attach files documenting the number of visitors (PDF)
          </label>
          <FileInput onFileSelect={handleFileSelect} data={data} />
          <small style={{ color: "#8f8f8f" }}>
            Add statistics generated directly from Google Analytics for the last
            three months documenting the number of unique users.
          </small>
        </div>
      </form>
    </>
  );
};

export default Popularity;
