import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_API_URL } from './config/constants';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner';
import { Alert } from 'react-bootstrap';
import { Upload, Download, Trash2, Eye, FileDown, Link, FileText, Globe, Building2 } from 'lucide-react';
import './PublisherReports.css';
import { jwtDecode } from 'jwt-decode';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PublisherReports = () => {
  const [publisherReports, setPublisherReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploading, setUploading] = useState([]);
  const [files, setFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [downloadingInvoices, setDownloadingInvoices] = useState([]);
  const [deletingInvoices, setDeletingInvoices] = useState([]);
  const [selectedMonthReport, setSelectedMonthReport] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const token = localStorage.getItem('token');
  const userId = getUserIdFromToken(token);

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            
            const [reportsResponse, invoicesResponse] = await Promise.all([
                axios.get(`${BASE_API_URL}api/linksreports/get-publisher-reports`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/get-user-invoices`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
            ]);

            const reports = reportsResponse.data || [];
            const invoices = invoicesResponse.data || [];

            // Combine reports with invoices based on date range
            const combinedReports = reports.map(report => {
                const matchingInvoices = invoices.filter(invoice => 
                    new Date(invoice.dateStart) >= new Date(report.dateStart) &&
                    new Date(invoice.dateEnd) <= new Date(report.dateEnd)
                );
                return { ...report, invoices: matchingInvoices };
            });

            setPublisherReports(combinedReports);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (userId) {
        fetchData();
    }
}, [userId]);

  const handleDownloadInvoice = async (invoiceId, fileName) => {
    try {
      setDownloadingInvoices(prev => [...prev, invoiceId]);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/download/${invoiceId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        responseType: 'blob'
      });

      // Check if the response is actually a blob and not an error message
      const contentType = response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
        // If it's JSON, it's probably an error message
        const reader = new FileReader();
        reader.onload = () => {
          const errorMessage = JSON.parse(reader.result);
        };
        reader.readAsText(response.data);
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error("Error downloading invoice:", error);
    } finally {
      setDownloadingInvoices(prev => prev.filter(id => id !== invoiceId));
    }
  };

  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];
    setFiles(newFiles);
  };

  const handleFileUpload = async (e, index) => {
    e.preventDefault();

    if (!files[index]) {
        return;
    }

    // Format dates to start of day in local timezone
    const dateStart = new Date(publisherReports[index].dateStart);
    const dateEnd = new Date(publisherReports[index].dateEnd);
    
    // Set time to noon (12:00) to avoid timezone issues
    dateStart.setHours(12, 0, 0, 0);
    dateEnd.setHours(12, 0, 0, 0);

    const formData = new FormData();
    formData.append('invoiceFile', files[index]);
    formData.append('userId', userId.toString());
    formData.append('dateStart', dateStart.toISOString());
    formData.append('dateEnd', dateEnd.toISOString());

    const currentUploading = [...uploading];
    currentUploading[index] = true;
    setUploading(currentUploading);

    try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${BASE_API_URL}api/PublisherInvoiceUpload/upload`, 
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.data.success) {
            // Clear the file input
            setFiles(prevFiles => {
                const newFiles = [...prevFiles];
                newFiles[index] = null;
                return newFiles;
            });

            // Fetch updated data
            const [reportsResponse, invoicesResponse] = await Promise.all([
                axios.get(`${BASE_API_URL}api/linksreports/get-publisher-reports`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/get-user-invoices`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
            ]);

            const reports = reportsResponse.data || [];
            const invoices = invoicesResponse.data || [];

            // Combine reports with invoices
            const updatedReports = reports.map(report => {
                const matchingInvoices = invoices.filter(invoice => {
                    const invStart = new Date(invoice.dateStart);
                    const invEnd = new Date(invoice.dateEnd);
                    const repStart = new Date(report.dateStart);
                    const repEnd = new Date(report.dateEnd);

                    return invStart.toDateString() === repStart.toDateString() && 
                           invEnd.toDateString() === repEnd.toDateString();
                });
                return { ...report, invoices: matchingInvoices };
            });

            setPublisherReports(updatedReports);
        }
    } catch (error) {
        console.error('Error uploading invoice:', error);
    } finally {
        const newUploading = [...uploading];
        newUploading[index] = false;
        setUploading(newUploading);
    }
  };

  const handleDeleteInvoice = async (invoiceId) => {
    try {
      setDeletingInvoices(prev => [...prev, invoiceId]);
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${BASE_API_URL}api/PublisherInvoiceUpload/delete/${invoiceId}`, 
        {
          headers: { 
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        const updatedReports = publisherReports.map(report => ({
          ...report,
          invoices: report.invoices.filter(invoice => invoice.id !== invoiceId)
        }));
        
        setPublisherReports(updatedReports);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    } finally {
      setDeletingInvoices(prev => prev.filter(id => id !== invoiceId));
    }
  };

  const confirmAndDeleteInvoice = (invoice) => {
    setInvoiceToDelete({
      id: invoice.id,
      fileName: invoice.fileName
    });
    setShowDeleteModal(true);
  };

  const DeleteConfirmationModal = ({ onClose, onConfirm }) => {
    return (
      <div className="delete-modal-overlay">
        <div className="delete-modal">
          <h2 className="delete-modal__title">Delete Invoice</h2>
          <p className="delete-modal__message">
            Are you sure you want to delete invoice: <br/>
            <span className="delete-modal__filename">{invoiceToDelete.fileName}</span>?
            <br/>This action cannot be undone.
          </p>
          <div className="delete-modal__buttons">
            <button className="delete-modal__cancel-btn" onClick={onClose}>
              Cancel
            </button>
            <button className="delete-modal__delete-btn" onClick={onConfirm}>
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleViewReport = (monthData) => {
    setSelectedMonthReport(monthData);
    setShowReportModal(true);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal = () => {
    setShowReportModal(false);
    document.body.classList.remove('modal-open');
  };

  const handleDownloadReport = (monthData) => {
    // Create new PDF document
    const doc = new jsPDF();
    
    // Set font to Times-Roman for better diacritic support
    doc.setFont("times", "roman");
    
    // Set title
    doc.setFontSize(20);
    doc.text('Publisher Report', doc.internal.pageSize.width/2, 20, { align: 'center' });
    
    // Add month and year
    doc.setFontSize(16);
    const monthYear = new Date(monthData.dateStart).toLocaleDateString('en-US', { 
      month: 'long', 
      year: 'numeric' 
    });
    doc.text(monthYear, doc.internal.pageSize.width/2, 30, { align: 'center' });

    // Add company details in top right
    doc.setFontSize(8);
    doc.setFont("times", "roman");
    const rightMargin = doc.internal.pageSize.width - 20; // 20 is right margin
    doc.text(companyDetails.companyName, rightMargin, 15, { align: 'right' });
    doc.text(`VAT: ${companyDetails.vatNumber}`, rightMargin, 20, { align: 'right' });
    doc.text(`Reg. No: ${companyDetails.registrationNumber}`, rightMargin, 25, { align: 'right' });
    doc.text(`${companyDetails.street}`, rightMargin, 30, { align: 'right' });
    doc.text(`${companyDetails.city}, ${companyDetails.state}`, rightMargin, 35, { align: 'right' });
    doc.text(companyDetails.country, rightMargin, 40, { align: 'right' });
    
    // Calculate total keywords
    const totalKeywords = monthData.orders?.reduce((total, order) => 
      total + (order.wordToLinks?.length || 0), 0
    ) || 0;

    // Add summary section
    doc.setFontSize(12);
    doc.text('Summary:', 20, 45);
    doc.setFontSize(10);
    doc.text(`Period: ${new Date(monthData.dateStart).toLocaleDateString()} - ${new Date(monthData.dateEnd).toLocaleDateString()}`, 20, 55);
    doc.text(`Total Revenue (without VAT): $${Number(monthData.amount).toFixed(2)}`, 20, 62);
    doc.text(`Total Orders: ${monthData.orders?.length || 0}`, 20, 69);
    doc.text(`Total Keywords: ${totalKeywords}`, 20, 76);

    // Prepare table data
    const tableData = [];
    monthData.orders?.forEach(order => {
      order.wordToLinks?.forEach(wtl => {
        // Create revenue string with warning symbol for partial months
        const revenueString = `$${Number(wtl.monthlyValue).toFixed(2)}${
          wtl.daysActive < wtl.daysInMonth ? ' ⚠️' : ''
        }`;

        tableData.push([
          {
            content: wtl.keyword,
            styles: { fontStyle: 'bold' }
          },
          wtl.publisherWebsite,
          wtl.typeOfLink,
          wtl.domainAuthority,
          {
            content: revenueString,
            styles: {}
          },
          wtl.isActive ? 'Active' : 'Inactive',
        ]);
        
        // Add details as separate rows with smaller font and indentation
        tableData.push([
          {
            content: `URL: ${wtl.url}`,
            colSpan: 6,
            styles: { fontSize: 8, textColor: [0, 0, 238] }
          }
        ]);
        tableData.push([
          {
            content: `Title: ${wtl.title}`,
            colSpan: 6,
            styles: { fontSize: 8 }
          }
        ]);
        tableData.push([
          {
            content: `Target URL: ${wtl.targetUrl}`,
            colSpan: 6,
            styles: { fontSize: 8, textColor: [0, 0, 238] }
          }
        ]);
        
        // Add partial month info if applicable
        if (wtl.daysActive < wtl.daysInMonth) {
          tableData.push([
            {
              content: `⚠️ Partial month: Active for ${wtl.daysActive} out of ${wtl.daysInMonth} days`,
              colSpan: 6,
              styles: { fontSize: 8, textColor: [255, 153, 0] }  // Orange color for warning
            }
          ]);
        }
        
        // Add cancellation date if exists
        if (wtl.cancellationDate) {
          tableData.push([
            {
              content: `Cancelled: ${new Date(wtl.cancellationDate).toLocaleDateString()}`,
              colSpan: 6,
              styles: { fontSize: 8, textColor: [220, 53, 69] }
            }
          ]);
        }
        
        // Add empty row for spacing
        tableData.push([{ content: '', colSpan: 6, styles: { minCellHeight: 2 } }]);
      });
    });

    // Add table
    doc.autoTable({
      startY: 87,
      head: [[
        'Keyword',
        'Website',
        'Type',
        'DA',
        'Publisher revenue (without VAT)',
        'Status'
      ]],
      body: tableData,
      theme: 'grid',
      headStyles: {
        fillColor: [51, 51, 51],
        textColor: 255,
        fontSize: 10,
        fontStyle: 'bold',
        halign: 'center'
      },
      styles: {
        fontSize: 9,
        cellPadding: 3,
        font: 'times'
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 25 },
        3: { cellWidth: 20 },
        4: { cellWidth: 30 },
        5: { cellWidth: 25 }
      },
      margin: { top: 80 },
      didDrawPage: function (data) {
        // Add header to each page
        doc.setFontSize(8);
        doc.setFont("times", "roman");
        doc.text('Publisher Report - ' + monthYear, data.settings.margin.left, 10);

        // Add company details to each page
        doc.text(companyDetails.companyName, rightMargin, 15, { align: 'right' });
        doc.text(`VAT: ${companyDetails.vatNumber}`, rightMargin, 20, { align: 'right' });
        doc.text(`Reg. No: ${companyDetails.registrationNumber}`, rightMargin, 25, { align: 'right' });
        doc.text(`${companyDetails.street}`, rightMargin, 30, { align: 'right' });
        doc.text(`${companyDetails.city}, ${companyDetails.state}`, rightMargin, 35, { align: 'right' });
        doc.text(companyDetails.country, rightMargin, 40, { align: 'right' });
      }
    });

    // Save the PDF
    const fileName = `publisher-report-${monthYear.toLowerCase().replace(' ', '-')}.pdf`;
    doc.save(fileName);
  };

  const ReportModal = ({ report, onClose }) => {
    // Calculate total keywords
    const totalKeywords = report.orders?.reduce((total, order) => 
      total + (order.wordToLinks?.length || 0), 0
    ) || 0;

    return (
      <div className="report-modal-overlay" onClick={onClose}>
        <div className="report-modal" onClick={e => e.stopPropagation()}>
          <div className="report-modal__header">
            <h2>Report for {new Date(report.dateStart).toLocaleDateString('en-US', { 
              month: 'long', 
              year: 'numeric' 
            })}</h2>
            <button className="report-modal__close-btn" onClick={onClose}>×</button>
          </div>
          <div className="report-modal__content">
            <div className="report-modal__summary">
              <h3>Summary</h3>
              <p>Period: {new Date(report.dateStart).toLocaleDateString()} - {new Date(report.dateEnd).toLocaleDateString()}</p>
              <p>Total Revenue (without VAT): ${Number(report.amount).toFixed(2)}</p>
              <p>Total Orders: {report.orders?.length || 0}</p>
              <p>Total Keywords: {totalKeywords}</p>
            </div>

            <div className="report-modal__orders">
              <h3>Active Links</h3>
              <table className="report-modal__table">
                <thead>
                  <tr>
                    <th>Keyword</th>
                    <th>Website</th>
                    <th>Type</th>
                    <th>DA</th>
                    <th>Publisher revenue</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {report.orders?.map(order => 
                    order.wordToLinks?.map(wtl => (
                      <tr key={wtl.wtlId}>
                        <td>
                          <div className="keyword-cell">
                            <span className="keyword">{wtl.keyword}</span>
                            <div className="keyword-cell__details">
                              <a href={wtl.url} target="_blank" rel="noopener noreferrer" className="detail-link">
                                URL: {wtl.url}
                              </a>
                              <span className="detail-text">Title: {wtl.title}</span>
                              <a href={wtl.targetUrl} target="_blank" rel="noopener noreferrer" className="detail-link">
                                Target URL: {wtl.targetUrl}
                              </a>
                              {wtl.cancellationDate && (
                                <span className="detail-text" style={{ color: '#dc3545' }}>
                                  Cancelled: {new Date(wtl.cancellationDate).toLocaleDateString()}
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>{wtl.publisherWebsite}</td>
                        <td>
                          <span className={`link-type ${wtl.typeOfLink.toLowerCase()}`}>
                            {wtl.typeOfLink}
                          </span>
                        </td>
                        <td>{wtl.domainAuthority}</td>
                        <td>
                          <div className="revenue-cell">
                            ${Number(wtl.monthlyValue).toFixed(2)}
                            {wtl.daysActive < wtl.daysInMonth && (
                              <span 
                                className="warning-icon" 
                                title={`Partial month revenue: Active for ${wtl.daysActive} out of ${wtl.daysInMonth} days`}
                              >
                                ⚠️
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <span className={`status-badge ${wtl.isActive ? 'active' : 'inactive'}`}>
                            {wtl.isActive ? 'Active' : 'Inactive'}
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const companyDetails = {
    companyName: "SC AYSA SEO SRL",
    registrationNumber: "J23/373/2024",
    vatNumber: "RO44523532",
    street: "Crisul Repede 77",
    city: "Bragadiru",
    state: "Ilfov",
    country: "Romania",
    bankName: "",
    iban: "",
    swift: ""
  };

  const CompanyDetailsModal = ({ onClose }) => {
    return (
      <div className="report-modal-overlay" onClick={onClose}>
        <div className="report-modal company-modal" onClick={e => e.stopPropagation()}>
          <div className="report-modal__header">
            <h2>Adverlink Company Details</h2>
            <button className="report-modal__close-btn" onClick={onClose}>×</button>
          </div>
          <div className="report-modal__content">
            <div className="company-details">
              <div className="company-details__section">
                <h3>Company Information</h3>
                <p><strong>Company Name:</strong> {companyDetails.companyName}</p>
                <p><strong>Registration Number:</strong> {companyDetails.registrationNumber}</p>
                <p><strong>VAT Number:</strong> {companyDetails.vatNumber}</p>
              </div>
              <div className="company-details__section">
                <h3>Address</h3>
                <p><strong>Street:</strong> {companyDetails.street}</p>
                <p><strong>City:</strong> {companyDetails.city}</p>
                <p><strong>State:</strong> {companyDetails.state}</p>
                <p><strong>Country:</strong> {companyDetails.country}</p>
              </div>
              {(companyDetails.bankName || companyDetails.iban || companyDetails.swift) && (
                <div className="company-details__section">
                  <h3>Bank Details</h3>
                  {companyDetails.bankName && <p><strong>Bank Name:</strong> {companyDetails.bankName}</p>}
                  {companyDetails.iban && <p><strong>IBAN:</strong> {companyDetails.iban}</p>}
                  {companyDetails.swift && <p><strong>SWIFT:</strong> {companyDetails.swift}</p>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="publisher-reports">
        <div className="publisher-reports__header-container">
          <h5 className="publisher-reports__header">Publisher Reports</h5>
          <button 
            className="view-company-btn"
            onClick={() => setShowCompanyModal(true)}
          >
            <Building2 size={16} />
            View Adverlink Company Details
          </button>
        </div>
        
        {isLoading ? (
          <MySpinner />
        ) : (
          <div className="monthly-reports">
            <div className="monthly-reports__grid">
              {publisherReports.map((monthData, index) => (
                <div key={index} className="monthly-reports__card">
                  <div className="monthly-reports__main-info">
                    <h3 className="monthly-reports__month">
                      {new Date(monthData.dateStart).toLocaleDateString('en-US', { 
                        month: 'long', 
                        year: 'numeric' 
                      })}
                    </h3>
                    <div className="monthly-reports__stats">
                      <p>Total Orders: {monthData.orders?.length || 0}</p>
                      <p>Total Keywords: {monthData.orders?.reduce((total, order) => 
                        total + (order.wordToLinks?.length || 0), 0) || 0}</p>
                      <p>Monthly Revenue (without VAT): ${monthData.amount?.toFixed(2) || '0.00'}</p>
                      {monthData.canceledWordToLinks?.length > 0 && (
                        <p>Canceled Links: {monthData.canceledWordToLinks.length}</p>
                      )}
                    </div>
                  </div>

                  <div className="monthly-reports__invoice-section">
                    <h4 className="monthly-reports__invoices-title">Invoices</h4>
                    
                    <form onSubmit={(e) => handleFileUpload(e, index)} className="monthly-reports__upload-form">
                      <div className="monthly-reports__file-input-wrapper">
                        <input
                          type="file"
                          id={`fileInput-${index}`}
                          className="monthly-reports__file-input"
                          accept="application/pdf"
                          onChange={(e) => handleFileChange(e, index)}
                        />
                        <label htmlFor={`fileInput-${index}`} className="monthly-reports__file-label">
                          <Upload size={16} />
                          <span>
                            {files[index] ? files[index].name : 'Upload Invoice'}
                          </span>
                        </label>
                      </div>
                      <button 
                        type="submit" 
                        className="monthly-reports__submit-btn" 
                        disabled={uploading[index] || !files[index]}
                      >
                        {uploading[index] ? <MySpinner small /> : 'Submit'}
                      </button>
                    </form>

                    {monthData.invoices && monthData.invoices.length > 0 && (
                      <div className="monthly-reports__invoices-list">
                        {monthData.invoices.map(invoice => (
                          <div key={invoice.id} className="monthly-reports__invoice-item">
                            <span className="invoice-name">{invoice.fileName}</span>
                            <div className="invoice-actions">
                              <button 
                                className="invoice-action-btn download"
                                onClick={() => handleDownloadInvoice(invoice.id, invoice.fileName)}
                                disabled={downloadingInvoices.includes(invoice.id)}
                                title="Download Invoice"
                              >
                                {downloadingInvoices.includes(invoice.id) ? (
                                  <MySpinner small />
                                ) : (
                                  <Download size={16} />
                                )}
                              </button>
                              <button 
                                className="invoice-action-btn delete"
                                onClick={() => confirmAndDeleteInvoice(invoice)}
                                disabled={deletingInvoices.includes(invoice.id)}
                                title="Delete Invoice"
                              >
                                {deletingInvoices.includes(invoice.id) ? (
                                  <MySpinner small />
                                ) : (
                                  <Trash2 size={16} />
                                )}
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="monthly-reports__actions">
                    <button
                      className="monthly-reports__view-btn"
                      onClick={() => handleViewReport(monthData)}
                    >
                      <Eye size={16} />
                      View Report
                    </button>
                    <button
                      className="monthly-reports__download-btn"
                      onClick={() => handleDownloadReport(monthData)}
                    >
                      <FileDown size={16} />
                      Download Report
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {showDeleteModal && (
          <DeleteConfirmationModal
            onClose={() => setShowDeleteModal(false)}
            onConfirm={() => {
              handleDeleteInvoice(invoiceToDelete.id);
              setShowDeleteModal(false);
            }}
          />
        )}
        {showReportModal && selectedMonthReport && (
          <ReportModal
            report={selectedMonthReport}
            onClose={handleCloseModal}
          />
        )}
        {showCompanyModal && (
          <CompanyDetailsModal onClose={() => setShowCompanyModal(false)} />
        )}
      </div>
    </PageLayout>
  );
};

export default PublisherReports;