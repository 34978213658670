import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const DeleteConfirmationModal = ({ isOpen, toggle, domain, onDelete }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Delete Domain</ModalHeader>
            <ModalBody>
                <p>Are you sure you want to delete the following domain?</p>
                <p><strong>Domain:</strong> {domain?.domain}</p>
                <p><strong>Domain Authority:</strong> {domain?.domainAuthority}</p>
                {/* Add any other details you want to display */}
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => { onDelete(); toggle(); }}>Delete</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteConfirmationModal;
