import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faUser,
  faGlobe,
  faPlug,
  faLightbulb,
  faBuilding,
  faLink,
  faDollarSign,
  faLanguage,
  faMapMarkerAlt,
  faTags,
  faList,
  faRobot,
  faSlidersH,
  faPencilAlt,
  faFilter,
  faBan
} from '@fortawesome/free-solid-svg-icons';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import PageLayout from './components/PageLayout';

const HowToUseIt = () => {
  return (
    <PageLayout>
      <div className="px-3">
        <h1><FontAwesomeIcon icon={faBook} className="mr-2" /> AdverLink Publisher Guide: How to Get Started</h1>
        <p>This guide provides a step-by-step walkthrough for publishers to set up and start using AdverLink. Follow these instructions to easily configure your profile, add your website, and begin collaborating with advertisers to integrate links into your content.</p>

        <h2>Get Started in 3 Easy Steps</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="mb-4 p-4 border rounded-lg">
            <h3><FontAwesomeIcon icon={faUser} className="mr-2" /> Step 1: Set Up Your Profile</h3>
            <p>The first step is to complete your profile to attract relevant advertisers.</p>
            <ul>
              <li><strong>Access the Profile Setup Section:</strong> Click on <a href="/MyAccount" className="button">Set up your profile</a> to open the form and begin filling in your details.</li>
              <li><strong>Complete the Required Information:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faUser} className="mr-2" /> <strong>For Individuals</strong> – Fill in: Full name, address, bank account (IBAN), country, city, county, and bank name.</li>
                  <li><FontAwesomeIcon icon={faBuilding} className="mr-2" /> <strong>For Companies</strong> – Check the "I am a company (not an individual)" box and provide additional information: Company name, VAT number, company registry number, bank account (IBAN), and company representative's name.</li>
                </ul>
              </li>
              <li><strong>Save Details:</strong> After filling in all required fields, click <a href="#" className="button">Save Account Info</a> to complete your profile setup.</li>
            </ul>
            <p className="tip"><FontAwesomeIcon icon={faLightbulb} className="mr-2" /> <strong>Tip:</strong> Double-check that all information is accurate, especially bank and billing details, as they will be used for invoicing and payments.</p>
          </div>

          <div className="mb-4 p-4 border rounded-lg">
            <h3><FontAwesomeIcon icon={faGlobe} className="mr-2" /> Step 2: Add Your Website and Accept Campaigns</h3>
            <p>Next, add your website details to start receiving relevant campaigns.</p>
            <h4>Steps to Complete Your Website Profile:</h4>
            <ul>
              <li><strong>Basic Data:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faLink} className="mr-2" /> <strong>Domain:</strong> Enter your website domain (e.g., www.website.com). Click outside the box to update the estimated monthly revenue per link based on your domain authority.</li>
                  <li><FontAwesomeIcon icon={faDollarSign} className="mr-2" /> <strong>Monthly Revenue per Link:</strong> Based on your domain authority, you'll see an estimated monthly revenue per link.</li>
                  <li><FontAwesomeIcon icon={faLanguage} className="mr-2" /> <strong>Language of Publication:</strong> Select the primary language of your website content.</li>
                  <li><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> <strong>Country:</strong> Choose the country where your website is based.</li>
                  <li><FontAwesomeIcon icon={faTags} className="mr-2" /> <strong>Type of Website:</strong> Specify the type of website (e.g., blog, corporate website).</li>
                  <li><FontAwesomeIcon icon={faList} className="mr-2" /> <strong>Occurrences:</strong> Enter the maximum number of allowed link placements in your content.</li>
                  <li><FontAwesomeIcon icon={faRobot} className="mr-2" /> <strong>AdverLink Bot Permission:</strong> Check this box to allow the AdverLink Bot to crawl your website for content analysis.</li>
                  <li><FontAwesomeIcon icon={faSlidersH} className="mr-2" /> <strong>Set Your Own Price:</strong> If you'd like to set a custom price for links, check this option and specify your preferred rate.</li>
                </ul>
              </li>
              <li><strong>Theme:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faPencilAlt} className="mr-2" /> <strong>Short Description:</strong> Enter a brief description of your website (up to 600 characters) to help advertisers understand its focus.</li>
                  <li><FontAwesomeIcon icon={faTags} className="mr-2" /> <strong>Tags:</strong> Add up to 10 tags relevant to your content to improve searchability and classification.</li>
                  <li><FontAwesomeIcon icon={faFilter} className="mr-2" /> <strong>Accept Business Keywords and Links:</strong> Select the business categories from which you are open to receiving keywords and links (e.g., Gambling, Erotica, CBD). You need to opt in for these specific categories, as they are excluded by default. All other categories are accepted automatically.</li>
                  <li><FontAwesomeIcon icon={faBan} className="mr-2" /> <strong>List of Blocked Websites:</strong> Enter any websites you do not wish to receive links from, separated by commas.</li>
                </ul>
              </li>
            </ul>
            <p className="tip"><FontAwesomeIcon icon={faLightbulb} className="mr-2" /> <strong>Save Settings:</strong> After completing each section, click <a href="#" className="button">Next</a> to move forward. This will finalize your website setup, making it eligible for relevant campaigns.</p>
          </div>

          <div className="mb-4 p-4 border rounded-lg">
            <h3><FontAwesomeIcon icon={faPlug} className="mr-2" /> Step 3: Install Our Plugin for Automatic Link Insertion</h3>
            <p>To easily integrate advertiser links into your content, install the AdverLink plugin on your WordPress website. Here's how:</p>
            <ul>
              <li><strong>Download the Plugin:</strong> Go to the <strong>WordPress Plugin</strong> section in your AdverLink account and click <a href="/Download" className="button">Download</a> to get the plugin file. (Note: The plugin is not yet available on WordPress.org, so it needs to be uploaded manually.)</li>
              <li><strong>Upload the Plugin to WordPress:</strong>
                <ul>
                  <li>Log in to your WordPress dashboard.</li>
                  <li>Go to <strong>Plugins &gt; Add New</strong>.</li>
                  <li>Click on <strong>Upload Plugin</strong>, select the downloaded file, and click <strong>Install Now</strong>.</li>
                  <li>Once installed, activate the plugin.</li>
                </ul>
              </li>
              <li><strong>Generate and Enter Your Secret Key:</strong>
                <ul>
                  <li>In your Publihser AdverLink account, navigate to the <strong>Websites</strong> section under <strong>Websites</strong>.</li>
                  <li>Find your website in the list and click "Generate secret key."</li>
                  <li>Copy the generated secret key.</li>
                  <li>Go back to your WordPress dashboard, open the AdverLink plugin settings, and paste the secret key.</li>
                </ul>
              </li>
              <li><strong>Save the Key and Configure the Plugin:</strong> After pasting the secret key, save the settings. The plugin will now automatically connect and insert advertiser links into designated areas in your content. All the links needs to be approved before insert. You will have to HIT replace in order for the link to be inserted.</li>
            </ul>
            <p className="tip"><FontAwesomeIcon icon={faLightbulb} className="mr-2" /> <strong>Tip:</strong> Review the plugin's settings to ensure link placements align with your content style and positioning preferences.</p>
          </div>
        </div>

        {/* YouTube Video Embed */}
        <div className="my-6">
          <h2>Watch Our Video Guide</h2>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/uNPhEP-XZJ0?si=k8nHVtoZ5UTp3H1m"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        <h2>Additional Sections for Further Optimization</h2>
        <ul>
          <li><strong>Optimizing Your Profile:</strong> Tips on refining your profile to attract more relevant advertisers.</li>
          <li><strong>Monitoring Campaign Performance:</strong> Guidance on tracking campaign success and adjusting settings for optimal results.</li>
          <li><strong>Resources and Support:</strong> Information on where to find help if you encounter issues, including FAQs and support contacts.</li>
        </ul>

        <p>By following these steps, you'll be set up to start generating revenue through advertiser partnerships on AdverLink.</p>
      </div>
    </PageLayout>
  );
};

export default HowToUseIt;
