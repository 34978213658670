import React, { useState } from 'react';
import PageLayout from '../../PageLayout';
import PublishersList from './PublishersList';
import AdvertisersList from './AdvertisersList';
import NotificationsManager from './NotificationsManager';
import VouchersManager from './VouchersManager';
import EmailTemplatesManager from './EmailTemplatesManager';
import NotificationSender from './NotificationSender';
import PublisherInvoicesManager from './PublisherInvoicesManager';
import { jwtDecode } from 'jwt-decode';
import './AdminDashboard.css';  // Make sure CSS is imported

const getUserTypeFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
}

export default function AdminDashboard() {
    const [currentView, setCurrentView] = useState('publishers');
    const [selectedPublisher, setSelectedPublisher] = useState(null);
    const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
    const token = localStorage.getItem('token');
    const userType = getUserTypeFromToken(token);

    const handleShowPublisherDetails = (publisher) => {
        setSelectedPublisher(publisher);
    };

    const handleShowAdvertiserDetails = (advertiser) => {
        setSelectedAdvertiser(advertiser);
    };

    const content = (
        <div className="main">
            <div className="nav-container">
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${currentView === 'publishers' ? 'active' : ''}`}
                            onClick={() => setCurrentView('publishers')}
                        >
                            Publishers
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${currentView === 'advertisers' ? 'active' : ''}`}
                            onClick={() => setCurrentView('advertisers')}
                        >
                            Advertisers
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${currentView === 'notifications' ? 'active' : ''}`}
                            onClick={() => setCurrentView('notifications')}
                        >
                            Notifications
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${currentView === 'emails' ? 'active' : ''}`}
                            onClick={() => setCurrentView('emails')}
                        >
                            Email Sender
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${currentView === 'notificationSender' ? 'active' : ''}`}
                            onClick={() => setCurrentView('notificationSender')}
                        >
                            Notification Sender
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${currentView === 'vouchers' ? 'active' : ''}`}
                            onClick={() => setCurrentView('vouchers')}
                        >
                            Vouchers
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${currentView === 'invoices' ? 'active' : ''}`}
                            onClick={() => setCurrentView('invoices')}
                        >
                            Publisher Invoices
                        </button>
                    </li>
                </ul>
            </div>

            <div className="header-section">
                <h2>{currentView.charAt(0).toUpperCase() + currentView.slice(1)} Management</h2>
                {currentView !== 'emails' && currentView !== 'notificationSender' && currentView !== 'invoices' && (
                    <div className="action-buttons">
                        <button className="btn btn-outline-primary">
                            Import
                        </button>
                        <button className="btn btn-primary">
                            Add
                        </button>
                    </div>
                )}
            </div>

            <div className="content-area">
                <div className="table-responsive">
                    {currentView === 'publishers' && (
                        <PublishersList 
                            showHeader={false}
                            onShowDetails={handleShowPublisherDetails}
                        />
                    )}
                    {currentView === 'advertisers' && (
                        <AdvertisersList 
                            showHeader={false}
                            onShowDetails={handleShowAdvertiserDetails}
                        />
                    )}
                    {currentView === 'notifications' && <NotificationsManager />}
                    {currentView === 'emails' && <EmailTemplatesManager />}
                    {currentView === 'notificationSender' && <NotificationSender />}
                    {currentView === 'vouchers' && <VouchersManager />}
                    {currentView === 'invoices' && <PublisherInvoicesManager />}
                </div>
            </div>
        </div>
    );

    if (userType !== 'sysadmin') {
        return <div>Access Denied</div>;
    }

    return (
        <PageLayout>
            {content}
        </PageLayout>
    );
}