// src/components/NewPassword.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ForgotPassword.css';
import logoImage from './assets/images/Link-Building-Software.png';
import { BASE_API_URL } from '../src/config/constants';
import axios from 'axios';

const NewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { key, email } = useParams();
console.log('ok');
useEffect(() => {
    const validateKey = async () => {
      try {
        const isValid = await ValidateResetPasswordKey(key);
        console.log(isValid);
        if (!isValid) {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error validating key:', error);
        navigate('/login');
      }
    };

    validateKey();
  }, [key, navigate]);

    const ValidateResetPasswordKey = async (key) => {
        try {
            const response = await axios.post(`${BASE_API_URL}api/verificationcode/verifyKey/`+key)
            if(response.data.valid != true)
                return false;
              else {
                return true;
              }
          } catch (error) {
            setMessage('An error occurred. Please try again.');
            return false;
          }
    }

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = await ValidateResetPasswordKey(key);
    if (!isValid)
        navigate('/login');

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch(`${BASE_API_URL}api/authentication/set-new-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword:password, key }),
      });

      if (response.ok) {
        setMessage('Password has been reset successfully.');
        setTimeout(() => navigate('/login'), 2000);
      } else {
        setMessage('Failed to reset password. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="container1">
      <div className="left-side">
        <div className="content">
          <div className="logo-container">
            <img src={logoImage} alt="AdverLink" />
          </div>
          <h3 style={{fontSize:'2.5rem'}} className="my-5">Set New Password</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="password">New Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handleChangePassword}
                placeholder="Enter your new password"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                placeholder="Confirm your new password"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Set New Password</button>
          </form>
          {message && <div className="message">{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
