import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, 
  faChevronDown, 
  faChevronUp,
  faPlus,
  faMinus
} from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '../context/LanguageContext';
import Modal from '../Modal';
import './SearchBox.css';

// Default values moved to the top level
const defaultLanguages = ['English', 'Romanian', 'Spanish', 'French'];
const defaultCountries = ['United States', 'United Kingdom', 'Romania', 'India'];

// Updated interfaces pentru limbi și țări (simple strings)
const SearchBox = ({ onResults }) => {
  const [dropdowns, setDropdowns] = useState({
    language: false,
    country: false
  });
  const [selectedLanguage, setSelectedLanguage] = useState('all');
  const [selectedCountry, setSelectedCountry] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [languageSearch, setLanguageSearch] = useState('');
  const [countrySearch, setCountrySearch] = useState('');
  const [results, setResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [expandedDomains, setExpandedDomains] = useState(new Set());
  const [expandedArticlesCount, setExpandedArticlesCount] = useState({});
  const [filters, setFilters] = useState({
    domainAuthority: { min: '', max: '' },
    price: { min: '', max: '' },
    country: 'All Countries',
    language: 'All Languages'
  });
  const [showSearchSummary, setShowSearchSummary] = useState(false);
  const [lastSearchedQuery, setLastSearchedQuery] = useState('');
  const searchTimeoutRef = useRef(null);
  const { language, translations } = useLanguage();
  const [error, setError] = useState(null);
  const languageInputRef = useRef(null);
  const countryInputRef = useRef(null);
  const headerLanguageDropdownRef = useRef(null);
  const headerCountryDropdownRef = useRef(null);
  const filterLanguageInputRef = useRef(null);
  const filterCountryInputRef = useRef(null);
  const languageDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);

  // Use ref to track if we've already loaded the data
  const dataLoadedRef = useRef(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [vatRate, setVatRate] = useState(null);

  const [loadedPagesCount, setLoadedPagesCount] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Load languages and countries from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching languages and countries...');
        const [languagesResponse, countriesResponse] = await Promise.all([
          fetch(`${BASE_API_URL}api/StaticValues/languages`),
          fetch(`${BASE_API_URL}api/StaticValues/countries`)
        ]);

        if (!languagesResponse.ok || !countriesResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const languagesData = await languagesResponse.json();
        const countriesData = await countriesResponse.json();

        console.log('Languages data:', languagesData);
        console.log('Countries data:', countriesData);

        // Convert simple string arrays to objects with code and name
        const formattedLanguages = languagesData.map(lang => ({
          code: lang.toLowerCase(),
          name: lang
        }));

        const formattedCountries = countriesData.map(country => ({
          code: country.toLowerCase(),
          name: country
        }));

        setLanguages(formattedLanguages);
        setCountries(formattedCountries);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Fallback to default values if API fails
        setLanguages(defaultLanguages.map(lang => ({
          code: lang.toLowerCase(),
          name: lang
        })));
        setCountries(defaultCountries.map(country => ({
          code: country.toLowerCase(),
          name: country
        })));
      }
    };

    fetchData();
  }, []);

  // Use an effect to notify parent component when search results change
  useEffect(() => {
    if (onResults) {
      onResults(results.length > 0);
    }
  }, [results, onResults]);

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;
    
    // Reset all states
    setLoading(true);
    setError(null);
    setResults([]);
    setOriginalResults([]);
    setCurrentPage(1);
    setLoadedPagesCount(1);
    setHasMore(true);
    setExpandedDomains(new Set());
    setExpandedArticlesCount({});
    setFilters({
      domainAuthority: { min: '', max: '' },
      price: { min: '', max: '' },
      country: 'All Countries',
      language: 'All Languages'
    });
    setShowSearchSummary(false);

    try {
      const params = new URLSearchParams({
        keyword: searchQuery,
        country: selectedCountry === 'all' ? '' : selectedCountry,
        language: selectedLanguage === 'all' ? '' : selectedLanguage,
        pageNumber: 1
      });

      const response = await fetch(`${BASE_API_URL}search/new-search-design?${params}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('No reader available');
      }

      let buffer = '';
      let receivedResults = false;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        buffer += new TextDecoder().decode(value);
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';
        
        for (const line of lines) {
          if (line.trim()) {
            try {
              const data = JSON.parse(line);
              if (data && data.Domain && Array.isArray(data.Urls)) {
                receivedResults = true;
                setResults(prevResults => {
                  const existingDomainIndex = prevResults.findIndex(
                    result => result.domain.Domain === data.Domain.Domain
                  );

                  if (existingDomainIndex !== -1) {
                    const newResults = [...prevResults];
                    newResults[existingDomainIndex] = {
                      ...newResults[existingDomainIndex],
                      articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                    };
                    return newResults;
                  } else {
                    return [...prevResults, {
                      domain: data.Domain,
                      articles: data.Urls
                    }];
                  }
                });
                setOriginalResults(prevResults => {
                  const existingDomainIndex = prevResults.findIndex(
                    result => result.domain.Domain === data.Domain.Domain
                  );

                  if (existingDomainIndex !== -1) {
                    const newResults = [...prevResults];
                    newResults[existingDomainIndex] = {
                      ...newResults[existingDomainIndex],
                      articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                    };
                    return newResults;
                  } else {
                    return [...prevResults, {
                      domain: data.Domain,
                      articles: data.Urls
                    }];
                  }
                });
              }
            } catch (err) {
              console.warn('Error parsing JSON line:', err);
              continue;
            }
          }
        }
      }
      
      // Process any remaining data in the buffer
      if (buffer.trim()) {
        try {
          const data = JSON.parse(buffer);
          if (data && data.Domain && Array.isArray(data.Urls)) {
            receivedResults = true;
            setResults(prevResults => {
              const existingDomainIndex = prevResults.findIndex(
                result => result.domain.Domain === data.Domain.Domain
              );

              if (existingDomainIndex !== -1) {
                const newResults = [...prevResults];
                newResults[existingDomainIndex] = {
                  ...newResults[existingDomainIndex],
                  articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                };
                return newResults;
              } else {
                return [...prevResults, {
                  domain: data.Domain,
                  articles: data.Urls
                }];
              }
            });
            setOriginalResults(prevResults => {
              const existingDomainIndex = prevResults.findIndex(
                result => result.domain.Domain === data.Domain.Domain
              );

              if (existingDomainIndex !== -1) {
                const newResults = [...prevResults];
                newResults[existingDomainIndex] = {
                  ...newResults[existingDomainIndex],
                  articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                };
                return newResults;
              } else {
                return [...prevResults, {
                  domain: data.Domain,
                  articles: data.Urls
                }];
              }
            });
          }
        } catch (err) {
          console.warn('Error parsing final JSON line:', err);
        }
      }
      
      setHasMore(receivedResults);
      if (receivedResults) {
        setShowSearchSummary(true);
        setLastSearchedQuery(searchQuery);
      }
    } catch (err) {
      console.error('Search error:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key ? prev.direction === 'asc' ? 'desc' : 'asc' : 'asc'
    }));

    setCurrentPage(1);

    setResults(prev => {
      const sortedResults = [...prev].sort((a, b) => {
        if (key === 'domainAuthority') {
          const aValue = parseInt(a.domain.DomainAuthority);
          const bValue = parseInt(b.domain.DomainAuthority);
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        } else if (key === 'price') {
          const aValue = parseFloat(a.domain.Price);
          const bValue = parseFloat(b.domain.Price);
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }
        return 0;
      });
      return sortedResults;
    });
  };

  const loadMoreArticles = (domain) => {
    if (!domain) return;
    setExpandedArticlesCount(prev => {
      const currentCount = prev[domain] || 5;
      const newCount = currentCount + 5;
      return { ...prev, [domain]: newCount };
    });
  };

  const toggleDomain = (domain) => {
    if (!domain) return;
    setExpandedDomains(prev => {
      const newSet = new Set(prev);
      if (newSet.has(domain)) {
        newSet.delete(domain);
      } else {
        newSet.add(domain);
        // Initialize expandedArticlesCount for this domain if not exists
        setExpandedArticlesCount(prev => ({
          ...prev,
          [domain]: prev[domain] || 5
        }));
      }
      return newSet;
    });
  };

  const loadMore = async () => {
    if (!results.length || isLoadingMore || !hasMore) return;
    
    setIsLoadingMore(true);
    const nextPage = loadedPagesCount + 1;
    
    try {
      const params = new URLSearchParams({
        keyword: searchQuery,
        country: selectedCountry === 'all' ? '' : selectedCountry,
        language: selectedLanguage === 'all' ? '' : selectedLanguage,
        pageNumber: nextPage
      });

      const response = await fetch(`${BASE_API_URL}search/new-search-design?${params}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('No reader available');
      }

      let buffer = '';
      let receivedResults = false;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        buffer += new TextDecoder().decode(value);
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';
        
        for (const line of lines) {
          if (line.trim()) {
            try {
              const data = JSON.parse(line);
              if (data && data.Domain && Array.isArray(data.Urls)) {
                receivedResults = true;
                setResults(prevResults => {
                  const existingDomainIndex = prevResults.findIndex(
                    result => result.domain.Domain === data.Domain.Domain
                  );

                  if (existingDomainIndex !== -1) {
                    const newResults = [...prevResults];
                    newResults[existingDomainIndex] = {
                      ...newResults[existingDomainIndex],
                      articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                    };
                    return newResults;
                  } else {
                    return [...prevResults, {
                      domain: data.Domain,
                      articles: data.Urls
                    }];
                  }
                });

                setOriginalResults(prevResults => {
                  const existingDomainIndex = prevResults.findIndex(
                    result => result.domain.Domain === data.Domain.Domain
                  );

                  if (existingDomainIndex !== -1) {
                    const newResults = [...prevResults];
                    newResults[existingDomainIndex] = {
                      ...newResults[existingDomainIndex],
                      articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                    };
                    return newResults;
                  } else {
                    return [...prevResults, {
                      domain: data.Domain,
                      articles: data.Urls
                    }];
                  }
                });
              }
            } catch (err) {
              console.warn('Error parsing JSON line:', err);
              continue;
            }
          }
        }
      }
      
      // Process any remaining data in the buffer
      if (buffer.trim()) {
        try {
          const data = JSON.parse(buffer);
          if (data && data.Domain && Array.isArray(data.Urls)) {
            receivedResults = true;
            setResults(prevResults => {
              const existingDomainIndex = prevResults.findIndex(
                result => result.domain.Domain === data.Domain.Domain
              );

              if (existingDomainIndex !== -1) {
                const newResults = [...prevResults];
                newResults[existingDomainIndex] = {
                  ...newResults[existingDomainIndex],
                  articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                };
                return newResults;
              } else {
                return [...prevResults, {
                  domain: data.Domain,
                  articles: data.Urls
                }];
              }
            });

            setOriginalResults(prevResults => {
              const existingDomainIndex = prevResults.findIndex(
                result => result.domain.Domain === data.Domain.Domain
              );

              if (existingDomainIndex !== -1) {
                const newResults = [...prevResults];
                newResults[existingDomainIndex] = {
                  ...newResults[existingDomainIndex],
                  articles: [...newResults[existingDomainIndex].articles, ...data.Urls]
                };
                return newResults;
              } else {
                return [...prevResults, {
                  domain: data.Domain,
                  articles: data.Urls
                }];
              }
            });
          }
        } catch (err) {
          console.warn('Error parsing final JSON line:', err);
        }
      }

      setLoadedPagesCount(nextPage);
      // Only set hasMore to false if we received no results at all
      if (!receivedResults) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading more results:', error);
      setError('An error occurred while loading more results. Please try again.');
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleFilterChange = (field, subfield, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: subfield ? {
        ...prev[field],
        [subfield]: value
      } : value
    }));
  };

  // Update the useEffect for filtering
  useEffect(() => {
    if (originalResults.length > 0) {
      const filteredResults = originalResults.filter(result => {
        const domainAuth = parseInt(result.domain.DomainAuthority);
        const price = parseFloat(result.domain.Price);
        
        // Check Domain Authority filters
        if (filters.domainAuthority.min !== '' && domainAuth < parseInt(filters.domainAuthority.min)) {
          return false;
        }
        if (filters.domainAuthority.max !== '' && domainAuth > parseInt(filters.domainAuthority.max)) {
          return false;
        }
        
        // Check Price filters
        if (filters.price.min !== '' && price < parseFloat(filters.price.min)) {
          return false;
        }
        if (filters.price.max !== '' && price > parseFloat(filters.price.max)) {
          return false;
        }

        // Check Language filter
        if (filters.language !== 'All Languages' && filters.language !== '') {
          if (!result.domain.Language.toLowerCase().includes(filters.language.toLowerCase())) {
            return false;
          }
        }

        // Check Country filter
        if (filters.country !== 'All Countries' && filters.country !== '') {
          if (!result.domain.Country.toLowerCase().includes(filters.country.toLowerCase())) {
            return false;
          }
        }
        
        return true;
      });

      setResults(filteredResults);
    }
  }, [filters, originalResults]);

  // Update resetFilters function
  const resetFilters = () => {
    setFilters({
      domainAuthority: { min: '', max: '' },
      price: { min: '', max: '' },
      country: 'All Countries',
      language: 'All Languages'
    });
    setResults(originalResults);
  };

  // Add useEffect to fetch VAT rate
  useEffect(() => {
    const fetchVatRate = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}api/StaticValues/vat-rate`);
        setVatRate(response.data);
      } catch (error) {
        console.error('Error fetching VAT rate:', error);
      }
    };
    fetchVatRate();
  }, []);

  const handleAddToCart = (domain, article) => {
    const entry = {
      domain: domain.Domain,
      domainAuthority: domain.DomainAuthority,
      price: domain.Price,
      totalPrice: domain.TotalPrice,
      targetUrl: article ? article.url : null,
      isFromArticle: !!article,
      articleUrl: article ? (typeof article === 'string' ? article : article.url) : null
    };
    setSelectedEntry(entry);
    setModalVisible(true);
  };

  // Add filtered lists
  const filteredLanguages = useMemo(() => {
    return languages.filter(lang => 
      lang.name.toLowerCase().includes(languageSearch.toLowerCase())
    );
  }, [languages, languageSearch]);

  const filteredCountries = useMemo(() => {
    return countries.filter(country => 
      country.name.toLowerCase().includes(countrySearch.toLowerCase())
    );
  }, [countries, countrySearch]);

  // Add handlers for dropdown toggles
  const toggleLanguageDropdown = () => {
    setDropdowns(prev => ({ ...prev, language: !prev.language }));
    if (!dropdowns.language) {
      setTimeout(() => languageInputRef.current?.focus(), 0);
    }
  };

  const toggleCountryDropdown = () => {
    setDropdowns(prev => ({ ...prev, country: !prev.country }));
    if (!dropdowns.country) {
      setTimeout(() => countryInputRef.current?.focus(), 0);
    }
  };

  // Add handlers for search inputs
  const handleLanguageSearch = (e) => {
    setLanguageSearch(e.target.value);
  };

  const handleCountrySearch = (e) => {
    setCountrySearch(e.target.value);
  };

  // Add handlers for selection
  const handleLanguageSelect = (lang) => {
    setSelectedLanguage(lang.code);
    setDropdowns(prev => ({ ...prev, language: false }));
    setLanguageSearch('');
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country.code);
    setDropdowns(prev => ({ ...prev, country: false }));
    setCountrySearch('');
  };

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);

  // Add useEffect for click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Handle header dropdowns
      if (headerLanguageDropdownRef.current && !headerLanguageDropdownRef.current.contains(event.target)) {
        setDropdowns(prev => ({ ...prev, language: false }));
      }
      if (headerCountryDropdownRef.current && !headerCountryDropdownRef.current.contains(event.target)) {
        setDropdowns(prev => ({ ...prev, country: false }));
      }

      // Handle filter dropdowns
      if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
        setLanguageDropdownOpen(false);
      }
      if (countryDropdownRef.current && !countryDropdownRef.current.contains(event.target)) {
        setCountryDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add useEffect for autofocus on filter dropdowns
  useEffect(() => {
    if (languageDropdownOpen) {
      setTimeout(() => filterLanguageInputRef.current?.focus(), 0);
    }
  }, [languageDropdownOpen]);

  useEffect(() => {
    if (countryDropdownOpen) {
      setTimeout(() => filterCountryInputRef.current?.focus(), 0);
    }
  }, [countryDropdownOpen]);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  // Calculate paginated results
  const paginatedResults = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return results.slice(startIndex, endIndex);
  }, [results, currentPage, itemsPerPage]);

  // Calculate total pages
  const totalPages = Math.ceil(results.length / itemsPerPage);

  return (
    <div className="search-container">
      <div className="search-header">
        <div className="dropdown-container" ref={headerLanguageDropdownRef}>
          <div 
            className="dropdown-header" 
            onClick={toggleLanguageDropdown}
          >
            {selectedLanguage === 'all' ? 'All Languages' : 
              languages.find(lang => lang.code === selectedLanguage)?.name || 'All Languages'}
            <FontAwesomeIcon icon={dropdowns.language ? faChevronUp : faChevronDown} />
          </div>
          {dropdowns.language && (
            <div className="dropdown-content">
              <input
                ref={languageInputRef}
                type="text"
                value={languageSearch}
                onChange={handleLanguageSearch}
                placeholder="Search language..."
                className="dropdown-search"
              />
              <div className="dropdown-list">
                <div 
                  className="dropdown-item"
                  onClick={() => handleLanguageSelect({ code: 'all', name: 'All Languages' })}
                >
                  All Languages
                </div>
                {filteredLanguages.map(lang => (
                  <div 
                    key={lang.code}
                    className="dropdown-item"
                    onClick={() => handleLanguageSelect(lang)}
                  >
                    {lang.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="dropdown-container" ref={headerCountryDropdownRef}>
          <div 
            className="dropdown-header" 
            onClick={toggleCountryDropdown}
          >
            {selectedCountry === 'all' ? 'All Countries' : 
              countries.find(country => country.code === selectedCountry)?.name || 'All Countries'}
            <FontAwesomeIcon icon={dropdowns.country ? faChevronUp : faChevronDown} />
          </div>
          {dropdowns.country && (
            <div className="dropdown-content">
              <input
                ref={countryInputRef}
                type="text"
                value={countrySearch}
                onChange={handleCountrySearch}
                placeholder="Search country..."
                className="dropdown-search"
              />
              <div className="dropdown-list">
                <div 
                  className="dropdown-item"
                  onClick={() => handleCountrySelect({ code: 'all', name: 'All Countries' })}
                >
                  All Countries
                </div>
                {filteredCountries.map(country => (
                  <div 
                    key={country.code}
                    className="dropdown-item"
                    onClick={() => handleCountrySelect(country)}
                  >
                    {country.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="search-box">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={translations.search[language]}
            className="search-input"
            disabled={loading}
          />
          <button 
            onClick={handleSearch} 
            className="search-button"
            disabled={loading}
          >
            {loading ? 'Searching...' : (
              <>
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                Search
              </>
            )}
          </button>
        </div>
      </div>

      {showSearchSummary && originalResults.length > 0 && (
        <div className="search-summary">
          <div className="summary-content">
            <h2>Your Search Results</h2>
            <p>We found {originalResults.length} domains matching your query "{lastSearchedQuery}". Click on a domain to see available articles.</p>
          </div>
        </div>
      )}

      {originalResults.length > 0 && (
        <div className="filters">
          <div className="filter-group">
            <label>Domain Authority</label>
            <div className="d-flex gap-2">
              <input
                type="number"
                value={filters.domainAuthority.min}
                onChange={(e) => handleFilterChange('domainAuthority', 'min', e.target.value)}
                placeholder="Min"
              />
              <span>to</span>
              <input
                type="number"
                value={filters.domainAuthority.max}
                onChange={(e) => handleFilterChange('domainAuthority', 'max', e.target.value)}
                placeholder="Max"
              />
            </div>
          </div>

          <div className="filter-group">
            <label>Price</label>
            <div className="d-flex gap-2">
              <input
                type="number"
                value={filters.price.min}
                onChange={(e) => handleFilterChange('price', 'min', e.target.value)}
                placeholder="Min"
              />
              <span>to</span>
              <input
                type="number"
                value={filters.price.max}
                onChange={(e) => handleFilterChange('price', 'max', e.target.value)}
                placeholder="Max"
              />
            </div>
          </div>

          <div className="filter-group">
            <label>Country</label>
            <div className="dropdown-container" ref={countryDropdownRef}>
              <div 
                className="dropdown-header" 
                onClick={() => setCountryDropdownOpen(!countryDropdownOpen)}
              >
                {filters.country === 'All Countries' ? 'All Countries' : filters.country}
                <FontAwesomeIcon icon={countryDropdownOpen ? faChevronUp : faChevronDown} />
              </div>
              {countryDropdownOpen && (
                <div className="dropdown-content">
                  <input
                    ref={filterCountryInputRef}
                    type="text"
                    value={countrySearch}
                    onChange={(e) => setCountrySearch(e.target.value)}
                    placeholder="Search country..."
                    className="dropdown-search"
                  />
                  <div className="dropdown-list">
                    <div 
                      className="dropdown-item"
                      onClick={() => {
                        handleFilterChange('country', null, 'All Countries');
                        setCountryDropdownOpen(false);
                        setCountrySearch('');
                      }}
                    >
                      All Countries
                    </div>
                    {countries
                      .filter(country => 
                        country.name.toLowerCase().includes(countrySearch.toLowerCase())
                      )
                      .map(country => (
                        <div 
                          key={country.code}
                          className="dropdown-item"
                          onClick={() => {
                            handleFilterChange('country', null, country.name);
                            setCountryDropdownOpen(false);
                            setCountrySearch('');
                          }}
                        >
                          {country.name}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="filter-group">
            <label>Language</label>
            <div className="dropdown-container" ref={languageDropdownRef}>
              <div 
                className="dropdown-header" 
                onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
              >
                {filters.language === 'All Languages' ? 'All Languages' : filters.language}
                <FontAwesomeIcon icon={languageDropdownOpen ? faChevronUp : faChevronDown} />
              </div>
              {languageDropdownOpen && (
                <div className="dropdown-content">
                  <input
                    ref={filterLanguageInputRef}
                    type="text"
                    value={languageSearch}
                    onChange={(e) => setLanguageSearch(e.target.value)}
                    placeholder="Search language..."
                    className="dropdown-search"
                  />
                  <div className="dropdown-list">
                    <div 
                      className="dropdown-item"
                      onClick={() => {
                        handleFilterChange('language', null, 'All Languages');
                        setLanguageDropdownOpen(false);
                        setLanguageSearch('');
                      }}
                    >
                      All Languages
                    </div>
                    {languages
                      .filter(lang => 
                        lang.name.toLowerCase().includes(languageSearch.toLowerCase())
                      )
                      .map(lang => (
                        <div 
                          key={lang.code}
                          className="dropdown-item"
                          onClick={() => {
                            handleFilterChange('language', null, lang.name);
                            setLanguageDropdownOpen(false);
                            setLanguageSearch('');
                          }}
                        >
                          {lang.name}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <button onClick={resetFilters} className="reset-filters-button">
            Reset Filters
          </button>
        </div>
      )}

      <table className="results-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Domain</th>
            <th onClick={() => handleSort('domainAuthority')}>
              DOMAIN AUTHORITY
              {sortConfig.key === 'domainAuthority' && (
                <FontAwesomeIcon 
                  icon={sortConfig.direction === 'asc' ? faChevronUp : faChevronDown} 
                  className="ml-1"
                />
              )}
            </th>
            <th>DESCRIPTION</th>
            <th>Country</th>
            <th>Language</th>
            <th onClick={() => handleSort('price')}>
              Price per month
              {sortConfig.key === 'price' && (
                <FontAwesomeIcon 
                  icon={sortConfig.direction === 'asc' ? faChevronUp : faChevronDown} 
                  className="ml-1"
                />
              )}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {results.length > 0 ? (
            paginatedResults.map((result, index) => (
              <React.Fragment key={result?.domain?.Domain || index}>
                <tr className="domain-row">
                  <td>{index + 1}.</td>
                  <td>
                    <span className="domain-name">
                      {result?.domain?.Domain || 'N/A'}
                    </span>
                    <div 
                      className="articles-count"
                      onClick={() => toggleDomain(result?.domain?.Domain)}
                    >
                      {result?.articles?.length || 0} articles available
                    </div>
                  </td>
                  <td>
                    <div className="domain-authority-circle">
                      {result?.domain?.DomainAuthority || '0'}
                    </div>
                  </td>
                  <td>
                    <div className="domain-description">
                      {result?.domain?.Description || 'No description available'}
                    </div>
                  </td>
                  <td>
                    <span className="country-badge">
                      {result?.domain?.Country === 'United Kingdom of Great Britain and Northern Ireland' ? 'UK' :
                       result?.domain?.Country === 'United States of America' ? 'USA' : 
                       result?.domain?.Country || 'N/A'}
                    </span>
                  </td>
                  <td>
                    <span className="language-badge">
                      {result?.domain?.Language || 'N/A'}
                    </span>
                  </td>
                  <td>
                    {result?.domain?.Price ? `$${result?.domain?.Price}` : 'N/A'}
                  </td>
                  <td>
                    <button
                      onClick={() => handleAddToCart(result?.domain)}
                      className="buy-button"
                      disabled={!result?.domain?.Price}
                    >
                      Buy
                    </button>
                  </td>
                </tr>
                {expandedDomains.has(result?.domain?.Domain) && (
                  <tr className="articles-row">
                    <td colSpan="8">
                      <div className="articles-list">
                        {(result?.articles || []).slice(0, expandedArticlesCount[result?.domain?.Domain] || 5).map((article, articleIndex) => (
                          <div key={articleIndex} className="article-item">
                            <div className="article-content">
                              <span className="article-number">{articleIndex + 1}.</span>
                              <a 
                                href={typeof article === 'string' ? article : article?.url} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="article-title"
                              >
                                {typeof article === 'string' ? article : article?.url}
                              </a>
                              <button
                                onClick={() => handleAddToCart(result?.domain, typeof article === 'string' ? { url: article } : article)}
                                className="add-to-cart-button"
                                disabled={!result?.domain?.Price}
                              >
                                Buy
                              </button>
                            </div>
                          </div>
                        ))}
                        {(result?.articles || []).length > (expandedArticlesCount[result?.domain?.Domain] || 5) && (
                          <button
                            onClick={() => loadMoreArticles(result?.domain?.Domain)}
                            className="load-more-articles-button"
                          >
                            Load More Articles
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="no-results">
                No results match your current filters. Try adjusting the filters or click "Reset Filters" to see all results.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button 
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button 
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
      {hasMore && results.length > 0 && (
        <button 
          onClick={loadMore} 
          className="load-more-button"
          disabled={isLoadingMore}
        >
          {isLoadingMore ? 'Loading...' : 'Load More'}
        </button>
      )}

      {modalVisible && (
        <Modal 
          entry={selectedEntry} 
          onClose={() => setModalVisible(false)} 
          vatRate={vatRate}
        />
      )}
    </div>
  );
};

export default SearchBox; 