import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_API_URL } from '../src/config/constants';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import './KeywordsReports.css';
import targetUrlImg from './assets/images/targeturl.png';
import PageLayout from './components/PageLayout';
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap

const KeywordsReports = () => {
  const [soldKeywords, setSoldKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for loading

  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState(''); //bootstrap alert

  useEffect(() => {
    const fetchSoldKeywords = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BASE_API_URL}api/linksreports/get-keyword-reports-for-advertiser`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSoldKeywords(response.data);
      } catch (error) {
        console.error('Error fetching sold keywords:', error);
        setError('Failed to fetch sold keywords.');
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchSoldKeywords();
  }, []);

  return (
    <PageLayout>
      <div className="">
        <div className="">
          <div className="">
            {isLoading ? (
              <div className="text-center" style={{ marginLeft: '100px', marginTop: '50px' }}> {/* Increased margin here */}
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div> // Show loading spinner during data fetch
            ) : (
              <>
                <div className="content">
                            {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}
                  <h3 className="my-4">Keywords Reports</h3>
                  {soldKeywords.length === 0 ? (
                    <p>No keywords reports available.</p> // Show message if no data
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th>Keyword</th>
                            <th>URL</th>
                            <th>Title</th>
                            <th>Exclusivity</th>
                            <th>Link Type</th>
                            <th>Creation Date</th>
                            <th>TargetUrl</th>
                            <th>Occurrences</th>
                            <th>Publisher Website</th>
                            <th>Domain Authority</th>
                            <th>Monthly Value</th>
                            <th>Active Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {soldKeywords.map((keyword, index) => (
                            <tr key={index}>
                              <td>{keyword.keyword}</td>
                              <td>{keyword.url}</td>
                              <td>{keyword.title}</td>
                              <td>{keyword.exclusivity ? 'Yes' : 'No'}</td>
                              <td>{keyword.typeOfLink}</td>
                              <td>{keyword.dateCreated}</td>
                              <td>
                                {keyword.targetUrl && (
                                  <a href={keyword.targetUrl} target="_blank" rel="noopener noreferrer">
                                    <img
                                      src={targetUrlImg}
                                      alt="Target url"
                                      style={{ width: '16px', height: '16px' }}
                                      title={keyword.targetUrl}
                                    />
                                  </a>
                                )}
                              </td>
                              <td>{keyword.occurences}</td>
                              <td>{keyword.publisherWebsite}</td>
                              <td>{keyword.domainAuthority}</td>
                              <td>${keyword.monthlyValue}</td>
                              <td>{keyword.isActive ? 'Active' : 'Inactive'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default KeywordsReports;
