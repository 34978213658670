import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Row, Col, Tabs, Tab, Container } from 'react-bootstrap';
import ProgressSteps from './ProgressSteps';
import PageLayout from '../components/PageLayout';
import { relatedKeywords as testRelatedKeywords, lsiKeywords as testLsiKeywords, rankedKeywords as testRankedKeywords } from './TestReponseApi';
import { useNavigate } from 'react-router-dom';
import { BASE_API_URL } from '../config/constants';
import axios from 'axios';
import {linkTypes} from "./LinkTypes"; // Adjust the path as necessary
import languageCodes from './LanguageCodes';
import { X } from 'lucide-react';
import usePersistedState from './usePersistedState'; // Adjust the path as necessary
import { RefreshCw } from 'lucide-react'; // Import the refresh icon

const BulkOrder1 = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false); // for process order
  const [loadingKeywords, setLoadingKeywords] = useState(false);

  const [maxDomainAuthority, setMaxDomainAuthority] = useState(null);
  const [errorMessageDA, setErrorMessageDA] = useState('');

  const [url, setUrl] = usePersistedState('bulkOrder1_url', '');
  const [keyword, setKeyword] = usePersistedState('bulkOrder1_keyword', '');
  const [linkType, setLinkType] = usePersistedState('bulkOrder1_linkType', 'NormalFollow');
  const [sentiment, setSentiment] = usePersistedState('bulkOrder1_sentiment', 'Positive');
  const [domainAuthority, setDomainAuthority] = usePersistedState('bulkOrder1_domainAuthority', '');
  const [country, setCountry] = usePersistedState('bulkOrder1_country', '');
  const [language, setLanguage] = usePersistedState('bulkOrder1_language', '');
  const [theme, setTheme] = usePersistedState('bulkOrder1_theme', '');
  const [budget, setBudget] = usePersistedState('bulkOrder1_budget', '');
  const [isExclusive, setIsExclusive] = usePersistedState('bulkOrder1_isExclusive', false);


  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [themes, setThemes] = useState([]);
  const [loadingThemes, setLoadingThemes] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [loadingLanguages, setLoadingLanguages] = useState(true);

  const suggestedBudget = 250;

   // Persisted state for keyword arrays
  const [relatedKeywords, setRelatedKeywords] = usePersistedState('bulkOrder1_relatedKeywords', []);
  const [lsiKeywords, setLsiKeywords] = usePersistedState('bulkOrder1_lsiKeywords', []);
  const [rankedKeywords, setRankedKeywords] = usePersistedState('bulkOrder1_rankedKeywords', []);


  const [relatedUserKeywords, setRelatedUserKeywords] = usePersistedState('bulkOrder1_relatedUserKeywords', []);
  const [lsiUserKeywords, setLsiUserKeywords] = usePersistedState('bulkOrder1_lsiUserKeywords', []);
  const [rankedUserKeywords, setRankedUserKeywords] = usePersistedState('bulkOrder1_rankedUserKeywords', []);
  const [showInput, setShowInput] = useState({ related: false, lsi: false, ranked: false });
  const [inputValue, setInputValue] = useState({ related: '', lsi: '', ranked: '' });

    // Selected keywords tracking
    const [selectedRelatedKeywords, setSelectedRelatedKeywords] = usePersistedState('bulkOrder1_selectedRelatedKeywords', []);
    const [selectedLsiKeywords, setSelectedLsiKeywords] = usePersistedState('bulkOrder1_selectedLsiKeywords', []);
    const [selectedRankedKeywords, setSelectedRankedKeywords] = usePersistedState('bulkOrder1_selectedRankedKeywords', []);
    
  // New state to track if keywords have been generated
  const [keywordsGenerated, setKeywordsGenerated] = usePersistedState('bulkOrder1_keywordsGenerated', false);


  // State for errors
  const [errors, setErrors] = useState({});
  const [moreThan10, setMoreThan10] = useState(false);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/StaticValues/countries`);
      setCountries(response.data); // Set the countries from API response
      setLoadingCountries(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoadingCountries(false);
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/StaticValues/languages`); // Replace with your actual endpoint
      setLanguages(response.data);
      setLoadingLanguages(false);
    } catch (error) {
      console.error('Error fetching languages:', error);
      setLoadingLanguages(false);
    }
  };

  const fetchThemes = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/theme`);
      if (!response.ok) {
        throw new Error('Failed to fetch themes');
      }
      const data = await response.json();
      setThemes(data);
      setLoadingThemes(false);
    } catch (err) {
      //setErrors(err.message);
    } finally {
      setLoading(false);
      setLoadingThemes(false);
    }
  };

  const fetchMaxDomainAuthority = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/UserDomains/GetMaximumDomainAuthority`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const maxDA = await response.json();
        setMaxDomainAuthority(maxDA); // Set the max DA value
      } else {
        throw new Error('Failed to fetch maximum domain authority');
      }
    } catch (error) {
      console.error(error);
      setDomainAuthority(100);
    }
  };

  useEffect(() => {
    fetchThemes();
    fetchCountries();
    fetchLanguages();
    fetchMaxDomainAuthority();
  }, []);

  const handleResetAll = () => {
    setMoreThan10(false);
    setUrl('');
    setKeyword('');
    setLinkType('NormalFollow');
    setSentiment('Positive');
    setDomainAuthority('');
    setCountry('');
    setLanguage('');
    setTheme('');
    setBudget('');
    setIsExclusive(false);
    setRelatedKeywords([]);
    setLsiKeywords([]);
    setRankedKeywords([]);
    setRelatedUserKeywords([]);
    setLsiUserKeywords([]);
    setRankedUserKeywords([]);
    setSelectedRelatedKeywords([]);
    setSelectedLsiKeywords([]);
    setSelectedRankedKeywords([]);
    setKeywordsGenerated(false);
  };
  
  const handleAddKeyword = (type, e) => {
    if (e.key === 'Enter' && inputValue[type].trim()) {
      const newKeyword = inputValue[type].trim();
      
      // Check if keyword already exists in either original keywords or user keywords
      const isDuplicate = (() => {
        switch(type) {
          case 'related':
            return relatedKeywords.includes(newKeyword) || 
                   relatedUserKeywords.includes(newKeyword) ||
                   selectedRelatedKeywords.includes(newKeyword);
          case 'lsi':
            return lsiKeywords.includes(newKeyword) || 
                   lsiUserKeywords.includes(newKeyword) ||
                   selectedLsiKeywords.includes(newKeyword);
          case 'ranked':
            return rankedKeywords.includes(newKeyword) || 
                   rankedUserKeywords.includes(newKeyword) ||
                   selectedRankedKeywords.includes(newKeyword);
          default:
            return false;
        }
      })();
  
      if (isDuplicate) {
        // Optionally show an error message to user
        alert('This keyword already exists!');
        return;
      }
  
    // Prevent adding if there are already 10 selected keywords
    const selectedKeywords = 
          [...selectedRelatedKeywords, ...selectedLsiKeywords, ...selectedRankedKeywords];



    if (selectedKeywords.length == 10) {
      setMoreThan10(true);
      return;
    }

      // Add to user keywords list if not duplicate
      switch (type) {
        case 'related':
          setRelatedUserKeywords(prev => [...prev, newKeyword]);
          handleKeywordSelection(newKeyword, 'related', true);
          break;
        case 'lsi':
          setLsiUserKeywords(prev => [...prev, newKeyword]);
          handleKeywordSelection(newKeyword, 'lsi', true);
          break;
        case 'ranked':
          setRankedUserKeywords(prev => [...prev, newKeyword]);
          handleKeywordSelection(newKeyword, 'ranked', true);
          break;
      }
  
      // Clear input and hide it
      setInputValue({ ...inputValue, [type]: '' });
      setShowInput({ ...showInput, [type]: false });
    }
  };

  const handleDeleteKeyword = (type, keyword) => {
    switch (type) {
      case 'related':
        setRelatedUserKeywords(relatedUserKeywords.filter(k => k !== keyword));
        // Uncheck the keyword when deleting
        handleKeywordSelection(keyword, 'related', false);
        setMoreThan10(false);
        break;
      case 'lsi':
        setLsiUserKeywords(lsiUserKeywords.filter(k => k !== keyword));
        handleKeywordSelection(keyword, 'lsi', false);
        setMoreThan10(false);
        break;
      case 'ranked':
        setRankedUserKeywords(rankedUserKeywords.filter(k => k !== keyword));
        handleKeywordSelection(keyword, 'ranked', false);
        setMoreThan10(false);
        break;
    }
  };

  const renderKeywordInput = (type) => (
    <div className="mt-2">
      <div className="d-flex flex-wrap gap-2 mb-3 align-items-center"> {/* Keeps alignment consistent */}
        {/* User added keywords */}
        {(type === 'related' ? relatedUserKeywords :
          type === 'lsi' ? lsiUserKeywords :
          rankedUserKeywords).map((keyword) => (
          <span
            key={keyword}
            className="px-3 bg-primary text-white rounded-pill d-inline-flex align-items-center"
            style={{ height: '36px' }}  // Set consistent height for keywords
          >
            {keyword}
            <button
              onClick={() => handleDeleteKeyword(type, keyword)}
              className="d-flex align-items-center justify-content-center ms-2"
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'red',
                fontSize: '1.6rem',
                width: '24px',
                height: '24px',
                lineHeight: 1,
                borderRadius: '50%',
                cursor: 'pointer',
                transition: 'background-color 0.2s ease',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 0, 0, 0.1)'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            >
              ×
            </button>
          </span>
        ))}
        
        {/* Add keyword input inline with existing keywords */}
        {showInput[type] ? (
          <input
            type="text"
            value={inputValue[type]}
            onChange={(e) => setInputValue({ ...inputValue, [type]: e.target.value })}
            onKeyPress={(e) => handleAddKeyword(type, e)}
            placeholder="Press Enter to add"
            style={{
              maxWidth: '200px',
              height: '36px',  // Match height with keyword pills
              padding: '0 15px',
              borderRadius: '20px',
              border: '1px solid #ced4da',
            }}
            autoFocus
            className="ms-2"
          />
        ) : (
          <Button
            variant="primary"
            onClick={() => setShowInput({ ...showInput, [type]: true })}
            className="rounded-circle ms-2"
            style={{marginBottom:'15px', width: '30px', height: '30px', padding: 0 }}
          >
            +
          </Button>
        )}
      </div>
    </div>
  );
  


  const handleKeywordSelection = (keyword, type, isChecked) => {
    // Combine all selected keywords from each type
    const allSelectedKeywords = [
      ...selectedRelatedKeywords,
      ...selectedLsiKeywords,
      ...selectedRankedKeywords
    ];
  
    // Check if the total number of selected keywords is already 10
    if (isChecked && allSelectedKeywords.length >= 10) {
      setMoreThan10(true)
      return;
    }else setMoreThan10(false)
  
    switch(type) {
      case 'related':
        setSelectedRelatedKeywords(prev => 
          isChecked ? [...prev, keyword] : prev.filter(k => k !== keyword)
        );
        break;
      case 'lsi':
        setSelectedLsiKeywords(prev => 
          isChecked ? [...prev, keyword] : prev.filter(k => k !== keyword)
        );
        break;
      case 'ranked':
        setSelectedRankedKeywords(prev => 
          isChecked ? [...prev, keyword] : prev.filter(k => k !== keyword)
        );
        break;
      default:
        break;
    }
  };
  

  const ValidateGenerateKeyword = () => {
    const newErrors = {};
    if (!language) {
      newErrors.language = "Please select a language!";
    }
    if (!country) {
      newErrors.country = "Please select a country!";
    }
    if (!url) {
      newErrors.url = "Please type your url!";
    }
    if (!keyword) {
      newErrors.keyword = "Please type your keyword!";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }


  const validateForm = () => {
    const newErrors = {};

    if (!keywordsGenerated) {
      newErrors.keywords = 'Please generate keywords before proceeding';
    }

    if (!linkType) {
      newErrors.linkType = 'Please select a link type';
    }

    if (!sentiment) {
      newErrors.sentiment = 'Please select article sentiment';
    }


    if (domainAuthority && (Number(domainAuthority) <= 0 || isNaN(Number(domainAuthority)))) {
      newErrors.domainAuthority = 'Please enter a valid positive minimum domain authority';
    }
    if (errorMessageDA) {
      newErrors.domainAuthorityqqq = "Insert a lower domain authority!";
    }

    if (!country) {
      newErrors.country = 'Please select a country';
    }

    if (!language) {
      newErrors.language = "Please select a language!";
    }

    // if (!theme) {
    //   newErrors.theme = 'Please select a theme';
    // }

    // if (!budget) {
    //   newErrors.budget = 'Please enter your budget';
    // }

    const totalSelectedKeywords = selectedRelatedKeywords.length + 
                                selectedLsiKeywords.length + 
                                selectedRankedKeywords.length;
    
    if (totalSelectedKeywords === 0) {
      newErrors.selectedKeywords = 'Please select at least one keyword';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleGenerateKeywords = () => {

    if (!ValidateGenerateKeyword()) 
      return;

    console.log("Generating keywords for", url, keyword);
    
    // Fetch keywords and update the state
    const fetchKeywords = async () => {
      try {
        // Define parameters
        setLoadingKeywords(true);
        const languageCode = languageCodes[language] || "en";
        const languageName = language;
  
        let goodCountry;
        if (country === "United States of America") {
          goodCountry = "United States";
      }
      else{
        goodCountry = country;
      }

        // Perform parallel requests for the three API endpoints
        const [lsiResponse, relatedResponse, rankedResponse] = await Promise.all([
          // LSI endpoint with keyword and language as query parameters
          fetch(`${BASE_API_URL}Search/lsi?keyword=${encodeURIComponent(keyword)}&language=${encodeURIComponent(languageName)}&url=${encodeURIComponent(url)}`),
          
          // Related keywords endpoint with GET request and query parameters
          fetch(`${BASE_API_URL}api/Seo/bulkorder-related?keyword=${encodeURIComponent(keyword)}&locationName=${encodeURIComponent(goodCountry)}`),
    
          // Ranked keywords endpoint with target, languageName, and locationName as query parameters
          fetch(`${BASE_API_URL}api/Seo/bulk-order-ranked-keywords?target=${encodeURIComponent(url)}&languageName=${encodeURIComponent(languageName)}&locationName=${encodeURIComponent(goodCountry)}`)
        ]);
  
        // Await and parse JSON responses for each API call
        const lsiData = await lsiResponse.json();
        const relatedData = await relatedResponse.json();
        const rankedData = await rankedResponse.json();
  
        // Assuming the API returns data directly as arrays
        setLsiKeywords(lsiData || []);
        setRelatedKeywords(relatedData || []);
        setRankedKeywords(rankedData || []);

        setLsiUserKeywords([]);
        setRelatedUserKeywords([]);
        setRankedUserKeywords([]);

        setSelectedLsiKeywords([]);
        setSelectedRelatedKeywords([]);
        setSelectedRankedKeywords([]);
  
        // Set keywords generated to true
        setKeywordsGenerated(true);
        setLoadingKeywords(false);
      } catch (error) {
        console.error("Error fetching keywords:", error);

        setLsiKeywords([]);
        setRelatedKeywords([]);
        setRankedKeywords([]);

        setLsiUserKeywords([]);
        setRelatedUserKeywords([]);
        setRankedUserKeywords([]);

        setSelectedLsiKeywords([]);
        setSelectedRelatedKeywords([]);
        setSelectedRankedKeywords([]);

        setLoadingKeywords(false);
        // Use test data if any API call fails
        // setLsiKeywords(testLsiKeywords);
        // setRelatedKeywords(testRelatedKeywords);
        // setRankedKeywords(testRankedKeywords);
  
        // Set keywords generated to true even with test data
        setKeywordsGenerated(true);
      }
    };
  
    fetchKeywords();
  };
  
  
  
  

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Start loading when request begins
  
      const formData = {
        url,
        mainKeyword: keyword,
        linkType,
        sentiment,
        domainAuthority,
        country,
        language,
        theme: theme === "All Themes" ? null : theme,
        budget,
        isExclusive,
        selectedKeywords: {
          related: selectedRelatedKeywords,
          lsi: selectedLsiKeywords,
          ranked: selectedRankedKeywords,
        }
      };
  
      try {
        const requestData = {
          MainKeyword: keyword,
          TypeOfLink: linkType,
          Sentiment: sentiment,
          Country: country,
          Language: language,
          Theme: theme === "All Themes" ? null : theme,

          MinimumDomainAuthority: domainAuthority,
          Theme: theme,
          Keywords: [
            ...selectedRelatedKeywords,
            ...selectedLsiKeywords,
            ...selectedRankedKeywords,
          ], // Combine all selected keywords into a single array
        };
        const token = localStorage.getItem('token');
        // Step 1: POST request to /start to initiate the search job
        const startResponse = await fetch(`${BASE_API_URL}search/start`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });
  
        if (!startResponse.ok) {
          throw new Error("Failed to start search job");
        }
  
        const { jobId } = await startResponse.json();  // Get the jobId from the response
  
        // Step 2: Poll the status of the job until it is either completed or failed
        let jobStatus = 'Processing';
        let result = null;
        while (jobStatus === 'Processing') {
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before polling again
  
          const statusResponse = await fetch(`${BASE_API_URL}search/status/${jobId}`);
          const statusData = await statusResponse.json();
  
          jobStatus = statusData.status;
  
          if (jobStatus === 'Completed') {
            result = statusData.result;  // Get the result data
          } else if (jobStatus === 'Failed') {
            throw new Error(statusData.error || 'Job failed without an error message');
          }
        }
  
        // Step 3: Process the results (once completed)
        if (result) {
          const finalFormData = {
            ...formData,
            articlesByKeyword: result.Items,  // Assuming the result is structured correctly
          };
  
          setLoading(false);
  
          // Navigate to BulkOrder with the articles data
        }
  
      } catch (error) {
        setLoading(false);
        console.error("Error processing order:", error);
  
        // Navigate with the basic form data if there was an error
        navigate('/bulkorder', { state: formData });
      }
    }
  };
  
  
  

  // useEffect(() => {
  //   const fetchKeywords = async () => {
  //     try {
  //       const response = await fetch('YOUR_API_ENDPOINT'); // Replace with your API endpoint
  //       const data = await response.json();

  //       // Assuming the API returns an object with properties for related, LSI, and ranked keywords
  //       setRelatedKeywords(data.related || []);
  //       setLsiKeywords(data.lsi || []);
  //       setRankedKeywords(data.ranked || []);
  //     } catch (error) {
  //       console.error("Error fetching keywords:", error);
  //       setRelatedKeywords(testRelatedKeywords);
  //       setLsiKeywords(testLsiKeywords);
  //       setRankedKeywords(testRankedKeywords);
  //     }
  //   };

  //   fetchKeywords();
  // }, [url, keyword]); // Re-fetch when URL or keyword changes

  return (
    <PageLayout pageType='bulkorder1'>
      <Container fluid style={{ maxWidth: '100%', margin: '0 auto', padding: '0 20px' }}>
        {/* Progress Steps */}
        <ProgressSteps steps={['Keyword & Strategy Details', 'Filtered websites and articles', 'Order Confirmation & details']} currentStep={1} />

        {/* Form Header */}
        <h4 className="my-4">Automatic selection of the websites:</h4>

        {/* URL and Keyword Inputs */}
        <Form.Group className="mb-3" style={{ maxWidth: '75%' }}>
          <Row className="align-items-start">
            <Col xs={12} md={4} className="mb-2">
              <Form.Label>Add your URL:*</Form.Label>
              <Form.Control
                type="text"
                className={`w-100 form-control ${errors && errors.url ? 'is-invalid' : ''}`}
                placeholder="Add your URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              {errors.url && <div className="text-danger">{errors.url}</div>}
            </Col>
            <Col xs={12} md={4} className="mb-2">
              <Form.Label style={{ whiteSpace: "nowrap" }}>
                Keyword you want to optimize for:*
              </Form.Label>
              <Form.Control
                type="text"
                className={`w-100 form-control ${errors && errors.keyword ? 'is-invalid' : ''}`}
                placeholder="Enter your keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              {errors.keyword && <div className="text-danger">{errors.keyword}</div>}
            </Col>
          </Row>
          <Row className="align-items-start">
            <Col xs={12} md={4} className="mb-2">
              <Form.Label>Country*</Form.Label>
              <Form.Select
              disabled={loadingCountries}
              lassName={`w-100 form-control ${errors && errors.country ? 'is-invalid' : ''}`} value={country} onChange={(e) => setCountry(e.target.value)}>
        <option value="">Select Country</option>
        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </Form.Select>
              {errors.country && <div className="text-danger">{errors.country}</div>}
            </Col>
            <Col xs={12} md={4} className="mb-2">
              <Form.Label style={{ whiteSpace: "nowrap" }}>
                Language*
              </Form.Label>
              <Form.Select
      className={`w-100 form-control ${errors && errors.language ? 'is-invalid' : ''}`}
      id="language"
      name="language"
      value={language || ''}
      disabled={loadingLanguages}
      onChange={(e) => setLanguage(e.target.value)}
      required
    >
      <option value="">Choose your language</option>
      {languages.map((lang, index) => (
        <option key={index} value={lang}>
          {lang}
        </option>
      ))}
    </Form.Select>
{errors.language && <div className="text-danger">{errors.language}</div>}

            </Col>
          </Row>
          <Row className="align-items-center">
          <Col xs={12} md={4} className="d-flex align-items-center justify-content-start mb-2">
              <Button 
                variant="primary" 
                onClick={handleGenerateKeywords} 
                disabled={loadingKeywords}
                className="rounded shadow"
                style={{
                  whiteSpace: 'normal',
                  flex: '0 0 auto',
                  maxWidth: '80%',
                }}
              >
                 {loadingKeywords ? "Researching..." : "Research Keywords"} {/* Toggle button text */}
              </Button>
            </Col>
            <Col xs={12} md={4} className="d-flex align-items-center justify-content-start mb-2">
            <div
  className="d-flex align-items-center"
  style={{
    whiteSpace: 'nowrap', // Ensure text stays on a single line
    cursor: 'pointer',    // Add pointer cursor to indicate interactivity
    color: '#007bff',     // Use a primary color for text
    textDecoration: 'underline', // Optional: make it look like a link
    fontSize: '18px',     // Adjust text size if needed
    marginTop: '10px'
  }}
  onClick={handleResetAll} // Trigger reset on click
>
  <RefreshCw size={16} style={{ marginRight: '8px' }} /> {/* Icon */}
  Reset Inputs
</div>


  </Col>
          </Row>
          {errors.keywords && <div className="text-danger mt-2">{errors.keywords}</div>}
        </Form.Group>

        {/* Keyword Tabs */}
        {keywordsGenerated && (
          <Container fluid className="px-0">
            <div style={{ backgroundColor: '#EEF2EF', padding: '15px', borderRadius: '8px', marginBottom: '20px', Width: '80%', maxWidth:'100vw' }}>
              <Tabs defaultActiveKey="related" id="keyword-tabs" className="mb-3">
              <Tab eventKey="related" title="Related Keywords">
  <Form.Text className="mb-3 d-block">
    These are closely connected to your main keyword and relevant for SEO optimization.
  </Form.Text>
  <div className="mt-3" style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
  {relatedKeywords.map((kw, index) => (
    <label
      key={`related-${index}`}
      className="d-flex align-items-center"
      style={{ cursor: 'pointer', marginLeft: '10px', paddingRight: '10px' }}
    >
      <Form.Check
        inline
        type="checkbox"
        checked={selectedRelatedKeywords.includes(kw)}
        onChange={(e) => handleKeywordSelection(kw, 'related', e.target.checked)}
        className="me-3"
        style={{ whiteSpace: 'normal' }}
      />
      <span>{kw}</span>
    </label>
  ))}
</div>

  {renderKeywordInput('related')}
  {/* Render keyword input inline with existing keywords */}
</Tab>
          <Tab eventKey="lsi" title="LSI Keywords">
            <Form.Text>LSI Keywords...</Form.Text>
            <div className="mt-3" style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
  {lsiKeywords.map((kw, index) => (
    <label
      key={`lsi-${index}`}
      className="d-flex align-items-center"
      style={{ cursor: 'pointer', marginLeft: '10px', paddingRight: '10px' }}
    >
      <Form.Check
        inline
        type="checkbox"
        checked={selectedLsiKeywords.includes(kw)}
        onChange={(e) => handleKeywordSelection(kw, 'lsi', e.target.checked)}
        className="me-3"
        style={{ whiteSpace: 'normal' }}
      />
      <span>{kw}</span>
    </label>
  ))}
</div>

            {renderKeywordInput('lsi')}
            <Form.Text>*Warning: These keywords are AI-generated and may require further refinement or validation to meet specific requirements.</Form.Text>
          </Tab>
          
          <Tab eventKey="ranked" title="Keywords you already rank for">
            <Form.Text>Keywords you rank for...</Form.Text>
            <div className="mt-3" style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
  {rankedKeywords.map((kw, index) => (
    <label
      key={`ranked-${index}`}
      className="d-flex align-items-center"
      style={{ cursor: 'pointer', marginLeft: '10px', paddingRight: '10px' }}
    >
      <Form.Check
        inline
        type="checkbox"
        checked={selectedRankedKeywords.includes(kw)}
        onChange={(e) => handleKeywordSelection(kw, 'ranked', e.target.checked)}
        className="me-3"
        style={{ whiteSpace: 'normal' }}
      />
      <span>{kw}</span>
    </label>
  ))}
</div>

            {renderKeywordInput('ranked')}
          </Tab>
              </Tabs>
              {moreThan10 && <div className="text-danger">You can not select more than 10 keywords!</div>}
            </div>
          </Container>
        )}
        {errors.selectedKeywords && <div className="text-danger">{errors.selectedKeywords}</div>}

        {/* Exclusivity Checkbox */}
        {/* <Form.Group className="mb-3">
          <Form.Check 
            type="checkbox" 
            id="exclusivity-checkbox"
            label="I want Exclusivity (+40% of the base price)"
            checked={isExclusive}
            onChange={(e) => setIsExclusive(e.target.checked)}
            className="mb-2"
          />
          <Form.Text>
            <span style={{display: 'block', paddingTop: '10px'}}>
              By selecting this option, your chosen keyword will be exclusive to your link within the article, ensuring no other advertiser
            </span>
            <span style={{ display: 'block' }}>
              can use the same keyword in this content.
            </span>
          </Form.Text>
        </Form.Group> */}

        {/* Link Type Selection */}
        <Form.Group className="mb-3">
          <Form.Label>Type of links you want*</Form.Label>
          <Form.Select
      value={linkType}
      onChange={(e) => setLinkType(e.target.value)}
      style={{ maxWidth: "21%" }}
    >
      <option value="">Select Link Type</option>
      {Object.keys(linkTypes).map((key) => (
        <option key={key} value={key}>
          {linkTypes[key]}
        </option>
      ))}
    </Form.Select>
          {errors.linkType && <div className="text-danger">{errors.linkType}</div>}
          <Form.Text>
            <span style={{display: 'block', paddingTop: '10px'}}>
              Choose the type of link you want to insert. Dofollow links pass SEO value (link juice) to the target site, helping improve
            </span>
            <span style={{ display: 'block' }}>
              search rankings. Nofollow links do not pass SEO value but can still drive traffic and build brand awareness.
            </span>
            <span style={{display: 'block', paddingBottom: '10px' }}>
              Sponsored links indicate paid content, and UGC (User Generated Content) links are typically found in user comments or forum posts.
            </span>
          </Form.Text>
        </Form.Group>

        {/* Sentiment Selection */}
        <Form.Group className="mb-3">
          <Form.Label>Sentiment of the article (AI-powered option):</Form.Label>
          <Form.Select value={sentiment} onChange={(e) => setSentiment(e.target.value)} style={{maxWidth: '21%'}}>
            <option value="Positive">Positive</option>
            <option value="Neutral">Neutral</option>
            <option value="Negative">Negative</option>
          </Form.Select>
          {errors.sentiment && <div className="text-danger">{errors.sentiment}</div>}
          <Form.Text>
            <span style={{display: 'block', paddingTop: '10px'}}>
              Select the desired tone for your article. Our AI analyzes the content to ensure it aligns with your chosen sentiment (e.g.,
            </span>
            <span style={{display: 'block', paddingBottom: '10px' }}>
              Positive, Neutral, or Negative). This helps tailor the context of your link to better suit your brand messaging.
            </span>
          </Form.Text>
        </Form.Group>

        {/* Basic Filters */}
        <Form.Group className="mb-3" style={{maxWidth:'100%'}}>
          <Form.Label>Basic filters:</Form.Label>
          <Row>
            <Col xs={12} md={4}>
            <Form.Label>Minimum Domain Authority:</Form.Label>
            {maxDomainAuthority == null ? (
            <Form.Control
  type="number"
  placeholder="Minimum Domain Authority"
  value={''}
  disabled={true}
  onChange={(e) => {
    
    // Check if value is either empty or a valid number between 0 and 100
    const value = e.target.value;

    if (value === '' || (/^\d+$/.test(value) && Number(value) >= 0 && Number(value) <= 100 && Number(value) <= maxDomainAuthority)) {
      setDomainAuthority(value);
      setErrorMessageDA('');
    }
    else {
      if (Number(value) > 100)
        return;
      setDomainAuthority(value);
      setErrorMessageDA(`There is no domain yet with authority ${value}. Please choose a minimum domain authority between 1 and ${maxDomainAuthority}.`);
    }
  }}
  min="0"
  max="100"
/>
            ) : (
            <Form.Control
  type="number"
  placeholder="Minimum Domain Authority"
  value={domainAuthority}
  onChange={(e) => {
    
    // Check if value is either empty or a valid number between 0 and 100
    const value = e.target.value;

    if (value === '' || (/^\d+$/.test(value) && Number(value) >= 0 && Number(value) <= 100 && Number(value) <= maxDomainAuthority)) {
      setDomainAuthority(value);
      setErrorMessageDA('');
    }
    else {
      if (Number(value) > 100)
        return;
      setDomainAuthority(value);
      setErrorMessageDA(`There is no domain yet with authority ${value}. Please choose a minimum domain authority between 1 and ${maxDomainAuthority}.`);
    }
  }}
  min="0"
  max="100"
/>)}
              {errors.domainAuthority && <div className="text-danger">{errors.domainAuthority}</div>}
              {errorMessageDA && <div className="text-danger">{errorMessageDA}</div>}
            </Col>

            {/* <Col xs={12} md={4}>
            <Form.Select value={country} onChange={(e) => setCountry(e.target.value)}>
        <option value="">Select Language</option>
        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </Form.Select>
              {errors.country && <div className="text-danger">{errors.country}</div>}
            </Col> */}
            <Col xs={12} md={4}>
            <Form.Label>Site Theme:</Form.Label>
            <Form.Select disabled={loadingThemes} value={theme} onChange={(e) => setTheme(e.target.value)}>
      <option value="">All Themes</option>
      {themes.map((category, idx) => (
        <optgroup label={category.label} key={idx}>
          {category.options.map((option) => (
                        <option 
                        key={option.name} 
                        value={option.name}
                      >
                        {option.name} ({option.count})
                      </option>
          ))}
        </optgroup>
      ))}
    </Form.Select>
              {/* {errors.theme && <div className="text-danger">{errors.theme}</div>} */}
            </Col>
          </Row>
        </Form.Group>

        {/* Budget */}
        <Form.Group className="mb-4" style={{ maxWidth: '60%' }}>
          <Form.Label>Budget:</Form.Label>
          <InputGroup className="align-items-center">
            <Form.Control
              type="text"
              placeholder="What's your budget?"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
            {/* <div className="ms-3 text-success">
              Suggested budget based on your selection:
              <div className="font-weight-bold">${suggestedBudget} / Month</div>
            </div> */}
          </InputGroup>
          {errors.budget && <div className="text-danger">{errors.budget}</div>}
        </Form.Group>

        {/* Process Order Button */}
        <div className="d-flex justify-content-start">
        <Button
        variant="primary"
        onClick={handleSubmit}
        disabled={
          loading || 
          !(
            selectedLsiKeywords?.length || 
            selectedRankedKeywords?.length || 
            selectedRelatedKeywords?.length
          )
        }// Disable button when loading
        className="w-auto"
      >
        {loading ? "Processing..." : "PROCESS MY ORDER"} {/* Toggle button text */}
      </Button>
      
        </div>
        <Form.Text>
            <span style={{display: 'block', paddingTop: '10px'}}>
            You need to select or input keywords for the selection process to work.
            </span>
          </Form.Text>
      </Container>
    </PageLayout>
  );
};

export default BulkOrder1;