import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import MyLineChart from './components/LineChart';
import {
    Table,
    Badge,
    UncontrolledDropdown,
    DropdownItem,
    Input
} from "reactstrap";
import { useAuth } from './authService';
import { Link, useNavigate } from 'react-router-dom';
import DomainKeyModal from './DomainKeyModal';  // Import the modal component
import { BASE_API_URL } from './config/constants';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner';

const PublisherStep2 = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [secretKeys, setSecretKeys] = useState({}); // To store secret keys
    const [secretKeyWasGenerated, setSecretKeyWasGenerated] = useState(false); //TODO: maybe better way

    // Edit modal state
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedDomainData, setSelectedDomainData] = useState(null);

    // Delete modal state
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedDomainId, setSelectedDomainId] = useState(null);

    const fetchData = async () => {
        setLoading(true); // Start loading
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }
    
            const domainResponse = await axios.get(`${BASE_API_URL}api/userdomains/publisher-get`, {           
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const domains = domainResponse.data;
    
            const keyResponse = await axios.get(`${BASE_API_URL}api/userdomains/get-secret-keys`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const keys = keyResponse.data;
    
            const keyMap = keys.reduce((acc, keyObj) => {
                acc[keyObj.domain] = keyObj.secretKey;
                return acc;
            }, {});
    
            const parsedData = domains.map(item => ({
                ...item,
                secretKey: keyMap[item.domain] || 'N/A'
            }));
            setData(parsedData);
            setSecretKeys(keyMap);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to fetch data. Please try again later.'); // User feedback
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchData();
        } else {
            navigate('/login');
        }
    }, [isLoggedIn, navigate, secretKeyWasGenerated]);

    const toggleModal = (domain = '') => {
        setSelectedDomain(domain);
        setModalOpen(!modalOpen);
    };

    // const toggleEditModal = (domainId) => {
    //     console.log(`edit/${domainId}`);
    //     if (domainId) {
    //         navigate(`edit/${domainId}`); // Navigate to edit page with the domainId
    //     }
    // };

    const toggleDeleteModal = (domainId = null) => {
        setSelectedDomainId(domainId);
        setDeleteModalOpen(!deleteModalOpen);
    };

    const ActionButtonEdit = ({ domainId }) => {
        console.log(domainId)
        return (
            <div className="d-flex">
                <Link to={`/edit/${domainId}`} className="btn btn-info">
                    Edit
                </Link>
            </div>
        );
    };
    
     const ActionButton = ({ domain, domainId }) => {
        return (
            <div className="d-flex">
                <UncontrolledDropdown>
                    <div className="d-flex gap-2">
                        <DropdownItem className="text-center" onClick={() => toggleModal(domain)}>
                            <Badge color="info">Generate secret key</Badge>
                        </DropdownItem>
                        <DropdownItem className="text-center">
                        <Badge color="warning">
    <a href={`/edit/${domainId}`} style={{ color: 'inherit', textDecoration: 'none' }}>Edit</a>
</Badge>
                        </DropdownItem>
                        {/* <DropdownItem className="text-center" onClick={() => toggleDeleteModal(domainData.id)}>
                            <Badge color="danger">Delete</Badge>
                        </DropdownItem> */}
                    </div>
                </UncontrolledDropdown>
            </div>
        );
    };

    return (
        <>
        <PageLayout>
            <div className='container3'>
                <div className="main2 ">
                    <div className="header">
                        <h5>Publisher</h5>
                    </div>
                    <p>(website owner, blog owner, corporate websites, personal websites)</p>
                    {loading ? ( // Show spinner while loading
                        <MySpinner/>
                    ) : data.length > 0 ? (
                        <>
                            <h6 className='my-4'>Keywords in your publisher network</h6>
                            <div className='bg-primary p-2 rounded text-white' style={{ width: '150vh'}}>Keyword</div>
                            <div className="table-responsive" style={{ width: '150vh', maxHeight: 'none' }}>
                            <Table style={{ width: '100%', textAlign: 'center' }}>
    <thead>
        <tr>
            <th scope="col" style={{ textAlign: 'center' }}>#</th>
            {/* <th scope="col" style={{ textAlign: 'center' }}>Description</th> */}
            <th scope="col" style={{ textAlign: 'center' }}>Website</th>
            <th scope="col" style={{ textAlign: 'center' }}>Monthly Revenue / Link</th>
            <th scope="col" style={{ textAlign: 'center' }}>Intervention needed</th>
            <th scope="col" style={{ textAlign: 'center' }}>Secret key</th>
            <th scope="col" style={{ textAlign: 'center' }}>Action</th>
        </tr>
    </thead>
    <tbody>
        {data.map((item, index) => (
            <tr key={index}>
                <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                {/* <td style={{ 
    textAlign: 'center', 
    maxWidth: '30vw',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflow: 'hidden'
}} >{item.description}</td> */}
                <td style={{ textAlign: 'center' }}>{item.domain}</td>
                <td style={{ textAlign: 'center' }}>{item.price} USD + VAT</td>
                <td style={{ textAlign: 'center' }}>{/* Intervention needed value */}</td>
                <td style={{ textAlign: 'center' }}>
                    <Input
                        type="text"
                        value={item.secretKey}
                        readOnly
                        className="cursor-pointer"
                        onClick={() => navigator.clipboard.writeText(item.secretKey)}
                        title="Click to copy"
                    />
                    <small
                        className="text-muted cursor-pointer"
                        onClick={() => navigator.clipboard.writeText(item.secretKey)}
                    >
                        Click to copy
                    </small>
                </td>
                <td style={{ textAlign: 'center' }}>
                    <ActionButton domain={item.domain} domainId={item.id}/>
                </td>
            </tr>
        ))}
    </tbody>
</Table>

                            </div>
                        </>
                    ) : (
                        <div className="text-center my-5">
                            <p>No data found.</p>
                        </div>
                    )}
                    {/* <div className='my-5 chartHead'>
                        <h6 className='my-4'>Income last 30 days / link as a service concept</h6>
                        <div className='text-center'>Keyword Evolution vs. Revenue Over 30 Days</div >
                    </div>
                    <MyLineChart /> */}
                </div>
            </div>
            <DomainKeyModal isOpen={modalOpen} toggle={toggleModal} domain={selectedDomain} setWasGenerated={setSecretKeyWasGenerated} />
        </PageLayout>
        </>
    );
}

export default PublisherStep2;
