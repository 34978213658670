// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-domain-page {
    padding: 0px 20px 20px 20px;
}


.edit-domain-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow for wrapping on small screens */
}

.edit-domain-column {
    flex: 1 1; /* Make the column take equal width */
    margin-right: 20px;
    min-width: 300px; /* Minimum width for each column */
}

.form-container {
    margin-bottom: 20px; /* Spacing between form sections */
}

@media (max-width: 768px) {
    .column {
        margin-right: 0; /* Remove margin on small screens */
        margin-bottom: 20px; /* Spacing between rows */
    }
}

.edit-domain-disabled-input {
    background-color: #f0f0f0; /* Light grey background */
    color: #888; /* Grey text */
    cursor: not-allowed; /* Change cursor to indicate it's not editable */
    border: 1px solid #ccc; /* Light border */
}

`, "",{"version":3,"sources":["webpack://./src/PublisherWebsitesModals/EditDomainPage.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;;AAGA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe,EAAE,wCAAwC;AAC7D;;AAEA;IACI,SAAO,EAAE,qCAAqC;IAC9C,kBAAkB;IAClB,gBAAgB,EAAE,kCAAkC;AACxD;;AAEA;IACI,mBAAmB,EAAE,kCAAkC;AAC3D;;AAEA;IACI;QACI,eAAe,EAAE,mCAAmC;QACpD,mBAAmB,EAAE,yBAAyB;IAClD;AACJ;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;IACrD,WAAW,EAAE,cAAc;IAC3B,mBAAmB,EAAE,gDAAgD;IACrE,sBAAsB,EAAE,iBAAiB;AAC7C","sourcesContent":[".edit-domain-page {\n    padding: 0px 20px 20px 20px;\n}\n\n\n.edit-domain-row {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap; /* Allow for wrapping on small screens */\n}\n\n.edit-domain-column {\n    flex: 1; /* Make the column take equal width */\n    margin-right: 20px;\n    min-width: 300px; /* Minimum width for each column */\n}\n\n.form-container {\n    margin-bottom: 20px; /* Spacing between form sections */\n}\n\n@media (max-width: 768px) {\n    .column {\n        margin-right: 0; /* Remove margin on small screens */\n        margin-bottom: 20px; /* Spacing between rows */\n    }\n}\n\n.edit-domain-disabled-input {\n    background-color: #f0f0f0; /* Light grey background */\n    color: #888; /* Grey text */\n    cursor: not-allowed; /* Change cursor to indicate it's not editable */\n    border: 1px solid #ccc; /* Light border */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
