import React, { useEffect, useState } from 'react';
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Spinner,
    Container,
    Row,
    Col
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import { BASE_API_URL } from '../src/config/constants';
import './AdvertiserStep1.css';
import AdvertiserStep1PageLayout from './components/AdvertiserStep1PageLayout';
import PageLayout from './components/PageLayout';

const AdvertiserStep1 = () => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [filters, setFilters] = useState({
        domainName: '',
        theme: '',
        country: '',
        typeOfPortal: '',
        region: '',
        minUniqueUsers: '',
        dofollowLinks: false,
        typeOfLinks: '',
        numberOfLinks: '',
        priceFrom: '',
        priceTo: '',
        attractivePrice: false,
        portalQuality: '',
        favorites: false,
        customDomainAuthorityMin: '0',
        customDomainAuthorityMax: '100',
    });
    const [uniqueCountries, setUniqueCountries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    
    // Add loading state
    const [loading, setLoading] = useState(true);

    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'asc'
    });

    // Add a new state for storing all data
    const [filteredData, setFilteredData] = useState([]);

    // Add vatRate state near the top with other state declarations
    const [vatRate, setVatRate] = useState(0);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_API_URL}api/UserDomains/get-all-userdomains-for-advertiser`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setAllData(jsonData);

            // Extract unique countries from data
            const countries = [...new Set(jsonData.map(entry => entry.country))];
            setUniqueCountries(countries);
            
            // Initial filtering
            applyFilters(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Add this function after fetchData and before the useEffect hooks
    const applyFilters = (dataToFilter = allData) => {
        let filtered = [...dataToFilter];

        // Domain Authority filter
        if (filters.customDomainAuthorityMin || filters.customDomainAuthorityMax) {
            const min = parseInt(filters.customDomainAuthorityMin) || 0;
            const max = parseInt(filters.customDomainAuthorityMax) || 100;
            filtered = filtered.filter(item => {
                const da = parseInt(item.priceResult?.domainAuthority || item.domainAuthority) || 0;
                return da >= min && da <= max;
            });
        }

        // Domain name filter
        if (filters.domainName) {
            const searchTerm = filters.domainName.toLowerCase();
            filtered = filtered.filter(item => 
                item.domain.toLowerCase().includes(searchTerm) ||
                (item.theme && item.theme.toLowerCase().includes(searchTerm))
            );
        }

        // Country filter
        if (filters.country) {
            filtered = filtered.filter(item => 
                item.country === filters.country
            );
        }

        // Unique users filter
        if (filters.minUniqueUsers) {
            const minUsers = parseInt(filters.minUniqueUsers.replace(/[^0-9]/g, '')) || 0;
            filtered = filtered.filter(item => {
                const users = parseInt(String(item.uniqueUsers).replace(/[^0-9]/g, '')) || 0;
                return users >= minUsers;
            });
        }

        // Price range filters
        if (filters.priceFrom || filters.priceTo) {
            filtered = filtered.filter(item => {
                const price = item.priceResult?.basePrice || 0;
                const fromPrice = filters.priceFrom ? parseFloat(filters.priceFrom) : 0;
                const toPrice = filters.priceTo ? parseFloat(filters.priceTo) : Infinity;
                return price >= fromPrice && price <= toPrice;
            });
        }

        setData(filtered);
        setCurrentPage(1); // Reset to first page when filters change
    };

    // Update useEffect to fetch data only once
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array means it only runs once

    // Update useEffect to handle filter changes
    useEffect(() => {
        applyFilters();
    }, [filters, allData]); // Run whenever filters or allData change

    // Update handleSearch to use client-side filtering
    const handleSearch = () => {
        applyFilters();
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters({ ...filters, [name]: checked });
    };

    const handleChooseClick = (entry) => {
        setSelectedEntry(entry);
        setModalVisible(true);
    };

    const ArrowSpan = () => {
        return (
            <span className='row h-25'>
                <FontAwesomeIcon icon={faCaretUp} size='m' style={{ color: "black" }} />
                <FontAwesomeIcon icon={faCaretDown} size='m' style={{ color: "black" }} />
            </span>
        );
    };

    const handlePageChange = (pageNumber) => {
        console.log(`Changing page to: ${pageNumber}`);
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    console.log(`Current Page: ${currentPage}, Items per Page: ${itemsPerPage}`);
    console.log(`Index of First Item: ${indexOfFirstItem}, Index of Last Item: ${indexOfLastItem}`);
    console.log(`Current Items: ${currentItems.length}`);

    const priceStyle = {
        fontSize: '1.25rem'
    };

    const handleSort = (columnIndex) => {
        console.log('Sorting column:', columnIndex);
        setSortConfig(prevConfig => {
            const isSameColumn = prevConfig.key === columnIndex;
            const newDirection = isSameColumn && prevConfig.direction === 'asc' ? 'desc' : 'asc';
            console.log('New sort config:', { key: columnIndex, direction: newDirection });
            return {
                key: columnIndex,
                direction: newDirection
            };
        });
    };

    const getSortedItems = (items) => {
        if (!sortConfig.key && sortConfig.key !== 0) return items;

        return [...items].sort((a, b) => {
            let aValue, bValue;

            switch (sortConfig.key) {
                case 0: // Portal
                    aValue = String(a.domain || '').toLowerCase();
                    bValue = String(b.domain || '').toLowerCase();
                    break;
                case 1: // Unique Users
                    aValue = parseInt(String(a.uniqueUsers || '0').replace(/[^0-9]/g, '')) || 0;
                    bValue = parseInt(String(b.uniqueUsers || '0').replace(/[^0-9]/g, '')) || 0;
                    break;
                case 2: // Dofollow
                    aValue = a.dofollow ? 1 : 0;
                    bValue = b.dofollow ? 1 : 0;
                    break;
                case 3: // Traffic Tracking
                    aValue = a.trafficTracking ? 1 : 0;
                    bValue = b.trafficTracking ? 1 : 0;
                    break;
                case 4: // Domain Authority
                    aValue = parseInt(a.priceResult?.domainAuthority || a.domainAuthority) || 0;
                    bValue = parseInt(b.priceResult?.domainAuthority || b.domainAuthority) || 0;
                    break;
                case 5: // Price
                    aValue = parseFloat(a.priceResult?.basePrice) || 0;
                    bValue = parseFloat(b.priceResult?.basePrice) || 0;
                    break;
                default:
                    return 0;
            }

            const compareResult = typeof aValue === 'string'
                ? aValue.localeCompare(bValue)
                : aValue - bValue;

            return sortConfig.direction === 'asc' ? compareResult : -compareResult;
        });
    };

    const renderSortableHeader = (title, index) => (
        <div 
            className={`header-content ${
                sortConfig.key === index 
                    ? sortConfig.direction === 'asc'
                        ? 'sort-asc'
                        : 'sort-desc'
                    : ''
            }`} 
            onClick={(e) => {
                e.stopPropagation();
                handleSort(index);
            }}
        >
            {title}
        </div>
    );

    const renderPrice = (entry) => (
        <small style={priceStyle} className='me-2'>
            {entry.priceResult?.basePrice != null
                ? `${entry.priceResult.basePrice}$`
                : 'Loading...'} /month
        </small>
    );

    // Add useEffect to fetch VAT rate
    useEffect(() => {
        const fetchVatRate = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${BASE_API_URL}api/StaticValues/get-vat`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch VAT rate');
                }
                
                const rate = await response.json();
                setVatRate(rate);
            } catch (error) {
                console.error('Error fetching VAT rate:', error);
                setVatRate(0);
            }
        };

        fetchVatRate();
    }, []);

    return (
        <PageLayout>
            <div className="advertiser-step1-container">
                <Row className="g-3">
                    <Col xs={12}>
                        <div className="header">
                            <h5 className='mx-3'>Advertiser</h5>
                        </div>
                        <p className='mx-3'>
                            (you will be able to buy links in already existing native articles - the most valuable ones)
                        </p>
                        <h5 className='mt-4 mx-3'>Choose a publication</h5>
                        <div className='inputDiv'>
                            <h5>Basic filters</h5>
                            <div className="form-group2">
                                <div className="input-row">
                                    <input
                                        name='domainName'
                                        placeholder='Domain website name, tag'
                                        type="text"
                                        value={filters.domainName}
                                        onChange={handleInputChange}
                                    />
                                    <select
                                        name='country'
                                        value={filters.country}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">All countries</option>
                                        {uniqueCountries.map((country, index) => (
                                            <option key={index} value={country}>{country}</option>
                                        ))}
                                    </select>
                                    <input
                                        name='minUniqueUsers'
                                        placeholder='Min. unique users'
                                        type="text"
                                        value={filters.minUniqueUsers}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="input-row justify-content-center">
                                    <div className="domain-authority-input" style={{ maxWidth: '400px' }}>
                                        <label className="domain-authority-label">Domain Authority</label>
                                            <div className="custom-range-inputs">
                                                <div className="range-values">
                                                    <small>{filters.customDomainAuthorityMin}</small>
                                                    <small>{filters.customDomainAuthorityMax}</small>
                                                </div>
                                                <div className="range-sliders">
                                                    <input
                                                        type="range"
                                                        min="0"
                                                        max="100"
                                                        value={filters.customDomainAuthorityMin}
                                                        onChange={(e) => {
                                                            const value = parseInt(e.target.value);
                                                            const max = parseInt(filters.customDomainAuthorityMax);
                                                            if (value <= max) {
                                                                setFilters(prev => ({
                                                                    ...prev,
                                                                    customDomainAuthorityMin: e.target.value
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                    <input
                                                        type="range"
                                                        min="0"
                                                        max="100"
                                                        value={filters.customDomainAuthorityMax}
                                                        onChange={(e) => {
                                                            const value = parseInt(e.target.value);
                                                            const min = parseInt(filters.customDomainAuthorityMin);
                                                            if (value >= min) {
                                                                setFilters(prev => ({
                                                                    ...prev,
                                                                    customDomainAuthorityMax: e.target.value
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="input-row">
                                    <input
                                        name='priceFrom'
                                        placeholder='Price from'
                                        type="text"
                                        value={filters.priceFrom}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        name='priceTo'
                                        placeholder='Price to'
                                        type="text"
                                        value={filters.priceTo}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {/* <div className="search-button-container">
                                    <button 
                                        onClick={handleSearch} 
                                        type="button" 
                                        className="btn btn-primary search-button"
                                    >
                                        Search
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className='adverTable'>
                            {data.length > itemsPerPage && !loading && (
                                <div className="pagination-dropdown mb-3">
                                    <div className="d-flex align-items-center gap-2">
                                        <button 
                                            className="btn btn-outline-primary"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        
                                        <select 
                                            className="pagination-select"
                                            value={currentPage}
                                            onChange={(e) => handlePageChange(Number(e.target.value))}
                                        >
                                            {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => (
                                                <option key={index + 1} value={index + 1}>
                                                    Page {index + 1} of {Math.ceil(data.length / itemsPerPage)}
                                                </option>
                                            ))}
                                        </select>
                                        
                                        <button 
                                            className="btn btn-outline-primary"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="table-responsive overflow-visible table-wrapper">
                                {loading ? (
                                    <div className="spinner-container">
                                        <Spinner style={{ width: '3rem', height: '3rem', color: 'blue' }} />
                                    </div>
                                ) : (
                                    <>
                                        <div className="desktop-view">
                                            <Table hover bordered responsive className="advertiser-table">
                                                <thead style={{ verticalAlign: 'middle' }}>
                                                    <tr>
                                                        <th scope="col">
                                                            <div className="column-content">
                                                                {renderSortableHeader('Portal', 0)}
                                                                <span className='d-flex flex-column'>
                                                                    <small style={{ fontWeight: 'lighter' }}>
                                                                        Type <br />
                                                                        Country: Region<br />
                                                                        Theme
                                                                    </small>
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Unique Users', 1)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Dofollow', 2)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Traffic Tracking', 3)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Domain Authority', 4)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Price / Month', 5)}
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getSortedItems(currentItems).map((entry, index) => (
                                                        <tr key={index}>
                                                            <td className='border-right align-middle portal-cell-advertiser'>
                                                                <div className="portal-content-advertiser">
                                                                    <h5>{entry.domain}</h5>
                                                                    <div className="portal-details-advertiser">
                                                                        <small>{entry.websiteType}</small>
                                                                        <small>{entry.country}</small>
                                                                        <small>{entry.theme}</small>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <small>{entry.uniqueUsers}</small>
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <FontAwesomeIcon icon={entry.dofollow ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.dofollow ? "red" : "#21c442", marginRight: '3px' }} />
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <FontAwesomeIcon icon={entry.trafficTracking ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.trafficTracking ? "#21c442" : "red", marginRight: '3px' }} />
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <small>{entry.priceResult?.domainAuthority || entry.domainAuthority || 0}</small>
                                                            </td>
                                                            <td className='border-left align-middle text-center'>
                                                                {renderPrice(entry)}
                                                                <button 
                                                                    className='mt-0 btn btn-primary btn-sm btn-add' 
                                                                    onClick={() => handleChooseClick(entry)}
                                                                >
                                                                    Add
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                        <div className="mobile-view">
                                            {getSortedItems(currentItems).map((entry, index) => (
                                                <div key={index} className="advertiser-mobile-card">
                                                    <div className="advertiser-mobile-header">
                                                        <h3 className="advertiser-mobile-title">{entry.domain}</h3>
                                                    </div>
                                                    
                                                    <div className="advertiser-mobile-details">
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Type</span>
                                                            <span className="advertiser-mobile-value">{entry.websiteType}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Country</span>
                                                            <span className="advertiser-mobile-value">{entry.country}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Theme</span>
                                                            <span className="advertiser-mobile-value">{entry.theme}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Unique Users</span>
                                                            <span className="advertiser-mobile-value">{entry.uniqueUsers}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Dofollow</span>
                                                            <span className="advertiser-mobile-value">
                                                                <FontAwesomeIcon 
                                                                    icon={entry.dofollow ? faCircleCheck : faCircleXmark} 
                                                                    style={{ color: entry.dofollow ? "red" : "#21c442" }} 
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Traffic Tracking</span>
                                                            <span className="advertiser-mobile-value">
                                                                <FontAwesomeIcon 
                                                                    icon={entry.trafficTracking ? faCircleCheck : faCircleXmark} 
                                                                    style={{ color: entry.trafficTracking ? "#21c442" : "red" }} 
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Domain Authority</span>
                                                            <span className="advertiser-mobile-value">
                                                                {entry.priceResult?.domainAuthority || entry.domainAuthority || 0}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="advertiser-mobile-price">
                                                        <span className="advertiser-mobile-price-value">
                                                            {entry.priceResult?.basePrice != null
                                                                ? `${entry.priceResult.basePrice}$ /month`
                                                                : 'Loading...'}
                                                        </span>
                                                        <button 
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => handleChooseClick(entry)}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="text-end mt-2" style={{ paddingRight: '98px', fontSize: '1.50rem' }}>
                            {!loading && <small><em>*prices without VAT</em></small>}
                        </div>
                    </Col>
                </Row>
            </div>
            {modalVisible && (
                <Modal 
                    entry={selectedEntry} 
                    onClose={() => setModalVisible(false)} 
                    vatRate={vatRate}  // Pass vatRate to Modal
                />
            )}
        </PageLayout>
    );
}

export default AdvertiserStep1;
