import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../authService';  // Assuming useAuth returns token
import { jwtDecode } from 'jwt-decode';
import { BASE_API_URL } from '../config/constants';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');

    const getUserIdFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.nameid; // Adjust based on your token's structure
        } catch (error) {
            console.error("Invalid token", error);
            return null;
        }
    };

    const fetchNotifications = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${BASE_API_URL}api/notification/user/${getUserIdFromToken(token)}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setNotifications(response.data);
          setLoading(false);
        } catch (err) {
          setError('Failed to fetch notifications.');
          setLoading(false);
        }
      };

    useEffect(() => {
        fetchNotifications(); // Fetch when token is available
    }, [token]);

    return (
        <NotificationContext.Provider value={{ notifications, setNotifications, fetchNotifications, loading, error }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useNotifications must be used within a NotificationProvider");
    }
    return context;
};
