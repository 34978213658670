import React, { useState, useEffect } from 'react';
import PageLayout from './components/PageLayout';
import { Alert } from 'react-bootstrap';
import MySpinner from './components/MySpinner';
import { BASE_API_URL } from './config/constants';
import { jwtDecode } from 'jwt-decode';
import './MyAccount.css';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const MyAccount = () => {
  const location = useLocation();
  const initialTab = location.state?.activeTab || 'Account Info';
  
  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState(''); //bootstrap alert
  const token = localStorage.getItem('token');
  const getUserName = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.sub; // Assuming 'sub' contains the username
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const [activeTab, setActiveTab] = useState(initialTab);
  
  // Update activeTab when location.state changes
  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const [accountInfo, setAccountInfo] = useState({
    fullName: '',
    phone: '',
    country: 'Romania', // Default country can be set to one of the fetched countries
  });

  const [email, setEmail] = useState({
    current: getUserName(token),
    new: ''
  });

  const [password, setPassword] = useState({
    current: '',
    new: '',
    confirm: ''
  });

  const [countries, setCountries] = useState([]);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);

  // Validation states
  const [errors, setErrors] = useState({
    fullName: '',
    phone: '',
    country: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}api/myaccount/1`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAccountInfo(data);
        } else {
          console.error('Failed to fetch account info');
        }
      } catch (error) {
        console.error('Error fetching account info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccountInfo();
  }, [token]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}api/StaticValues/countries`);
        if (response.ok) {
          const countriesData = await response.json();
          setCountries(countriesData);
        } else {
          console.error('Failed to fetch countries');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e, section) => {
    if (section === 'accountInfo') {
      setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
      // Clear the corresponding error when input changes
      setErrors({ ...errors, [e.target.name]: '' });
    } else if (section === 'email') {
      setEmail({ ...email, [e.target.name]: e.target.value });
      // Clear the email error
      setErrors({ ...errors, email: '' });
    } else if (section === 'password') {
      setPassword({ ...password, [e.target.name]: e.target.value });
      // Clear password errors
      if (e.target.name === 'current') {
        setErrors({ ...errors, currentPassword: '' });
      } else if (e.target.name === 'new') {
        setErrors({ ...errors, newPassword: '' });
      } else if (e.target.name === 'confirm') {
        setErrors({ ...errors, confirmPassword: '' });
      }
    }
  };

  const handleSaveAccountInfo = async (e) => {
    e.preventDefault();
    let formIsValid = true;

    // Validate necessary fields
    const newErrors = {};
    if (!accountInfo.fullName) {
      newErrors.fullName = 'Full Name is required.';
      formIsValid = false;
    }
    if (!accountInfo.phone) {
      newErrors.phone = 'Phone is required.';
      formIsValid = false;
    }

    if (!accountInfo.country) {
      newErrors.country = 'Country is required';
      formIsValid = false;
    }

    setErrors(newErrors); // Set errors state

    if (!formIsValid) {
      return; // Stop if validation fails
    }

    try {
      const response = await fetch(`${BASE_API_URL}api/myaccount/1`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ ...accountInfo }),
      });

      if (response.status === 200) {
        setSuccess("Account info has been saved!");
      } else {
        const errorData = await response.json();
        setError(`Error: ${errorData?.message || "Failed to save account info"}`);
      }
    } catch (error) {
      setError("Error saving account info");
      console.error('Error saving account info:', error);
    }
  };

  const handleSaveEmail = async (e) => {
    e.preventDefault();

    // Validate that the new email is not empty
    if (!email.new) {
      setErrors({ ...errors, email: 'New email cannot be empty.' });
      return;
    }

    const payload = {
      newEmail: email.new
    };

    try {
      const response = await fetch(`${BASE_API_URL}api/myaccount/email`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 200) {
        setSuccess("Email was saved!");
        setTimeout(() => {window.location.href = '/login';;
          localStorage.removeItem('token');}, 2000);
        setEmail({ ...email, new: '' });
      } else {
        const errorData = await response.json();
        setError(`Error: ${errorData?.message || "Failed to save email"}`);
      }
    } catch (error) {
      setError("Error saving email!");
      console.error('Error saving email:', error);
    }
  };

  const handleSavePassword = async (e) => {
    e.preventDefault();
    let formIsValid = true;

    // Validate that new password and confirm password match
    const newErrors = {};
    if (password.new !== password.confirm) {
      newErrors.confirmPassword = 'New password and confirm password do not match.';
      formIsValid = false;
    }
    if (!password.current) {
      newErrors.currentPassword = 'Current password is required.';
      formIsValid = false;
    }
    if (!password.new) {
      newErrors.newPassword = 'New password is required.';
      formIsValid = false;
    }

    setErrors(newErrors); // Set errors state

    if (!formIsValid) {
      return; // Stop if validation fails
    }

    const payload = {
      email: getUserName(token),  // Ensure this function returns the correct email
      currentPassword: password.current,
      newPassword: password.new
    };

    try {
      const response = await fetch(`${BASE_API_URL}api/authentication/set-new-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 200) {
        setSuccess("New password has been set!");
        setPassword({
          current: '',
          new: '',
          confirm: ''
        });
      } else {
        const errorData = await response.json();
        setError(`Error: ${errorData?.message || "Failed to set new password"}`);
      }
    } catch (error) {
      setError("Error saving password!");
      console.error('Error saving password:', error);
    }
  };

  return (
    <PageLayout>
      <div className="my-account-page">
        <div className="content" style={{'margin-top': '-20px'}}>
          <h5>My Account</h5>
          
          <div className="tabs">
            <button 
              className={activeTab === 'Account Info' ? 'active' : ''}
              onClick={() => handleTabClick('Account Info')}
            >
              Account Info
            </button>
            <button 
              className={activeTab === 'Email' ? 'active' : ''}
              onClick={() => handleTabClick('Email')}
            >
              Email
            </button>
            <button 
              className={activeTab === 'Password' ? 'active' : ''}
              onClick={() => handleTabClick('Password')}
            >
              Password
            </button>
          </div>

          {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}

          {isLoading ? (
            <MySpinner />
          ) : (
            <div className="form">
              {activeTab === 'Account Info' && (
                <form onSubmit={handleSaveAccountInfo}>
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="fullName"
                      value={accountInfo.fullName}
                      onChange={(e) => handleInputChange(e, 'accountInfo')}
                      className={`form-control ${errors && errors.fullName ? 'is-invalid' : ''}`}
                    />
                    {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
                  </div>

                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="tel"
                      name="phone"
                      value={accountInfo.phone}
                      onChange={(e) => handleInputChange(e, 'accountInfo')}
                      className={`form-control ${errors && errors.phone ? 'is-invalid' : ''}`}
                    />
                    {errors.phone && <div className="text-danger">{errors.phone}</div>}
                  </div>

                  <div className="form-group">
                    <label>Country</label>
                    <select name="country" value={accountInfo.country} onChange={(e) => handleInputChange(e, 'accountInfo')} className={`form-control ${errors && errors.country ? 'is-invalid' : ''}`}>
                      <option value="">Select Country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>{country}</option>
                      ))}
                    </select>
                    {errors.country && <div className="text-danger">{errors.country}</div>}
                  </div>

                  <button type="submit" className="save-btn">Save Account Info</button>
                </form>
              )}

              {activeTab === 'Email' && (
                <form onSubmit={handleSaveEmail}>
                  <div className="form-group">
                    <label>Current Email</label>
                    <input
                      type="email"
                      value={email.current}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label>New Email</label>
                    <input
                      type="email"
                      name="new"
                      value={email.new}
                      onChange={(e) => handleInputChange(e, 'email')}
                      className={errors.email ? 'error' : ''}
                    />
                    {errors.email && <div className="text-danger">{errors.email}</div>}
                  </div>

                  <button type="submit" className="save-btn">Save Email</button>
                </form>
              )}

              {activeTab === 'Password' && (
                <form onSubmit={handleSavePassword}>
                  <div className="form-group">
                    <label>Current Password</label>
                    <input
                      type="password"
                      name="current"
                      value={password.current}
                      onChange={(e) => handleInputChange(e, 'password')}
                      className={errors.currentPassword ? 'error' : ''}
                    />
                    {errors.currentPassword && <div className="text-danger">{errors.currentPassword}</div>}
                  </div>

                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      type="password"
                      name="new"
                      value={password.new}
                      onChange={(e) => handleInputChange(e, 'password')}
                      className={errors.newPassword ? 'error' : ''}
                    />
                    {errors.newPassword && <div className="text-danger">{errors.newPassword}</div>}
                  </div>

                  <div className="form-group">
                    <label>Confirm New Password</label>
                    <input
                      type="password"
                      name="confirm"
                      value={password.confirm}
                      onChange={(e) => handleInputChange(e, 'password')}
                      className={errors.confirmPassword ? 'error' : ''}
                    />
                    {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                  </div>

                  <button type="submit" className="save-btn">Save Password</button>
                </form>
              )}

              {activeTab === 'Security' && (
                <div className="security-settings">
                  <h3>Two-Factor Authentication</h3>
                  <label>
                    <input
                      type="checkbox"
                      checked={twoFactorAuth}
                      onChange={() => setTwoFactorAuth(!twoFactorAuth)}
                    />
                    Enable Two-Factor Authentication
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default MyAccount;
