// PublisherDetails.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Mail, Cog, Check, Ban, Trash, Globe, Link, DollarSign, Key } from 'lucide-react';

const StatBox = ({ title, value, trend }) => (
  <div className="card bg-light shadow-sm">
    <div className="card-body p-4">
      <h6 className="text-muted text-sm mb-2">{title}</h6>
      <h3 className="mb-1">{value}</h3>
      <small className={`text-${trend?.type || 'muted'}`}>{trend?.text}</small>
    </div>
  </div>
);

export default function PublisherDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const publisher = location.state?.publisher || {};

  const [activeTab, setActiveTab] = useState('domains');
  const isEditMode = publisher?.mode === 'edit';

  // Fetch publisher details based on ID (mock data used here)
  const publisherData = {
    id: id || 'PUB-12345',
    name: publisher?.name || 'Jane Smith',
    email: publisher?.email || 'jane@example.com',
    joinDate: 'Jan 15, 2024',
    status: 'active',
    stats: {
      totalDomains: 156,
      totalLinks: 234,
      totalRevenue: 45678,
      totalTraffic: '2.5M',
      responseTime: '2h',
      successRate: '98%'
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="container-fluid p-0">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center gap-3">
          <button 
            className="btn btn-outline-secondary"
            onClick={handleBack}
          >
            <ArrowLeft className="w-4 h-4 me-2" />
            Back to Publishers
          </button>
          <h2 className="mb-0">
            {isEditMode ? 'Edit Publisher' : 'Publisher Details'}
          </h2>
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-outline-primary">
            <Mail className="w-4 h-4 me-2" />
            Contact Publisher
          </button>
          <div className="dropdown">
            <button 
              className="btn btn-primary dropdown-toggle" 
              data-bs-toggle="dropdown"
            >
              <Cog className="w-4 h-4 me-2" />
              Actions
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  <Check className="w-4 h-4 me-2" />
                  Approve Domain
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <Ban className="w-4 h-4 me-2" />
                  Suspend Account
                </a>
              </li>
              <li>
                <a className="dropdown-item text-danger" href="#">
                  <Trash className="w-4 h-4 me-2" />
                  Delete Account
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Publisher Overview Card */}
      <div className="card shadow-sm mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-4">
                <div className="bg-primary rounded-circle p-3 text-white me-3">
                  <span className="h4 mb-0">{publisherData.name.charAt(0)}</span>
                </div>
                <div>
                  <h4 className="mb-1">{publisherData.name}</h4>
                  <p className="mb-0 text-muted">{publisherData.email}</p>
                </div>
                <span className="badge bg-success ms-3">Active</span>
              </div>
              <div className="row g-3">
                <div className="col-6">
                  <p className="mb-1 text-muted">Publisher ID</p>
                  <p className="fw-medium">{publisherData.id}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1 text-muted">Join Date</p>
                  <p className="fw-medium">{publisherData.joinDate}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1 text-muted">Total Domains</p>
                  <p className="fw-medium">{publisherData.stats.totalDomains}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1 text-muted">Total Links</p>
                  <p className="fw-medium">{publisherData.stats.totalLinks}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row g-3">
                <div className="col-6">
                  <StatBox
                    title="Total Revenue"
                    value={`$${publisherData.stats.totalRevenue.toLocaleString()}`}
                    trend={{ type: 'success', text: '+15% this month' }}
                  />
                </div>
                <div className="col-6">
                  <StatBox
                    title="Total Traffic"
                    value={publisherData.stats.totalTraffic}
                    trend={{ type: 'success', text: 'All domains' }}
                  />
                </div>
                <div className="col-6">
                  <StatBox
                    title="Avg. Response Time"
                    value={publisherData.stats.responseTime}
                    trend={{ type: 'success', text: 'Within SLA' }}
                  />
                </div>
                <div className="col-6">
                  <StatBox
                    title="Success Rate"
                    value={publisherData.stats.successRate}
                    trend={{ type: 'success', text: 'Order completion' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Navigation */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'domains' ? 'active' : ''}`}
            onClick={() => setActiveTab('domains')}
          >
            <Globe className="w-4 h-4 me-2" />
            Domains
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'activeLinks' ? 'active' : ''}`}
            onClick={() => setActiveTab('activeLinks')}
          >
            <Link className="w-4 h-4 me-2" />
            Active Links
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'earnings' ? 'active' : ''}`}
            onClick={() => setActiveTab('earnings')}
          >
            <DollarSign className="w-4 h-4 me-2" />
            Earnings
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'secretKeys' ? 'active' : ''}`}
            onClick={() => setActiveTab('secretKeys')}
          >
            <Key className="w-4 h-4 me-2" />
            Secret Keys
          </button>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="card shadow-sm border-top-0 rounded-top-0">
        <div className="card-body p-4">
          {activeTab === 'domains' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Registered Domains</h5>
                <button className="btn btn-primary">
                  <Globe className="w-4 h-4 me-2" />
                  Add New Domain
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Domain</th>
                      <th>Rating</th>
                      <th>Traffic</th>
                      <th>Active Links</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="fw-medium">example.com</div>
                        <small className="text-muted">Added Jan 15, 2024</small>
                      </td>
                      <td>DR 65</td>
                      <td>
                        <div>850K</div>
                        <small className="text-success">+5% MoM</small>
                      </td>
                      <td>45</td>
                      <td><span className="badge bg-success">Active</span></td>
                      <td>
                        <button className="btn btn-sm btn-outline-secondary">
                          <Cog className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                    {/* Add more domain rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {activeTab === 'activeLinks' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Active Links</h5>
                <div className="d-flex gap-2">
                  <button className="btn btn-outline-primary">Export</button>
                  <button className="btn btn-primary">Add New Link</button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>URL</th>
                      <th>Domain</th>
                      <th>Placement Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="fw-medium">https://example.com/post-1</div>
                        <small className="text-muted">Blog Post</small>
                      </td>
                      <td>example.com</td>
                      <td>Jan 20, 2024</td>
                      <td><span className="badge bg-success">Active</span></td>
                      <td>
                        <button className="btn btn-sm btn-outline-secondary">
                          <Cog className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                    {/* Add more active link rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {activeTab === 'earnings' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Earnings History</h5>
                <button className="btn btn-outline-primary">Export Report</button>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Links Added</th>
                      <th>Total Revenue</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>January 2024</td>
                      <td>23</td>
                      <td>$4,567</td>
                      <td><span className="badge bg-success">Paid</span></td>
                    </tr>
                    {/* Add more earnings history rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {activeTab === 'secretKeys' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">API Keys</h5>
                <button className="btn btn-primary">Generate New Key</button>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Key Name</th>
                      <th>Created</th>
                      <th>Last Used</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="fw-medium">Production Key</div>
                        <small className="text-muted">****-****-****-5678</small>
                      </td>
                      <td>Jan 15, 2024</td>
                      <td>2 hours ago</td>
                      <td><span className="badge bg-success">Active</span></td>
                      <td>
                        <div className="d-flex gap-2">
                          <button className="btn btn-sm btn-outline-secondary">
                            <Cog className="w-4 h-4" />
                          </button>
                          <button className="btn btn-sm btn-outline-danger">
                            <Trash className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    {/* Add more API key rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
