import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../Footer';
import './PageLayout.css';

//TODO FIX DESIGN, design for bulkorder1 is good

const PageLayout = ({ children }) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    // Set initial sidebar state based on screen size
    const shouldOpenSidebar = window.innerWidth >= 992;
    setIsSidebarOpen(shouldOpenSidebar);
    if (shouldOpenSidebar) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
    setIsInitialLoad(false);

    return () => {
      document.body.classList.remove('sidebar-open');
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth >= 992;
      setIsSidebarOpen(isDesktop);
      if (isDesktop) {
        document.body.classList.add('sidebar-open');
      } else {
        document.body.classList.remove('sidebar-open');
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Update body class whenever sidebar state changes
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (window.innerWidth < 992) {
      document.body.style.overflow = !isSidebarOpen ? 'hidden' : '';
    }
  };

  return (
    <div className="app-container">
      <Navbar toggleSidebar={toggleSidebar} />
      <div className={`page-content ${isInitialLoad ? 'initial-load' : ''}`}>
        <Sidebar />
        <main className={`main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default PageLayout;