import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const ArrowSpan = () => {
    return (
        <span className='row h-25'>
            <FontAwesomeIcon icon={faCaretUp} size='m' style={{ color: "black" }} />
            <FontAwesomeIcon icon={faCaretDown} size='m' style={{ color: "black" }} />
        </span>
    );
};

export default ArrowSpan;