import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService'; // Ensure this is your auth service import
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faInfoCircle,
  faPlug,
  faCheckCircle,
  faRobot,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import './Download.css'; // Import the CSS file
import PageLayout from './components/PageLayout';

const Download = () => {
  return (
    <PageLayout>
      <div className="download-page">
        <h1 className="download-title">
          <FontAwesomeIcon icon={faDownload} className="icon" /> Download AdverLink Plugin
        </h1>
        
        <div className="download-card">
          <p className="download-intro">
            Enhance your sales strategy by integrating AdverLink with your WordPress site. Follow these steps:
          </p>
          
          <div className="download-sections">
            {/* Steps Section */}
            <div className="download-section steps-section">
              <h2 className="section-title">
                <FontAwesomeIcon icon={faPlug} className="icon" /> Installation Steps
              </h2>
              <ol className="steps-list">
                <li>Download the .zip file.</li>
                <li>In WordPress, go to Plugins → Add New → Upload Plugin.</li>
                <li>Select the downloaded .zip file and install it.</li>
                <li>Activate the plugin once installation is complete.</li>
                <li>
                  <strong>Activate Plugin:</strong> To complete activation, enter your Secret Key found in the Your Websites section on AdverLink. 
                  Copy the key for the correct domain and paste it into WordPress.
                </li>
                <li>Access the AdverLink menu from the left panel in WordPress to check for new orders.</li>
              </ol>
            </div>

            {/* Auto-Generate Section */}
            <div className="download-section auto-generate-section">
              <h2 className="section-title">
                <FontAwesomeIcon icon={faRobot} className="icon" /> Auto-Generate Articles with Adverlink
              </h2>
              <p>
                If no content matches the requested keyword in an order, use the AdverLink plugin to automatically generate a relevant article and insert the link seamlessly. Just generate, review, personalise and post it! Then get paid!
              </p>
            </div>

            {/* Download Section */}
            <div className="download-section download-button-section">
              <div className="download-button-container">
                <a 
                  href="https://download.adverlink.net/download/adverlink.zip" 
                  className="download-button"
                >
                  <FontAwesomeIcon icon={faDownload} className="icon" />
                  Download
                </a>
                <div className="download-tooltip-container">
                  <FontAwesomeIcon 
                    icon={faInfoCircle} 
                    className="download-info-icon"
                  />
                  <div className="download-tooltip">
                    <p>Click to download the AdverLink plugin as a .zip file. Do not unzip the file before uploading it to WordPress.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Download;
