import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import PageLayout from './components/PageLayout';
import targetUrlImg from './assets/images/targeturl.png';
import MySpinner from './components/MySpinner';
import { Alert } from 'react-bootstrap';
import './LinksReports.css';

const LinksReports = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [linksReports, setLinksReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedCard, setExpandedCard] = useState(null);
  const [filters, setFilters] = useState({
    keyword: '',
    url: '',
    title: '',
    status: 'all',
    monthlyValue: '',
    linkType: 'all',
    exclusivity: 'all',
    domainAuthority: '',
    creationDate: '',
    domain: '',
    selectedDomain: 'all'
  });

  const [uniqueDomains, setUniqueDomains] = useState([]);

  useEffect(() => {
    const fetchLinksReports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BASE_API_URL}api/linksreports/get-links-for-publisher`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data && Array.isArray(response.data)) {
          setLinksReports(response.data);
          setFilteredReports(response.data);

          const domains = [...new Set(response.data.map(link => {
            const url = link.url || '';
            return url.replace(/^https?:\/\//, '').replace(/^www\./, '').split('/')[0];
          }))].sort();
          
          setUniqueDomains(domains);
          setError('');
        } else {
          setError("No data received from server");
        }
      } catch (error) {
        if (error.response) {
          setError(`Server error: ${error.response.data}`);
        } else if (error.request) {
          setError("No response received from server");
        } else {
          setError("Failed to fetch links reports: " + error.message);
        }
        console.error("Error fetching links reports:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLinksReports();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    let result = linksReports;

    if (filters.keyword) {
      result = result.filter(link => 
        link.keyword.toLowerCase().includes(filters.keyword.toLowerCase())
      );
    }

    if (filters.url) {
      result = result.filter(link => 
        link.url.toLowerCase().includes(filters.url.toLowerCase())
      );
    }

    if (filters.title) {
      result = result.filter(link => 
        link.title.toLowerCase().includes(filters.title.toLowerCase())
      );
    }

    if (filters.status !== 'all') {
      result = result.filter(link => 
        link.isActive === (filters.status === 'active')
      );
    }

    if (filters.monthlyValue) {
      result = result.filter(link => 
        link.monthlyValue <= parseFloat(filters.monthlyValue)
      );
    }

    if (filters.linkType !== 'all') {
      result = result.filter(link => 
        link.typeOfLink === filters.linkType
      );
    }

    if (filters.exclusivity !== 'all') {
      result = result.filter(link => 
        link.exclusivity === (filters.exclusivity === 'yes')
      );
    }

    if (filters.domainAuthority) {
      result = result.filter(link => 
        link.domainAuthority >= parseInt(filters.domainAuthority)
      );
    }

    if (filters.creationDate) {
      result = result.filter(link => 
        new Date(link.dateCreated).toISOString().split('T')[0] === filters.creationDate
      );
    }

    if (filters.selectedDomain !== 'all') {
      result = result.filter(link => {
        const domain = (link.url || '').replace(/^https?:\/\//, '').replace(/^www\./, '').split('/')[0];
        return domain === filters.selectedDomain;
      });
    }

    if (filters.domain) {
      result = result.filter(link => {
        const domain = (link.url || '').replace(/^https?:\/\//, '').replace(/^www\./, '').split('/')[0];
        return domain.toLowerCase().includes(filters.domain.toLowerCase());
      });
    }

    setFilteredReports(result);
  }, [filters, linksReports]);

  const toggleExpandCard = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  return (
    <PageLayout pageType="linksreports">
      <div className="links-reports-container" style={{'margin-left': '10px'}}>
        <h5 style={{'margin-left': '10px'}}>Links Reports</h5>
        
        <div className="links-reports-filters">
          <div className="links-reports-filters-row">
            <input
              type="text"
              name="keyword"
              placeholder="Filter by keyword"
              value={filters.keyword}
              onChange={handleFilterChange}
              className="links-reports-filter-input"
            />
            <input
              type="text"
              name="url"
              placeholder="Filter by URL"
              value={filters.url}
              onChange={handleFilterChange}
              className="links-reports-filter-input"
            />
            <input
              type="text"
              name="title"
              placeholder="Filter by title"
              value={filters.title}
              onChange={handleFilterChange}
              className="links-reports-filter-input"
            />
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="links-reports-filter-select"
            >
              <option value="all">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          <div className="links-reports-filters-row">
            <input
              type="number"
              name="monthlyValue"
              placeholder="Max monthly value"
              value={filters.monthlyValue}
              onChange={handleFilterChange}
              className="links-reports-filter-input"
            />
            <select
              name="linkType"
              value={filters.linkType}
              onChange={handleFilterChange}
              className="links-reports-filter-select"
            >
              <option value="all">All Link Types</option>
              <option value="Follow">Follow</option>
              <option value="NoFollow">NoFollow</option>
              <option value="UGC">UGC</option>
              <option value="Sponsored">Sponsored</option>
            </select>
            <select
              name="exclusivity"
              value={filters.exclusivity}
              onChange={handleFilterChange}
              className="links-reports-filter-select"
            >
              <option value="all">All Exclusivity</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <input
              type="number"
              name="domainAuthority"
              placeholder="Min domain authority"
              value={filters.domainAuthority}
              onChange={handleFilterChange}
              className="links-reports-filter-input"
            />
            <input
              type="date"
              name="creationDate"
              value={filters.creationDate}
              onChange={handleFilterChange}
              className="links-reports-filter-input"
            />
          </div>

          <div className="links-reports-filters-row">
            <input
              type="text"
              name="domain"
              placeholder="Filter by domain"
              value={filters.domain}
              onChange={handleFilterChange}
              className="links-reports-filter-input"
            />
            <select
              name="selectedDomain"
              value={filters.selectedDomain}
              onChange={handleFilterChange}
              className="links-reports-filter-select"
            >
              <option value="all">All Domains</option>
              {uniqueDomains.map(domain => (
                <option key={domain} value={domain}>
                  {domain}
                </option>
              ))}
            </select>
          </div>
        </div>

        {error && (
          <Alert variant="danger" onClose={() => setError('')} dismissible>
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="success" onClose={() => setSuccess('')} dismissible>
            {success}
          </Alert>
        )}
        {isLoading ? (
          <MySpinner />
        ) : (
          <div className="links-reports-card-container">
            {filteredReports.length === 0 ? (
              <p>No links reports available.</p>
            ) : (
              filteredReports.map((link, index) => (
                <div 
                  className="links-reports-card" 
                  key={index}
                  onClick={() => toggleExpandCard(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="links-reports-card-body">
                    <div className="links-reports-card-header">
                      <div className="links-reports-header-left">
                        <h5 className="links-reports-card-title">
                          {link.keyword} 
                          <span className="links-reports-title">({link.title})</span>
                          {link.targetUrl && (
                            <a 
                              href={link.targetUrl} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="links-reports-target-icon"
                            >
                              <img 
                                src={targetUrlImg} 
                                alt="Target url" 
                                title={link.targetUrl} 
                              />
                            </a>
                          )}
                          <span className="links-reports-link-type">{link.typeOfLink}</span>
                          <span className="links-reports-date">{link.dateCreated}</span>
                        </h5>
                      </div>
                      <div className="links-reports-header-right">
                        <span className="links-reports-monthly-value">${link.monthlyValue}</span>
                        <span className={`links-reports-status ${link.isActive ? 'links-reports-status-active' : 'links-reports-status-inactive'}`}>
                          {link.isActive ? 'Active' : 'Inactive'}
                        </span>
                        <span className="links-reports-expand-icon">
                          {expandedCard === index ? '−' : '+'}
                        </span>
                      </div>
                    </div>
                    
                    {expandedCard === index && (
                      <div 
                        className="links-reports-card-details"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="links-reports-info-group">
                          <p className="links-reports-card-text">
                            <strong>URL:</strong> 
                            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                          </p>
                          <p className="links-reports-card-text">
                            <strong>Your domain:</strong> 
                            <a href={link.publisherWebsite} target="_blank" rel="noopener noreferrer">
                              {link.publisherWebsite}
                            </a>
                          </p>
                          <p className="links-reports-card-text">
                            <strong>Exclusivity:</strong> {link.exclusivity ? 'Yes' : 'No'}
                          </p>
                          <p className="links-reports-card-text">
                            <strong>Domain Authority:</strong> {link.domainAuthority}
                          </p>
                          <p className="links-reports-card-text">
                            <strong>Occurrences:</strong> {link.occurences}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default LinksReports;
