import React from 'react';

const AdminSpinner = ({ size = 'md' }) => {
    const sizeClass = size === 'sm' ? 'spinner-border-sm' : '';
    
    return (
        <div className={`spinner-border ${sizeClass} text-primary`} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );
};

export default AdminSpinner; 