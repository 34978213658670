import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import './styles/Invoices.css';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner';
import { Alert, Table, Form, Container, Card } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const Invoices = () => {
  const [error, setError] = useState(''); // State to manage error messages
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [filters, setFilters] = useState({
    id: '',
    series: '',
    number: '',
    date: '',
    currency: ''
  });

  const navigate = useNavigate();

  // Fetch invoices data
  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_API_URL}api/billing/get-all-invoices-by-email`, {
        params: { email: getUserName(token) }
      });

      if (response.status === 200) {
        setInvoices(response.data);
        setFilteredInvoices(response.data);
      } else {
        setError('Failed to fetch invoices'); // Update error state
      }
    } catch (error) {
      console.error('Error fetching invoices:', error);
      setError('Error fetching invoices'); // Update error state
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Update filters and filter invoices
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    filterInvoices({ ...filters, [name]: value });
  };

  // Function to filter invoices based on filter values
  const filterInvoices = (filters) => {
    const filtered = invoices.filter((invoice) => {
      return (
        invoice.facturi_key.toLowerCase().includes(filters.id.toLowerCase()) &&
        invoice.facturi_serie.toLowerCase().includes(filters.series.toLowerCase()) &&
        invoice.facturi_serie_numar.toLowerCase().includes(filters.number.toLowerCase()) &&
        invoice.facturi_data.toLowerCase().includes(filters.date.toLowerCase()) &&
        invoice.facturi_moneda.toLowerCase().includes(filters.currency.toLowerCase())
      );
    });
    setFilteredInvoices(filtered);
  };

  // Function to handle PDF download
  const getInvoicePdf = async (invoice) => {
    try {
      const requestUrl = `${BASE_API_URL}api/Billing/get-invoice-pdf?` +
        `Met=FacturiHtml&` +
        `Act=Facturi&` +
        `Id_fact=${encodeURIComponent(invoice.facturi_key)}&` +
        `Serie=${encodeURIComponent(invoice.facturi_serie)}&` +
        `Nr=${encodeURIComponent(invoice.facturi_serie_numar)}&` +
        `Select_language=1&` +
        `Select_currency=${encodeURIComponent(invoice.facturi_moneda)}&` +
        `Select_format=1`;

      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/pdf'
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || response.statusText);
      }

      // Check if the response is actually a PDF
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/pdf')) {
        throw new Error('Received invalid response format');
      }

      const blob = await response.blob();
      if (blob.size === 0) {
        throw new Error('Received empty PDF file');
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      
      const fileName = `Adverlink-Invoice-${invoice.facturi_serie}-${invoice.facturi_serie_numar}-${formatDate(invoice.facturi_data)}.pdf`;
      link.download = fileName;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      
    } catch (error) {
      console.error("Error fetching invoice PDF:", error);
    }
  };

  // Add function to format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    try {
      // Split the date and time
      const [datePart] = dateString.split(' ');
      
      // Split the date parts (from DD-MM-YYYY)
      const [day, month, year] = datePart.split('-');
      
      // Return formatted date in YYYY-MM-DD format
      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  return (
    <PageLayout pageType="invoices">
      <Container fluid className="px-4 py-0">
        <Card className="invoices-card shadow-sm">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5 className="mb-0">Invoices</h5>
            </div>

            {error && (
              <Alert variant="danger" onClose={() => setError('')} dismissible>
                {error}
              </Alert>
            )}

            {loading ? (
              <div className="text-center py-5">
                <MySpinner />
              </div>
            ) : (
              <>
                <div className="invoices-desktop-view">
                  <div className="invoices-table-wrapper">
                  <Table hover bordered className="invoices-table shadow-sm">
                    <thead className="bg-light">
                      <tr>
                        <th>
                          <div className="mb-2">ID</div>
                          <Form.Control
                            className="invoices-filter-input"
                            type="text"
                            name="id"
                            value={filters.id}
                            onChange={handleFilterChange}
                            placeholder="Filter by ID"
                            size="sm"
                          />
                        </th>
                        <th>
                          <div className="mb-2">Series</div>
                          <Form.Control
                            className="invoices-filter-input"
                            type="text"
                            name="series"
                            value={filters.series}
                            onChange={handleFilterChange}
                            placeholder="Filter by Series"
                            size="sm"
                          />
                        </th>
                        <th>
                          <div className="mb-2">Number</div>
                          <Form.Control
                            className="invoices-filter-input"
                            type="text"
                            name="number"
                            value={filters.number}
                            onChange={handleFilterChange}
                            placeholder="Filter by Number"
                            size="sm"
                          />
                        </th>
                        <th>
                          <div className="mb-2">Date</div>
                          <Form.Control
                            className="invoices-filter-input"
                            type="text"
                            name="date"
                            value={filters.date}
                            onChange={handleFilterChange}
                            placeholder="Filter by Date"
                            size="sm"
                          />
                        </th>
                        <th>
                          <div className="mb-2">Currency</div>
                          <Form.Control
                            className="invoices-filter-input"
                            type="text"
                            name="currency"
                            value={filters.currency}
                            onChange={handleFilterChange}
                            placeholder="Filter by Currency"
                            size="sm"
                          />
                        </th>
                        <th>
                          <div className="mb-2">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredInvoices.map((invoice) => (
                        <tr key={invoice.facturi_key}>
                          <td>{invoice.facturi_key}</td>
                          <td>{invoice.facturi_serie}</td>
                          <td>{invoice.facturi_serie_numar}</td>
                          <td>{formatDate(invoice.facturi_data)}</td>
                          <td>{invoice.facturi_moneda}</td>
                          <td>
                            {loading ? (
                              <span className="text-muted">Loading...</span>
                            ) : (
                              <button
                                className="btn btn-sm btn-outline-primary invoices-download-btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  getInvoicePdf(invoice);
                                }}
                              >
                                <i className="fas fa-download me-2"></i>
                                Download PDF
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  </div>
                </div>

                <div className="invoices-mobile-view">
                  <div className="invoices-mobile-filters">
                    <Form.Group className="invoices-mobile-filter-group">
                      <Form.Control
                        className="invoices-filter-input"
                        type="text"
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                        placeholder="Filter by ID"
                        size="sm"
                      />
                    </Form.Group>
                    <Form.Group className="invoices-mobile-filter-group">
                      <Form.Control
                        className="invoices-filter-input"
                        type="text"
                        name="series"
                        value={filters.series}
                        onChange={handleFilterChange}
                        placeholder="Filter by Series"
                        size="sm"
                      />
                    </Form.Group>
                    <Form.Group className="invoices-mobile-filter-group">
                      <Form.Control
                        className="invoices-filter-input"
                        type="text"
                        name="number"
                        value={filters.number}
                        onChange={handleFilterChange}
                        placeholder="Filter by Number"
                        size="sm"
                      />
                    </Form.Group>
                    <Form.Group className="invoices-mobile-filter-group">
                      <Form.Control
                        className="invoices-filter-input"
                        type="text"
                        name="date"
                        value={filters.date}
                        onChange={handleFilterChange}
                        placeholder="Filter by Date"
                        size="sm"
                      />
                    </Form.Group>
                    <Form.Group className="invoices-mobile-filter-group">
                      <Form.Control
                        className="invoices-filter-input"
                        type="text"
                        name="currency"
                        value={filters.currency}
                        onChange={handleFilterChange}
                        placeholder="Filter by Currency"
                        size="sm"
                      />
                    </Form.Group>
                  </div>

                  {filteredInvoices.map((invoice) => (
                    <div key={invoice.facturi_key} className="invoices-mobile-card">
                      <div className="invoices-mobile-card-header">
                        <span className="invoices-mobile-label">Invoice #{invoice.facturi_serie_numar}</span>
                        <button
                          className="btn btn-sm btn-outline-primary invoices-download-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            getInvoicePdf(invoice);
                          }}
                        >
                          <i className="fas fa-download me-2"></i>
                          Download
                        </button>
                      </div>
                      <div className="invoices-mobile-card-body">
                        <div className="invoices-mobile-row">
                          <span className="invoices-mobile-label">ID:</span>
                          <span className="invoices-mobile-value">{invoice.facturi_key}</span>
                        </div>
                        <div className="invoices-mobile-row">
                          <span className="invoices-mobile-label">Series:</span>
                          <span className="invoices-mobile-value">{invoice.facturi_serie}</span>
                        </div>
                        <div className="invoices-mobile-row">
                          <span className="invoices-mobile-label">Date:</span>
                          <span className="invoices-mobile-value">{formatDate(invoice.facturi_data)}</span>
                        </div>
                        <div className="invoices-mobile-row">
                          <span className="invoices-mobile-label">Currency:</span>
                          <span className="invoices-mobile-value">{invoice.facturi_moneda}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
    </PageLayout>
  );
};

export default Invoices;
