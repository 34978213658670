import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Button, FormGroup, Label } from 'reactstrap';
import './EditDomainPage.css';
import { BASE_API_URL } from '../config/constants';
import MySpinner from '../components/MySpinner';
import PageLayout from '../components/PageLayout';
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap
import DeleteConfirmationModal from './DeleteConfirmationModal';
import BackArrowIcon from './BackArrowIcon';

const EditDomainPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [themes, setThemes] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const [error, setError] = useState(''); //bootstrap alert
    const [success, setSuccess] = useState(''); //bootstrap alert

    const [countries, setCountries] = useState([]);
    const fetchCountries = async () => {
        try {
          const response = await axios.get(`${BASE_API_URL}api/StaticValues/countries`);
          setCountries(response.data); // Set the countries from API response
        } catch (error) {
          console.error('Error fetching countries:', error);
        }
      };
      const fetchThemes = async () => {
        try {
          const response = await fetch(`${BASE_API_URL}api/theme`);
          if (!response.ok) {
            throw new Error('Failed to fetch themes');
          }
          const data = await response.json();
          setThemes(data);
        } catch (err) {
          //setErrors(err.message);
        } finally {
          setLoading(false);
        }
      };

    useEffect(() => {
        fetchCountries();
        fetchThemes();
        const fetchDomainData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BASE_API_URL}api/userdomains/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setFormData({
                    ...response.data,
                    acceptedCategories: response.data.acceptedCategories || [],
                });
            } catch (error) {
                console.error('Error fetching domain data:', error);
                navigate('/publisher');
                setError('Failed to fetch domain data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchDomainData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox' && name === 'acceptedCategories') {
            setFormData((prevData) => ({
                ...prevData,
                acceptedCategories: checked
                    ? [...prevData.acceptedCategories, value]
                    : prevData.acceptedCategories.filter((category) => category !== value),
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`${BASE_API_URL}api/userdomains/delete/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            if (response.status === 200) {
                setSuccess("Your website has been deleted!");
                // Refresh or redirect logic if needed
            } else {
                setError("Failed to delete website!");
            }
        } catch (error) {
            console.error('Error deleting domain:', error);
    
            // Check if the error response has a specific message from the server
            if (error.response && error.response.status === 400 && error.response.data?.message) {
                setError(error.response.data.message); // Display server's error message
            } else {
                setError('Failed to delete domain. Please try again later.'); // Fallback error message
            }
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');

            // Exclude immutable fields
            const { domain, ...updateData } = formData;

            const response = await axios.put(`${BASE_API_URL}api/userdomains/update`, updateData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                setSuccess("Your website info has been saved!");
              } else {
                const errorData = await response.json();
                setError(`Error: ${errorData?.message || "Failed to save website info"}`);
              }
            //navigate('/');
        } catch (error) {
            setError(`Error: ${"Failed to save website info"}`);
        }
    };

    if (loading) {
        return <MySpinner />;
    }

    const handleBack = () => {
        window.location.href = '/publisher-step2';
    };

    return (
        <PageLayout>
            <div className="edit-domain-page">
            <button 
                onClick={handleBack} 
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    backgroundColor: '#007bff',
                    border: 'none',
                    borderRadius: '10px',
                    color: '#ffffff',
                    fontSize: '16px',
                    fontWeight: '500',
                    cursor: 'pointer',
                }}
            >
                <BackArrowIcon width="24px" height="24px" fill="#ffffff" style={{ marginRight: '8px' }} />
                <span style={{ marginTop: '1px' }}></span>
            </button>
                <h2>Edit Domain</h2>
                {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
                )}
{success && (
    <Alert variant="success" onClose={() => setSuccess('')} dismissible>
      {success}
    </Alert>
  )}
                <form onSubmit={handleSubmit}>
                    <div className="edit-domain-row">
                        <div className="edit-domain-column">
                            <div className="form-container">
                                <FormGroup>
                                    <Label for="domain">Domain</Label>
                                    <Input
                                        type="text"
                                        name="domain"
                                        id="domain"
                                        value={formData.domain}
                                        readOnly
                                        className="edit-domain-disabled-input"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="domainAuthority">Domain Authority</Label>
                                    <Input
                                        type="number"
                                        name="domainAuthority"
                                        id="domainAuthority"
                                        value={formData.domainAuthority}
                                        readOnly
                                        className="edit-domain-disabled-input"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="price">Price</Label>
                                    <Input
                                        type="number"
                                        name="price"
                                        id="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="language">Language of Publication</Label>
                                    <select
                                        className="w-100 form-control"
                                        id="language"
                                        name="language"
                                        value={formData.language || ''}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Choose your language</option>
                                        <option value="Bulgarian">Bulgarian</option>
                                        <option value="Croatian">Croatian</option>
                                        <option value="Czech">Czech</option>
                                        <option value="Danish">Danish</option>
                                        <option value="Dutch">Dutch</option>
                                        <option value="English">English</option>
                                        <option value="Estonian">Estonian</option>
                                        <option value="Finnish">Finnish</option>
                                        <option value="French">French</option>
                                        <option value="German">German</option>
                                        <option value="Greek">Greek</option>
                                        <option value="Hungarian">Hungarian</option>
                                        <option value="Irish">Irish</option>
                                        <option value="Italian">Italian</option>
                                        <option value="Latvian">Latvian</option>
                                        <option value="Lithuanian">Lithuanian</option>
                                        <option value="Maltese">Maltese</option>
                                        <option value="Polish">Polish</option>
                                        <option value="Portuguese">Portuguese</option>
                                        <option value="Romanian">Romanian</option>
                                        <option value="Slovak">Slovak</option>
                                        <option value="Slovenian">Slovenian</option>
                                        <option value="Spanish">Spanish</option>
                                    </select>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="edit-domain-column">
                            <div className="form-container">
                            <FormGroup>
                                    <Label for="country">Country</Label>
                                    <select
                                        className="form-control"
                                        id="country"
                                        name="country"
                                        value={formData.country || ''}
                                        onChange={handleChange}
                                    >
                                        <option value="">Choose your country</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tags">Tags *</Label>
                                    <Input
                                        type="text"
                                        name="tags"
                                        id="tags"
                                        value={formData.tags}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="blockedWebsites">Blocked Websites</Label>
                                    <Input
                                        type="text"
                                        name="blockedWebsites"
                                        id="blockedWebsites"
                                        value={formData.blockedWebsites}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="uniqueUsers">Unique Users per Month *</Label>
                                    <Input
                                        type="number"
                                        name="uniqueUsers"
                                        id="uniqueUsers"
                                        value={formData.uniqueUsers}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="edit-domain-column">
                            <div className="form-container">
                            <FormGroup>
                                    <Label for="websiteType">Website Type</Label>
                                    <select
    id="website-type"
    name="websiteType"
    value={formData.websiteType || ''}
    onChange={handleChange}
    required
    className="form-control"
>
    <option value="">Choose your website type</option>
    {themes.map((category, idx) => (
  <optgroup label={category.label} key={idx}>
    {category.options.map((option) => (
      <option 
        key={option.name} 
        value={option.name}
      >
        {option.name} ({option.count})
      </option>
    ))}
  </optgroup>
))}
</select>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="occurences">Occurrences</Label>
                                    <Input
                                        type="number"
                                        name="occurences"
                                        id="occurences"
                                        value={formData.occurences}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Accepted Categories</Label>
                                    <div style={{ color: "#8f8f8f", gap: "1em" }} className="d-sm-flex mb-3">
                                        <span>
                                            <input
                                                style={{ width: "20px" }}
                                                type="checkbox"
                                                name="acceptedCategories"
                                                value="Gambling"
                                                checked={formData.acceptedCategories.includes("Gambling")}
                                                onChange={handleChange}
                                            />
                                            Gambling
                                            <br />
                                            <input
                                                style={{ width: "20px" }}
                                                type="checkbox"
                                                name="acceptedCategories"
                                                value="CBD"
                                                checked={formData.acceptedCategories.includes("CBD")}
                                                onChange={handleChange}
                                            />
                                            CBD
                                            <br />
                                            <input
                                                style={{ width: "20px" }}
                                                type="checkbox"
                                                name="acceptedCategories"
                                                value="Cryptocurrencies"
                                                checked={formData.acceptedCategories.includes("Cryptocurrencies")}
                                                onChange={handleChange}
                                            />
                                            Cryptocurrencies
                                            <br />
                                        </span>
                                        <span>
                                            <input
                                                style={{ width: "20px" }}
                                                type="checkbox"
                                                name="acceptedCategories"
                                                value="Erotica"
                                                checked={formData.acceptedCategories.includes("Erotica")}
                                                onChange={handleChange}
                                            />
                                            Erotica
                                            <br />
                                            <input
                                                style={{ width: "20px" }}
                                                type="checkbox"
                                                name="acceptedCategories"
                                                value="Dietary supplements and pharmacy"
                                                checked={formData.acceptedCategories.includes("Dietary supplements and pharmacy")}
                                                onChange={handleChange}
                                            />
                                            Dietary supplements and pharmacy
                                            <br />
                                            <input
                                                style={{ width: "20px" }}
                                                type="checkbox"
                                                name="acceptedCategories"
                                                value="Loans"
                                                checked={formData.acceptedCategories.includes("Loans")}
                                                onChange={handleChange}
                                            />
                                            Loans
                                            <br />
                                        </span>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        id="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <Button color="primary" type="submit">Save Changes</Button>
                    <Button
    color="danger"
    onClick={toggleModal}
    style={{ backgroundColor: 'red', borderColor: 'red' }} // Force styles if necessary
>
    Delete
</Button>

                </form>
                {/* Delete Confirmation Modal */}
                <DeleteConfirmationModal
                    isOpen={isModalOpen}
                    toggle={toggleModal}
                    domain={formData} // Pass domain data for confirmation (if needed)
                    onDelete={handleDelete}
                />
            </div>
        </PageLayout>
    );
};

export default EditDomainPage;
