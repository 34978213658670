import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faShoppingCart, faWallet, faBars, faUser, faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../authService";
import { useCart } from "../CartContext";
import NotificationDropdown from "./NotificationDropdown";
import { useNotifications } from "./NotificationContext";
import axios from 'axios';
import { BASE_API_URL } from '../config/constants';
import './Navbar.css';

// Cache duration in milliseconds (5 minutes)
const CACHE_DURATION = 5 * 60 * 1000;

// Create a custom event for wallet balance updates
export const refreshWalletBalance = () => {
  const event = new CustomEvent('walletBalanceUpdated');
  window.dispatchEvent(event);
};

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const getUserTypeFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType; // Assuming 'userType' is a claim in the token
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const Navbar = ({ toggleSidebar }) => {
  const { setIsLoggedIn, setToken, setUserType } = useAuth();
  const { notifications, setNotifications } = useNotifications();
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);

  const token = localStorage.getItem('token');
  const userName = getUserName(token);
  const userTypeFromToken = getUserTypeFromToken(token);
  const cartItemCount = cartItems ? cartItems.length : 0;
  const unreadCount = notifications.filter(notification => !notification.isRead).length;

  // Close user menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Listen for wallet balance update events
  useEffect(() => {
    const handleWalletUpdate = () => {
      fetchWalletBalance(true); // Force refresh
    };

    window.addEventListener('walletBalanceUpdated', handleWalletUpdate);

    return () => {
      window.removeEventListener('walletBalanceUpdated', handleWalletUpdate);
    };
  }, []);

  useEffect(() => {
    // Check if we need to fetch the wallet balance
    const shouldFetchBalance = () => {
      // If we're not an advertiser, don't fetch
      if (userTypeFromToken !== 'advertiser') return false;
      
      // Get cached balance from localStorage
      const cachedData = localStorage.getItem('walletBalanceCache');
      
      if (cachedData) {
        try {
          const { balance, timestamp } = JSON.parse(cachedData);
          const now = Date.now();
          
          // If the cache is still valid, use it
          if (now - timestamp < CACHE_DURATION) {
            setWalletBalance(balance);
            return false;
          }
        } catch (error) {
          // If there's an error parsing the cache, fetch fresh data
          return true;
        }
      }
      
      // If there's no cache or it's expired, fetch fresh data
      return true;
    };

    if (shouldFetchBalance()) {
      fetchWalletBalance();
    }
  }, [userTypeFromToken]);

  const fetchWalletBalance = async (forceRefresh = false) => {
    // Don't fetch if we've fetched recently, unless force refresh is true
    const now = Date.now();
    if (!forceRefresh && now - lastFetchTime < CACHE_DURATION) {
      return;
    }
    
    setIsLoadingBalance(true);
    try {
      const response = await axios.get(`${BASE_API_URL}api/codeamount/get-total-amount`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        const balance = response.data.totalAmount;
        setWalletBalance(balance);
        
        // Cache the balance in localStorage
        localStorage.setItem('walletBalanceCache', JSON.stringify({
          balance,
          timestamp: now
        }));
        
        setLastFetchTime(now);
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    } finally {
      setIsLoadingBalance(false);
    }
  };

  const handleCartClick = () => {
    navigate('/cart');
  };

  const handleNotificationClick = (e) => {
    e.stopPropagation();
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const handleWalletClick = () => {
    // Force refresh the balance when clicking on the wallet
    fetchWalletBalance(true);
    navigate('/wallet');
  };

  const handleUserMenuToggle = (e) => {
    e.stopPropagation();
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleAccountOptionClick = (tab) => {
    navigate('/MyAccount', { state: { activeTab: tab } });
    setIsUserMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="container-fluid">
        {/* Left side */}
        <div className="navbar-start">
          <button 
            className="icon-button"
            onClick={toggleSidebar}
            aria-label="Toggle menu"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          
          <a className="navbar-brand" onClick={(e) => {
            e.preventDefault();
            navigate(userTypeFromToken === 'advertiser' ? "/advertiser" : "/publisher");
          }}>
            <img 
              src={require("../assets/images/Link-Building-Software.png")} 
              alt="AdverLink"
            />
          </a>
        </div>
        
        {/* Right side */}
        <div className="navbar-end">
          {userTypeFromToken === 'advertiser' && (
            <>
              <button 
                className="icon-button cart-button" 
                onClick={handleCartClick}
                aria-label="Shopping cart"
              >
                <FontAwesomeIcon icon={faShoppingCart} />
                {cartItemCount > 0 && (
                  <span className={`badge ${cartItemCount > 99 ? 'large-number' : ''}`}>
                    {cartItemCount > 999 ? '999+' : cartItemCount}
                  </span>
                )}
              </button>
              
              <div className="wallet-container" onClick={handleWalletClick}>
                <div className="wallet-icon">
                  <FontAwesomeIcon icon={faWallet} />
                </div>
                <div className="wallet-balance">
                  {isLoadingBalance ? (
                    <span className="loading-balance">...</span>
                  ) : (
                    <span>${Number(walletBalance).toFixed(2)}</span>
                  )}
                </div>
              </div>
            </>
          )}
          
          <div className="username-container" ref={userMenuRef}>
            <div className="username-display" onClick={handleUserMenuToggle}>
              <span>{userName}</span>
            </div>
            
            {isUserMenuOpen && (
              <div className="user-dropdown">
                <div className="user-dropdown-item" onClick={() => handleAccountOptionClick('Account Info')}>
                  <FontAwesomeIcon icon={faUser} className="dropdown-icon" />
                  <span>Account Info</span>
                </div>
                <div className="user-dropdown-item" onClick={() => handleAccountOptionClick('Email')}>
                  <FontAwesomeIcon icon={faEnvelope} className="dropdown-icon" />
                  <span>Email</span>
                </div>
                <div className="user-dropdown-item" onClick={() => handleAccountOptionClick('Password')}>
                  <FontAwesomeIcon icon={faLock} className="dropdown-icon" />
                  <span>Password</span>
                </div>
              </div>
            )}
          </div>
          
          <div className="notification-container">
            <button 
              className="icon-button notification-button"
              onClick={handleNotificationClick}
              aria-label="Notifications"
            >
              <FontAwesomeIcon icon={faBell} />
              {unreadCount > 0 && (
                <span className={`badge ${unreadCount > 99 ? 'large-number' : ''}`}>
                  {unreadCount > 999 ? '999+' : unreadCount}
                </span>
              )}
            </button>
            
            {isNotificationsOpen && (
              <NotificationDropdown 
                notifications={notifications}
                setNotifications={setNotifications}
                isOpen={isNotificationsOpen}
                setIsOpen={setIsNotificationsOpen}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
