// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
    padding: 0 20px;
  }
  
  .content {
    width: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .table {
    margin-top: 20px;
  }
  
  .table thead {
    background-color: #343a40;
    color: #fff;
  }
  
  .table th,
  .table td {
    vertical-align: middle;
  }
  
  .table-hover tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  h2 {
    font-weight: bold;
    color: #343a40;
  }
  
  p-911 {
    font-size: 1.1rem;
    color: #6c757d;
  }
  `, "",{"version":3,"sources":["webpack://./src/KeywordsReports.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;;IAEE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":[".container-fluid {\n    padding: 0 20px;\n  }\n  \n  .content {\n    width: auto;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .table {\n    margin-top: 20px;\n  }\n  \n  .table thead {\n    background-color: #343a40;\n    color: #fff;\n  }\n  \n  .table th,\n  .table td {\n    vertical-align: middle;\n  }\n  \n  .table-hover tbody tr:hover {\n    background-color: #f5f5f5;\n  }\n  \n  h2 {\n    font-weight: bold;\n    color: #343a40;\n  }\n  \n  p-911 {\n    font-size: 1.1rem;\n    color: #6c757d;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
