// AdvertiserDetails.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ArrowLeft, Mail, Cog, Ban, Trash, 
  BarChart2, Link, DollarSign, FileText, 
  Calendar, TrendingUp, AlertCircle, Clock, Globe
} from 'lucide-react';

const StatBox = ({ title, value, trend }) => (
  <div className="card bg-light shadow-sm">
    <div className="card-body p-4">
      <h6 className="text-muted text-sm mb-2">{title}</h6>
      <h3 className="mb-1">{value}</h3>
      <small className={`text-${trend?.type || 'muted'}`}>{trend?.text}</small>
    </div>
  </div>
);

export default function AdvertiserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const advertiser = location.state?.advertiser || {};

  const [activeTab, setActiveTab] = useState('campaigns');
  const isEditMode = advertiser?.mode === 'edit';

  // Fetch advertiser details based on ID (mock data used here)
  // In a real-world scenario, you would fetch data from an API
  const advertiserData = {
    id: id || 'ADV-12345',
    name: advertiser?.name || 'TechCorp Solutions',
    email: advertiser?.email || 'john@techcorp.com',
    industry: advertiser?.industry || 'Technology',
    subIndustry: advertiser?.subIndustry || 'SaaS',
    joinDate: 'Jan 15, 2024',
    status: 'active',
    stats: {
      totalBudget: 50000,
      activeCampaigns: 12,
      totalSpent: 234567,
      avgCampaignValue: 3450,
      conversionRate: '68%',
      responseTime: '4h',
      lastPayment: '2024-01-20'
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="container-fluid p-0">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center gap-3">
          <button 
            className="btn btn-outline-secondary"
            onClick={handleBack}
          >
            <ArrowLeft className="w-4 h-4 me-2" />
            Back to Advertisers
          </button>
          <h2 className="mb-0">
            {isEditMode ? 'Edit Advertiser' : 'Advertiser Details'}
          </h2>
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-outline-primary">
            <Mail className="w-4 h-4 me-2" />
            Contact Advertiser
          </button>
          <div className="dropdown">
            <button 
              className="btn btn-primary dropdown-toggle" 
              data-bs-toggle="dropdown"
            >
              <Cog className="w-4 h-4 me-2" />
              Actions
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  <Calendar className="w-4 h-4 me-2" />
                  Schedule Meeting
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <AlertCircle className="w-4 h-4 me-2" />
                  Report Issue
                </a>
              </li>
              <li>
                <a className="dropdown-item text-danger" href="#">
                  <Ban className="w-4 h-4 me-2" />
                  Block Account
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Advertiser Overview Card */}
      <div className="card shadow-sm mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-4">
                <div className="bg-primary rounded-circle p-3 text-white me-3">
                  <span className="h4 mb-0">{advertiserData.name.charAt(0)}</span>
                </div>
                <div>
                  <h4 className="mb-1">{advertiserData.name}</h4>
                  <p className="mb-0 text-muted">{advertiserData.email}</p>
                </div>
                <span className="badge bg-success ms-3">Active</span>
              </div>
              <div className="row g-3">
                <div className="col-6">
                  <p className="mb-1 text-muted">Advertiser ID</p>
                  <p className="fw-medium">{advertiserData.id}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1 text-muted">Join Date</p>
                  <p className="fw-medium">{advertiserData.joinDate}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1 text-muted">Industry</p>
                  <p className="fw-medium">{advertiserData.industry} - {advertiserData.subIndustry}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1 text-muted">Last Payment</p>
                  <p className="fw-medium">{advertiserData.stats.lastPayment}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row g-3">
                <div className="col-6">
                  <StatBox
                    title="Monthly Budget"
                    value={`$${advertiserData.stats.totalBudget.toLocaleString()}`}
                    trend={{ type: 'success', text: '+15% this month' }}
                  />
                </div>
                <div className="col-6">
                  <StatBox
                    title="Total Spent"
                    value={`$${advertiserData.stats.totalSpent.toLocaleString()}`}
                    trend={{ type: 'success', text: 'Lifetime value' }}
                  />
                </div>
                <div className="col-6">
                  <StatBox
                    title="Active Campaigns"
                    value={advertiserData.stats.activeCampaigns}
                    trend={{ type: 'success', text: '8 running' }}
                  />
                </div>
                <div className="col-6">
                  <StatBox
                    title="Conversion Rate"
                    value={advertiserData.stats.conversionRate}
                    trend={{ type: 'success', text: '+5% vs last month' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Navigation */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'campaigns' ? 'active' : ''}`}
            onClick={() => setActiveTab('campaigns')}
          >
            <BarChart2 className="w-4 h-4 me-2" />
            Campaigns
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'placements' ? 'active' : ''}`}
            onClick={() => setActiveTab('placements')}
          >
            <Link className="w-4 h-4 me-2" />
            Placements
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'billing' ? 'active' : ''}`}
            onClick={() => setActiveTab('billing')}
          >
            <DollarSign className="w-4 h-4 me-2" />
            Billing
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'reports' ? 'active' : ''}`}
            onClick={() => setActiveTab('reports')}
          >
            <FileText className="w-4 h-4 me-2" />
            Reports
          </button>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="card shadow-sm border-top-0 rounded-top-0">
        <div className="card-body p-4">
          {activeTab === 'campaigns' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Active Campaigns</h5>
                <button className="btn btn-primary">
                  <Calendar className="w-4 h-4 me-2" />
                  Create Campaign
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Campaign Name</th>
                      <th>Type</th>
                      <th>Budget</th>
                      <th>Spent</th>
                      <th>Status</th>
                      <th>Performance</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="fw-medium">Q1 Product Launch</div>
                        <small className="text-muted">Started Jan 15, 2024</small>
                      </td>
                      <td>Product Marketing</td>
                      <td>$25,000</td>
                      <td>
                        <div>$12,345</div>
                        <small className="text-success">49.4% of budget</small>
                      </td>
                      <td><span className="badge bg-success">Active</span></td>
                      <td>
                        <div className="d-flex align-items-center">
                          <TrendingUp className="text-success me-2" />
                          <span>+15% CTR</span>
                        </div>
                      </td>
                      <td>
                        <button className="btn btn-sm btn-outline-secondary">
                          <Cog className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                    {/* Add more campaign rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {activeTab === 'placements' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Active Placements</h5>
                <div className="d-flex gap-2">
                  <button className="btn btn-outline-primary">Export</button>
                  <button className="btn btn-primary">Add Placement</button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>URL</th>
                      <th>Publisher</th>
                      <th>Campaign</th>
                      <th>Performance</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="fw-medium">https://example.com/tech-review</div>
                        <small className="text-muted">Product Review</small>
                      </td>
                      <td>TechBlog Media</td>
                      <td>Q1 Product Launch</td>
                      <td>
                        <div>2.5k Clicks</div>
                        <small className="text-success">4.2% CTR</small>
                      </td>
                      <td><span className="badge bg-success">Active</span></td>
                      <td>
                        <button className="btn btn-sm btn-outline-secondary">
                          <Cog className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                    {/* Add more placement rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {activeTab === 'billing' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Billing History</h5>
                <button className="btn btn-outline-primary">Export Statement</button>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Invoice ID</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Campaign</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>#INV-2024-001</td>
                      <td>Jan 20, 2024</td>
                      <td>$12,345</td>
                      <td>Q1 Product Launch</td>
                      <td><span className="badge bg-success">Paid</span></td>
                      <td>
                        <button className="btn btn-sm btn-outline-secondary">
                          <FileText className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                    {/* Add more billing history rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {activeTab === 'reports' && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Performance Reports</h5>
                <div className="d-flex gap-2">
                  <button className="btn btn-outline-primary">Schedule Report</button>
                  <button className="btn btn-primary">Generate Report</button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Report Name</th>
                      <th>Type</th>
                      <th>Generated</th>
                      <th>Period</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="fw-medium">Q1 Campaign Performance</div>
                        <small className="text-muted">Automated Report</small>
                      </td>
                      <td>Performance Metrics</td>
                      <td>
                        <div>Jan 20, 2024</div>
                        <small className="text-muted">08:30 AM</small>
                      </td>
                      <td>Jan 2024</td>
                      <td><span className="badge bg-success">Generated</span></td>
                      <td>
                        <div className="d-flex gap-2">
                          <button className="btn btn-sm btn-outline-primary">
                            <FileText className="w-4 h-4" />
                          </button>
                          <button className="btn btn-sm btn-outline-secondary">
                            <Clock className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="fw-medium">ROI Analysis</div>
                        <small className="text-muted">Custom Report</small>
                      </td>
                      <td>Financial Analysis</td>
                      <td>
                        <div>Jan 15, 2024</div>
                        <small className="text-muted">14:15 PM</small>
                      </td>
                      <td>Q4 2023</td>
                      <td><span className="badge bg-success">Generated</span></td>
                      <td>
                        <div className="d-flex gap-2">
                          <button className="btn btn-sm btn-outline-primary">
                            <FileText className="w-4 h-4" />
                          </button>
                          <button className="btn btn-sm btn-outline-secondary">
                            <Clock className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              {/* Scheduled Reports Section */}
              <div className="mt-5">
                <h6 className="mb-3">Scheduled Reports</h6>
                <div className="table-responsive">
                  <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Report Name</th>
                        <th>Frequency</th>
                        <th>Recipients</th>
                        <th>Next Run</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="fw-medium">Weekly Performance</div>
                          <small className="text-muted">Campaign Metrics</small>
                        </td>
                        <td>Weekly</td>
                        <td>3 recipients</td>
                        <td>Jan 27, 2024</td>
                        <td><span className="badge bg-primary">Scheduled</span></td>
                        <td>
                          <div className="d-flex gap-2">
                            <button className="btn btn-sm btn-outline-secondary">
                              <Cog className="w-4 h-4" />
                            </button>
                            <button className="btn btn-sm btn-outline-danger">
                              <Trash className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="fw-medium">Monthly ROI Report</div>
                          <small className="text-muted">Financial Metrics</small>
                        </td>
                        <td>Monthly</td>
                        <td>2 recipients</td>
                        <td>Feb 1, 2024</td>
                        <td><span className="badge bg-primary">Scheduled</span></td>
                        <td>
                          <div className="d-flex gap-2">
                            <button className="btn btn-sm btn-outline-secondary">
                              <Cog className="w-4 h-4" />
                            </button>
                            <button className="btn btn-sm btn-outline-danger">
                              <Trash className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
