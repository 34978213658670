// TestResponseAPI.js

// Mock data for related keywords
export const relatedKeywords = [
    "Flori",
    "Florarie",
    "Livrare flori",
    "Buchete flori",
    "Aranjamente florale",
    "Florarie online",
    "Cutii cu flori",
    "Flori pentru ocazii",
    "Plante",
    "Coroane funerare"
  ];
  
  // Mock data for LSI keywords
  export const lsiKeywords = [
    "Decoratiuni florale",
    "Cadouri cu flori",
    "Buchete aniversare",
    "Flori de primavara",
    "Flori exotice",
    "Flori pentru birou",
    "Livrare plante"
  ];
  
  // Mock data for ranked keywords
  export const rankedKeywords = [
    "Flori Bucuresti"
  ];
  
  // Export all mock data as default for convenience
  export default {
    relatedKeywords,
    lsiKeywords,
    rankedKeywords
  };
  