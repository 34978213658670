// CartContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [showCart, setShowCart] = useState(false);

// Load cart data from localStorage when the component mounts
  useEffect(() => {
    const savedCart = localStorage.getItem('cartItems');
    if (savedCart) {
      setCartItems(JSON.parse(savedCart));
    }
  }, [setCartItems]);

    // Save cart data to localStorage whenever cartItems change
    useEffect(() => {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);


  const addToCart = (item) => {
    setCartItems(prevItems => [...prevItems, item]);
  };

  const removeFromCart = (index) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.filter((_, i) => i !== index);
      // Save updated cart to localStorage
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      console.log(updatedItems);
      return updatedItems;
    });
  };

  const clearCart = () => {
    setCartItems([]);
    // Clear cart data from localStorage
    localStorage.removeItem('cartItems');
  };

  const toggleCart = () => {
    setShowCart(prev => !prev);
  };

  return (
    <CartContext.Provider value={{ cartItems, showCart, addToCart, removeFromCart, clearCart, toggleCart }}>
      {children}
    </CartContext.Provider>
  );
};