import React, { useRef, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../config/constants';
import { useNavigate } from 'react-router-dom';
import { Bell, Check } from 'lucide-react';

const formatTimestamp = (createdAt) => {
  const now = new Date();
  const created = new Date(createdAt);
  const diffInSeconds = Math.floor((now - created) / 1000);

  if (diffInSeconds < 60) {
    return 'Just now';
  } else {
    return created.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }
};

const NotificationDropdown = ({ notifications, setNotifications, isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  React.useEffect(() => {
    const globalStyles = `
      @keyframes dropdownFade {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .notification-dropdown::-webkit-scrollbar {
        width: 6px;
      }

      .notification-dropdown::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
      }

      .notification-dropdown::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 3px;
      }

      .notification-dropdown::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
      }
    `;

    const styleSheet = document.createElement("style");
    styleSheet.innerText = globalStyles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const deleteNotification = async (notificationId) => {
    try {
      await axios.delete(`${BASE_API_URL}api/notification/${notificationId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== notificationId)
      );
      console.log(`Deleted notification with ID: ${notificationId}`);
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const handleNotificationClick = async (notification) => {
    if (notification.link && notification.link.trim()) {
      // Navigate to the link first
      navigate(notification.link);
      
      // Then delete the notification
      await deleteNotification(notification.id);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await axios.put(`${BASE_API_URL}api/notification/mark-all-as-read`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const deletePromises = notifications.map(notification => 
        deleteNotification(notification.id)
      );
      await Promise.all(deletePromises);
      
      setNotifications([]);
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  return (
    <div className={`notification-dropdown ${isOpen ? 'show' : ''}`} ref={dropdownRef}>
      {notifications.length === 0 ? (
        <div style={emptyStateStyle}>
          <span style={emptyIconStyle}>🔔</span>
          <span>No notifications</span>
        </div>
      ) : (
        <ul style={listStyle}>
          <div style={headerStyle}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>Notifications</span>
              <button
                onClick={handleMarkAllAsRead}
                style={markAllReadButtonStyle}
                title="Mark all as read"
              >
                <Check size={16} />
                <span style={{ marginLeft: '4px' }}>Mark all as read</span>
              </button>
            </div>
          </div>
          {notifications.map((notification) => (
            <li key={notification.id} style={listItemStyle}>
              <div style={notificationIconStyle}>
                <span style={iconCircleStyle}>📋</span>
              </div>
              <div style={contentStyle}>
                {notification.link ? (
                  <span
                    style={linkMessageStyle}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    {notification.message}
                  </span>
                ) : (
                  <span style={messageStyle}>
                    {notification.message}
                  </span>
                )}
                <span style={timestampStyle}>
                  {formatTimestamp(notification.createdAt)}
                </span>
              </div>
              <button
                style={deleteButtonStyle}
                onClick={() => deleteNotification(notification.id)}
                onMouseEnter={(e) => {
                  e.target.style.transform = 'rotate(90deg)';
                  e.target.style.backgroundColor = '#ffebee';
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = 'rotate(0deg)';
                  e.target.style.backgroundColor = '#fff';
                }}
              >
                ×
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// Enhanced styling
const dropdownStyle = {
  position: 'absolute',
  top: '45px',
  right: '10px',
  backgroundColor: '#ffffff',
  border: 'none',
  borderRadius: '16px',
  padding: '8px',
  width: '320px',
  zIndex: 1000,
  boxShadow: '0 6px 30px rgba(0, 0, 0, 0.12)',
  maxHeight: '480px',
  overflowY: 'auto',
  animation: 'dropdownFade 0.3s ease',
};

const headerStyle = {
  padding: '12px 16px',
  fontWeight: '600',
  color: '#1a1a1a',
  borderBottom: '1px solid #f0f0f0',
  fontSize: '16px',
  marginBottom: '8px',
};

const listStyle = {
  listStyleType: 'none',
  padding: '4px',
  margin: 0,
};

const listItemStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  padding: '16px',
  marginBottom: '4px',
  borderRadius: '12px',
  backgroundColor: '#ffffff',
  transition: 'all 0.2s ease',
  border: '1px solid #f0f0f0',
  ':hover': {
    backgroundColor: '#f8f9fa',
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
  }
};

const contentStyle = {
  flex: 1,
  marginLeft: '12px',
  display: 'flex',
  flexDirection: 'column',
};

const notificationIconStyle = {
  marginTop: '2px',
};

const iconCircleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: '#f0f7ff',
  fontSize: '16px',
};

const linkMessageStyle = {
  cursor: 'pointer',
  color: '#2563eb',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.5',
  marginBottom: '4px',
  transition: 'color 0.2s ease',
  ':hover': {
    color: '#1d4ed8',
  }
};

const messageStyle = {
  fontSize: '14px',
  color: '#374151',
  lineHeight: '1.5',
  marginBottom: '4px',
};

const timestampStyle = {
  fontSize: '12px',
  color: '#6b7280',
  marginTop: '4px',
};

const deleteButtonStyle = {
  background: '#fff',
  border: 'none',
  color: '#dc3545',
  cursor: 'pointer',
  fontSize: '18px',
  fontWeight: '500',
  width: '28px',
  height: '28px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '12px',
  transition: 'all 0.3s ease',
  border: '1px solid #f0f0f0',
};

const emptyStateStyle = {
  padding: '24px 16px',
  textAlign: 'center',
  color: '#6b7280',
  fontSize: '14px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
};

const emptyIconStyle = {
  fontSize: '24px',
  opacity: '0.5',
};

const markAllReadButtonStyle = {
  background: 'none',
  border: 'none',
  padding: '4px 8px',
  fontSize: '12px',
  color: '#6b7280',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  transition: 'all 0.2s ease',
  ':hover': {
    backgroundColor: '#f3f4f6',
    color: '#4b5563'
  }
};

export default NotificationDropdown;
