import React from 'react';

const MySpinner = () => (
  <div className="text-center" style={{ }}> {/* Increased margin here */}
  <div className="spinner-border text-primary" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
</div> // Show loading spinner during data fetch
);

export default MySpinner;



