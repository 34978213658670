import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService'; // Ensure this is your auth service import
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import toast from 'react-hot-toast';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import './Download.css'; // Import the CSS file
import PageLayout from './components/PageLayout';

const Download = () => {
  return (
    <>
    <PageLayout>
      <div className="" style={{width:'1000px'}}> 
        <div className="download-content">
          <h2>Download</h2>
          <div className="download-text">
            <p>Get started with <strong>AdverLink</strong> today and take your sales strategy to the next level.</p>
            <a href="https://download.adverlink.net/download/adverlink.zip" className="download-link">Download AdverLink</a>
          </div>
        </div>
      </div>
      </PageLayout>
    </>
  );
};

export default Download;
