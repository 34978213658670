import React, { useState, useEffect } from 'react';
import './Wallet.css';
import PageLayout from './components/PageLayout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_API_URL } from './config/constants';
import { Alert } from 'react-bootstrap';
import congratulationsImg from "./assets/images/congratulations.png";
import TopUpCheckout from './TopUpCheckout'; // We'll create this next
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { refreshWalletBalance } from './components/Navbar';
 

const WalletPage = () => {
  // Existing states
  const [code, setAmount] = useState('');
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [notification, setNotification] = useState('');
  const [totalBalance, setTotalBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isRedeemLoading, setIsRedeemLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Top-up specific states
  const [showTopUp, setShowTopUp] = useState(false);
  const [topUpAmount, setTopUpAmount] = useState('');
  const [showTopUpCheckout, setShowTopUpCheckout] = useState(false);
  
  const navigate = useNavigate();

  // Keep your existing useEffects for localStorage...
  useEffect(() => {
    const savedPaymentStatus = JSON.parse(localStorage.getItem('isPaymentSuccessful')) || false;
    setPaymentSuccessful(savedPaymentStatus);
  }, []);

  useEffect(() => {
    localStorage.setItem('isPaymentSuccessful', JSON.stringify(isPaymentSuccessful));
  }, [isPaymentSuccessful]);

  // Keep your existing fetch functions...
  const fetchTotalBalance = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_API_URL}api/codeamount/get-total-amount`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      if (response.status === 200) {
        setTotalBalance(response.data.totalAmount);
        // Refresh the wallet balance in the header
        refreshWalletBalance();
      }
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred while fetching total balance.');
    } finally {
      setLoading(false);
    }
  };

  const fetchRedeemedCodes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_API_URL}api/codeamount/transactions`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      if (response.status === 200) {
        setTransactions(response.data.redeemedCodes || []);
      }
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred while fetching redeemed codes.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTotalBalance();
    fetchRedeemedCodes();
  }, []);

  // Code redemption handlers
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (code.trim() === '') {
      setError('Please enter a code');
      return;
    }

    try {
      setIsRedeemLoading(true);
      const response = await axios.get(`${BASE_API_URL}api/codeamount/validate-code`, {
        params: { code },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      if (response.status === 200) {
        await fetchRedeemedCodes();
        setPaymentSuccessful(true);
        setNotification('The funds have been successfully added!');
        await fetchTotalBalance();
        // Refresh the wallet balance in the header
        refreshWalletBalance();
      }
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred.');
    } finally {
      setIsRedeemLoading(false);
    }
  };

  // Top-up handlers
  const handleProceedToPayment = () => {
    if (topUpAmount && parseFloat(topUpAmount) > 0) {
      setShowTopUpCheckout(true);
    }
  };

  const handleTopUpSuccess = async (type, data) => {
    if (type === 'payment_created') {
      console.log('Top-up payment created:', data);
    } else if (type === 'payment_method_updated') {
      setSuccess('Payment successful!');
      await fetchTotalBalance();
      // Refresh the wallet balance in the header
      refreshWalletBalance();
      setShowTopUp(false);
      setShowTopUpCheckout(false);
      setTopUpAmount('');
      setPaymentSuccessful(true);
      setNotification('The funds have been successfully added to your wallet!');
    }
  };

  const handleTopUpError = (error) => {
    setError(error.message || 'An error occurred during payment');
    setShowTopUp(false);
    setShowTopUpCheckout(false);
    setTopUpAmount('');
  };

  const handleTopUpClick = () => {
    setShowTopUp(true);
  };

  const handleTopUpCancel = () => {
    setShowTopUp(false);
    setTopUpAmount('');
    setShowTopUpCheckout(false);
  };

  const handleAddMoreFunds = () => {
    setAmount('');
    setPaymentSuccessful(false);
    setNotification('');
  };

  const handleStartPublishing = () => {
    navigate('/advertiser-step1');
  };

  const handleDismissNotification = () => {
    setNotification('');
  };

  const handleCheckWallet = () => {
    setPaymentSuccessful(true);
  };

  // Render functions
  const renderTopUpForm = () => {
    const stripePromise = loadStripe('pk_live_51N3JFnL4S5a1WxAjiPBiwb4ShFOftVd2QS4TPJH9dSqSCLylagT0XFSYbPKCppctTwT4WeXwf2wed4Ya05mVYchL00sGrXx9vV');
  
    return (
      <div className="top-up-modal">
        <div className="top-up-content">
          <h2>Top Up Your Wallet</h2>
          <div className="amount-input">
            <label>Amount to add ($):</label>
            <input
              type="number"
              min="1"
              value={topUpAmount}
              onChange={(e) => setTopUpAmount(e.target.value)}
              placeholder="Enter amount"
            />
          </div>
          
          {!showTopUpCheckout ? (
            <div className="top-up-buttons">
              <button 
                onClick={handleProceedToPayment}
                className="proceed-button"
                disabled={!topUpAmount || parseFloat(topUpAmount) <= 0}
              >
                Proceed to Payment
              </button>
              <button onClick={handleTopUpCancel} className="cancel-button">
                Cancel
              </button>
            </div>
          ) : (
            <Elements stripe={stripePromise}>
              <TopUpCheckout
                amount={parseFloat(topUpAmount)}
                onSuccess={handleTopUpSuccess}
                onError={handleTopUpError}
                onCancel={handleTopUpCancel}
              />
            </Elements>
          )}
        </div>
      </div>
    );
  };

  // Keep your existing renderPaymentSuccessContent and renderFormContent...
  const renderPaymentSuccessContent = () => {
    const sortedRedeemedCodes = [...transactions].sort((a, b) => b.UserCodeId - a.UserCodeId);

    return (
      <div className="congratulations-container">
        {notification && (
          <div className="notification">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
              <h1 style={{ fontSize: '2rem', color: '#333', marginLeft: '50px' }}>Congratulations!</h1>
              <img src={congratulationsImg} alt="Congratulations" style={{ width: '100px', marginLeft: '20px' }} />
            </div>
            <hr style={{ marginBottom: '20px' }} />
            <div style={{ textAlign: 'center', padding: '10px 0' }}>{notification}</div>
            <button onClick={handleDismissNotification} className="dismiss-button" style={{ marginTop: '10px' }}>X</button>
          </div>
        )}

        <div className="wallet-details">
          <table>
            <thead>
              <tr>
                <th colSpan="3" style={{ backgroundColor: '#68b684', color: 'white', border: 'none', textAlign: 'left' }}>
                  AdverLink Wallet
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {sortedRedeemedCodes.map((transaction) => (
                <tr key={transaction.userCodeId}>
                  <td>{transaction.userCodeId}</td>
                  <td>{transaction.code}</td>
                  <td style={{ color: 'green' }}>{transaction.amount}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2"><strong>Total Balance:</strong></td>
                <td style={{ color: 'green' }}>
                  <strong>{(totalBalance?.toFixed(2) ?? '0.00')} USD</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="buttons-container">
          <button className="start-publishing-button" onClick={handleStartPublishing}>
            Start publishing links
          </button>
          <button className="add-more-funds-button" onClick={handleTopUpClick}>
            Top Up Wallet
          </button>
          <button className="add-more-funds-button" onClick={handleAddMoreFunds}>
            Add Code
          </button>
        </div>
      </div>
    );
  };

  const renderFormContent = () => (
    <div className="wallet-layout">
      <div className="wallet-table-section">
        <div className="wallet-details">
          <table>
            <thead>
              <tr>
                <th colSpan="3" style={{ backgroundColor: '#68b684', color: 'white', border: 'none', textAlign: 'left' }}>
                  AdverLink Wallet
                </th>
              </tr>
              <tr>
                <td colSpan="2"><strong>Total Balance:</strong></td>
                <td style={{ color: 'green' }}>
                  <strong>{(totalBalance?.toFixed(2) ?? '0.00')} USD</strong>
                </td>
              </tr>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.userCodeId}>
                  <td>{transaction.userCodeId}</td>
                  <td>{transaction.code}</td>
                  <td style={{ color: 'green' }}>{transaction.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="add-more-funds-button" onClick={handleTopUpClick}>
          Top Up Wallet
        </button>
      </div>

      <div className="code-redemption-section">
        {error && (
          <Alert variant="danger" onClose={() => setError('')} dismissible>
            {error}
          </Alert>
        )}

        {success && (
          <Alert variant="success" onClose={() => setSuccess('')} dismissible>
            {success}
          </Alert>
        )}
        
        <h1 style={{ marginLeft: 0 }}>Add funds to your wallet</h1>
        <p style={{ fontSize: '1.2rem', marginBottom: '10px', color: '#666' }}>
          Add funds to your wallet to enjoy exclusive bonuses and access our premium services. 
          Enter your desired amount and instantly see the bonus you'll receive!
        </p>

        <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
          <label htmlFor="amount" style={{ display: 'block', fontSize: '1rem', marginBottom: '10px', color: '#333' }}>
            Enter your promotional code to redeem your reward ($):
          </label>
          <input
            type="text"
            id="amount"
            value={code}
            onChange={handleAmountChange}
            placeholder="Enter your code"
            style={{ width: '100%', padding: '10px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px' }}
          />
          <div className="form-buttons">
            {isRedeemLoading ? (
              <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <button type="submit" className="pay-now-button">Redeem code</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <PageLayout pageType='wallet'>
      {loading ? (
        <div className="text-center" style={{ marginLeft: '70px', marginTop: '50px' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {renderFormContent()}
          {showTopUp && renderTopUpForm()}
        </>
      )}
    </PageLayout>
  );
};

export default WalletPage;