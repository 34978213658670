import React from 'react';
import SearchBox from '../components/SearchBox';
import PageLayout from '../components/PageLayout';

const Search = () => {
  return (
    <PageLayout>
      <div className="mt-0 ms-4 subCont5 pt-0">
        <h1>Search for relevant articles</h1>
        <p>Search for relevant articles and add them to your cart</p>
        <SearchBox />
      </div>
    </PageLayout>
  );
};

export default Search; 