// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-responsive {
    margin-top: 20px;
  }
  
  .table {
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .thead-dark {
    background-color: #343a40;
    color: white;
  }
  
  .p-911 {
    text-align: center;
    color: #f00;
    font-weight: bold;
  }
  

  .table-responsive {
    overflow-x: visible;
  }

  
  thead th {
    min-width: 100px; /* Ensure minimum width for each header cell */
  }

  .content {
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px;
    background-color: #fff; /* Background behind your table */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adds shadow effect behind the table */
  }
  `, "",{"version":3,"sources":["webpack://./src/AdminLinksReports.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,iBAAiB;EACnB;;;EAGA;IACE,mBAAmB;EACrB;;;EAGA;IACE,gBAAgB,EAAE,8CAA8C;EAClE;;EAEA;IACE,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,sBAAsB,EAAE,iCAAiC;IACzD,kBAAkB;IAClB,uCAAuC,EAAE,wCAAwC;EACnF","sourcesContent":[".table-responsive {\n    margin-top: 20px;\n  }\n  \n  .table {\n    background-color: #fff;\n    border: 1px solid #ddd;\n  }\n  \n  .thead-dark {\n    background-color: #343a40;\n    color: white;\n  }\n  \n  .p-911 {\n    text-align: center;\n    color: #f00;\n    font-weight: bold;\n  }\n  \n\n  .table-responsive {\n    overflow-x: visible;\n  }\n\n  \n  thead th {\n    min-width: 100px; /* Ensure minimum width for each header cell */\n  }\n\n  .content {\n    width: fit-content;\n    padding: 20px;\n    background-color: #fff; /* Background behind your table */\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adds shadow effect behind the table */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
