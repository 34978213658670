// SendNotification.jsx
import React, { useState, useEffect } from 'react';
import { 
    Bell, Mail, Send, X, Search,
} from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

export default function SendNotification({ show, onClose, onSend }) {
    const [notificationData, setNotificationData] = useState({
        title: '',
        message: '',
        type: 'info',
        priority: 'normal',
        sendTo: [],
        notificationMethod: ['app'],
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState({ publishers: [], advertisers: [] });
    const [filters, setFilters] = useState({
        advertiserSpent: 'all',      // Options: all, 0-10k, 10k-50k, 50k+
        publisherDomains: 'all',     // Options: all, 1-5, 6-10, 11+
        publisherAuthority: 'all',   // Options: all, 0-30, 31-60, 61-100
    });

    // Sample data - replace with your API call
    const sampleUsers = {
        publishers: [
            { id: 'pub1', name: 'Publisher One', email: 'pub1@example.com', numberOfDomains: 5, domainAuthority: 45 },
            { id: 'pub2', name: 'Publisher Two', email: 'pub2@example.com', numberOfDomains: 12, domainAuthority: 60 },
            { id: 'pub3', name: 'Publisher Three', email: 'pub3@example.com', numberOfDomains: 3, domainAuthority: 30 },
            // Add more publishers as needed
        ],
        advertisers: [
            { id: 'adv1', name: 'Advertiser One', email: 'adv1@example.com', totalSpent: 25000 },
            { id: 'adv2', name: 'Advertiser Two', email: 'adv2@example.com', totalSpent: 50000 },
            { id: 'adv3', name: 'Advertiser Three', email: 'adv3@example.com', totalSpent: 15000 },
            // Add more advertisers as needed
        ]
    };

    useEffect(() => {
        // Filter users based on search term and additional filters
        const filtered = {
            publishers: sampleUsers.publishers.filter(user => {
                const matchesSearch = user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                      user.email.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesDomains = filters.publisherDomains === 'all' ||
                    (filters.publisherDomains === '1-5' && user.numberOfDomains >=1 && user.numberOfDomains <=5) ||
                    (filters.publisherDomains === '6-10' && user.numberOfDomains >=6 && user.numberOfDomains <=10) ||
                    (filters.publisherDomains === '11+' && user.numberOfDomains >=11);
                const matchesAuthority = filters.publisherAuthority === 'all' ||
                    (filters.publisherAuthority === '0-30' && user.domainAuthority >=0 && user.domainAuthority <=30) ||
                    (filters.publisherAuthority === '31-60' && user.domainAuthority >=31 && user.domainAuthority <=60) ||
                    (filters.publisherAuthority === '61-100' && user.domainAuthority >=61 && user.domainAuthority <=100);
                return matchesSearch && matchesDomains && matchesAuthority;
            }),
            advertisers: sampleUsers.advertisers.filter(user => {
                const matchesSearch = user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                      user.email.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesSpent = filters.advertiserSpent === 'all' ||
                    (filters.advertiserSpent === '0-10k' && user.totalSpent >=0 && user.totalSpent <=10000) ||
                    (filters.advertiserSpent === '10k-50k' && user.totalSpent >=10001 && user.totalSpent <=50000) ||
                    (filters.advertiserSpent === '50k+' && user.totalSpent >=50001);
                return matchesSearch && matchesSpent;
            })
        };
        setFilteredUsers(filtered);
    }, [searchTerm, filters]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSend({
            ...notificationData,
            sendTo: selectedRecipients.map(r => r.id)
        });
        onClose();
    };

    const toggleRecipient = (recipient) => {
        setSelectedRecipients(prev => {
            const isSelected = prev.find(r => r.id === recipient.id);
            if (isSelected) {
                return prev.filter(r => r.id !== recipient.id);
            }
            return [...prev, recipient];
        });
    };

    const handleSelectAll = (group) => {
        setSelectedRecipients(prev => {
            const currentIds = prev.map(r => r.id);
            const groupUsers = sampleUsers[group];
            const newRecipients = [...prev];

            groupUsers.forEach(user => {
                if (!currentIds.includes(user.id)) {
                    newRecipients.push(user);
                }
            });

            return newRecipients;
        });
    };

    const handleClearAll = () => {
        setFilters({
            advertiserSpent: 'all',
            publisherDomains: 'all',
            publisherAuthority: 'all',
        });
        setSearchTerm('');
        setSelectedRecipients([]);
    };

    if (!show) return null;

    return (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="sendNotificationModal" aria-modal="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    {/* Modal Header */}
                    <div className="modal-header">
                        <h5 className="modal-title" id="sendNotificationModal">Send Notification</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                    </div>

                    {/* Modal Body */}
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="notificationTitle" className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="notificationTitle"
                                    value={notificationData.title}
                                    onChange={(e) => setNotificationData({
                                        ...notificationData,
                                        title: e.target.value
                                    })}
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="notificationMessage" className="form-label">Message</label>
                                <textarea
                                    className="form-control"
                                    id="notificationMessage"
                                    rows="4"
                                    value={notificationData.message}
                                    onChange={(e) => setNotificationData({
                                        ...notificationData,
                                        message: e.target.value
                                    })}
                                    required
                                ></textarea>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="notificationType" className="form-label">Type</label>
                                    <select
                                        className="form-select"
                                        id="notificationType"
                                        value={notificationData.type}
                                        onChange={(e) => setNotificationData({
                                            ...notificationData,
                                            type: e.target.value
                                        })}
                                    >
                                        <option value="info">Information</option>
                                        <option value="success">Success</option>
                                        <option value="warning">Warning</option>
                                        <option value="alert">Alert</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="notificationPriority" className="form-label">Priority</label>
                                    <select
                                        className="form-select"
                                        id="notificationPriority"
                                        value={notificationData.priority}
                                        onChange={(e) => setNotificationData({
                                            ...notificationData,
                                            priority: e.target.value
                                        })}
                                    >
                                        <option value="low">Low</option>
                                        <option value="normal">Normal</option>
                                        <option value="high">High</option>
                                        <option value="urgent">Urgent</option>
                                    </select>
                                </div>
                            </div>

                            {/* Recipients Section */}
                            <div className="mb-3">
                                <label className="form-label">Recipients</label>
                                <div className="card p-3">
                                    {/* Filters */}
                                    <div className="mb-3">
                                        <h6>Filters</h6>
                                        <div className="row">
                                            {/* Advertiser $ Spent Filter */}
                                            <div className="col-md-4">
                                                <label htmlFor="filterAdvertiserSpent" className="form-label">Advertiser $ Spent</label>
                                                <select
                                                    className="form-select"
                                                    id="filterAdvertiserSpent"
                                                    value={filters.advertiserSpent}
                                                    onChange={(e) => setFilters({
                                                        ...filters,
                                                        advertiserSpent: e.target.value
                                                    })}
                                                >
                                                    <option value="all">All</option>
                                                    <option value="0-10k">$0 - $10k</option>
                                                    <option value="10k-50k">$10k - $50k</option>
                                                    <option value="50k+">$50k+</option>
                                                </select>
                                            </div>
                                            {/* Publisher Number of Domains Filter */}
                                            <div className="col-md-4">
                                                <label htmlFor="filterPublisherDomains" className="form-label">Publisher Domains</label>
                                                <select
                                                    className="form-select"
                                                    id="filterPublisherDomains"
                                                    value={filters.publisherDomains}
                                                    onChange={(e) => setFilters({
                                                        ...filters,
                                                        publisherDomains: e.target.value
                                                    })}
                                                >
                                                    <option value="all">All</option>
                                                    <option value="1-5">1 - 5</option>
                                                    <option value="6-10">6 - 10</option>
                                                    <option value="11+">11+</option>
                                                </select>
                                            </div>
                                            {/* Publisher Domain Authority Filter */}
                                            <div className="col-md-4">
                                                <label htmlFor="filterPublisherAuthority" className="form-label">Domain Authority</label>
                                                <select
                                                    className="form-select"
                                                    id="filterPublisherAuthority"
                                                    value={filters.publisherAuthority}
                                                    onChange={(e) => setFilters({
                                                        ...filters,
                                                        publisherAuthority: e.target.value
                                                    })}
                                                >
                                                    <option value="all">All</option>
                                                    <option value="0-30">0 - 30</option>
                                                    <option value="31-60">31 - 60</option>
                                                    <option value="61-100">61 - 100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Search Input */}
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="searchRecipients">
                                            <Search className="text-muted" />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search recipients..."
                                            aria-label="Search recipients"
                                            aria-describedby="searchRecipients"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>

                                    {/* Quick Actions */}
                                    <div className="d-flex justify-content-end mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary me-2"
                                            onClick={() => handleSelectAll('publishers')}
                                        >
                                            Select All Publishers
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary me-2"
                                            onClick={() => handleSelectAll('advertisers')}
                                        >
                                            Select All Advertisers
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={handleClearAll}
                                        >
                                            Clear All
                                        </button>
                                    </div>

                                    {/* Recipients List */}
                                    <div className="border rounded p-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        {/* Publishers */}
                                        <div className="fw-bold mb-2">Publishers</div>
                                        {filteredUsers.publishers.length > 0 ? (
                                            filteredUsers.publishers.map((publisher) => (
                                                <div
                                                    key={publisher.id}
                                                    className="form-check mb-2"
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={publisher.id}
                                                        id={`publisher-${publisher.id}`}
                                                        checked={selectedRecipients.some(r => r.id === publisher.id)}
                                                        onChange={() => toggleRecipient(publisher)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`publisher-${publisher.id}`}>
                                                        {publisher.name} ({publisher.email})
                                                    </label>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-muted">No publishers found.</p>
                                        )}

                                        {/* Advertisers */}
                                        <div className="fw-bold mb-2 mt-3">Advertisers</div>
                                        {filteredUsers.advertisers.length > 0 ? (
                                            filteredUsers.advertisers.map((advertiser) => (
                                                <div
                                                    key={advertiser.id}
                                                    className="form-check mb-2"
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={advertiser.id}
                                                        id={`advertiser-${advertiser.id}`}
                                                        checked={selectedRecipients.some(r => r.id === advertiser.id)}
                                                        onChange={() => toggleRecipient(advertiser)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`advertiser-${advertiser.id}`}>
                                                        {advertiser.name} ({advertiser.email})
                                                    </label>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-muted">No advertisers found.</p>
                                        )}
                                    </div>

                                    {/* Selected Recipients */}
                                    {selectedRecipients.length > 0 && (
                                        <div className="mt-3">
                                            <h6>Selected Recipients ({selectedRecipients.length})</h6>
                                            <ul className="list-group">
                                                {selectedRecipients.map((recipient) => (
                                                    <li key={recipient.id} className="list-group-item d-flex justify-content-between align-items-center">
                                                        {recipient.name} ({recipient.email})
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-outline-danger"
                                                            onClick={() => toggleRecipient(recipient)}
                                                        >
                                                            <X className="w-4 h-4" />
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Notification Method */}
                            <div className="mb-3">
                                <label className="form-label">Notification Method</label>
                                <div className="d-flex gap-3">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="app"
                                            id="methodApp"
                                            checked={notificationData.notificationMethod.includes('app')}
                                            onChange={(e) => {
                                                const methods = e.target.checked
                                                    ? [...notificationData.notificationMethod, 'app']
                                                    : notificationData.notificationMethod.filter(m => m !== 'app');
                                                setNotificationData({
                                                    ...notificationData,
                                                    notificationMethod: methods
                                                });
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="methodApp">
                                            <Bell className="me-2" />
                                            App Notification
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="email"
                                            id="methodEmail"
                                            checked={notificationData.notificationMethod.includes('email')}
                                            onChange={(e) => {
                                                const methods = e.target.checked
                                                    ? [...notificationData.notificationMethod, 'email']
                                                    : notificationData.notificationMethod.filter(m => m !== 'email');
                                                setNotificationData({
                                                    ...notificationData,
                                                    notificationMethod: methods
                                                });
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="methodEmail">
                                            <Mail className="me-2" />
                                            Email
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Modal Footer */}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>
                                <X className="me-2" />
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary">
                                <Send className="me-2" />
                                Send Notification
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
