import React, { useState, useEffect } from 'react';
import { BASE_API_URL } from './config/constants';

const PromptEditor = () => {
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [error, setError] = useState('');

  const fetchPrompts = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/Prompt`);
      if (!response.ok) throw new Error('Failed to fetch prompts');
      const data = await response.json();
      setPrompts(data);
    } catch (err) {
      setError('Failed to load prompts');
    }
  };

  useEffect(() => {
    fetchPrompts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = selectedPrompt?.id 
        ? `${BASE_API_URL}api/Prompt/${selectedPrompt.id}`
        : `${BASE_API_URL}api/Prompt`;
      
      const response = await fetch(url, {
        method: selectedPrompt?.id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedPrompt)
      });

      if (!response.ok) throw new Error('Failed to save prompt');
      
      fetchPrompts();
      setIsEditing(false);
      setSelectedPrompt(null);
    } catch (err) {
      setError('Failed to save prompt');
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/Prompt/${selectedPrompt.id}`, {
        method: 'DELETE'
      });
      
      if (!response.ok) throw new Error('Failed to delete prompt');
      
      fetchPrompts();
      setShowDeleteDialog(false);
      setSelectedPrompt(null);
    } catch (err) {
      setError('Failed to delete prompt');
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <button 
          onClick={() => {
            setSelectedPrompt({ promptText: '', usedFor: '' });
            setIsEditing(true);
          }}
          className="btn btn-primary"
        >
          New Prompt
        </button>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      
      {isEditing ? (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div className="form-group">
            <label htmlFor="promptText" style={{ marginBottom: '8px', fontWeight: 'bold' }}>Prompt Text</label>
            <textarea
              id="promptText"
              className="form-control"
              value={selectedPrompt?.promptText || ''}
              onChange={e => setSelectedPrompt({...selectedPrompt, promptText: e.target.value})}
              placeholder="Enter prompt text"
              rows={10}
              style={{ 
                resize: 'vertical',
                minHeight: '200px',
                fontFamily: 'monospace',
                fontSize: '14px',
                lineHeight: '1.5',
                padding: '12px'
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="usedFor" style={{ marginBottom: '8px', fontWeight: 'bold' }}>Used For</label>
            <input
              id="usedFor"
              className="form-control"
              value={selectedPrompt?.usedFor || ''}
              onChange={e => setSelectedPrompt({...selectedPrompt, usedFor: e.target.value})}
              placeholder="Used for"
            />
          </div>
          <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setIsEditing(false);
                setSelectedPrompt(null);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </form>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {prompts.map(prompt => (
            <div 
              key={prompt.id} 
              className="card"
              style={{ marginBottom: '10px' }}
            >
              <div className="card-body d-flex justify-content-between align-items-start">
                <div style={{ flex: 1, marginRight: '16px' }}>
                  <pre style={{ 
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    margin: 0,
                    fontFamily: 'monospace',
                    fontSize: '14px',
                    lineHeight: '1.5'
                  }}>
                    {prompt.promptText}
                  </pre>
                  <small className="text-muted">Used for: {prompt.usedFor}</small>
                </div>
                <div className="btn-group">
                  <button
                    onClick={() => {
                      setSelectedPrompt(prompt);
                      setIsEditing(true);
                    }}
                    className="btn btn-outline-secondary btn-sm"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      setSelectedPrompt(prompt);
                      setShowDeleteDialog(true);
                    }}
                    className="btn btn-outline-danger btn-sm"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {showDeleteDialog && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '24px',
            borderRadius: '8px',
            maxWidth: '400px'
          }}>
            <h3 style={{ marginTop: 0 }}>Are you sure you want to delete this prompt?</h3>
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
              <button
                onClick={() => setShowDeleteDialog(false)}
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: '1px solid #ddd',
                  backgroundColor: 'white',
                  cursor: 'pointer'
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  backgroundColor: '#dc2626',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptEditor;