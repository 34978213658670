// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-layout {
  display: flex;
  padding: 20px;
  min-height: 100vh;
}

.container {
  display: flex;
  width: 100%;
}


.content {
  flex-grow: 1;
  margin-left: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input, 
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-group {
  margin-top: 20px;
}

.button-group .save-btn, 
.button-group .cancel-btn {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-group .cancel-btn {
  background-color: #6c757d;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background-color: #f1f1f1;
  cursor: pointer;
  border-radius: 5px;
}

.tabs button.active {
  background-color: #007bff;
  color: white;
}

.error {
  color: red;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageLayout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;;AAGA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".page-layout {\n  display: flex;\n  padding: 20px;\n  min-height: 100vh;\n}\n\n.container {\n  display: flex;\n  width: 100%;\n}\n\n\n.content {\n  flex-grow: 1;\n  margin-left: 20px;\n  padding: 20px;\n  background-color: #fff;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n\n.form-group {\n  margin-bottom: 15px;\n}\n\n.form-group label {\n  display: block;\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n\n.form-group input, \n.form-group select {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.button-group {\n  margin-top: 20px;\n}\n\n.button-group .save-btn, \n.button-group .cancel-btn {\n  padding: 10px 20px;\n  margin-right: 10px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.button-group .cancel-btn {\n  background-color: #6c757d;\n}\n\n.tabs {\n  display: flex;\n  gap: 10px;\n  margin-bottom: 20px;\n}\n\n.tabs button {\n  padding: 10px 20px;\n  border: none;\n  background-color: #f1f1f1;\n  cursor: pointer;\n  border-radius: 5px;\n}\n\n.tabs button.active {\n  background-color: #007bff;\n  color: white;\n}\n\n.error {\n  color: red;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
