import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import logoImage from './assets/images/Link-Building-Software.png';
import axios from 'axios';
import { useAuth } from './authService';
import { BASE_API_URL } from '../src/config/constants';
import toast from 'react-hot-toast';
import { Alert } from 'react-bootstrap';

const EmailVerification = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(180); // 180 seconds = 3 minutes
  const [canResend, setCanResend] = useState(false);
  const [formData, setFormData] = useState({
    code: ''
  });

  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState(''); //bootstrap alert

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          setCanResend(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${BASE_API_URL}api/verificationCode/${user.email}/${formData.code}`);
      if (response.data.valid !== true) {
        setError('Code is not valid');
      } else {
        setSuccess("Verification code is valid");
        setTimeout(() => {
          navigate(`/${user.userType}`);
        }, 2000);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Code is not valid!');
    }
  };

  const handleResend = async () => {
    try {
      const formData = {
        email: user.email,
      };
      await axios.post(`${BASE_API_URL}api/verificationCode`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setSuccess('Verification code resent successfully');
      setTimeLeft(180);
      setCanResend(false);
    } catch (error) {
      console.error('Error resending code:', error);
      setError('Failed to resend verification code');
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="container1">
      <div className="left-side">
        <div className="content">
        {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}
          <div className="logo-container">
            <img src={logoImage} alt="AdverLink" />
          </div>
          <h3 style={{fontSize:'2.5rem'}} className="my-5">Confirm your email</h3>
          <h5 style={{color:'gray'}}>A verification code has been sent to your email. Enter the code</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-group my-4 w-50">
              <input
                onChange={handleChange}
                name='code'
                type="text"
                placeholder="Enter code"
                required
              />
            </div>
            {!canResend && (
              <h5 style={{color:'gray'}}>
                You can resend the code in {formatTime(timeLeft)}
              </h5>
            )}
            <button type="submit" className="btn m-4 w-75 btn-primary">Confirm email</button>
          </form>
          {canResend && (
            <button 
              onClick={handleResend} 
              className="btn btn-secondary"
            >
              Resend Code
            </button>
          )}
          <p>
            Haven't received an email? Please check your spam folder or contact us at register@adverlink.net
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;