import React from 'react';
import './Footer.css'; // You can create this file to style your footer

const Footer = () => {
  return (
    <footer className="footer">
      <p>Copyright 2024 © Aysa SEO SRL. All rights reserved.</p>
    </footer>
  );
};

export default Footer;