// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27%23333%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style all select dropdowns to show arrow */
select.form-control,
.form-control select,
select.form-select {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 12px) center !important;
  background-size: 12px !important;
  padding-right: 30px !important;
  cursor: pointer !important;
  background-color: #fff !important;
}

select.form-control:focus,
.form-control select:focus,
select.form-select:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-color: #80bdff !important;
} `, "",{"version":3,"sources":["webpack://./src/styles/dropdowns.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;;;EAGE,2BAA2B;EAC3B,mCAAmC;EACnC,gCAAgC;EAChC,oEAAoS;EACpS,uCAAuC;EACvC,wDAAwD;EACxD,gCAAgC;EAChC,8BAA8B;EAC9B,0BAA0B;EAC1B,iCAAiC;AACnC;;AAEA;;;EAGE,2DAA2D;EAC3D,gCAAgC;AAClC","sourcesContent":["/* Style all select dropdowns to show arrow */\nselect.form-control,\n.form-control select,\nselect.form-select {\n  appearance: none !important;\n  -webkit-appearance: none !important;\n  -moz-appearance: none !important;\n  background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E\") !important;\n  background-repeat: no-repeat !important;\n  background-position: calc(100% - 12px) center !important;\n  background-size: 12px !important;\n  padding-right: 30px !important;\n  cursor: pointer !important;\n  background-color: #fff !important;\n}\n\nselect.form-control:focus,\n.form-control select:focus,\nselect.form-select:focus {\n  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;\n  border-color: #80bdff !important;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
