import React, { useState, useEffect } from 'react';
import { BASE_API_URL } from './config/constants';

 

const PromptEditor = () => {
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [error, setError] = useState('');

  const fetchPrompts = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/Prompt`);
      if (!response.ok) throw new Error('Failed to fetch prompts');
      const data = await response.json();
      setPrompts(data);
    } catch (err) {
      setError('Failed to load prompts');
    }
  };

  useEffect(() => {
    fetchPrompts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = selectedPrompt?.id 
        ? `${BASE_API_URL}api/Prompt/${selectedPrompt.id}`
        : `${BASE_API_URL}api/Prompt`;
      
      const response = await fetch(url, {
        method: selectedPrompt?.id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedPrompt)
      });

      if (!response.ok) throw new Error('Failed to save prompt');
      
      fetchPrompts();
      setIsEditing(false);
      setSelectedPrompt(null);
    } catch (err) {
      setError('Failed to save prompt');
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/Prompt/${selectedPrompt.id}`, {
        method: 'DELETE'
      });
      
      if (!response.ok) throw new Error('Failed to delete prompt');
      
      fetchPrompts();
      setShowDeleteDialog(false);
      setSelectedPrompt(null);
    } catch (err) {
      setError('Failed to delete prompt');
    }
  };

  return (
    <div className="container" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <div className="card" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <h2 style={{ margin: 0 }}>Prompt Management</h2>
          <button 
            onClick={() => {
              setSelectedPrompt({ promptText: '', usedFor: '' });
              setIsEditing(true);
            }}
            style={{
              backgroundColor: '#0066cc',
              color: 'white',
              border: 'none',
              padding: '8px 16px',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            New Prompt
          </button>
        </div>

        {error && (
          <div style={{ 
            backgroundColor: '#fee2e2', 
            color: '#dc2626', 
            padding: '12px', 
            borderRadius: '4px',
            marginBottom: '20px' 
          }}>
            {error}
          </div>
        )}
        
        {isEditing ? (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <input
              value={selectedPrompt?.promptText || ''}
              onChange={e => setSelectedPrompt({...selectedPrompt, promptText: e.target.value})}
              placeholder="Enter prompt text"
              style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
            />
            <input
              value={selectedPrompt?.usedFor || ''}
              onChange={e => setSelectedPrompt({...selectedPrompt, usedFor: e.target.value})}
              placeholder="Used for"
              style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
            />
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
              <button
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  setSelectedPrompt(null);
                }}
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: '1px solid #ddd',
                  backgroundColor: 'white',
                  cursor: 'pointer'
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  backgroundColor: '#0066cc',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {prompts.map(prompt => (
              <div 
                key={prompt.id} 
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px',
                  border: '1px solid #ddd',
                  borderRadius: '4px'
                }}
              >
                <div>
                  <p style={{ margin: '0 0 4px 0' }}>{prompt.promptText}</p>
                  <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>
                    Used for: {prompt.usedFor}
                  </p>
                </div>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <button
                    onClick={() => {
                      setSelectedPrompt(prompt);
                      setIsEditing(true);
                    }}
                    style={{
                      padding: '4px 8px',
                      borderRadius: '4px',
                      border: '1px solid #ddd',
                      backgroundColor: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      setSelectedPrompt(prompt);
                      setShowDeleteDialog(true);
                    }}
                    style={{
                      padding: '4px 8px',
                      borderRadius: '4px',
                      border: '1px solid #dc2626',
                      backgroundColor: 'white',
                      color: '#dc2626',
                      cursor: 'pointer'
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {showDeleteDialog && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '24px',
            borderRadius: '8px',
            maxWidth: '400px'
          }}>
            <h3 style={{ marginTop: 0 }}>Are you sure you want to delete this prompt?</h3>
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
              <button
                onClick={() => setShowDeleteDialog(false)}
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: '1px solid #ddd',
                  backgroundColor: 'white',
                  cursor: 'pointer'
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  backgroundColor: '#dc2626',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptEditor;