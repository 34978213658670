// CalculatorHelper.js
const LinkType = {
    NormalFollow: "NormalFollow",
    NoFollow: "NoFollow",
    Sponsored: "Sponsored",
    UGC: "UGC",
    GeneralKeyword: "GeneralKeyword",
  };
  
  const getLinkTypeMultiplier = (linkType) => {
    switch (linkType) {
      case LinkType.NormalFollow:
        return 1;
      case LinkType.NoFollow:
        return 0.5;
      case LinkType.Sponsored:
        return 0.3;
      case LinkType.UGC:
        return 0.1;
      case LinkType.GeneralKeyword:
        return 0.7;
      default:
        throw new Error("Invalid link type");
    }
  };


  const getVAT = (amount, rate = 0.19) => {
    if (typeof amount !== 'number' || amount < 0) {
      throw new Error("Invalid amount for VAT calculation");
    }
    return amount * rate;
  };


  export { LinkType, getLinkTypeMultiplier, getVAT };
  