import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_API_URL } from '../src/config/constants';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner';
import { Alert } from 'react-bootstrap';
import { Upload, Download, FileText } from 'lucide-react';
import './PublisherReports.css';
import { jwtDecode } from 'jwt-decode';

const PublisherReports = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [publisherReports, setPublisherReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploading, setUploading] = useState([]);
  const [files, setFiles] = useState([]);

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const token = localStorage.getItem('token');
  const userId = getUserIdFromToken(token);

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const [reportsResponse, invoicesResponse] = await Promise.all([
          axios.get(`${BASE_API_URL}api/linksreports/get-publisher-reports`, {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/get-user-invoices`, {
            headers: { 'Authorization': `Bearer ${token}` }
          })
        ]);
    
        const reports = reportsResponse.data;
        const invoices = invoicesResponse.data;
    
        // Debugging Logs
        console.log("Reports:", reports);
        console.log("Invoices:", invoices);
    
        // Combine reports with invoices
        const combinedReports = reports.map(report => {
          const matchingInvoice = invoices.find(invoice => 
            isValidDate(invoice.uploadDate) && 
            isValidDate(report.dateStart) && 
            isValidDate(report.dateEnd) &&
            new Date(invoice.uploadDate) >= new Date(report.dateStart) &&
            new Date(invoice.uploadDate) <= new Date(report.dateEnd)
          );
    
          return { ...report, invoice: matchingInvoice };
        });
    
        setPublisherReports(combinedReports);
        console.log("Combined Reports:", combinedReports);
        setSuccess('Data fetched successfully!');
      } catch (error) {
        setError("Failed to fetch data.");
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDownloadInvoice = async (invoiceId, fileName) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/download/${invoiceId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading invoice:", error);
      setError("Failed to download invoice.");
    }
  };

  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];
    setFiles(newFiles);
  };

  const handleFileUpload = async (e, index) => {
    e.preventDefault();

    if (!files[index]) {
      setError('Please select a file before uploading.');
      return;
    }

    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('invoiceFile', files[index]);
    formData.append('dateStart', publisherReports[index].dateStart);
    formData.append('dateEnd', publisherReports[index].dateEnd);

    const currentUploading = [...uploading];
    currentUploading[index] = true;
    setUploading(currentUploading);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${BASE_API_URL}api/PublisherInvoiceUpload/upload`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setSuccess('Invoice uploaded successfully!');
        // Update the publisherReports state with the new invoice
        const updatedReports = [...publisherReports];
        updatedReports[index].invoice = response.data;
        setPublisherReports(updatedReports);
        
        setFiles(prevFiles => {
          const newFiles = [...prevFiles];
          newFiles[index] = null;
          return newFiles;
        });
      } else {
        setError(`Failed to upload invoice. Status code: ${response.status}`);
      }
    } catch (error) {
      setError('Failed to upload invoice.');
      console.error('Error uploading invoice:', error);
    } finally {
      currentUploading[index] = false;
      setUploading(currentUploading);
    }
  };

  return (
    <PageLayout>
      <div className="publisher-reports">
        <div className="container-fluid">
          <h3 className="publisher-reports__header">Publisher Reports</h3>
          {isLoading ? (
            <MySpinner />
          ) : (
            <>
              {error && (
                <Alert variant="danger" onClose={() => setError('')} dismissible className="publisher-reports__alert">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert variant="success" onClose={() => setSuccess('')} dismissible className="publisher-reports__alert">
                  {success}
                </Alert>
              )}
              <div className="publisher-reports__table-responsive">
                <table className="publisher-reports__table table table-striped table-hover">
                  <thead className="publisher-reports__thead">
                    <tr>
                      <th className="publisher-reports__th">Start Date</th>
                      <th className="publisher-reports__th">End Date</th>
                      <th className="publisher-reports__th">Amount</th>
                      <th className="publisher-reports__th">Number of Orders</th>
                      <th className="publisher-reports__th">Invoice Actions</th>
                    </tr>
                  </thead>
                  <tbody className="publisher-reports__tbody">
                    {publisherReports.map((report, index) => (
                      <tr key={index}>
                        <td className="publisher-reports__td">{new Date(report.dateStart).toLocaleDateString()}</td>
                        <td className="publisher-reports__td">{new Date(report.dateEnd).toLocaleDateString()}</td>
                        <td className="publisher-reports__td">${report.amount.toFixed(2)}</td>
                        <td className="publisher-reports__td">{report.orders.length}</td>
                        <td className="publisher-reports__td">
                          <div className="publisher-reports__invoice-actions">
                            {report.invoice ? (
                              <>
                                <button 
                                  className="publisher-reports__download-btn"
                                  onClick={() => handleDownloadInvoice(report.invoice.id, report.invoice.fileName)}
                                >
                                  <Download size={16} />
                                  {/* <span>{report.invoice.fileName}</span> */}
                                </button>
                                {/* <span className="publisher-reports__file-name">
                                  <FileText size={16} />
                                  {report.invoice.fileName}
                                </span> */}
                              </>
                            ) : (
                              <form onSubmit={(e) => handleFileUpload(e, index)} className="publisher-reports__upload-form">
                                <div className="publisher-reports__file-input-wrapper">
                                  <input
                                    type="file"
                                    id={`fileInput-${index}`}
                                    className="publisher-reports__file-input"
                                    accept="application/pdf"
                                    onChange={(e) => handleFileChange(e, index)}
                                  />
                                  <label htmlFor={`fileInput-${index}`} className="publisher-reports__file-label">
                                    <Upload size={16} />
                                    {/* <span>Choose file</span> */}
                                  </label>
                                </div>
                                <button type="submit" className="publisher-reports__submit-btn" disabled={uploading[index] || !files[index]}>
                                  {uploading[index] ? 'Uploading...' : 'Submit'}
                                </button>
                              </form>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default PublisherReports;